import { takeLatest } from "redux-saga/effects";

import * as ActionsTypes from "../../../../common/state/StatesConstants";
import {
  addMissingCompanySaga,
  deleteMissingCompanySaga,
  editMissingCompanySaga,
  getMissingCompaniesSaga,
  updateMissingCompanySaga,
  get,
  getRapportSaga
} from "./MissingCompaniesSaga";

export default function* MissingCompaniesWatcher() {
  yield takeLatest(ActionsTypes.GET_MISSING_COMPANIES, getMissingCompaniesSaga);
  yield takeLatest(ActionsTypes.ADD_MISSING_COMPANY, addMissingCompanySaga);
  yield takeLatest(ActionsTypes.EDIT_MISSING_COMPANY, editMissingCompanySaga);
  yield takeLatest(ActionsTypes.UPDATE_MISSING_COMPANY, updateMissingCompanySaga);
  yield takeLatest(ActionsTypes.DELETE_MISSING_COMPANY, deleteMissingCompanySaga);
  yield takeLatest(ActionsTypes.DOWNLOAD_RAPPORT, getRapportSaga);
}

import React, { useEffect } from "react";
import MDBox from "components/MDBox";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/state/CountryProfilesAction";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import { Box, Icon, IconButton } from "@mui/material";
import MDButton from "components/MDButton";
import * as modalActions from "../modalActionTypes";

export default function Data(setOpen, setCountryProfileId, setAction, countrySlug) {
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(countrySlug);
    dispatch(actions.getCountryProfiles({ countrySlug: countrySlug }));
  }, [countrySlug]);

  const handleOpenDelete = (id) => {
    setOpen(true);
    setCountryProfileId(id);
    setAction(modalActions.DELETE);
  };
  const handleOpenEdit = (id) => {
    // console.log("edit id", id);
    setOpen(true);
    setCountryProfileId(id);
    setAction(modalActions.UPDATE);
  };

  const countryProfiles = useSelector((state) => state?.countryProfiles?.countryProfiles);

  const usersRows = countryProfiles && countryProfiles?.data?.map((countryProfile, i) => ({
    country: countryProfile?.country?.name,
    year: countryProfile?.year,
    population: countryProfile?.population,
    pib_taux_fix: countryProfile?.pib_taux_fix,
    pib_taux_crois: countryProfile?.pib_taux_crois,
    pib_tete: countryProfile?.pib_tete,
    taux_inflation: countryProfile?.taux_inflation,
    equ_budgetaire: countryProfile?.equ_budgetaire,
    dette_publique: countryProfile?.dette_publique,
    chomage: countryProfile?.chomage,
    balance_paiement_courant: countryProfile?.balance_paiement_courant,
    balance_comerciale: countryProfile?.balance_comerciale,
    reserve_internationale: countryProfile?.reserve_internationale,
    action: (
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box>
          <IconButton
            color="info"
            onClick={() => handleOpenEdit(countryProfile?.id)}
            component="label"
          >
            <Icon color="info" fontSize="small">
              mode
            </Icon>
          </IconButton>
        </Box>
        <Box>
          <IconButton
            color="primary"
            onClick={() => handleOpenDelete(countryProfile?.id)}
            component="label"
          >
            <Icon color="primary" fontSize="small">
              delete
            </Icon>
          </IconButton>
        </Box>
      </Box>
    ),
  }));

  return {
    columns: [
      { Header: "Pays", accessor: "country", align: "left" },
      { Header: "Annee", accessor: "year", align: "left" },
      { Header: "Population", accessor: "population", align: "left" },
      { Header: "PIB a taux fix", accessor: "pib_taux_fix", align: "left" },
      { Header: "Taux de crois reel de PIB", accessor: "pib_taux_crois", align: "left" },
      { Header: "PIB par tete", accessor: "pib_tete", align: "left" },
      { Header: "Taux d'inflation", accessor: "taux_inflation", align: "left" },
      { Header: "Equ budgetaire", accessor: "equ_budgetaire", align: "left" },
      { Header: "Dette publique", accessor: "dette_publique", align: "left" },
      { Header: "Chomage", accessor: "chomage", align: "left" },
      {
        Header: "Balance des paiements courants",
        accessor: "balance_paiement_courant",
        align: "left",
      },
      { Header: "Balance commerciale", accessor: "balance_comerciale", align: "left" },
      { Header: "Reserves internationales", accessor: "reserve_internationale", align: "left" },
      { Header: "Acion", accessor: "action", align: "center" },
    ],

    rows: usersRows,
  };
}

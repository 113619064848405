import React, { useEffect } from "react";
import MDBox from "components/MDBox";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/state/DirectorsAction";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import { Box, Icon, IconButton } from "@mui/material";
import MDButton from "components/MDButton";
import * as modalActions from "../modalActionTypes";
import { useLocation } from "react-router-dom";

export default function Data(setOpen, setDirectorId, setAction, page) {
  const dispatch = useDispatch();
  const location = useLocation();
  let data = {}
  useEffect(() => {
    if (location?.state?.id) {
      data = { companyId: location?.state?.id };
    }
    //dispatch(actions.getDirectors(data));
    dispatch(actions.getDirectors({...data,page}));
  }, [page]);

  const handleOpenDelete = (id) => {
    setOpen(true);
    setDirectorId(id);
    setAction(modalActions.DELETE);
  };
  const handleOpenEdit = (id) => {
    // console.log("edit id", id);
    setOpen(true);
    setDirectorId(id);
    setAction(modalActions.UPDATE);
  };

  const directors = useSelector((state) => state?.directors?.directors);

  const FullName = ({ first_name, last_name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      {/* <MDAvatar src={image} utilisateur={utilisateur} size="sm" /> */}
      <MDBox lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {first_name} {last_name}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const directorsRows = directors?.data?.map((director, i) => ({
    fullname: <FullName first_name={director.first_name} last_name={director.last_name} />,
    company_id: director?.company?.raison_social,
    associate: director.associate,
    role: (
      <MDBox ml={-1}>
        <MDBadge badgeContent={director.role} color="secondary" variant="gradient" size="sm" />
      </MDBox>
    ),
    action: (
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box>
          <IconButton color="info" onClick={() => handleOpenEdit(director.id)} component="label">
            <Icon color="info" fontSize="small">
              mode
            </Icon>
          </IconButton>
        </Box>
        <Box>
          <IconButton
            color="primary"
            onClick={() => handleOpenDelete(director.id)}
            component="label"
          >
            <Icon color="primary" fontSize="small">
              delete
            </Icon>
          </IconButton>
        </Box>
      </Box>
    ),
  }));

  return {
    columns: [
      { Header: "Nom", accessor: "fullname", align: "left" },
      { Header: "Entreprise", accessor: "company_id", align: "left" },
      { Header: "Associer", accessor: "associate", align: "left" },
      { Header: "Role", accessor: "role", align: "center" },
      // { Header: "active", accessor: "active", align: "left" },
      { Header: "action", accessor: "action", align: "left" },
    ],

    rows: directorsRows,
    data: directors
  };
}

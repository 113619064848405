import { put, call } from "redux-saga/effects";
import * as actions from "../state/AuthAction";
import { loginUserApi } from "../../../../common/services/Apis/authApi";

export function* loginUserSaga(action) {
  console.log("action", action);
  console.log("action", action.successCallBack);

  try {
    const response = yield call(loginUserApi, action.payload.payload);
    if (response) {
      console.log(response);
      yield put(actions.loginUserSuccess(response));
      yield call(action.payload.successCallBack, response);
    }
  } catch (error) {
    yield put(actions.loginUserFail(error));
    yield call(action.payload.failCallBack, error);
  }
}

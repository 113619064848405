import { Alert, Grid, Switch, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMaterialUIController, setLayout } from "context";
import LogoAssurtrade from "../../../assets/images/logo-assurtrade.png";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/state/AuthAction";
import { useFormik } from "formik";
import bgImage from "../../../assets/images/bg-sign-in-basic.jpeg";
import { getErrorMessage } from "common/utils/Helper";

const Login = () => {
  const [, dispatch] = useMaterialUIController();
  const { pathname } = useLocation();
  const [isLogedIn, setIsLogedIn] = useState(false);

  useEffect(() => {
    setLayout(dispatch, "page");
  }, [pathname]);

  const dispatchRedux = useDispatch();
  const [rememberMe, setRememberMe] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [errorSB, setErrorSB] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const btnEnable = () => setEnabled(true);
  const btnDisable = () => setEnabled(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  if(localStorage.getItem("access_token") && localStorage.getItem("access_token")!==""){
    navigate("/recherche");
  }

  const onSubmit = (values) => {
    // e.preventDefault();
    btnDisable();
    localStorage.clear();
    const request = {
      payload: values,
      successCallBack: (response) => {
        localStorage.setItem("access_token", response.data.token);
        closeErrorSB();
        //navigate("/dashboard");
        window.location.reload()
      },
      failCallBack: (error) => {
        setErrorMessage(getErrorMessage(error))
        navigate("/authentication/sign-in");
        openErrorSB();
        btnEnable();
      },
    };
    dispatchRedux(actions.loginUser(request));
  };
  const { handleSubmit, errors, values, handleChange, touched } = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "password",
    },
    // validationSchema: loginSchema,
    onSubmit,
  });
  return (
    // <ThemeProvider theme={theme}>
    <Grid container component="main" sx={{ height: "100vh", backgroundColor: "#e3e8ed" }}>
      <Grid item xs={12} sm={8} md={4} elevation={6}>
        <Box
          sx={{
            my: 8,
            // mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#e3e8ed",
          }}
        >
          <MDBox mb={5}>
            <img src={LogoAssurtrade} height="55px" alt="logo" />
          </MDBox>
          {errorSB && (
            // <Box mt={3}>
            <Alert severity="error" sx={{ width: "70%" }}>
              {/* {alertContent("error")} */}
              {errorMessage}
            </Alert>
          )}
          <Typography my={3} component="h1" variant="h5">
            Se Connecter
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ width: "70%" }}>
            <MDBox mb={2}>
              <MDInput
                name="email"
                type="email"
                label="Email"
                fullWidth
                // error={errors.email ? true : false}
                onChange={handleChange}
                value={values.email}
                // helperText={errors?.email ?? ""}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                name="password"
                type="password"
                label="Mot de passe"
                fullWidth
                // error={errors.password ? true : false}
                onChange={handleChange}
                value={values.password}
                // helperText={errors?.password ?? ""}
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Souviens de moi
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton type="submit" variant="gradient" color="info" fullWidth disabled={!enabled}>
                Se Connecter
              </MDButton>
            </MDBox>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={false}
        sm={4}
        md={8}
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
    </Grid>
    // </ThemeProvider>
  );
};

export default Login;

import { takeLatest } from "redux-saga/effects";

import * as ActionsTypes from "../../../../common/state/StatesConstants";
import {
  addDirectorSaga,
  deleteDirectorSaga,
  editDirectorSaga,
  getDirectorsSaga,
  updateDirectorSaga,
  uploadDirectorSaga,
} from "./DirectorsSaga";

export default function* DirectorsWatcher() {
  yield takeLatest(ActionsTypes.GET_DIRECTORS, getDirectorsSaga);
  yield takeLatest(ActionsTypes.ADD_DIRECTOR, addDirectorSaga);
  yield takeLatest(ActionsTypes.EDIT_DIRECTOR, editDirectorSaga);
  yield takeLatest(ActionsTypes.UPDATE_DIRECTOR, updateDirectorSaga);
  yield takeLatest(ActionsTypes.DELETE_DIRECTOR, deleteDirectorSaga);
  yield takeLatest(ActionsTypes.UPLOAD_DIRECTOR, uploadDirectorSaga);
}

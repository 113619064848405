import * as Yup from "yup";

export const addActifBilanSchema = Yup.object().shape({
  // actif_immobilise: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  immobilisation_incorporelle: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  immobilisation_corporelle: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  autre_actif_immobilise: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  actif_circulant: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  actif_circulant_stock: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  actif_circulant_creance: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  autre_actif_circulant: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  valeur_disponible: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  total_actif: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  date: Yup.date().required("Obligatoire"),
  company_id: Yup.number("La valeur doit être un nombre").integer().required("Obligatoire"),
});

export const updateActifBilanSchema = Yup.object().shape({
  // actif_immobilise: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  immobilisation_incorporelle: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  immobilisation_corporelle: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  autre_actif_immobilise: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  actif_circulant: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  actif_circulant_stock: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  actif_circulant_creance: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  autre_actif_circulant: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  valeur_disponible: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  total_actif: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  date: Yup.date().required("Obligatoire"),
  company_id: Yup.number("La valeur doit être un nombre").integer().required("Obligatoire"),
});

import * as ActionsTypes from "../../../../../../common/state/StatesConstants";

// ##-----------GET_MEMO_BILANS-----------##
export const getMemoBilans = (data) => {
  return { type: ActionsTypes.GET_MEMO_BILANS, payload: data };
};

export const getMemoBilansSuccess = (response) => {
  return { type: ActionsTypes.GET_MEMO_BILANS_SUCCESS, response };
};

export const getMemoBilansFail = (error) => {
  return { type: ActionsTypes.GET_MEMO_BILANS_FAIL, error };
};
// ##-----------END_GET_MEMO_BILANS-----------##

// ##-----------ADD_MEMO_BILAN-----------##

export const addMemoBilan = (data) => {
  return { type: ActionsTypes.ADD_MEMO_BILAN, payload: data };
};

export const addMemoBilanSuccess = (response) => {
  return { type: ActionsTypes.ADD_MEMO_BILAN_SUCCESS, response };
};

export const addMemoBilanFail = (error) => {
  return { type: ActionsTypes.ADD_MEMO_BILAN_FAIL, error };
};
// ##-----------END_ADD_MEMO_BILAN-----------##

// ##-----------EDIT_MEMO_BILAN-----------##
export const editMemoBilan = (data) => {
  return { type: ActionsTypes.EDIT_MEMO_BILAN, payload: data };
};

export const editMemoBilanSuccess = (response) => {
  return { type: ActionsTypes.EDIT_MEMO_BILAN_SUCCESS, response };
};

export const editMemoBilanFail = (error) => {
  return { type: ActionsTypes.EDIT_MEMO_BILAN_FAIL, error };
};
// ##-----------END_EDIT_MEMO_BILAN-----------##

// ##-----------UPDATE_MEMO_BILAN-----------##
export const updateMemoBilan = (data) => {
  return { type: ActionsTypes.UPDATE_MEMO_BILAN, payload: data };
};

export const updateMemoBilanSuccess = (response) => {
  return { type: ActionsTypes.UPDATE_MEMO_BILAN_SUCCESS, response };
};

export const updateMemoBilanFail = (error) => {
  return { type: ActionsTypes.UPDATE_MEMO_BILAN_FAIL, error };
};
// ##-----------END_UPDATE_MEMO_BILAN-----------##

// ##-----------DELETE_MEMO_BILAN-----------##
export const deleteMemoBilan = (data) => {
  return { type: ActionsTypes.DELETE_MEMO_BILAN, payload: data };
};

export const deleteMemoBilanSuccess = (response) => {
  return { type: ActionsTypes.DELETE_MEMO_BILAN_SUCCESS, response };
};

export const deleteMemoBilanFail = (error) => {
  return { type: ActionsTypes.DELETE_MEMO_BILAN_FAIL, error };
};
// ##-----------END_DELETE_MEMO_BILAN-----------##

import {
  Alert,
  Autocomplete,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addCompanySchema } from "../schema";
import * as actions from "../redux/state/CompaniesAction";
import * as actionsCountries from "../../countries/redux/state/CountriesAction";
import * as actionsProductServices from "../../productServices/redux/state/ProductServicesAction";
import * as actionsShareholdings from "../../shareholdings/redux/state/ShareholdingsAction";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers";
import { getErrorMessage } from "common/utils/Helper";
import * as Devises from "common/staticData/Devises";


const isActive = ["inactive","active"];

export default function FormAddCompany({ setOpen }) {
  const dispatch = useDispatch();

  const [errorSB, setErrorSB] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  // order select countries
  const orderCountries = {
    sort: "name",
    order: "asc",
  };
  // order select countries
  const orderProductServices = {
    sort: "name",
    order: "asc",
  };
  // order select countries
  const orderSharholdings = {
    sort: "name",
    order: "asc",
  };
  useEffect(() => {
    dispatch(actionsCountries.getCountries(orderCountries));
    dispatch(actionsProductServices.getProductServices(orderProductServices));
    dispatch(actionsShareholdings.getShareholdings(orderSharholdings));
  }, []);

  const onSubmit = (values) => {
    // console.log("values", values);
    // return false;
    const request = {
      payload: values,
      successCallBack: (response) => {
        closeErrorSB()
        dispatch(actions.getCompanies());
        setOpen(false);
        console.log("response", response);
      },
      failCallBack: (error) => {
        console.log("error", error);
        setErrorMessage(getErrorMessage(error))
        openErrorSB();
      },
    };
    dispatch(actions.addCompany(request));
  };

  
  const devises = Devises.devises;
  Object.keys(devises);

  const countries = useSelector((state) => state?.countries.countries);
  const activities = useSelector((state) => state?.productServices.productServices);
  const shareholdings = useSelector((state) => state?.shareholdings.shareholdings);
  console.log("activities",activities,"shareholdings",shareholdings)
  const handleClose = () => {
    setOpen(false);
  };
  const formik = useFormik({
    initialValues: {
      raison_social: "",
      forme_juridique: "",
      statut: 1,
      numero_rc: "",
      website: "",
      adresse_siege_social: "",
      city: "",
      prefecture: "",
      type_identite: "",
      numero_ice: "",
      country_id: "1",
      date_creation: "",
      product_service_id: "",
      shareholdings: [],
      devise:""
    },
    onSubmit,
    validationSchema: addCompanySchema,
  });
  const { handleSubmit, errors, values, handleChange, setFieldValue } = formik;
  return (
    <MDBox m={1}>
      {errorSB && (
        <Alert severity="error">
          {errorMessage}
        </Alert>
      )}
      <form onSubmit={handleSubmit}>
        <MDBox py={2}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {/* <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="assurtrad_id"
                  label="assurtrad_id"
                  error={errors.assurtrad_id ? true : false}
                  onChange={handleChange}
                  value={values.assurtrad_id}
                  helperText={errors?.assurtrad_id ?? ""}
                />
              </FormControl>
            </Grid> */}
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="raison_social"
                  label="raison social"
                  error={errors.raison_social ? true : false}
                  onChange={handleChange}
                  value={values.raison_social}
                  helperText={errors?.raison_social ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="forme_juridique"
                  label="forme juridique"
                  error={errors.forme_juridique ? true : false}
                  onChange={handleChange}
                  value={values.forme_juridique}
                  helperText={errors?.forme_juridique ?? ""}
                />
              </FormControl>
            </Grid>
            
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <InputLabel id="statut">Statut</InputLabel>
                <Select
                  name="statut"
                  style={{ height: "40px" }}
                  labelId="state_label"
                  label="Statut"
                  error={errors.statut ? true : false}
                  onChange={handleChange}
                  value={values.statut}
                >
                  {isActive?.map((value, id) => {
                    return (
                      <MenuItem key={id} value={id}>
                        {value}
                      </MenuItem>
                    );
                  })}
                </Select>
                {errors.statut ? (
                  <FormHelperText error>{errors?.statut}</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="numero_rc"
                  label="numero rc"
                  error={errors.numero_rc ? true : false}
                  onChange={handleChange}
                  value={values.numero_rc}
                  helperText={errors?.numero_rc ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="website"
                  label="site web"
                  error={errors.website ? true : false}
                  onChange={handleChange}
                  value={values.website}
                  helperText={errors?.website ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="adresse_siege_social"
                  label="adresse siege social"
                  error={errors.adresse_siege_social ? true : false}
                  onChange={handleChange}
                  value={values.adresse_siege_social}
                  helperText={errors?.adresse_siege_social ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="city"
                  label="ville"
                  error={errors.city ? true : false}
                  onChange={handleChange}
                  value={values.city}
                  helperText={errors?.city ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="prefecture"
                  label="prefecture"
                  error={errors.prefecture ? true : false}
                  onChange={handleChange}
                  value={values.prefecture}
                  helperText={errors?.prefecture ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="type_identite"
                  label="type identite"
                  error={errors.type_identite ? true : false}
                  onChange={handleChange}
                  value={values.type_identite}
                  helperText={errors?.type_identite ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="numero_ice"
                  label="numero ice"
                  error={errors.numero_ice ? true : false}
                  onChange={handleChange}
                  value={values.numero_ice}
                  helperText={errors?.numero_ice ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    renderInput={(props) => <TextField {...props} />}
                    value={values.date_creation}
                    name="date_creation"
                    error={errors.date_creation ? true : false}
                    onChange={(newValue) => {
                      setFieldValue("date_creation", moment(newValue).format("YYYY-MM-DD")); // December 22nd 2022, 10:01:13 pm);
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <InputLabel id="country_id">Pays</InputLabel>
                <Select
                  name="country_id"
                  style={{ height: "40px" }}
                  labelId="state_label"
                  label="pays"
                  error={errors.country_id ? true : false}
                  onChange={handleChange}
                  value={values.country_id}
                >
                  {countries?.data?.map((country) => {
                    return (
                      <MenuItem key={country?.id} value={country?.id}>
                        {country?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                {errors.country_id ? (
                  <FormHelperText error>{errors?.country_id}</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
            
            {/*<Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <InputLabel id="product_service_id">Activités</InputLabel>
                <Select
                  name="product_service_id"
                  style={{ height: "40px" }}
                  labelId="state_label"
                  label="Activités"
                  error={errors.product_service_id ? true : false}
                  onChange={handleChange}
                  value={values.product_service_id}
                >
                  {activities && activities?.data?.map((val) => {
                    return (
                      <MenuItem key={val?.id} value={val?.id}>
                        {val?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                {errors.product_service_id ? (
                  <FormHelperText error>{errors?.product_service_id}</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
             */}
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <Autocomplete
                  id="product_service_id"
                  options={activities && activities.data ? activities?.data : []}
                  getOptionLabel={(option) => option.name}
                  //onChange={handleChange}
                  onChange={(e, newValue) => {
                    console.log("new value:::", newValue)
                    formik.setFieldValue(
                      "product_service_id",
                      newValue.id
                    );
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={errors.product_service_id ? true : false}
                      name="product_service_id"
                      label="Activités"
                      placeholder="Activités"
                    />
                  )}
                />
                {errors.product_service_id ? (
                  <FormHelperText error>{errors?.product_service_id}</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
            
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <InputLabel id="devise_product">Devises</InputLabel>
                <Select
                  name="devise_product"
                  style={{ height: "40px" }}
                  labelId="Devises"
                  label="Devises"
                  error={errors.devise_product ? true : false}
                  onChange={handleChange}
                  value={values.devise_product}
                >
                  {Object.entries(devises).map(([key, val]) => {
                      return (
                          <MenuItem key={key} value={key}>
                            {key}
                            {/* {`${key} ( ${val.symbol_native} )`} */}
                          </MenuItem>
                      );
                  })}

                </Select>
                {errors.devise_product ? (
                  <FormHelperText error>{errors?.devise_product}</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  id="shareholdings"
                  options={shareholdings && shareholdings.data ? shareholdings.data :[]}
                  getOptionLabel={(option) => option.name}
                  defaultValue={[]}
                  onChange={(e, newValue) => {
                    formik.setFieldValue(
                      "shareholdings",
                      newValue.map((value) => {
                        return value.id;
                      })
                    );
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={errors.shareholdings ? true : false}
                      name="shareholdings"
                      label="Actionnaires"
                      placeholder="Actionnaires"
                    />
                  )}
                />
                {errors.shareholdings ? (
                  <FormHelperText error>{errors?.shareholdings}</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox sx={{ display: "flex" }}>
          <MDBox mr={1} sx={{ flexGrow: "1" }}>
            <MDButton onClick={handleClose} type="submit" color="primary" fullWidth>
              Annuler
            </MDButton>
          </MDBox>
          <MDBox sx={{ flexGrow: "1" }}>
            <MDButton type="submit" color="info" fullWidth>
              Ajouter
            </MDButton>
          </MDBox>
        </MDBox>
      </form>
    </MDBox>
  );
}

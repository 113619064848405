import { takeLatest } from "redux-saga/effects";

import * as ActionsTypes from "../../../../common/state/StatesConstants";
import {
  addShareholdingSaga,
  deleteShareholdingSaga,
  editShareholdingSaga,
  getShareholdingsSaga,
  updateShareholdingSaga,
} from "./ShareholdingsSaga";

export default function* ShareholdingsWatcher() {
  yield takeLatest(ActionsTypes.GET_SHAREHOLDINGS, getShareholdingsSaga);
  yield takeLatest(ActionsTypes.ADD_SHAREHOLDING, addShareholdingSaga);
  yield takeLatest(ActionsTypes.EDIT_SHAREHOLDING, editShareholdingSaga);
  yield takeLatest(ActionsTypes.UPDATE_SHAREHOLDING, updateShareholdingSaga);
  yield takeLatest(ActionsTypes.DELETE_SHAREHOLDING, deleteShareholdingSaga);
}

import { Alert, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addFinancialProfileSchema } from "../schema";
import * as actions from "../redux/state/FinancialProfilesAction";
import * as actionsCompanies from "../../companies/redux/state/CompaniesAction";
import { useLocation } from "react-router-dom";
import { getErrorMessage } from "common/utils/Helper";

export default function FormAddFinancialProfile({ setOpen }) {
  const dispatch = useDispatch();
  const [errorSB, setErrorSB] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const location = useLocation();
  // order select companies
  const orderCompanies = {
    sort: "raison_social",
    order: "asc",
  };
  const onSubmit = (values) => {
    let data = {};
    const request = {
      payload: values,
      successCallBack: (response) => {
        closeErrorSB()
        if (location?.state?.id) {
          data = { companyId: location?.state?.id };
        }
        dispatch(actions.getFinancialProfiles(data));
        setOpen(false);
        console.log("response", response);
      },
      failCallBack: (error) => {
        console.log("error", error);
        setErrorMessage(getErrorMessage(error))
        openErrorSB();
      },
    };
    dispatch(actions.addFinancialProfile(request));
  };

  useEffect(() => {
    dispatch(actionsCompanies.getCompanies(orderCompanies));
  }, []);

  const companies = useSelector((state) => state?.companies.companies);
  const { handleSubmit, errors, values, handleChange } = useFormik({
    initialValues: {
      taux_change: "",
      effectifs: "",
      rend_capital_investi:"",
      total_prod_exploitations: "",
      resultat_courant_avant_impots: "",
      benefice_perte: "",
      capacite_autofin_avant_rep: "",
      total_actif: "",
      capitaux_propres: "",
      liquidite_reduite: "",
      performance: "",
      rend_capitaux_propres_nets: "",
      ratio_solvabilite: "",
      year: "",
      company_id: location?.state?.id ?? "",
    },
    onSubmit,
    validationSchema: addFinancialProfileSchema,
  });
  const handleClose = () => {
    setOpen(false);
  };

  const disabledSelect = location?.state?.id ? true: false;

  return (
    <MDBox m={1}>
      {errorSB && (
        <Alert severity="error">
          {errorMessage}
        </Alert>
      )}
      <form onSubmit={handleSubmit}>
        <MDBox py={2}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="taux_change"
                  label="Taux change"
                  error={errors.taux_change ? true : false}
                  onChange={handleChange}
                  value={values.taux_change?.toString().replace(",", ".") ?? ""}
                  helperText={errors?.taux_change ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="total_prod_exploitations"
                  label="Total prod exploitations"
                  error={errors.total_prod_exploitations ? true : false}
                  onChange={handleChange}
                  value={values.total_prod_exploitations?.toString().replace(",", ".") ?? ""}
                  helperText={errors?.total_prod_exploitations ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="resultat_courant_avant_impots"
                  label="Resultat courant avant impots"
                  error={errors.resultat_courant_avant_impots ? true : false}
                  onChange={handleChange}
                  value={values.resultat_courant_avant_impots?.toString().replace(",", ".") ?? ""}
                  helperText={errors?.resultat_courant_avant_impots ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="benefice_perte"
                  label="Benefice perte"
                  error={errors.benefice_perte ? true : false}
                  onChange={handleChange}
                  value={values.benefice_perte?.toString().replace(",", ".") ?? ""}
                  helperText={errors?.benefice_perte ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="capacite_autofin_avant_rep"
                  label="Capacite autofin avant rep"
                  error={errors.capacite_autofin_avant_rep ? true : false}
                  onChange={handleChange}
                  value={values.capacite_autofin_avant_rep?.toString().replace(",", ".") ?? ""}
                  helperText={errors?.capacite_autofin_avant_rep ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="total_actif"
                  label="Total actif"
                  error={errors.total_actif ? true : false}
                  onChange={handleChange}
                  value={values.total_actif?.toString().replace(",", ".") ?? ""}
                  helperText={errors?.total_actif ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="capitaux_propres"
                  label="Capitaux propres"
                  error={errors.capitaux_propres ? true : false}
                  onChange={handleChange}
                  value={values.capitaux_propres?.toString().replace(",", ".") ?? ""}
                  helperText={errors?.capitaux_propres ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="liquidite_reduite"
                  label="Liquidite reduite"
                  error={errors.liquidite_reduite ? true : false}
                  onChange={handleChange}
                  value={values.liquidite_reduite?.toString().replace(",", ".") ?? ""}
                  helperText={errors?.liquidite_reduite ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="performance"
                  label="Performance"
                  error={errors.performance ? true : false}
                  onChange={handleChange}
                  value={values.performance?.toString().replace(",", ".") ?? ""}
                  helperText={errors?.performance ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="rend_capitaux_propres_nets"
                  label="Rend capitaux propres nets"
                  error={errors.rend_capitaux_propres_nets ? true : false}
                  onChange={handleChange}
                  value={values.rend_capitaux_propres_nets?.toString().replace(",", ".") ?? ""}
                  helperText={errors?.rend_capitaux_propres_nets ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="ratio_solvabilite"
                  label="Ratio solvabilite"
                  error={errors.ratio_solvabilite ? true : false}
                  onChange={handleChange}
                  value={values.ratio_solvabilite?.toString().replace(",", ".") ?? ""}
                  helperText={errors?.ratio_solvabilite ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="year"
                  label="Annee"
                  error={errors.year ? true : false}
                  onChange={handleChange}
                  value={values.year?.toString().replace(",", ".") ?? ""}
                  helperText={errors?.year ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="effectifs"
                  label="Effectifs"
                  error={errors.effectifs ? true : false}
                  onChange={handleChange}
                  value={values.effectifs?.toString().replace(",", ".") ?? ""}
                  helperText={errors?.effectifs ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="rend_capital_investi"
                  label="Rend. du capital investi (%)"
                  error={errors.rend_capital_investi ? true : false}
                  onChange={handleChange}
                  value={values.rend_capital_investi?.toString().replace(",", ".") ?? ""}
                  helperText={errors?.rend_capital_investi ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl fullWidth>
                <InputLabel id="company_id">Entreprise</InputLabel>
                <Select
                  name="company_id"
                  style={{ height: "40px" }}
                  labelId="state_label"
                  label="Entreprise"
                  error={errors.company_id ? true : false}
                  onChange={handleChange}
                  value={values.company_id}
                  disabled = {disabledSelect}
                >
                  {companies?.data?.map((company) => {
                    return (
                      <MenuItem key={company?.id} value={company?.id}>
                        {/* <b>{company?.raison_social}</b>&nbsp;({company?.country_id}) */}
                        <p>
                          <b>{company?.raison_social}</b> ({company?.country?.name})
                        </p>
                      </MenuItem>
                    );
                  })}
                </Select>
                {errors.country_id ? (
                  <FormHelperText error>{errors?.country_id}</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox sx={{ display: "flex" }}>
          <MDBox mr={1} sx={{ flexGrow: "1" }}>
            <MDButton onClick={handleClose} type="submit" color="primary" fullWidth>
              Annuler
            </MDButton>
          </MDBox>
          <MDBox sx={{ flexGrow: "1" }}>
            <MDButton type="submit" color="info" fullWidth>
              Ajouter
            </MDButton>
          </MDBox>
        </MDBox>
      </form>
    </MDBox>
  );
}

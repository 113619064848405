import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../companies/redux/state/CompaniesAction";

// Function to format numbers with spaces
function formatNumberWithSpaces(input) {
  if (isNaN(input)) {
     return input;
  }
  const roundedNum = (Math.round(input * 100) / 100).toFixed(2);
  const numStr = roundedNum.toString();
  const formattedStr = numStr.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return formattedStr;
 }

export default function Data() {
  // console.log(companyId);
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   companyId && dispatch(actions.editCompany(companyId));
  // }, [companyId]);

  const shareholdings = useSelector((state) => state?.companies?.company?.shareholdings) ?? [];

  console.log("shareholding", shareholdings);
  const shareholdingsRows = shareholdings?.map((shareholding, i) => ({
    nom: shareholding.name,
    type: shareholding.type,
    act_direct: shareholding.act_direct,
    act_total: shareholding.act_total,
    op_rev: formatNumberWithSpaces(shareholding.op_rev),
    source: shareholding.source,
    date: shareholding.date,
    country: shareholding.country?.name,
    created_at: new Date(shareholding.created_at)?.toLocaleDateString(),
    updated_at: new Date(shareholding.updated_at)?.toLocaleDateString(),
  }));
  return {
    columns: [
      { Header: "nom", accessor: "nom", align: "left" },
      { Header: "type", accessor: "type", align: "left" },
      { Header: "act_direct", accessor: "act_direct", align: "left" },
      { Header: "act_total", accessor: "act_total", align: "left" },
      { Header: "op_rev", accessor: "op_rev", align: "right" },
      { Header: "source", accessor: "source", align: "left" },
      { Header: "date", accessor: "date", align: "left" },
      { Header: "country", accessor: "country", align: "left" },
      { Header: "Date d'ajout", accessor: "created_at", align: "left" },
      { Header: "Date de moodification", accessor: "updated_at", align: "left" },
    ],

    rows: shareholdingsRows,
  };
}

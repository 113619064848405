import {
  Alert,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addCompteResultatBilanSchema } from "../schema";
import * as actions from "../redux/state/CompteResultatBilansAction";
import * as actionsCompanies from "../../../../companies/redux/state/CompaniesAction";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import { getErrorMessage } from "common/utils/Helper";

export default function FormAddCompteResultatBilan({ setOpen, companyId, setCompanyId }) {
  const dispatch = useDispatch();
  const location = useLocation();

  const [errorSB, setErrorSB] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  // order select companies
  const orderCompanies = {
    sort: "raison_social",
    order: "asc",
  };

  const onSubmit = (values) => {
    const request = {
      payload: values,
      successCallBack: (response) => {
        closeErrorSB()
        setCompanyId(values?.company_id ?? companyId);
        let queryParam = {};
        if (
          (companyId.toString().length > 0 && companyId !== undefined) ||
          (values?.company_id.toString().length > 0 && values?.company_id !== undefined)
        ) {
          queryParam = { companyId: values?.company_id ?? companyId, sort: "date", order: "desc" };
        }
        dispatch(actions.getCompteResultatBilans(queryParam));
        setOpen(false);
        console.log("response", response);
      },
      failCallBack: (error) => {
        console.log("error", error);
        setErrorMessage(getErrorMessage(error))
        openErrorSB();
      },
    };
    dispatch(actions.addCompteResultatBilan(request));
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    dispatch(actionsCompanies.getCompanies(orderCompanies));
  }, []);

  const companies = useSelector((state) => state?.companies.companies);

  const formik = useFormik({
    initialValues: {
      total_production_exploitation: "",
      chiffre_affaire_net: "",
      cout_vente: "",
      marge_brut: "",
      autre_charge_exploitation: "",
      resultat_exploitation: "",
      resultat_financier: "",
      produit_financier: "",
      charge_financier: "",
      resultat_courant_avant_impot: "",
      impot_taxe_assimitee: "",
      resultat_courant_apres_imposition: "",
      resultat_exceptionnel: "",
      change_exceptionnelle: "",
      benefice_pert: "",
      date: "",
      exceptional_products:"",
      company_id: location?.state?.id ?? "",
    },
    onSubmit,
    validationSchema: addCompteResultatBilanSchema,
  });
  const disabledSelect = location?.state?.id ? true: false;

  const { handleSubmit, errors, values, handleChange } = formik;
  return (
    <MDBox m={1}>
      {errorSB && (
        <Alert severity="error">
          {errorMessage}
        </Alert>
      )}
      <form onSubmit={handleSubmit}>
        <MDBox py={2}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="total_production_exploitation"
                  label="Total des productions exploitations"
                  error={errors.total_production_exploitation ? true : false}
                  onChange={handleChange}
                  value={values.total_production_exploitation.replace(",", ".") ?? ""}
                  helperText={errors?.total_production_exploitation ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="chiffre_affaire_net"
                  label="Chiffre d'affaires net"
                  error={errors.chiffre_affaire_net ? true : false}
                  onChange={handleChange}
                  value={values.chiffre_affaire_net.replace(",", ".") ?? ""}
                  helperText={errors?.chiffre_affaire_net ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="cout_vente"
                  label="Cout des ventes"
                  error={errors.cout_vente ? true : false}
                  onChange={handleChange}
                  value={values.cout_vente.replace(",", ".") ?? ""}
                  helperText={errors?.cout_vente ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="marge_brut"
                  label="Marge brut"
                  error={errors.marge_brut ? true : false}
                  onChange={handleChange}
                  value={values.marge_brut.replace(",", ".") ?? ""}
                  helperText={errors?.marge_brut ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="autre_charge_exploitation"
                  label="Autres charges d'exploitation"
                  error={errors.autre_charge_exploitation ? true : false}
                  onChange={handleChange}
                  value={values.autre_charge_exploitation.replace(",", ".") ?? ""}
                  helperText={errors?.autre_charge_exploitation ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="resultat_exploitation"
                  label="Resultat d'exploitation"
                  error={errors.resultat_exploitation ? true : false}
                  onChange={handleChange}
                  value={values.resultat_exploitation.replace(",", ".") ?? ""}
                  helperText={errors?.resultat_exploitation ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="resultat_financier"
                  label="Resultat financier"
                  error={errors.resultat_financier ? true : false}
                  onChange={handleChange}
                  value={values.resultat_financier.replace(",", ".") ?? ""}
                  helperText={errors?.resultat_financier ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="produit_financier"
                  label="Produit_financier"
                  error={errors.produit_financier ? true : false}
                  onChange={handleChange}
                  value={values.produit_financier.replace(",", ".") ?? ""}
                  helperText={errors?.produit_financier ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="charge_financier"
                  label="Charge financier"
                  error={errors.charge_financier ? true : false}
                  onChange={handleChange}
                  value={values.charge_financier.replace(",", ".") ?? ""}
                  helperText={errors?.charge_financier ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="resultat_courant_avant_impot"
                  label="Resultat courant avant impots"
                  error={errors.resultat_courant_avant_impot ? true : false}
                  onChange={handleChange}
                  value={values.resultat_courant_avant_impot.replace(",", ".") ?? ""}
                  helperText={errors?.resultat_courant_avant_impot ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="impot_taxe_assimitee"
                  label="Impot et taxes assimitees"
                  error={errors.impot_taxe_assimitee ? true : false}
                  onChange={handleChange}
                  value={values.impot_taxe_assimitee.replace(",", ".") ?? ""}
                  helperText={errors?.impot_taxe_assimitee ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="resultat_courant_apres_imposition"
                  label="Resultat courant apres imposition"
                  error={errors.resultat_courant_apres_imposition ? true : false}
                  onChange={handleChange}
                  value={values.resultat_courant_apres_imposition.replace(",", ".") ?? ""}
                  helperText={errors?.resultat_courant_apres_imposition ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="resultat_exceptionnel"
                  label="Resultat exceptionnels"
                  error={errors.resultat_exceptionnel ? true : false}
                  onChange={handleChange}
                  value={values.resultat_exceptionnel.replace(",", ".") ?? ""}
                  helperText={errors?.resultat_exceptionnel ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="exceptional_products"
                  label="Produits exceptionnels"
                  error={errors.exceptional_products ? true : false}
                  onChange={handleChange}
                  value={values.exceptional_products.replace(",", ".") ?? ""}
                  helperText={errors?.exceptional_products ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="change_exceptionnelle"
                  label="Changes exceptionnelles"
                  error={errors.change_exceptionnelle ? true : false}
                  onChange={handleChange}
                  value={values.change_exceptionnelle.replace(",", ".") ?? ""}
                  helperText={errors?.change_exceptionnelle ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="benefice_pert"
                  label="Benefices ou perts"
                  error={errors.benefice_pert ? true : false}
                  onChange={handleChange}
                  value={values.benefice_pert.replace(",", ".") ?? ""}
                  helperText={errors?.benefice_pert ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    renderInput={(props) => <TextField {...props} />}
                    value={values.date}
                    name="date"
                    error={errors.date ? true : false}
                    onChange={(newValue) => {
                      formik.setFieldValue("date", dayjs(newValue).format("YYYY-MM-DD")); // December 22nd 2022, 10:01:13 pm);
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl fullWidth>
                <InputLabel id="company_id">Entreprise</InputLabel>
                <Select
                  name="company_id"
                  style={{ height: "40px" }}
                  labelId="state_label"
                  label="Entreprise"
                  error={errors.company_id ? true : false}
                  onChange={handleChange}
                  value={values.company_id}
                  disabled = {disabledSelect}
                >
                  {companies?.data?.map((company) => {
                    return (
                      <MenuItem key={company?.id} value={company?.id}>
                        {/* <b>{company?.raison_social}</b>&nbsp;({company?.country_id}) */}
                        <p>
                          <b>{company?.raison_social}</b> ({company?.country?.name})
                        </p>
                      </MenuItem>
                    );
                  })}
                </Select>
                {errors.country_id ? (
                  <FormHelperText error>{errors?.country_id}</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox sx={{ display: "flex" }}>
          <MDBox mr={1} sx={{ flexGrow: "1" }}>
            <MDButton onClick={handleClose} type="submit" color="primary" fullWidth>
              Annuler
            </MDButton>
          </MDBox>
          <MDBox sx={{ flexGrow: "1" }}>
            <MDButton type="submit" color="info" fullWidth>
              Ajouter
            </MDButton>
          </MDBox>
        </MDBox>
      </form>
    </MDBox>
  );
}

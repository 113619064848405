import * as Yup from "yup";

export const addInformationCompanySchema = Yup.object().shape({
  year_total_produits_exploitation: Yup.number("La valeur doit être un nombre").required(
    "Obligatoire"
  ),
  total_produits_exploitation: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  pm_product: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  devise_product: Yup.string().max(19, "Trop long!").required("Obligatoire"),
  pourcentage_product: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  year_net_income: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  net_income: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  pm_exercice: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  devise_exercice: Yup.string().max(19, "Trop long!").required("Obligatoire"),
  pourcentage_exercice: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  ppe: Yup.string().max(600, "Trop long!").required("Obligatoire"),
  actionnaires: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  filiales: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  companies_du_groupe: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
});

import { takeLatest } from "redux-saga/effects";

import * as ActionsTypes from "../../../../../../common/state/StatesConstants";
import {
  addPassifBilanSaga,
  deletePassifBilanSaga,
  editPassifBilanSaga,
  getPassifBilansSaga,
  updatePassifBilanSaga,
} from "./PassifBilansSaga";

export default function* PassifBilansWatcher() {
  yield takeLatest(ActionsTypes.GET_PASSIF_BILANS, getPassifBilansSaga);
  yield takeLatest(ActionsTypes.ADD_PASSIF_BILAN, addPassifBilanSaga);
  yield takeLatest(ActionsTypes.EDIT_PASSIF_BILAN, editPassifBilanSaga);
  yield takeLatest(ActionsTypes.UPDATE_PASSIF_BILAN, updatePassifBilanSaga);
  yield takeLatest(ActionsTypes.DELETE_PASSIF_BILAN, deletePassifBilanSaga);
}

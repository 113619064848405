import { takeLatest } from "redux-saga/effects";

import * as ActionsTypes from "../../../../common/state/StatesConstants";
import { addCountrySaga, deleteCountrySaga, editCountrySaga, getCountriesSaga, updateCountrySaga } from "./CountriesSaga";

export default function* CountriesWatcher() {
  yield takeLatest(ActionsTypes.GET_COUNTRIES, getCountriesSaga);
  yield takeLatest(ActionsTypes.ADD_COUNTRY, addCountrySaga);
  yield takeLatest(ActionsTypes.EDIT_COUNTRY, editCountrySaga);
  yield takeLatest(ActionsTypes.UPDATE_COUNTRY, updateCountrySaga);
  yield takeLatest(ActionsTypes.DELETE_COUNTRY, deleteCountrySaga);
}

import { useEffect } from "react";
import * as ActionsTypes from "../../../../common/state/StatesConstants";

const initialStateCompanies = {
  error: null,
  isLoading: false,
  companies: [],
  company: {},
};

// const initialStateCompany = {
//   error: null,
//   isLoading: false,
//   company: {},
// };

const companiesReducer = (
  stateCompanies = initialStateCompanies,
  // stateCompany = initialStateCompany,
  action
) => {
  switch (action.type) {
    // ##-----------GET_COMPANIES-----------##
    case ActionsTypes.GET_COMPANIES:
      return {
        ...stateCompanies,
        isLoading: true,
        error: null,
        companies: [],
      };

    case ActionsTypes.GET_COMPANIES_SUCCESS:
      return {
        ...stateCompanies,
        isLoading: false,
        error: null,
        companies: action.response.data.data,
      };

    case ActionsTypes.GET_COMPANIES_FAIL:
      return {
        ...stateCompanies,
        isLoading: false,
        error: action.error,
        companies: [],
      };
    // ##-----------END_GET_COMPANIES-----------##

    // ##-----------ADD_COMPANY-----------##
    case ActionsTypes.ADD_COMPANY:
      return {
        ...stateCompanies,
        isLoading: true,
        error: null,
        //companies: [...stateCompanies.companies],
      };

    case ActionsTypes.ADD_COMPANY_SUCCESS:
      return {
        ...stateCompanies,
        isLoading: false,
        error: null,
        // companies: [
        //   action.response.data.data,
        //   ...stateCompanies.companies,
        // ],
      };

    case ActionsTypes.ADD_COMPANY_FAIL:
      return {
        ...stateCompanies,
        isLoading: false,
        error: action.error,
        //companies: [...stateCompanies.companies],
      };
    // ##-----------END_ADD_COMPANY-----------##

    // ##-----------EDIT_COMPANY-----------##
    case ActionsTypes.EDIT_COMPANY:
      return {
        ...stateCompanies,
        isLoading: true,
        error: null,
        company: {},
      };

    case ActionsTypes.EDIT_COMPANY_SUCCESS:
      return {
        ...stateCompanies,
        isLoading: false,
        error: null,
        company: action.response.data.data,
      };

    case ActionsTypes.EDIT_COMPANY_FAIL:
      return {
        ...stateCompanies,
        isLoading: false,
        error: action.error,
        company: {},
      };
    // ##-----------END_EDIT_COMPANY-----------##

    // ##-----------UPDATE_COMPANY-----------##
    case ActionsTypes.UPDATE_COMPANY:
      return {
        ...stateCompanies,
        isLoading: true,
        error: null,
        //companies: [...stateCompanies.companies],
      };

    case ActionsTypes.UPDATE_COMPANY_SUCCESS:
      return {
        ...stateCompanies,
        isLoading: false,
        error: null,
        // companies: [
        //   action.response.data.data,
        //   ...stateCompanies.companies.filter(
        //     (company) => company.id != action.response.data.data.id
        //   ),
        // ],
      };

    case ActionsTypes.UPDATE_COMPANY_FAIL:
      return {
        ...stateCompanies,
        isLoading: false,
        error: action.error,
        //companies: [...stateCompanies.companies],
      };
    // ##-----------END_UPDATE_COMPANY-----------##

    // ##-----------DELETE_COMPANY-----------##
    case ActionsTypes.DELETE_COMPANY:
      return {
        ...stateCompanies,
        isLoading: true,
        error: null,
        //companies: [...stateCompanies.companies],
      };

    case ActionsTypes.DELETE_COMPANY_SUCCESS:
      return {
        ...stateCompanies,
        isLoading: false,
        error: null,
        //companies: [...stateCompanies.companies.filter((company)=>company.id!=action.response.data.deleted)],
        // companies: [...stateCompanies.companies],
      };

    case ActionsTypes.DELETE_COMPANY_FAIL:
      return {
        ...stateCompanies,
        isLoading: false,
        error: action.error,
        //companies: [...stateCompanies.companies],
      };
    // ##-----------END_DELETE_COMPANY-----------##


    case ActionsTypes.DOWNLOAD_RAPPORT:
      return {
        ...stateCompanies,
        isLoading: false,
        error: action.error,
        //companies: [...stateCompanies.companies],
      };
    default:
      return stateCompanies;
  }
};

export default companiesReducer;

import React from "react";
import { Outlet, Route } from "react-router-dom";
import JwtUtils from "./JwtUtils";
import ForbiddenComponent from "./ForbiddenComponent";

function ProtectedRoute({ roles, component: Component, ...rest }) {
  console.log("first");

  if (
    JwtUtils.isActif()
    && JwtUtils.hasAnyRole(roles)
  ) {
    return (
      <>
        <Outlet />
      </>
    );
  } else {
    return <ForbiddenComponent />;
  }
}

export default ProtectedRoute;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/state/MemoBilansAction";

// Images
import team2 from "assets/images/team-2.jpg";
import { Box, Icon, IconButton } from "@mui/material";
import MDButton from "components/MDButton";
import * as modalActions from "../modalActionTypes";

function formatNumberWithSpaces(input) {
  if (isNaN(input)) {
     return input;
  }
  const roundedNum = Math.round(input);
  const numStr = roundedNum.toString();
  const formattedStr = numStr.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return formattedStr;
 }

export default function Data(setOpen, setMemoBilanId, setAction, companyId) {
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(companyId);
    let queryParam = {};
    if (companyId.toString().length > 0 && companyId !== undefined) {
      queryParam = { companyId: companyId, sort: "date", order: "desc" };
    }
    dispatch(actions.getMemoBilans(queryParam));
    console.log(queryParam)
  }, [companyId]);

  const handleOpenDelete = (id) => {
    setOpen(true);
    setMemoBilanId(id);
    setAction(modalActions.DELETE);
  };
  const handleOpenEdit = (id) => {
    // console.log("edit id", id);
    setOpen(true);
    setMemoBilanId(id);
    setAction(modalActions.UPDATE);
  };

  const memoBilans = useSelector(
    (state) => state?.memoBilans?.memoBilans
  );

  const usersRows = memoBilans && memoBilans.data && memoBilans?.data.map((memoBilan, i) => ({
    fond_roulement_net_global: formatNumberWithSpaces(memoBilan?.fond_roulement_net_global),
    actif_circulant_net: formatNumberWithSpaces(memoBilan?.actif_circulant_net),
    effectifs: formatNumberWithSpaces(memoBilan?.effectifs),
    valeur_de_lentreprise: formatNumberWithSpaces(memoBilan?.valeur_de_lentreprise),
    date: memoBilan?.date,
    company: memoBilan?.company?.raison_social,

    action: (
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box>
          <IconButton
            color="info"
            onClick={() => handleOpenEdit(memoBilan.id)}
            component="label"
          >
            <Icon color="info" fontSize="small">
              mode
            </Icon>
          </IconButton>
        </Box>
        <Box>
          <IconButton
            color="primary"
            onClick={() => handleOpenDelete(memoBilan.id)}
            component="label"
          >
            <Icon color="primary" fontSize="small">
              delete
            </Icon>
          </IconButton>
        </Box>
      </Box>
    ),
}));

  return {
    columns: [
      // { Header: "Pays", accessor: "country", align: "left" },
      { Header: "date", accessor: "date", align: "left" },
      { Header: "Entreprise", accessor: "company", align: "left" },
      { Header: "Fonds de roulememt Net global", accessor: "fond_roulement_net_global", align: "right" },
      { Header: "Actif circulant net", accessor: "actif_circulant_net", align: "right" },
      { Header: "VALEUR DE L'ENTREPRISE", accessor: "valeur_de_lentreprise", align: "left" },
      { Header: "EFFECTIFS", accessor: "effectifs", align: "right" },
      { Header: "Action", accessor: "action", align: "center" },
    ],

    rows: usersRows,
  };
}

import { takeLatest } from "redux-saga/effects";

import * as ActionsTypes from "../../../../../../common/state/StatesConstants";
import {
  addActifBilanSaga,
  deleteActifBilanSaga,
  editActifBilanSaga,
  getActifBilansSaga,
  updateActifBilanSaga,
} from "./ActifBilansSaga";

export default function* ActifBilansWatcher() {
  yield takeLatest(ActionsTypes.GET_ACTIF_BILANS, getActifBilansSaga);
  yield takeLatest(ActionsTypes.ADD_ACTIF_BILAN, addActifBilanSaga);
  yield takeLatest(ActionsTypes.EDIT_ACTIF_BILAN, editActifBilanSaga);
  yield takeLatest(ActionsTypes.UPDATE_ACTIF_BILAN, updateActifBilanSaga);
  yield takeLatest(ActionsTypes.DELETE_ACTIF_BILAN, deleteActifBilanSaga);
}

import * as ActionsTypes from "../../../../common/state/StatesConstants";

// ##-----------GET_USERS-----------##
export const getUsers = () => {
  return { type: ActionsTypes.GET_USERS };
};

export const getUsersSuccess = (response) => {
  return { type: ActionsTypes.GET_USERS_SUCCESS, response };
};

export const getUsersFail = (error) => {
  return { type: ActionsTypes.GET_USERS_FAIL, error };
};
// ##-----------END_GET_USERS-----------##

// ##-----------ADD_USER-----------##
export const addUser = (data) => {
  return { type: ActionsTypes.ADD_USER, payload: data };
};

export const addUserSuccess = (response) => {
  return { type: ActionsTypes.ADD_USER_SUCCESS, response };
};

export const addUserFail = (error) => {
  return { type: ActionsTypes.ADD_USER_FAIL, error };
};
// ##-----------END_ADD_USER-----------##

// ##-----------DELETE_USER-----------##
export const deleteUser = (data) => {
  return { type: ActionsTypes.DELETE_USER, payload: data };
};

export const deleteUserSuccess = (response) => {
  return { type: ActionsTypes.DELETE_USER_SUCCESS, response };
};

export const deleteUserFail = (error) => {
  return { type: ActionsTypes.DELETE_USER_FAIL, error };
};
// ##-----------END_DELETE_USER-----------##

// ##-----------EDIT_USER-----------##
export const editUser = (data) => {
  return { type: ActionsTypes.EDIT_USER, payload: data };
};

export const editUserSuccess = (response) => {
  return { type: ActionsTypes.EDIT_USER_SUCCESS, response };
};

export const editUserFail = (error) => {
  return { type: ActionsTypes.EDIT_USER_FAIL, error };
};
// ##-----------END_EDIT_USER-----------##

// ##-----------UPDATE_USER-----------##
export const updateUser = (data) => {
  return { type: ActionsTypes.UPDATE_USER, payload: data };
};

export const updateUserSuccess = (response) => {
  return { type: ActionsTypes.UPDATE_USER_SUCCESS, response };
};

export const updateUserFail = (error) => {
  return { type: ActionsTypes.UPDATE_USER_FAIL, error };
};
// ##-----------END_UPDATE_USER-----------##

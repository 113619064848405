import { Alert, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addDirectorSchema } from "../schema";
import * as actions from "../redux/state/DirectorsAction";
import * as actionsCompanies from "../../companies/redux/state/CompaniesAction";
import { useLocation } from "react-router-dom";
import { getErrorMessage } from "common/utils/Helper";

export default function FormAddDirector({ setOpen }) {
  const dispatch = useDispatch();

  const [errorSB, setErrorSB] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const location = useLocation()
  // order select companies
  const orderCompanies = {
    sort: "raison_social",
    order: "asc",
  };
  const onSubmit = (values) => {
    let data = {};
    const request = {
      payload: values,
      successCallBack: (response) => {
        closeErrorSB()
        if (location?.state?.id) {
          data = { companyId: location?.state?.id };
        }
        dispatch(actions.getDirectors(data));
        setOpen(false);
        console.log("response", response);
      },
      failCallBack: (error) => {
        console.log("error", error);
        setErrorMessage(getErrorMessage(error))
        openErrorSB();
      },
    };
    dispatch(actions.addDirector(request));
  };

  useEffect(() => {
    dispatch(actionsCompanies.getCompanies(orderCompanies));
  }, []);

  const companies = useSelector((state) => state?.companies.companies);

  const { handleSubmit, errors, values, handleChange } = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      company_id: location?.state?.id ?? "",
      associate: "",
      role: "",
    },
    onSubmit,
    validationSchema: addDirectorSchema,
  });
  const handleClose = () => {
    setOpen(false);
  };

  const disabledSelect = location?.state?.id ? true: false;
  

  return (
    <MDBox m={1}>
      {errorSB && (
        <Alert severity="error">
          {errorMessage}
        </Alert>
      )}
      <form onSubmit={handleSubmit}>
        <MDBox py={2}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="first_name"
                  label="Prenom"
                  error={errors.first_name ? true : false}
                  onChange={handleChange}
                  value={values.first_name}
                  helperText={errors?.first_name ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="last_name"
                  label="Nom"
                  error={errors.last_name ? true : false}
                  onChange={handleChange}
                  value={values.last_name}
                  helperText={errors?.last_name ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="associate"
                  label="Associer"
                  error={errors.associate ? true : false}
                  onChange={handleChange}
                  value={values.associate}
                  helperText={errors?.associate ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="role"
                  label="Role"
                  error={errors.role ? true : false}
                  onChange={handleChange}
                  value={values.role}
                  helperText={errors?.role ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl fullWidth>
                <InputLabel id="company_id">Entreprise</InputLabel>
                <Select
                  name="company_id"
                  style={{ height: "40px" }}
                  labelId="state_label"
                  label="Entreprise"
                  error={errors.company_id ? true : false}
                  onChange={handleChange}
                  value={values.company_id}
                  disabled = {disabledSelect}
                  // disabled
                >
                  {companies?.data?.map((company) => {
                    return (
                      <MenuItem key={company?.id} value={company?.id}>
                        {/* <b>{company?.raison_social}</b>&nbsp;({company?.country_id}) */}
                        <p>
                          <b>{company?.raison_social}</b> ({company?.country?.name})
                        </p>
                      </MenuItem>
                    );
                  })}
                </Select>
                {errors.country_id ? (
                  <FormHelperText error>{errors?.country_id}</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox sx={{ display: "flex" }}>
          <MDBox mr={1} sx={{ flexGrow: "1" }}>
            <MDButton onClick={handleClose} type="submit" color="primary" fullWidth>
              Annuler
            </MDButton>
          </MDBox>
          <MDBox sx={{ flexGrow: "1" }}>
            <MDButton type="submit" color="info" fullWidth>
              Ajouter
            </MDButton>
          </MDBox>
        </MDBox>
      </form>
    </MDBox>
  );
}

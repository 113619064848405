import { put, call, delay } from "redux-saga/effects";
import * as actions from "../state/CountriesAction";
import {
  getCountriesApi,
  addCountryApi,
  editCountryApi,
  updateCountryApi,
  deleteCountryApi,
} from "../../../../common/services/Apis/countriesApi";

export function* getCountriesSaga(action = {}) {
  try {
    const response = yield call(getCountriesApi, action?.payload);
    yield put(actions.getCountriesSuccess(response));
  } catch (error) {
    yield put(actions.getCountriesFail(error));
  }
}
export function* addCountrySaga(action) {
  try {
    const response = yield call(addCountryApi, action?.payload?.payload);
    if (response) {
      yield put(actions.addCountrySuccess(response));
      yield call(action.payload.successCallBack, response);
    }
  } catch (error) {
    yield put(actions.addCountryFail(error));
    yield call(action.payload.failCallBack, error);
  }
}
export function* editCountrySaga(action) {
  try {
    const response = yield call(editCountryApi, action.payload);
    yield put(actions.editCountrySuccess(response));
  } catch (error) {
    yield put(actions.editCountryFail(error));
  }
}

export function* updateCountrySaga(action) {
  try {
    const response = yield call(updateCountryApi, action?.payload?.payload);
    if (response) {
      yield put(actions.updateCountrySuccess(response));
      yield call(action.payload.successCallBack, response);
    }
  } catch (error) {
    yield put(actions.updateCountryFail(error));
    yield call(action.payload.failCallBack, error);
  }
}

export function* deleteCountrySaga(action) {
  try {
    const response = yield call(deleteCountryApi, action?.payload?.payload);
    yield put(actions.deleteCountrySuccess(response));
    yield call(action.payload.successCallBack, response);
  } catch (error) {
    yield put(actions.deleteCountryFail(error));
    yield call(action.payload.failCallBack, error);
  }
}

import { useEffect } from "react";
import * as ActionsTypes from "../../../../common/state/StatesConstants";

const initialStateMissingCompanies = {
  error: null,
  isLoading: false,
  missingCompanies: [],
  missingCompany: {},
};

// const initialStateCompany = {
//   error: null,
//   isLoading: false,
//   missingCompany: {},
// };

const companiesReducer = (
  stateCompanies = initialStateMissingCompanies,
  // stateCompany = initialStateCompany,
  action
) => {
  switch (action.type) {
    // ##-----------GET_MISSING_COMPANIES-----------##
    case ActionsTypes.GET_MISSING_COMPANIES:
      return {
        ...stateCompanies,
        isLoading: true,
        error: null,
        missingCompanies: [],
      };

    case ActionsTypes.GET_MISSING_COMPANIES_SUCCESS:
      return {
        ...stateCompanies,
        isLoading: false,
        error: null,
        missingCompanies: action.response.data.data,
      };

    case ActionsTypes.GET_MISSING_COMPANIES_FAIL:
      return {
        ...stateCompanies,
        isLoading: false,
        error: action.error,
        missingCompanies: [],
      };
    // ##-----------END_GET_MISSING_COMPANIES-----------##

    // ##-----------ADD_MISSING_COMPANY-----------##
    case ActionsTypes.ADD_MISSING_COMPANY:
      return {
        ...stateCompanies,
        isLoading: true,
        error: null,
        //missingCompanies: [...stateCompanies.missingCompanies],
      };

    case ActionsTypes.ADD_MISSING_COMPANY_SUCCESS:
      return {
        ...stateCompanies,
        isLoading: false,
        error: null,
        // missingCompanies: [
        //   action.response.data.data,
        //   ...stateCompanies.missingCompanies,
        // ],
      };

    case ActionsTypes.ADD_MISSING_COMPANY_FAIL:
      return {
        ...stateCompanies,
        isLoading: false,
        error: action.error,
        //missingCompanies: [...stateCompanies.missingCompanies],
      };
    // ##-----------END_ADD_MISSING_COMPANY-----------##

    // ##-----------EDIT_MISSING_COMPANY-----------##
    case ActionsTypes.EDIT_MISSING_COMPANY:
      return {
        ...stateCompanies,
        isLoading: true,
        error: null,
        missingCompany: {},
      };

    case ActionsTypes.EDIT_MISSING_COMPANY_SUCCESS:
      return {
        ...stateCompanies,
        isLoading: false,
        error: null,
        missingCompany: action.response.data.data,
      };

    case ActionsTypes.EDIT_MISSING_COMPANY_FAIL:
      return {
        ...stateCompanies,
        isLoading: false,
        error: action.error,
        missingCompany: {},
      };
    // ##-----------END_EDIT_MISSING_COMPANY-----------##

    // ##-----------UPDATE_MISSING_COMPANY-----------##
    case ActionsTypes.UPDATE_MISSING_COMPANY:
      return {
        ...stateCompanies,
        isLoading: true,
        error: null,
        //missingCompanies: [...stateCompanies.missingCompanies],
      };

    case ActionsTypes.UPDATE_MISSING_COMPANY_SUCCESS:
      return {
        ...stateCompanies,
        isLoading: false,
        error: null,
        // missingCompanies: [
        //   action.response.data.data,
        //   ...stateCompanies.missingCompanies.filter(
        //     (missingCompany) => missingCompany.id != action.response.data.data.id
        //   ),
        // ],
      };

    case ActionsTypes.UPDATE_MISSING_COMPANY_FAIL:
      return {
        ...stateCompanies,
        isLoading: false,
        error: action.error,
        //missingCompanies: [...stateCompanies.missingCompanies],
      };
    // ##-----------END_UPDATE_MISSING_COMPANY-----------##

    // ##-----------DELETE_MISSING_COMPANY-----------##
    case ActionsTypes.DELETE_MISSING_COMPANY:
      return {
        ...stateCompanies,
        isLoading: true,
        error: null,
        //missingCompanies: [...stateCompanies.missingCompanies],
      };

    case ActionsTypes.DELETE_MISSING_COMPANY_SUCCESS:
      return {
        ...stateCompanies,
        isLoading: false,
        error: null,
        //missingCompanies: [...stateCompanies.missingCompanies.filter((missingCompany)=>missingCompany.id!=action.response.data.deleted)],
        // missingCompanies: [...stateCompanies.missingCompanies],
      };

    case ActionsTypes.DELETE_MISSING_COMPANY_FAIL:
      return {
        ...stateCompanies,
        isLoading: false,
        error: action.error,
        //missingCompanies: [...stateCompanies.missingCompanies],
      };
    // ##-----------END_DELETE_MISSING_COMPANY-----------##


    case ActionsTypes.DOWNLOAD_RAPPORT:
      return {
        ...stateCompanies,
        isLoading: false,
        error: action.error,
        //missingCompanies: [...stateCompanies.missingCompanies],
      };
    default:
      return stateCompanies;
  }
};

export default companiesReducer;

import { useEffect } from "react";
import * as ActionsTypes from "../../../../../../common/state/StatesConstants";

const initialState = {
  error: null,
  isLoading: false,
  memoBilans: [],
  memoBilan: {},
};

const memoBilansReducer = (state = initialState, action) => {
  switch (action.type) {
    // ##-----------GET_MEMO_BILANS-----------##
    case ActionsTypes.GET_MEMO_BILANS:
      return {
        ...state,
        isLoading: true,
        error: null,
        memoBilans: [],
      };
    case ActionsTypes.GET_MEMO_BILANS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        memoBilans: action.response.data.data,
      };
    case ActionsTypes.GET_MEMO_BILANS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        memoBilans: [],
      };
    // ##-----------END_GET_MEMO_BILANS-----------##

    // ##-----------ADD_MEMO_BILAN-----------##
    case ActionsTypes.ADD_MEMO_BILAN:
      return {
        ...state,
        isLoading: true,
        error: null,
        //memoBilans: [...state.memoBilans],
      };

    case ActionsTypes.ADD_MEMO_BILAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        //memoBilans: [...state.memoBilans, action.response.data.data],
      };

    case ActionsTypes.ADD_MEMO_BILAN_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        //memoBilans: [...state.memoBilans],
      };
    // ##-----------END_ADD_MEMO_BILAN-----------##

    // ##-----------EDIT_MEMO_BILAN-----------##
    case ActionsTypes.EDIT_MEMO_BILAN:
      return {
        ...state,
        isLoading: true,
        error: null,
        memoBilan: {},
      };

    case ActionsTypes.EDIT_MEMO_BILAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        memoBilan: action.response.data.data,
      };

    case ActionsTypes.EDIT_MEMO_BILAN_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        memoBilan: {},
      };
    // ##-----------END_EDIT_MEMO_BILAN-----------##

    // ##-----------UPDATE_MEMO_BILAN-----------##
    case ActionsTypes.UPDATE_MEMO_BILAN:
      return {
        ...state,
        isLoading: true,
        error: null,
        //memoBilans: [...state.memoBilans],
      };

    case ActionsTypes.UPDATE_MEMO_BILAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        // memoBilans: [
        //   action.response.data.data,
        //   ...state.memoBilans.filter(
        //     (memoBilan) => memoBilan.id != action.response.data.data.id
        //   ),
        // ],
      };

    case ActionsTypes.UPDATE_MEMO_BILAN_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        //memoBilans: [...state.memoBilans],
      };
    // ##-----------END_UPDATE_MEMO_BILAN-----------##

    case ActionsTypes.DELETE_MEMO_BILAN:
      return {
        ...state,
        isLoading: true,
        error: null,
        //memoBilans: [...state.memoBilans],
      };

    case ActionsTypes.DELETE_MEMO_BILAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        // memoBilans: [
        //   ...state.memoBilans.filter(
        //     (memoBilan) => memoBilan.id != action.response.data.data
        //   ),
        // ],
      };

    case ActionsTypes.DELETE_MEMO_BILAN_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        //memoBilans: [...state.memoBilans],
      };
    // ##-----------END_DELETE_MEMO_BILAN-----------##

    default:
      return state;
  }
};

export default memoBilansReducer;

import * as ActionsTypes from "../../../../../../common/state/StatesConstants";

// ##-----------GET_COMPTE_RESULTAT_BILANS-----------##
export const getCompteResultatBilans = (data) => {
  return { type: ActionsTypes.GET_COMPTE_RESULTAT_BILANS, payload: data };
};

export const getCompteResultatBilansSuccess = (response) => {
  return { type: ActionsTypes.GET_COMPTE_RESULTAT_BILANS_SUCCESS, response };
};

export const getCompteResultatBilansFail = (error) => {
  return { type: ActionsTypes.GET_COMPTE_RESULTAT_BILANS_FAIL, error };
};
// ##-----------END_GET_COMPTE_RESULTAT_BILANS-----------##

// ##-----------ADD_COMPTE_RESULTAT_BILAN-----------##

export const addCompteResultatBilan = (data) => {
  return { type: ActionsTypes.ADD_COMPTE_RESULTAT_BILAN, payload: data };
};

export const addCompteResultatBilanSuccess = (response) => {
  return { type: ActionsTypes.ADD_COMPTE_RESULTAT_BILAN_SUCCESS, response };
};

export const addCompteResultatBilanFail = (error) => {
  return { type: ActionsTypes.ADD_COMPTE_RESULTAT_BILAN_FAIL, error };
};
// ##-----------END_ADD_COMPTE_RESULTAT_BILAN-----------##

// ##-----------EDIT_COMPTE_RESULTAT_BILAN-----------##
export const editCompteResultatBilan = (data) => {
  return { type: ActionsTypes.EDIT_COMPTE_RESULTAT_BILAN, payload: data };
};

export const editCompteResultatBilanSuccess = (response) => {
  return { type: ActionsTypes.EDIT_COMPTE_RESULTAT_BILAN_SUCCESS, response };
};

export const editCompteResultatBilanFail = (error) => {
  return { type: ActionsTypes.EDIT_COMPTE_RESULTAT_BILAN_FAIL, error };
};
// ##-----------END_EDIT_COMPTE_RESULTAT_BILAN-----------##

// ##-----------UPDATE_COMPTE_RESULTAT_BILAN-----------##
export const updateCompteResultatBilan = (data) => {
  return { type: ActionsTypes.UPDATE_COMPTE_RESULTAT_BILAN, payload: data };
};

export const updateCompteResultatBilanSuccess = (response) => {
  return { type: ActionsTypes.UPDATE_COMPTE_RESULTAT_BILAN_SUCCESS, response };
};

export const updateCompteResultatBilanFail = (error) => {
  return { type: ActionsTypes.UPDATE_COMPTE_RESULTAT_BILAN_FAIL, error };
};
// ##-----------END_UPDATE_COMPTE_RESULTAT_BILAN-----------##

// ##-----------DELETE_COMPTE_RESULTAT_BILAN-----------##
export const deleteCompteResultatBilan = (data) => {
  return { type: ActionsTypes.DELETE_COMPTE_RESULTAT_BILAN, payload: data };
};

export const deleteCompteResultatBilanSuccess = (response) => {
  return { type: ActionsTypes.DELETE_COMPTE_RESULTAT_BILAN_SUCCESS, response };
};

export const deleteCompteResultatBilanFail = (error) => {
  return { type: ActionsTypes.DELETE_COMPTE_RESULTAT_BILAN_FAIL, error };
};
// ##-----------END_DELETE_COMPTE_RESULTAT_BILAN-----------##

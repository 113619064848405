import { Alert, Box, Chip, Divider, FormControl, Grid, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/state/CompaniesAction";
import * as modalActions from "../modalActionTypes";
import { grey } from "@mui/material/colors";
import { getErrorMessage } from "common/utils/Helper";

export default function ConfirmCompany({ setOpen, companyId, setCompanyId, setAction }) {
  const dispatch = useDispatch();

  const [errorSB, setErrorSB] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  useEffect(() => {
    dispatch(actions.editCompany(companyId));
  }, []);

  const company = useSelector((state) => state?.companies?.company);
  const companyErrorStatus = useSelector((state) => state?.companies?.error?.response?.status);

  const closeModal = () => {
    setAction(modalActions.ADD);
    setCompanyId("");
    setOpen(false);
  };

  const onSubmit = (values) => {
    const request = {
      payload: values,
      successCallBack: (response) => {
        closeErrorSB();
        dispatch(actions.getCompanies());
        closeModal();
        console.log("response", response);
      },
      failCallBack: (error) => {
        console.log("error", error);
        setErrorMessage(getErrorMessage(error, companyErrorStatus));
        openErrorSB();
      },
    };
    dispatch(actions.deleteCompany(request));
  };

  const { handleSubmit, touched } = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: company?.id,
      // assurtrad_id: company?.assurtrad_id,
      // raison_social: company?.raison_social,
    },
    onSubmit,
  });
  return (
    <MDBox my={1}>
      {errorSB && (
        <Typography textAlign="center" color="error" variant="body2">
          {errorMessage} {" < Echec de suppresion >"}
        </Typography>
      )}
      <form onSubmit={handleSubmit}>
        <MDBox py={1}>
          <Alert severity="error">
            Etes vous sure de vouloir supprimer - {company?.raison_social}
          </Alert>
        </MDBox>
        <Divider />
        <Box mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box mr={1}>
            <MDButton type="submit" variant="contained" size="small" color="error">
              Supprimer
            </MDButton>
          </Box>
          <Box mr={1}>
            <MDButton
              size="small"
              onClick={closeModal}
              style={{ background: grey[500], color: "white" }}
            >
              Annuler
            </MDButton>
          </Box>
        </Box>
      </form>
    </MDBox>
  );
}

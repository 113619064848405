import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/state/FinancialProfilesAction";
import { Box, Icon, IconButton } from "@mui/material";
import * as modalActions from "../modalActionTypes";
import { useLocation } from "react-router-dom";
// Function to format numbers with spaces
function formatNumberWithSpaces(input) {
  if (isNaN(input)) {
     return input;
  }
  const roundedNum = Math.round(input);
  const numStr = roundedNum.toString();
  const formattedStr = numStr.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return formattedStr;
 }
export default function Data(setOpen, setFinancialProfileId, setAction) {
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    let data = {}
    if (location?.state?.id) {
      data = { companyId: location?.state?.id };
    }
    dispatch(actions.getFinancialProfiles(data));
  }, []);

  const handleOpenDelete = (id) => {
    setOpen(true);
    setFinancialProfileId(id);
    setAction(modalActions.DELETE);
  };
  const handleOpenEdit = (id) => {
    // console.log("edit id", id);
    setOpen(true);
    setFinancialProfileId(id);
    setAction(modalActions.UPDATE);
  };

  const financialProfiles = useSelector((state) => state?.financialProfiles?.financialProfiles);
  const financialProfilesRows = financialProfiles && financialProfiles.length != 0 ? financialProfiles?.data.map((financialProfile, i) => ({
    company_id: financialProfile?.company?.raison_social,
    taux_change: formatNumberWithSpaces(financialProfile?.taux_change),
    total_prod_exploitations: formatNumberWithSpaces(financialProfile?.total_prod_exploitations),
    resultat_courant_avant_impots: formatNumberWithSpaces(financialProfile?.resultat_courant_avant_impots),
    benefice_perte: formatNumberWithSpaces(financialProfile?.benefice_perte),
    capacite_autofin_avant_rep: formatNumberWithSpaces(financialProfile?.capacite_autofin_avant_rep),
    total_actif: formatNumberWithSpaces(financialProfile?.total_actif),
    capitaux_propres: formatNumberWithSpaces(financialProfile?.capitaux_propres),
    liquidite_reduite: formatNumberWithSpaces(financialProfile?.liquidite_reduite),
    performance: formatNumberWithSpaces(financialProfile?.performance),
    rend_capitaux_propres_nets: formatNumberWithSpaces(financialProfile?.rend_capitaux_propres_nets),
    ratio_solvabilite: formatNumberWithSpaces(financialProfile?.rend_capitaux_propres_nets),
    rend_capital_investi: formatNumberWithSpaces(financialProfile?.rend_capital_investi),
    effectifs: formatNumberWithSpaces(financialProfile?.effectifs), // Correctly format 'effectifs'
    year: financialProfile?.year,
    action: (
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box>
          <IconButton
            color="info"
            onClick={() => handleOpenEdit(financialProfile.id)}
            component="label"
          >
            <Icon color="info" fontSize="small">
              mode
            </Icon>
          </IconButton>
        </Box>
        <Box>
          <IconButton
            color="primary"
            onClick={() => handleOpenDelete(financialProfile.id)}
            component="label"
          >
            <Icon color="primary" fontSize="small">
              delete
            </Icon>
          </IconButton>
        </Box>
      </Box>
    ),
 })) : [];


  console.log("financialProfiles",financialProfilesRows)
  return {
    columns: [
      { Header: "année", accessor: "year", align: "left" },
      { Header: "entreprise", accessor: "company_id", align: "left" },
      { Header: "taux change", accessor: "taux_change", align: "left" },
      { Header: "total des prod d'exploitation", accessor: "total_prod_exploitations", align: "left" },
      { Header: "bénéfice ou perte [= revenu net]", accessor: "benefice_perte", align: "left" },
      { Header: "capacité d’autofin. avant rép", accessor: "capacite_autofin_avant_rep", align: "left" },
      { Header: "total de l'actif", accessor: "total_actif", align: "left" },
      { Header: "capitaux propres", accessor: "capitaux_propres", align: "left" },
      { Header: "liquidité reduite (x)", accessor: "liquidite_reduite", align: "left" },
      { Header: "performance (%)", accessor: "performance", align: "left" },
      { Header: "rend. des capitaux propres nets (%)", accessor: "rend_capitaux_propres_nets", align: "left" },
      { Header: "rend. du capital investi (%)", accessor: "rend_capital_investi", align: "left" }, // Assuming a unique accessor for this column
      { Header: "ratio de solvabilité (à partir de l'actif) (%)", accessor: "ratio_solvabilite", align: "left" },
      { Header: "effectifs", accessor: "effectifs", align: "left" },
      { Header: "action", accessor: "action", align: "left" },
     ]
     ,

    rows: financialProfilesRows,
  };
}

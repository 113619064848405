import { useEffect } from "react";
import * as ActionsTypes from "../../../../common/state/StatesConstants";

const initialStateShareholdings = {
  error: null,
  isLoading: false,
  shareholdings: [],
  shareholding: {},
};

// const initialStateShareholding = {
//   error: null,
//   isLoading: false,
//   shareholding: {},
// };

const shareholdingsReducer = (
  stateShareholdings = initialStateShareholdings,
  // stateShareholding = initialStateShareholding,
  action
) => {
  switch (action.type) {
    // ##-----------GET_SHAREHOLDINGS-----------##
    case ActionsTypes.GET_SHAREHOLDINGS:
      return {
        ...stateShareholdings,
        isLoading: true,
        error: null,
        shareholdings: [],
      };

    case ActionsTypes.GET_SHAREHOLDINGS_SUCCESS:
      return {
        ...stateShareholdings,
        isLoading: false,
        error: null,
        shareholdings: action.response.data.data,
      };

    case ActionsTypes.GET_SHAREHOLDINGS_FAIL:
      return {
        ...stateShareholdings,
        isLoading: false,
        error: action.error,
        shareholdings: [],
      };
    // ##-----------END_GET_SHAREHOLDINGS-----------##

    // ##-----------ADD_SHAREHOLDING-----------##
    case ActionsTypes.ADD_SHAREHOLDING:
      return {
        ...stateShareholdings,
        isLoading: true,
        error: null,
        //shareholdings: [...stateShareholdings.shareholdings],
      };

    case ActionsTypes.ADD_SHAREHOLDING_SUCCESS:
      return {
        ...stateShareholdings,
        isLoading: false,
        error: null,
        //shareholdings: [...stateShareholdings.shareholdings, action.response.data.data],
      };

    case ActionsTypes.ADD_SHAREHOLDING_FAIL:
      return {
        ...stateShareholdings,
        isLoading: false,
        error: action.error,
        //shareholdings: [...stateShareholdings.shareholdings],
      };
    // ##-----------END_ADD_SHAREHOLDING-----------##

    // ##-----------EDIT_SHAREHOLDING-----------##
    case ActionsTypes.EDIT_SHAREHOLDING:
      return {
        ...stateShareholdings,
        isLoading: true,
        error: null,
        shareholding: {},
      };

    case ActionsTypes.EDIT_SHAREHOLDING_SUCCESS:
      return {
        ...stateShareholdings,
        isLoading: false,
        error: null,
        shareholding: action.response.data.data,
      };

    case ActionsTypes.EDIT_SHAREHOLDING_FAIL:
      return {
        ...stateShareholdings,
        isLoading: false,
        error: action.error,
        shareholding: {},
      };
    // ##-----------END_EDIT_SHAREHOLDING-----------##

    // ##-----------UPDATE_SHAREHOLDING-----------##
    case ActionsTypes.UPDATE_SHAREHOLDING:
      return {
        ...stateShareholdings,
        isLoading: true,
        error: null,
        //shareholdings: [...stateShareholdings.shareholdings],
      };

    case ActionsTypes.UPDATE_SHAREHOLDING_SUCCESS:
      return {
        ...stateShareholdings,
        isLoading: false,
        error: null,
        // shareholdings: [
        //   ...stateShareholdings.shareholdings.filter(
        //     (shareholding) => shareholding.id != action.response.data.data.id
        //   ),
        //   action.response.data.data,
        // ],
      };

    case ActionsTypes.UPDATE_SHAREHOLDING_FAIL:
      return {
        ...stateShareholdings,
        isLoading: false,
        error: action.error,
        //shareholdings: [...stateShareholdings.shareholdings],
      };
    // ##-----------END_UPDATE_SHAREHOLDING-----------##

    // ##-----------DELETE_SHAREHOLDING-----------##
    case ActionsTypes.DELETE_SHAREHOLDING:
      return {
        ...stateShareholdings,
        isLoading: true,
        error: null,
        //shareholdings: [...stateShareholdings.shareholdings],
      };

    case ActionsTypes.DELETE_SHAREHOLDING_SUCCESS:
      return {
        ...stateShareholdings,
        isLoading: false,
        error: null,
        // shareholdings: [...stateShareholdings.shareholdings, stateShareholdings.shareholdings.filter((shareholding)=>shareholding.id!=action.response.data.data.id)],
        //shareholdings: [...stateShareholdings.shareholdings],
      };

    case ActionsTypes.DELETE_SHAREHOLDING_FAIL:
      return {
        ...stateShareholdings,
        isLoading: false,
        error: action.error,
        //shareholdings: [...stateShareholdings.shareholdings],
      };
    // ##-----------END_DELETE_SHAREHOLDING-----------##

    default:
      return stateShareholdings;
  }
};

export default shareholdingsReducer;

import { Box, Card, Grid, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import DataTable from "examples/Tables/DataTable";
import React from "react";
import compteResultatBilansTableData from "../data/compteResultatBilansTableData";

function CompteResultatBilanTable() {
  const { columns, rows } = compteResultatBilansTableData();
  return (
    <MDBox py={2}>
      <Typography py={2} px={1} variant="h5">
        Compte et Resultat Bilan
      </Typography>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox pt={3}>
              <DataTable
                table={{ columns, rows }}
                isSorted={false}
                //entriesPerPage={true}
                entriesPerPage={{ defaultValue: 20 }}
                showTotalEntries={false}
                noEndBorder
              />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default CompteResultatBilanTable;

import { Box, Card, Grid, Icon, Typography, useMediaQuery } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import countryProfilesTableData from "./data/countryProfilesTableData";

function CountryProfiles({ companyId }) {
  const { columns, rows } = countryProfilesTableData();
  const media600 = useMediaQuery("(min-width:600px)");
  const { companySlug } = useParams();

  const country = useSelector((state) => state?.companies?.company?.country);

  return (
    <MDBox p={1}>
      <Grid container>
        <Grid item xs={6} sm={6} md={6}>
          <Typography component="div" pb={2} variant="h4" align="left">
            Profile de pays
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Box sx={{ textAlign: "right" }}>
            <Link
              to={`/pays/${country?.id}/${country?.slug}`}
              state={{
                id: companyId,
                slug: companySlug,
              }}
            >
              <MDButton p={0} type="submit" color="primary">
                <Icon fontSize="small">settings-icon</Icon>
                {media600 ? (
                  <Typography variant="p" pl={1}>
                    Paramétrage
                  </Typography>
                ) : (
                  ""
                )}
              </MDButton>
            </Link>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox pt={3}>
              {
                rows && 
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={{ defaultValue:15 }}
                  showTotalEntries={false}
                  noEndBorder
                />
              }
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default CountryProfiles;

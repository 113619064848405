import { Card, Grid, Icon, Pagination, Stack } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import * as modalActions from "./modalActionTypes";
import React, { useEffect, useState } from "react";
// Data
import productServicesTableData from "layouts/productServices/data/productServicesTableData";
import DataTable from "examples/Tables/DataTable";
import { Box } from "@mui/system";
import MDButton from "components/MDButton";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "./redux/state/ProductServicesAction";
import ProductServiceModal from "./components/ProductServiceModal";
import FormUpdateProductService from "./components/FormUpdateProductService";
import FormAddProductService from "./components/FormAddProductService";
import ConfirmDeleteProductService from "./components/ConfirmDeleteProductService";

function ProductServices() {
  const [open, setOpen] = useState(false);
  const [productServiceId, setProductServiceId] = useState("");
  const [action, setAction] = useState(modalActions.ADD);
  const [page, setPage] = useState(1);

  const handleOpen = () => {
    setAction(modalActions.ADD);
    setProductServiceId("");
    setOpen(true);
  };
  const changePage=(event, value)=>{
    console.log(value);
    setPage(old=>value)
  }
  const { columns, rows, productServicePageInfo } = productServicesTableData(setOpen, setProductServiceId, setAction, page);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box mr={1}>
          <MDButton onClick={handleOpen} to="#" variant="contained" size="small" color="info">
            <Icon>add</Icon> Produit ou service
          </MDButton>
        </Box>
      </Box>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Prouduits et services
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                { rows && 
                <>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                  pagination={false}
                />
                <Box sx={{ mt: 2}} style={{ paddingBottom:"20px", paddingLeft:"20px" }}>
                    <Stack spacing={2}>
                        <Pagination
                        count={productServicePageInfo.last_page} 
                        variant="outlined" 
                        shape="rounded"
                        page={page} 
                        onChange={changePage}
                        />
                    </Stack>
                </Box>
                </>
                }
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <ProductServiceModal
        open={open}
        setOpen={setOpen}
        title={
          action == modalActions.UPDATE
            ? "Modifier"
            : action == modalActions.DELETE
            ? "Supprimer"
            : "Ajouter"
        }
      >
        {/* ADD MODAL */}
        {action == modalActions.ADD && (
          <FormAddProductService
            setOpen={setOpen}
            setAction={setAction}
            setProductServiceId={setProductServiceId}
          />
        )}

        {/* UPDATE MODAL */}
        {action == modalActions.UPDATE && (
          <FormUpdateProductService
            setOpen={setOpen}
            setAction={setAction}
            setProductServiceId={setProductServiceId}
            productServiceId={productServiceId}
          />
        )}

        {/* DELETE MODAL */}
        {action == modalActions.DELETE && (
          <ConfirmDeleteProductService
            setOpen={setOpen}
            setAction={setAction}
            setProductServiceId={setProductServiceId}
            productServiceId={productServiceId}
          />
        )}
      </ProductServiceModal>
    </DashboardLayout>
  );
}
export default ProductServices;

import { Alert, FormControl, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { Form, Formik, useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/state/CountriesAction";
import * as modalActions from "../modalActionTypes";
import { addCountrySchema } from "../schema";
import { useNavigate } from "react-router-dom";
import { getErrorMessage } from "common/utils/Helper";

export default function FormAddCountry({ setOpen, setCountryId, setAction }) {
  const [errorSB, setErrorSB] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const onSubmit = (values) => {
    const request = {
      payload: values,
      successCallBack: (response) => {
        closeErrorSB();
        dispatch(actions.getCountries());
        setAction(modalActions.ADD);
        setCountryId("");
        setOpen(false);
        console.log("response", response);
      },
      failCallBack: (error) => {
        console.log("error", error);
        setErrorMessage(getErrorMessage(error));
        openErrorSB();
      },
    };
    dispatch(actions.addCountry(request));
  };
  const handleClose = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { handleSubmit, errors, values, handleChange, touched } = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: addCountrySchema,
    onSubmit,
  });
  return (
    <MDBox m={1}>
      {errorSB && <Alert severity="error">{errorMessage}</Alert>}
      <form onSubmit={handleSubmit}>
        <MDBox py={2}>
          <FormControl fullWidth>
            <MDInput
              id="name"
              label="Nom de pays"
              error={errors.name ? true : false}
              onChange={handleChange}
              value={values.name}
              helperText={errors?.name ?? ""}
            />
            {/* {errors.name && touched.name ? <div>{errors.name}</div> : null} */}
          </FormControl>
        </MDBox>

        <MDBox sx={{ display: "flex" }}>
          <MDBox mr={1} sx={{ flexGrow: "1" }}>
            <MDButton onClick={handleClose} type="submit" color="primary" fullWidth>
              Annuler
            </MDButton>
          </MDBox>
          <MDBox sx={{ flexGrow: "1" }}>
            <MDButton type="submit" color="info" fullWidth>
              Ajouter
            </MDButton>
          </MDBox>
        </MDBox>
      </form>
    </MDBox>
  );
}

import { instance } from "../../utils/Axios";

export const getCountriesApi = (data = {}) => {
  return instance
    .get(`/countries?sort=${data.sort ?? ""}&order=${data.order ?? ""}`, data)
    .then((response) => {
      return response;
    });
};
export const addCountryApi = (data) => {
  return instance.post("/countries", data).then((response) => {
    return response;
  });
};

export const editCountryApi = (id) => {
  return instance.get(`/countries/${id}`).then((response) => {
    return response;
  });
};

export const updateCountryApi = (data) => {
  return instance.put(`/countries/${data.id}`, data).then((response) => {
    return response;
  });
};

export const deleteCountryApi = (data) => {
  console.log("delete data", data);
  return instance.delete(`/countries/${data.id}`).then((response) => {
    return response;
  });
};

import * as ActionsTypes from "../../../../common/state/StatesConstants";

// ##-----------GET_COUNTRY_PROFILES-----------##
export const getCountryProfiles = (data) => {
  return { type: ActionsTypes.GET_COUNTRY_PROFILES, payload: data };
};

export const getCountryProfilesSuccess = (response) => {
  return { type: ActionsTypes.GET_COUNTRY_PROFILES_SUCCESS, response };
};

export const getCountryProfilesFail = (error) => {
  return { type: ActionsTypes.GET_COUNTRY_PROFILES_FAIL, error };
};
// ##-----------END_GET_COUNTRY_PROFILES-----------##

// ##-----------ADD_COUNTRY_PROFILE-----------##
export const addCountryProfile = (data) => {
  return { type: ActionsTypes.ADD_COUNTRY_PROFILE, payload: data };
};

export const addCountryProfileSuccess = (response) => {
  return { type: ActionsTypes.ADD_COUNTRY_PROFILE_SUCCESS, response };
};

export const addCountryProfileFail = (error) => {
  return { type: ActionsTypes.ADD_COUNTRY_PROFILE_FAIL, error };
};
// ##-----------END_ADD_COUNTRY_PROFILE-----------##

// ##-----------EDIT_COUNTRY_PROFILE-----------##
export const editCountryProfile = (data) => {
  return { type: ActionsTypes.EDIT_COUNTRY_PROFILE, payload: data };
};

export const editCountryProfileSuccess = (response) => {
  return { type: ActionsTypes.EDIT_COUNTRY_PROFILE_SUCCESS, response };
};

export const editCountryProfileFail = (error) => {
  return { type: ActionsTypes.EDIT_COUNTRY_PROFILE_FAIL, error };
};
// ##-----------END_EDIT_COUNTRY_PROFILE-----------##

// ##-----------UPDATE_COUNTRY_PROFILE-----------##
export const updateCountryProfile = (data) => {
  return { type: ActionsTypes.UPDATE_COUNTRY_PROFILE, payload: data };
};

export const updateCountryProfileSuccess = (response) => {
  return { type: ActionsTypes.UPDATE_COUNTRY_PROFILE_SUCCESS, response };
};

export const updateCountryProfileFail = (error) => {
  return { type: ActionsTypes.UPDATE_COUNTRY_PROFILE_FAIL, error };
};
// ##-----------END_UPDATE_COUNTRY_PROFILE-----------##

// ##-----------DELETE_COUNTRY_PROFILE-----------##
export const deleteCountryProfile = (data) => {
  return { type: ActionsTypes.DELETE_COUNTRY_PROFILE, payload: data };
};

export const deleteCountryProfileSuccess = (response) => {
  return { type: ActionsTypes.DELETE_COUNTRY_PROFILE_SUCCESS, response };
};

export const deleteCountryProfileFail = (error) => {
  return { type: ActionsTypes.DELETE_COUNTRY_PROFILE_FAIL, error };
};
// ##-----------END_DELETE_COUNTRY_PROFILE-----------##
import { put, call, delay } from "redux-saga/effects";
import * as actions from "../state/ProductServicesAction";
import {
  getProductServicesApi,
  addProductServiceApi,
  editProductServiceApi,
  updateProductServiceApi,
  deleteProductServiceApi,
} from "../../../../common/services/Apis/productServicesApi";

export function* getProductServicesSaga(data = {}) {
  try {
    const response = yield call(getProductServicesApi, data?.payload);
    yield put(actions.getProductServicesSuccess(response));
  } catch (error) {
    yield put(actions.getProductServicesFail(error));
  }
}
export function* addProductServiceSaga(action) {
  try {
    const response = yield call(addProductServiceApi, action?.payload?.payload);
    if (response) {
      yield put(actions.addProductServiceSuccess(response));
      yield call(action.payload.successCallBack, response);
    }
  } catch (error) {
    yield put(actions.addProductServiceFail(error));
    yield call(action.payload.failCallBack, error);
  }
}
export function* editProductServiceSaga(action) {
  try {
    const response = yield call(editProductServiceApi, action.payload);
    yield put(actions.editProductServiceSuccess(response));
  } catch (error) {
    yield put(actions.editProductServiceFail(error));
  }
}

export function* updateProductServiceSaga(action) {
  try {
    const response = yield call(updateProductServiceApi, action?.payload?.payload);
    if (response) {
      yield put(actions.updateProductServiceSuccess(response));
      yield call(action.payload.successCallBack, response);
    }
  } catch (error) {
    yield put(actions.updateProductServiceFail(error));
    yield call(action.payload.failCallBack, error);
  }
}

export function* deleteProductServiceSaga(action) {
  try {
    const response = yield call(deleteProductServiceApi, action?.payload?.payload);
    yield put(actions.deleteProductServiceSuccess(response));
    yield call(action.payload.successCallBack, response);
  } catch (error) {
    yield put(actions.deleteProductServiceFail(error));
    yield call(action.payload.failCallBack, error);
  }
}

import * as ActionsTypes from "../../../../common/state/StatesConstants";
const initialStateAuth = {
  error: null,
  isLoading: false,
  auth: {},
};

const AuthReducer = (stateAuth = initialStateAuth, action) => {
  switch (action.type) {
    // ##-----------LOGIN_USER-----------##
    case ActionsTypes.LOGIN_USER:
      return {
        ...stateAuth,
        isLoading: true,
        error: null,
        auth: {},
      };
    case ActionsTypes.LOGIN_USER_SUCCESS:
      return {
        ...stateAuth,
        isLoading: false,
        error: null,
        auth: action.response.data,
      };
    case ActionsTypes.LOGIN_USER_FAIL:
      return {
        ...stateAuth,
        isLoading: false,
        error: action.error,
        auth: {},
      };
    default:
      return stateAuth;
  }
};

export default AuthReducer;

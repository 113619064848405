import {
  Alert,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateCompteDeResultatBilanSchema } from "../schema";
import * as actions from "../redux/state/CompteDeResultatBilansAction";
import * as actionsCompanies from "../../../../companies/redux/state/CompaniesAction";
import * as modalActions from "../modalActionTypes";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useLocation } from "react-router-dom";
import { getErrorMessage } from "common/utils/Helper";

export default function FormUpdateCompteDeResultatBilan({
  setOpen,
  compteDeResultatBilanId,
  setCompteDeResultatBilanId,
  setAction,
  companyId,
  setCompanyId,
}) {
  const dispatch = useDispatch();
  const location = useLocation();

  const [errorSB, setErrorSB] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  // order select companies
  const orderCompanies = {
    sort: "raison_social",
    order: "asc",
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    dispatch(actions.editCompteDeResultatBilan(compteDeResultatBilanId));
    dispatch(actionsCompanies.getCompanies(orderCompanies));
  }, []);
  const compteDeResultatBilan = useSelector(
    (state) => state?.compteDeResultatBilans?.compteDeResultatBilan
  );
  const [value, setValue] = useState(dayjs(compteDeResultatBilan?.date));

  const companies = useSelector((state) => state?.companies.companies);

  const onSubmit = (values) => {
    const request = {
      payload: values,
      successCallBack: (response) => {
        closeErrorSB()
        setCompanyId(values?.company_id ?? companyId);
        let queryParam = {};
        if (
          (companyId.toString().length > 0 && companyId !== undefined) ||
          (values?.company_id.toString().length > 0 && values?.company_id !== undefined)
        ) {
          queryParam = { companyId: values?.company_id ?? companyId, sort: "date", order: "desc" };
        }
        dispatch(actions.getCompteDeResultatBilans(queryParam));
        setAction(modalActions.ADD);
        setCompteDeResultatBilanId("");
        setOpen(false);
        console.log("response", response);
      },
      failCallBack: (error) => {
        console.log("error", error);
        setErrorMessage(getErrorMessage(error))
        openErrorSB();
      },
    };
    dispatch(actions.updateCompteDeResultatBilan(request));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: compteDeResultatBilan?.id,
      achat_mat_prem_approv: compteDeResultatBilan?.achat_mat_prem_approv,
      added_value: compteDeResultatBilan?.added_value,
      autre_element_exploitation: compteDeResultatBilan?.autre_element_exploitation,
      capacite_autofin_avant_rep: compteDeResultatBilan?.capacite_autofin_avant_rep,
      company_id: location?.state?.id ?? "",
      date: compteDeResultatBilan?.date,
      depreciation_ammortissement: compteDeResultatBilan?.depreciation_ammortissement,
      ebitda: compteDeResultatBilan?.ebitda,
      export_turnover: compteDeResultatBilan?.export_turnover,
      interest_paid: compteDeResultatBilan?.interest_paid,
      r_d_expenses: compteDeResultatBilan?.r_d_expenses,
      salaries_traitement: compteDeResultatBilan?.salaries_traitement,
      company_id: location?.state?.id ?? "",
    },
    validationSchema: updateCompteDeResultatBilanSchema,
    onSubmit,
  });
  const disabledSelect = location?.state?.id ? true: false;

  const { handleSubmit, errors, values, handleChange } = formik;
  return (
    <MDBox m={1}>
      {errorSB && (
        <Alert severity="error">
          {errorMessage}
        </Alert>
      )}
      {compteDeResultatBilan && values.id!==undefined &&
      <form onSubmit={handleSubmit}>
        <MDBox py={2}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <MDInput
                    name="export_turnover"
                    label="C.A à l'export"
                    error={errors.export_turnover ? true : false}
                    onChange={handleChange}
                    value={values.export_turnover?.toString().replace(",", ".") ?? ""}
                    helperText={errors?.export_turnover ?? ""}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <MDInput
                    name="achat_mat_prem_approv"
                    label="Achat mat prem approv"
                    error={errors.achat_mat_prem_approv ? true : false}
                    onChange={handleChange}
                    value={values.achat_mat_prem_approv?.toString().replace(",", ".") ?? ""}
                    helperText={errors?.achat_mat_prem_approv ?? ""}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <MDInput
                    name="salaries_traitement"
                    label="Salaries traitement"
                    error={errors.salaries_traitement ? true : false}
                    onChange={handleChange}
                    value={values.salaries_traitement?.toString().replace(",", ".") ?? ""}
                    helperText={errors?.salaries_traitement ?? ""}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <MDInput
                    name="depreciation_ammortissement"
                    label="Dépréciation et Amortissement"
                    error={errors.depreciation_ammortissement ? true : false}
                    onChange={handleChange}
                    value={values.depreciation_ammortissement?.toString().replace(",", ".") ?? ""}
                    helperText={errors?.depreciation_ammortissement ?? ""}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <MDInput
                    name="autre_element_exploitation"
                    label="Autre element exploitation"
                    error={errors.autre_element_exploitation ? true : false}
                    onChange={handleChange}
                    value={values.autre_element_exploitation?.toString().replace(",", ".") ?? ""}
                    helperText={errors?.autre_element_exploitation ?? ""}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <MDInput
                    name="interest_paid"
                    label="Intérêts payés"
                    error={errors.interest_paid ? true : false}
                    onChange={handleChange}
                    value={values.interest_paid?.toString().replace(",", ".") ?? ""}
                    helperText={errors?.interest_paid ?? ""}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <MDInput
                    name="r_d_expenses"
                    label="Dépenses de R&D"
                    error={errors.r_d_expenses ? true : false}
                    onChange={handleChange}
                    value={values.r_d_expenses?.toString().replace(",", ".") ?? ""}
                    helperText={errors?.r_d_expenses ?? ""}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <MDInput
                    name="capacite_autofin_avant_rep"
                    label="Capacité d’autofin. avant rép"
                    error={errors.capacite_autofin_avant_rep ? true : false}
                    onChange={handleChange}
                    value={values.capacite_autofin_avant_rep?.toString().replace(",", ".") ?? ""}
                    helperText={errors?.capacite_autofin_avant_rep ?? ""}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <MDInput
                    name="added_value"
                    label="Valeur ajoutée"
                    error={errors.added_value ? true : false}
                    onChange={handleChange}
                    value={values.added_value?.toString().replace(",", ".") ?? ""}
                    helperText={errors?.added_value ?? ""}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <MDInput
                    name="ebitda"
                    label="EBITDA"
                    error={errors.ebitda ? true : false}
                    onChange={handleChange}
                    value={values.ebitda?.toString().replace(",", ".") ?? ""}
                    helperText={errors?.ebitda ?? ""}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      renderInput={(props) => <TextField {...props} />}
                      value={values.date}
                      name="date"
                      error={errors.date ? true : false}
                      onChange={(newValue) => {
                        formik.setFieldValue("date", dayjs(newValue).format("YYYY-MM-DD")); // December 22nd 2022, 10:01:13 pm);
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="company_id">Entreprise</InputLabel>
                  <Select
                    name="company_id"
                    style={{ height: "40px" }}
                    labelId="state_label"
                    label="Entreprise"
                    error={errors.company_id ? true : false}
                    onChange={handleChange}
                    value={values.company_id}
                    disabled={disabledSelect}
                  >
                    {companies?.data?.map((company) => {
                      return (
                        <MenuItem key={company?.id} value={company?.id}>
                        <p>
                            <b>{company?.raison_social}</b> ({company?.country?.name})
                        </p>
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {errors.country_id ? (
                    <FormHelperText error>{errors?.country_id}</FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
            </Grid>
        </MDBox>
        <MDBox sx={{ display: "flex" }}>
            <MDBox mr={1} sx={{ flexGrow: "1" }}>
              <MDButton onClick={handleClose} type="submit" color="primary" fullWidth>
                Annuler
              </MDButton>
            </MDBox>
            <MDBox sx={{ flexGrow: "1" }}>
              <MDButton type="submit" color="info" fullWidth>
                Modifier
              </MDButton>
            </MDBox>
        </MDBox>
      </form>
      }
    </MDBox>
  );
}

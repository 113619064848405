import { Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import React from "react";
import { Link } from "react-router-dom";

const SearchResultCards = ({
  result,
  companyId,
  companySlug,
  raison_social,
  date_creation,
  country,
  product_services,
  numero_rc,
}) => {
   console.log(result)
  return (
    <MDBox py={1}>
      <Link to={`/rapport/${companySlug}`}>
        <Card variant="contained" sx={{ display: "flex", flexDirection: "row" }}>
          <CardActionArea
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
            }}
          >
            <MDBox
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                width: "100%",
              }}
            >
              <CardMedia
                component="img"
                sx={{ width: 151 }}
                image={`https://logo.clearbit.com/${result.website}`}
                alt="Logo"
              />
              <MDBox sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography component="div" variant="h5" pb={2}>
                        {raison_social}
                      </Typography>
                      
                      {result?.adresse_siege_social}
                      
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="" color="text.dark" component="div">
                        <div style={{ padding:10 }}>
                          <b>{result?.productSercice?.name}</b>
                        </div>
                        <div style={{ padding:10 }}>
                          {result?.numero_ice}
                        </div>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="" color="text.dark" component="div">
                        <div style={{ padding:10 }}>
                          <b>{result?.info_companies?.total_produits_exploitation} {result.devise} ({result?.info_companies?.year_total_produits_exploitation})</b>
                        </div>
                        <div style={{ padding:10 }}>
                          {result?.financial_profiles[0]?.effectifs} Employees
                        </div>
                      </Typography>
                    </Grid>
                  </Grid>
                  
                  
                </CardContent>
              </MDBox>
            </MDBox>
            {/* <MDBox p={2} sx={{ display: "flex", justifyContent: "end" }}>
            <MDButton color="info">Details</MDButton>
          </MDBox> */}
          </CardActionArea>
        </Card>
      </Link>
    </MDBox>
  );
};

export default SearchResultCards;

import { Card, Grid, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import * as modalActions from "./modalActionTypes";
import React, { useEffect, useState } from "react";
// Data
import countriesTableData from "layouts/countries/data/countriesTableData";
import DataTable from "examples/Tables/DataTable";
import { Box } from "@mui/system";
import MDButton from "components/MDButton";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "./redux/state/CountriesAction";
import CountryModal from "./components/CountryModal";
import FormUpdateCountry from "./components/FormUpdateCountry";
import FormAddCountry from "./components/FormAddCountry";
import ConfirmDeleteCountry from "./components/ConfirmDeleteCountry";

function Countries() {
  const [open, setOpen] = useState(false);
  const [countryId, setCountryId] = useState("");
  const [action, setAction] = useState(modalActions.ADD);
  const handleOpen = () => {
    setAction(modalActions.ADD);
    setCountryId("");
    setOpen(true);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getCountries());
  }, []);
  // const data = useSelector((state) => state?.countries?.countries);

  const { columns, rows } = countriesTableData(setOpen, setCountryId, setAction);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box mr={1}>
          <MDButton onClick={handleOpen} to="#" variant="contained" size="small" color="info">
            <Icon>add</Icon> pays
          </MDButton>
        </Box>
      </Box>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Pays
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {
                  rows && 
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    canSearch={true}
                    noEndBorder
                  />
                }
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <CountryModal
        open={open}
        setOpen={setOpen}
        title={
          action == modalActions.UPDATE
            ? "Modifier"
            : action == modalActions.DELETE
            ? "Supprimer"
            : "Ajouter"
        }
      >
        {/* ADD MODAL */}
        {action == modalActions.ADD && (
          <FormAddCountry setOpen={setOpen} setAction={setAction} setCountryId={setCountryId} />
        )}

        {/* UPDATE MODAL */}
        {action == modalActions.UPDATE && (
          <FormUpdateCountry
            setOpen={setOpen}
            setAction={setAction}
            setCountryId={setCountryId}
            countryId={countryId}
          />
        )}

        {/* DELETE MODAL */}
        {action == modalActions.DELETE && (
          <ConfirmDeleteCountry
            setOpen={setOpen}
            setAction={setAction}
            setCountryId={setCountryId}
            countryId={countryId}
          />
        )}
      </CountryModal>
    </DashboardLayout>
  );
}
export default Countries;

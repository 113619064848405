import * as ActionsTypes from "../../../../common/state/StatesConstants";

// ##-----------GET_MISSING_COMPANIES-----------##
export const getMissingCompanies = (data = {}) => {
  
  console.log("missi inside getMissingCompanies")
  return { type: ActionsTypes.GET_MISSING_COMPANIES, payload: data };
};

export const getMissingCompaniesSuccess = (response) => {
  return { type: ActionsTypes.GET_MISSING_COMPANIES_SUCCESS, response };
};

export const getMissingCompaniesFail = (error) => {
  return { type: ActionsTypes.GET_MISSING_COMPANIES_FAIL, error };
};
// ##-----------END_GET_MISSING_COMPANIES-----------##

// ##-----------ADD_MISSING_COMPANY-----------##
export const addMissingCompany = (data) => {
  return { type: ActionsTypes.ADD_MISSING_COMPANY, payload: data };
};

export const addMissingCompanySuccess = (response) => {
  return { type: ActionsTypes.ADD_MISSING_COMPANY_SUCCESS, response };
};

export const addMissingCompanyFail = (error) => {
  return { type: ActionsTypes.ADD_MISSING_COMPANY_FAIL, error };
};
// ##-----------END_ADD_MISSING_COMPANY-----------##

// ##-----------EDIT_MISSING_COMPANY-----------##
export const editMissingCompany = (data) => {
  return { type: ActionsTypes.EDIT_MISSING_COMPANY, payload: data };
};

export const editMissingCompanySuccess = (response) => {
  return { type: ActionsTypes.EDIT_MISSING_COMPANY_SUCCESS, response };
};

export const editMissingCompanyFail = (error) => {
  return { type: ActionsTypes.EDIT_MISSING_COMPANY_FAIL, error };
};
// ##-----------END_EDIT_MISSING_COMPANY-----------##

// ##-----------UPDATE_MISSING_COMPANY-----------##
export const updateMissingCompany = (data) => {
  return { type: ActionsTypes.UPDATE_MISSING_COMPANY, payload: data };
};

export const updateMissingCompanySuccess = (response) => {
  return { type: ActionsTypes.UPDATE_MISSING_COMPANY_SUCCESS, response };
};

export const updateMissingCompanyFail = (error) => {
  return { type: ActionsTypes.UPDATE_MISSING_COMPANY_FAIL, error };
};
// ##-----------END_UPDATE_MISSING_COMPANY-----------##

// ##-----------DELETE_MISSING_COMPANY-----------##
export const deleteMissingCompany = (data) => {
  return { type: ActionsTypes.DELETE_MISSING_COMPANY, payload: data };
};

export const deleteMissingCompanySuccess = (response) => {
  return { type: ActionsTypes.DELETE_MISSING_COMPANY_SUCCESS, response };
};

export const deleteMissingCompanyFail = (error) => {
  return { type: ActionsTypes.DELETE_MISSING_COMPANY_FAIL, error };
};
// ##-----------END_DELETE_MISSING_COMPANY-----------##


// ##-----------UPLOAD_MISSING_COMPANIES-----------##
export const uploadMissingCompany = (data) => {
  return { type: ActionsTypes.UPLOAD_MISSING_COMPANY, payload: data };
};

export const uploadMissingCompanySuccess = (response) => {
  return { type: ActionsTypes.UPLOAD_MISSING_COMPANY_SUCCESS, response };
};

export const uploadMissingCompanyFail = (error) => {
  return { type: ActionsTypes.UPLOAD_MISSING_COMPANY_FAIL, error };
};
// ##-----------DOWNLOAD_RAPPORT-----------##
export const getRapport = (data) => {
  return { type: ActionsTypes.DOWNLOAD_RAPPORT, payload: data };
};


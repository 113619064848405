import { Badge, Box, Button, ButtonGroup, Card, Grid, Icon, Typography } from "@mui/material";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PhoneIcon from "@mui/icons-material/Phone";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import tabs from "./tabs";
import Information from "./pages/informations";
import FinancialProfile from "./pages/financialProfiles";
import Activities from "./pages/activities";
import CountryProfiles from "./pages/countryProfiles";
import Bilans from "./pages/bilans";
import Directors from "./pages/directors";
import Shareholdings from "./pages/shareholdings";
import { grey } from "@mui/material/colors";
import MDButton from "components/MDButton";
import { height } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../layouts/companies/redux/state/CompaniesAction";
import useMediaQuery from "@mui/material/useMediaQuery";
import MDBadge from "components/MDBadge";
import { instance } from "../../common/utils/Axios";
import config from "common/utils/Config";

import * as searchActions from "../search/redux/state/SearchAction";

const downloadFile = (id) => {
  // Assuming the access token is stored in sessionStorage or localStorage
  const token = localStorage.getItem(config.jwt_token_name);
 
  return instance
    .get(`/pdf/${id}`, {
      responseType: 'blob', // Set the response type to 'blob' for binary data
      headers: {
        Authorization: `Bearer ${token}`, // Include the access token in the Authorization header
      },
    })
    .then((response) => {
      // Extracting file name from response headers
      let fileName = response.headers['content-disposition'].split('filename=')[1];
      // Creating a blob from the response data
      let blob = new Blob([response.data], { type: response.headers['content-type'] });
      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);
      // Create a temporary anchor element to trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName); // Use the extracted file name
      document.body.appendChild(link);
      link.click();
      // Clean up: remove the link element and revoke the blob URL
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => {
      console.error('There was a problem with the fetch operation:', error);
    });
};
function Rapport() {
  const minWidth900 = useMediaQuery("(min-width:900px)");

  const { companySlug } = useParams();
  const location = useLocation();
  const [toggleState, setToggleState] = useState(location?.state?.rapportTab ?? 1);

  console.log(location?.state);
  const dispatch = useDispatch();
  const toggleTab = (index) => {
    setToggleState(index);
    if (location?.state?.rapportTab) {
      location.state.rapportTab = index;
    }
    // location.replace({...location, state:{rapportTab : index}})
    console.log(location?.state);
  };

  useEffect(() => {
    companySlug && dispatch(actions.editCompany(companySlug));
  }, [companySlug]);

  const company = useSelector((state) => state?.companies?.company);

  
  useEffect(() => {
    dispatch(searchActions.getMe());
  }, []);
  
  const me = useSelector((state) => state?.search.me);

  useEffect(() => {
    console.log(company)
    location?.state?.rapportTab && company?.id && setToggleState(location?.state?.rapportTab);
    // if (company.id == undefined) {
    //   companyId && dispatch(actions.editCompany(companyId));
    // }
  }, [company]);

  const renderTabs = tabs.map(({ index, name, icon, title, noCollapse, key, href, route }, i) => {
    let returnValue;

    returnValue = (
      <Button
        sx={{ minHeight: "50px", width: 1, display: "block", padding: "auto" }}
        key={key}
        color="white"
        variant={toggleState === index ? "contained" : ""}
        onClick={() => toggleTab(index)}
      >
        {/* <Box sx={{ display:"block", padding:"auto" }}> */}
        <Box>{icon}</Box>
        <Box>{name}</Box>
        {/* </Box> */}
      </Button>
    );

    return returnValue;
  });

  console.log("company : ",company)
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box styl={{ width: "100%" }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={2}>
            <Card>
              <Box m={2} sx={{ height: '480px', overflowY: 'auto' }}> {/* Adjust the height as needed */}
                <Grid container spacing={1}>
                 <Grid item xs={12} sm={12} md={12}>
                    <Typography align="center" variant="h6">
                      <img src={`https://logo.clearbit.com/${company?.website}`}/>
                    </Typography>
                 </Grid>
                 <Grid item xs={12} sm={12} md={12}>
                    <Typography align="center" variant="h6">
                      {company?.raison_social}
                    </Typography>
                 </Grid>
                 <Grid item xs={12} sm={12} md={12}>
                    <Typography fontWeight={900} variant="caption">
                      Date de creation :
                    </Typography>
                    <Typography variant="caption">
                      <br/>{company?.date_creation ?? "--"}
                    </Typography>
                 </Grid>
                 <Grid item xs={12} sm={12} md={12}>
                    <Typography fontWeight={900} variant="caption">
                      Numero RC :
                    </Typography>
                    <Typography variant="caption">
                    <br/>{company?.numero_rc ?? "--"}
                    </Typography>
                 </Grid>
                 <Grid item xs={12} sm={12} md={12}>
                    <Typography fontWeight={900} variant="caption">
                      Forme juridique :
                    </Typography>
                    <Typography variant="caption">
                    <br/>{company?.forme_juridique ?? "--"}
                    </Typography>
                 </Grid>
                 <Grid item xs={12} sm={12} md={12}>
                    <Typography fontWeight={900} variant="caption">
                      Adresse :
                    </Typography>
                    <Typography variant="caption">
                    <br/>{company?.adresse_siege_social ?? "--"}
                    </Typography>
                 </Grid>
                 <Grid item xs={12} sm={12} md={12}>
                    <Typography fontWeight={900} variant="caption">
                      Website :
                    </Typography>
                    <Typography variant="caption" fontWeight={800} color="#063970">
                      <Link to={company?.website ?? "#"}>{company?.website ?? "--"}</Link>
                    </Typography>
                 </Grid>
                 <Grid item xs={12} sm={12} md={12}>
                    <Typography fontWeight={900} variant="caption">
                      Prefecture :
                    </Typography>
                    <Typography variant="caption">
                      {company?.prefecture ?? "--"}
                    </Typography>
                 </Grid>
                </Grid>
              </Box>
            </Card>
            
          <Grid  item xs={12} sm={12} md={2} style={{ marginTop:5 }}>
          {
            company && company.id !== undefined && me && 
            <Badge badgeContent={`${me.count_downloads}/${me.limit_downloads}`} color="primary">
              <MDButton
                xs={{ width: "100%" }}
                fullWidth
                type="submit"
                color="info"
                variant="contained"
                onClick={()=>{downloadFile(company.id)}}
              >
                Exportation PDF
              </MDButton>
            </Badge>
          }
          </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={10}>
            <Box sx={{ width: `100%`, display: "flex" }}>
              <Box
                sx={{
                  width: `100%`,
                }}
              >
                <Box sx={{ borderBottom: 1, borderColor: "divider", overflowY: "scroll" }}>
                  {/* <Box className="bloc-tabs"> */}
                  <ButtonGroup variant="outlined" aria-label="outlined button group">
                    {renderTabs}
                  </ButtonGroup>
                  {/* </Box> */}
                </Box>
                <Box
                  sx={{
                    padding: "5px",
                    border: 1,
                    borderColor: "divider",
                    backgroundColor: grey[50],
                    borderRadius: "5px",
                    height: "auto",
                    marginTop: "10px",
                  }}
                >
                  {toggleState === 1 && (
                    <Box index={1}>
                      <Information companyId={company?.id} />
                    </Box>
                  )}
                  {toggleState === 2 && (
                    <Box index={2}>
                      <FinancialProfile companyId={company?.id} />
                    </Box>
                  )}
                  {toggleState === 3 && (
                    <Box index={3}>
                      <Activities companyId={company?.id} company={company}/>
                    </Box>
                  )}
                  {toggleState === 4 && (
                    <Box index={4}>
                      <CountryProfiles companyId={company?.id} />
                    </Box>
                  )}
                  {toggleState === 5 && (
                    <Box index={5}>
                      <Bilans companyId={company?.id} />
                    </Box>
                  )}
                  {toggleState === 6 && (
                    <Box index={6}>
                      <Directors companyId={company?.id} />
                    </Box>
                  )}
                  {toggleState === 7 && (
                    <Box index={7}>
                      <Shareholdings companyId={company?.id} />
                    </Box>
                  )}
                </Box>
                
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </DashboardLayout>
  );
}

export default Rapport;

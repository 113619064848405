import {
  Alert,
  Autocomplete,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actionsCountries from "../../countries/redux/state/CountriesAction";
import * as actionsCompanies from "../../companies/redux/state/CompaniesAction";
import * as actions from "../redux/state/ShareholdingsAction";
import * as modalActions from "../modalActionTypes";
import { addShareholdingSchema } from "../schema";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { getErrorMessage } from "common/utils/Helper";

export default function FormAddShareholding({ setOpen, setShareholdingId, setAction }) {

  const [errorSB, setErrorSB] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  // order select countries
  const orderCountries = {
    sort: "name",
    order: "asc",
  };
  // order select companies
  const orderCompanies = {
    sort: "raison_social",
    order: "asc",
  };
  useEffect(() => {
    dispatch(actionsCountries.getCountries(orderCountries));
    dispatch(actionsCompanies.getCompanies(orderCompanies));
  }, []);

  const location = useLocation();
  let data = {};

  const onSubmit = (values) => {
    const request = {
      payload: values,
      successCallBack: (response) => {
        console.log("response", response);
        closeErrorSB()
        setAction(modalActions.ADD);
        setShareholdingId("");
        setOpen(false);
        if (location?.state?.id) {
          data = { companyId: location?.state?.id };
        }
        dispatch(actions.getShareholdings(data));
      },
      failCallBack: (error) => {
        console.log("error", error);
        setErrorMessage(getErrorMessage(error))
        openErrorSB();
      },
    };
    dispatch(actions.addShareholding(request));
  };
  const dispatch = useDispatch();

  const countries = useSelector((state) => state?.countries?.countries);
  const companies = useSelector((state) => state?.companies?.companies);

  console.log("companies::::",companies,"location",location)
  console.log("location?.state?.id",location)
  const formik = useFormik({
    initialValues: {
      name: "",
      type: "",
      act_direct: "",
      act_total: "",
      op_rev: "",
      source: "",
      country_id: "",
      // companies: [location?.state?.id] ?? [],
      companies: [location?.state?.id] ?? [],
      date: "",
    },
    validationSchema: addShareholdingSchema,
    onSubmit,
  });
  const { handleSubmit, errors, values, handleChange, touched } = formik;
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <MDBox m={1}>
      {errorSB && (
        <Alert severity="error">
          {errorMessage}
        </Alert>
      )}
      <form onSubmit={handleSubmit}>
        <MDBox py={2}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="name"
                  label="name"
                  error={errors.name ? true : false}
                  onChange={handleChange}
                  value={values.name}
                  helperText={errors?.name ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="type"
                  label="type"
                  error={errors.type ? true : false}
                  onChange={handleChange}
                  value={values.type}
                  helperText={errors?.type ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="act_direct"
                  label="act direct"
                  error={errors.act_direct ? true : false}
                  onChange={handleChange}
                  value={values.act_direct.replace(",", ".") ?? ""}
                  helperText={errors?.act_direct ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="act_total"
                  label="act total"
                  error={errors.act_total ? true : false}
                  onChange={handleChange}
                  value={values.act_total.replace(",", ".") ?? ""}
                  helperText={errors?.act_total ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="op_rev"
                  label="op rev"
                  error={errors.op_rev ? true : false}
                  onChange={handleChange}
                  value={values.op_rev.replace(",", ".") ?? ""}
                  helperText={errors?.op_rev ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="source"
                  label="source"
                  error={errors.source ? true : false}
                  onChange={handleChange}
                  value={values.source}
                  helperText={errors?.source ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    renderInput={(props) => <TextField {...props} />}
                    value={values.date}
                    name="date"
                    error={errors.date ? true : false}
                    onChange={(newValue) => {
                      formik.setFieldValue("date", moment(newValue).format("YYYY-MM-DD")); // December 22nd 2022, 10:01:13 pm);
                    }}
                    helperText={errors?.date ?? ""}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <InputLabel id="country_id">Pays</InputLabel>
                <Select
                  name="country_id"
                  style={{ height: "40px" }}
                  labelId="state_label"
                  label="Pays"
                  error={errors.country_id ? true : false}
                  onChange={handleChange}
                  value={values.country_id}
                >
                  {countries && countries?.data?.map((country) => {
                    return (
                      <MenuItem key={country?.id} value={country?.id}>
                        {country?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                {errors.country_id ? (
                  <FormHelperText error>{errors?.country_id}</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
            {!location?.state?.id && companies && (
            <Grid item xs={12} sm={12} md={12}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  id="companies"
                  options={companies.data || []} // Ensure options is always an array
                  getOptionLabel={(option) => option.raison_social}
                  defaultValue={[]}
                  onChange={(e, newValue) => {
                    formik.setFieldValue(
                      "companies",
                      newValue.map((value) => {
                        return value.id;
                      })
                    );
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={errors.companies ? true : false}
                      name="companies"
                      label="Entreprises"
                      placeholder="Entreprises"
                    />
                  )}
                />
                {errors.activities ? (
                  <FormHelperText error>{errors?.activities}</FormHelperText>
                ) : null}
              </FormControl>
          </Grid>
          )}
        </Grid>
        </MDBox>

        <MDBox sx={{ display: "flex" }}>
          <MDBox mr={1} sx={{ flexGrow: "1" }}>
            <MDButton onClick={handleClose} type="submit" color="primary" fullWidth>
              Annuler
            </MDButton>
          </MDBox>
          <MDBox sx={{ flexGrow: "1" }}>
            <MDButton type="submit" color="info" fullWidth>
              Ajouter
            </MDButton>
          </MDBox>
        </MDBox>
      </form>
    </MDBox>
  );
}

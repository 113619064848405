import {
  Alert,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addPassifBilanSchema } from "../schema";
import * as actions from "../redux/state/PassifBilansAction";
import * as actionsCompanies from "../../../../companies/redux/state/CompaniesAction";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import { getErrorMessage } from "common/utils/Helper";

export default function FormAddPassifBilan({ setOpen, companyId, setCompanyId }) {
  const dispatch = useDispatch();
  const location = useLocation();

  const [errorSB, setErrorSB] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  // order select companies
  const orderCompanies = {
    sort: "raison_social",
    order: "asc",
  };

  const onSubmit = (values) => {
    const request = {
      payload: values,
      successCallBack: (response) => {
        closeErrorSB()
        setOpen(false);
        setCompanyId(values?.company_id ?? companyId);
        let queryParam = {};
        if (
          (companyId.toString().length > 0 && companyId !== undefined) ||
          (values?.company_id.toString().length > 0 && values?.company_id !== undefined)
        ) {
          queryParam = { companyId: values?.company_id ?? companyId, sort: "date", order: "desc" };
        }
        dispatch(actions.getPassifBilans(queryParam));
        console.log("response", response);
      },
      failCallBack: (error) => {
        console.log("error", error);
        setErrorMessage(getErrorMessage(error))
        openErrorSB();
      },
    };
    dispatch(actions.addPassifBilan(request));
  };

  useEffect(() => {
    dispatch(actionsCompanies.getCompanies(orderCompanies));
  }, []);

  const companies = useSelector((state) => state?.companies.companies);
  const handleClose = () => {
    setOpen(false);
  };
  const formik = useFormik({
    initialValues: {
      capitaux_propres:"",
      capital: "",
      autre_capitaux_propre: "",
      passif_non_courant: "",
      dette_long_terme: "",
      autre_dette_long_terme: "",
      provision_risque_charge: "",
      dette_court_terme: "",
      dette_ct_emprunt: "",
      dette_ct_fournisseur: "",
      autre_dette_ct: "",
      total_passif: "",
      date: "",
      company_id: location?.state?.id ?? "",
    },
    onSubmit,
    validationSchema: addPassifBilanSchema,
  });

  const disabledSelect = location?.state?.id ? true: false;

  const { handleSubmit, errors, values, handleChange } = formik;

  return (
    <MDBox m={1}>
      {errorSB && (
        <Alert severity="error">
          {errorMessage}
        </Alert>
      )}
      <form onSubmit={handleSubmit}>
        <MDBox py={2}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="capitaux_propres"
                  label="Capitaux propres"
                  error={errors.capitaux_propres ? true : false}
                  onChange={handleChange}
                  value={values.capitaux_propres.replace(",", ".") ?? ""}
                  helperText={errors?.capitaux_propres ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="capital"
                  label="Capital"
                  error={errors.capital ? true : false}
                  onChange={handleChange}
                  value={values.capital.replace(",", ".") ?? ""}
                  helperText={errors?.capital ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="autre_capitaux_propre"
                  label="Autre capitaux propre"
                  error={errors.autre_capitaux_propre ? true : false}
                  onChange={handleChange}
                  value={values.autre_capitaux_propre.replace(",", ".") ?? ""}
                  helperText={errors?.autre_capitaux_propre ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="passif_non_courant"
                  label="Passif non courant"
                  error={errors.passif_non_courant ? true : false}
                  onChange={handleChange}
                  value={values.passif_non_courant.replace(",", ".") ?? ""}
                  helperText={errors?.passif_non_courant ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="dette_long_terme"
                  label="Dette long terme"
                  error={errors.dette_long_terme ? true : false}
                  onChange={handleChange}
                  value={values.dette_long_terme.replace(",", ".") ?? ""}
                  helperText={errors?.dette_long_terme ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="autre_dette_long_terme"
                  label="Autre dette long terme"
                  error={errors.autre_dette_long_terme ? true : false}
                  onChange={handleChange}
                  value={values.autre_dette_long_terme.replace(",", ".") ?? ""}
                  helperText={errors?.autre_dette_long_terme ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="provision_risque_charge"
                  label="Provision risque charge"
                  error={errors.provision_risque_charge ? true : false}
                  onChange={handleChange}
                  value={values.provision_risque_charge.replace(",", ".") ?? ""}
                  helperText={errors?.provision_risque_charge ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="dette_court_terme"
                  label="Dette court terme"
                  error={errors.dette_court_terme ? true : false}
                  onChange={handleChange}
                  value={values.dette_court_terme.replace(",", ".") ?? ""}
                  helperText={errors?.dette_court_terme ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="dette_ct_emprunt"
                  label="Dette ct emprunt"
                  error={errors.dette_ct_emprunt ? true : false}
                  onChange={handleChange}
                  value={values.dette_ct_emprunt.replace(",", ".") ?? ""}
                  helperText={errors?.dette_ct_emprunt ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="dette_ct_fournisseur"
                  label="Dette ct fournisseur"
                  error={errors.dette_ct_fournisseur ? true : false}
                  onChange={handleChange}
                  value={values.dette_ct_fournisseur.replace(",", ".") ?? ""}
                  helperText={errors?.dette_ct_fournisseur ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="autre_dette_ct"
                  label="Autre dette ct"
                  error={errors.autre_dette_ct ? true : false}
                  onChange={handleChange}
                  value={values.autre_dette_ct.replace(",", ".") ?? ""}
                  helperText={errors?.autre_dette_ct ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="total_passif"
                  label="Totals de l'passif"
                  error={errors.total_passif ? true : false}
                  onChange={handleChange}
                  value={values.total_passif.replace(",", ".") ?? ""}
                  helperText={errors?.total_passif ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    renderInput={(props) => <TextField {...props} />}
                    value={values.date}
                    name="date"
                    error={errors.date ? true : false}
                    onChange={(newValue) => {
                      formik.setFieldValue("date", dayjs(newValue).format("YYYY-MM-DD")); // December 22nd 2022, 10:01:13 pm);
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <InputLabel id="company_id">Entreprise</InputLabel>
                <Select
                  name="company_id"
                  style={{ height: "40px" }}
                  labelId="state_label"
                  label="Entreprise"
                  error={errors.company_id ? true : false}
                  onChange={handleChange}
                  value={values.company_id}
                  disabled = {disabledSelect}
                >
                  {companies?.data?.map((company) => {
                    return (
                      <MenuItem key={company?.id} value={company?.id}>
                        {/* <b>{company?.raison_social}</b>&nbsp;({company?.country_id}) */}
                        <p>
                          <b>{company?.raison_social}</b> ({company?.country?.name})
                        </p>
                      </MenuItem>
                    );
                  })}
                </Select>
                {errors.country_id ? (
                  <FormHelperText error>{errors?.country_id}</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox sx={{ display: "flex" }}>
          <MDBox mr={1} sx={{ flexGrow: "1" }}>
            <MDButton onClick={handleClose} type="submit" color="primary" fullWidth>
              Annuler
            </MDButton>
          </MDBox>
          <MDBox sx={{ flexGrow: "1" }}>
            <MDButton type="submit" color="info" fullWidth>
              Ajouter
            </MDButton>
          </MDBox>
        </MDBox>
      </form>
    </MDBox>
  );
}

import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";

import rootSagas from "../saga/rootSaga";
import rootReducers from "./RootReducer";

const configureStore = () => {
  const sagaMidlleware = createSagaMiddleware();
  const store = createStore(rootReducers, composeWithDevTools(applyMiddleware(sagaMidlleware)));
  return {
    ...store,
    runSaga: sagaMidlleware.run(rootSagas),
  };
};

export default configureStore;

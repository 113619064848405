import { useEffect } from "react";
import * as ActionsTypes from "../../../../common/state/StatesConstants";

const initialStateProductServices = {
  error: null,
  isLoading: false,
  productServices: [],
  productService: {},
};

// const initialStateProductService = {
//   error: null,
//   isLoading: false,
//   productService: {},
// };

const productServicesReducer = (
  stateProductServices = initialStateProductServices,
  // stateProductService = initialStateProductService,
  action
) => {
  switch (action.type) {
    // ##-----------GET_PRODUCTSERVICES-----------##
    case ActionsTypes.GET_PRODUCTSERVICES:
      return {
        ...stateProductServices,
        isLoading: true,
        error: null,
        productServices: [],
      };

    case ActionsTypes.GET_PRODUCTSERVICES_SUCCESS:
      return {
        ...stateProductServices,
        isLoading: false,
        error: null,
        productServices: action.response.data.data,
      };

    case ActionsTypes.GET_PRODUCTSERVICES_FAIL:
      return {
        ...stateProductServices,
        isLoading: false,
        error: action.error,
        productServices: [],
      };
    // ##-----------END_GET_PRODUCTSERVICES-----------##

    // ##-----------ADD_PRODUCTSERVICE-----------##
    case ActionsTypes.ADD_PRODUCTSERVICE:
      return {
        ...stateProductServices,
        isLoading: true,
        error: null,
        //productServices: [...stateProductServices.productServices],
      };

    case ActionsTypes.ADD_PRODUCTSERVICE_SUCCESS:
      return {
        ...stateProductServices,
        isLoading: false,
        error: null,
        //productServices: [action.response.data.data, ...stateProductServices.productServices],
      };

    case ActionsTypes.ADD_PRODUCTSERVICE_FAIL:
      return {
        ...stateProductServices,
        isLoading: false,
        error: action.error,
        //productServices: [...stateProductServices.productServices],
      };
    // ##-----------END_ADD_PRODUCTSERVICE-----------##

    // ##-----------EDIT_PRODUCTSERVICE-----------##
    case ActionsTypes.EDIT_PRODUCTSERVICE:
      return {
        ...stateProductServices,
        isLoading: true,
        error: null,
        productService: {},
      };

    case ActionsTypes.EDIT_PRODUCTSERVICE_SUCCESS:
      return {
        ...stateProductServices,
        isLoading: false,
        error: null,
        productService: action.response.data.data,
      };

    case ActionsTypes.EDIT_PRODUCTSERVICE_FAIL:
      return {
        ...stateProductServices,
        isLoading: false,
        error: action.error,
        productService: {},
      };
    // ##-----------END_EDIT_PRODUCTSERVICE-----------##

    // ##-----------UPDATE_PRODUCTSERVICE-----------##
    case ActionsTypes.UPDATE_PRODUCTSERVICE:
      return {
        ...stateProductServices,
        isLoading: true,
        error: null,
        //productServices: [...stateProductServices.productServices],
      };

    case ActionsTypes.UPDATE_PRODUCTSERVICE_SUCCESS:
      return {
        ...stateProductServices,
        isLoading: false,
        error: null,
        // productServices: [
        //   action.response.data.data,
        //   ...stateProductServices.productServices.filter(
        //     (productService) => productService.id != action.response.data.data.id
        //   ),
        // ],
      };

    case ActionsTypes.UPDATE_PRODUCTSERVICE_FAIL:
      return {
        ...stateProductServices,
        isLoading: false,
        error: action.error,
        //productServices: [...stateProductServices.productServices],
      };
    // ##-----------END_UPDATE_PRODUCTSERVICE-----------##

    // ##-----------DELETE_PRODUCTSERVICE-----------##
    case ActionsTypes.DELETE_PRODUCTSERVICE:
      return {
        ...stateProductServices,
        isLoading: true,
        error: null,
        //productServices: [...stateProductServices.productServices],
      };

    case ActionsTypes.DELETE_PRODUCTSERVICE_SUCCESS:
      return {
        ...stateProductServices,
        isLoading: false,
        error: null,
        // productServices: [
        //   ...stateProductServices.productServices.filter(
        //     (productService) => productService.id != action.response.data.deleted
        //   ),
        // ],
        // productServices: [...stateProductServices.productServices],
      };

    case ActionsTypes.DELETE_PRODUCTSERVICE_FAIL:
      return {
        ...stateProductServices,
        isLoading: false,
        error: action.error,
        //productServices: [...stateProductServices.productServices],
      };
    // ##-----------END_DELETE_PRODUCTSERVICE-----------##

    default:
      return stateProductServices;
  }
};

export default productServicesReducer;

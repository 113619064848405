import { put, call } from "redux-saga/effects";
import * as actions from "../state/SearchAction";
import { getMeApi, getSearchApi } from "../../../../common/services/Apis/searchApi";

export function* getSearchSaga(action) {
  try {
    const response = yield call(getSearchApi, action.payload);
    yield put(actions.getSearchSuccess(response));
  } catch (error) {
    console.log("user response error",error)
    yield put(actions.getSearchFail(error));
  }
}
export function* getMeSaga(action) {
    try {
      const response = yield call(getMeApi, action.payload);
      console.log("user response user ",response)
      yield put(actions.getMeSuccess(response));
    } catch (error) {
      yield put(actions.getMeFail(error));
    }
  }
import React, { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {
  Autocomplete,
  Badge,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import "./style.css";
import SearchResultCards from "./components/SearchResultCards";
import * as actions from "./redux/state/SearchAction";
import * as actionsCountries from "../../layouts/countries/redux/state/CountriesAction";
import { searchSchema } from "./schema";
import { useFormik } from "formik";
import * as actionsProductServices from "../productServices/redux/state/ProductServicesAction";
import { useDispatch, useSelector } from "react-redux";

function Search() {
  const [searched, setSearched] = useState(false);
  const dispatch = useDispatch();
  // order select countries
  const orderCountries = {
    sort: "name",
    order: "asc",
  };
  const onSubmit = (values) => {
    console.log("valuesssss:::",values)
    if (
      values.raison_social.toString().length > 0 ||
      values.productService_id.toString().length > 0 ||
      values.adresse.toString().length > 0 ||
      values.num_ice.toString().length > 0
    ) {
      dispatch(actions.getSearch(values));
      dispatch(actions.getMe());
      setSearched(true);
    }
  };
  const orderProductServices = {
    sort: "name",
    order: "asc",
  };
  useEffect(() => {
    //dispatch(actionsCountries.getCountries(orderCountries));
    dispatch(actionsProductServices.getProductServices(orderProductServices));
    dispatch(actions.getMe());
  }, []);

  const me = useSelector((state) => state?.search.me);
  const {search:searchResult, error} = useSelector((state) => state?.search);
  //const countries = useSelector((state) => state?.countries.countries);
  const activities = useSelector((state) => state?.productServices.productServices);

  console.log("response me",me,"error",error,"searchResult",searchResult)


  useEffect(() => {
    searchResult && console.log(searchResult);
  }, [searchResult]);

  const formik = useFormik({
    initialValues: {
      raison_social: "",
      num_ice: "",
      adresse: "",
      productService_id:"",
    },
    onSubmit,
    // validationSchema: searchSchema,
  });
  const { handleSubmit, errors, values, handleChange } = formik;
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={2}>
        <form onSubmit={handleSubmit}>
          <Card variant="outlined">
            <Grid p={2} container spacing={2}>
              <Grid item xs={12} md={12} sm={12}>
                <MDTypography align="center" fontWeight="bold" verticalAlign="middle">
                  Recherche par mot clé
                </MDTypography>
              </Grid>
              <Grid item xs={12} md={12} sm={12}>
                <MDTypography align="center" verticalAlign="middle">
                  Dans les base de données de AssurTrade
                </MDTypography>
              </Grid>
              <Grid item xs={12} md={12} sm={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={9} sm={8}>
                    <MDInput
                      name="raison_social"
                      label="Trouvez une entreprise"
                      error={errors.raison_social ? true : false}
                      onChange={handleChange}
                      value={values.raison_social}
                      helperText={errors?.raison_social ?? ""}
                      fullWidth
                    />
                  </Grid>
                  {/* <Chip label={"hello"}  color="primary" size="small">
                    
                  </Chip> */}
                  <Grid item xs={12} md={3} sm={4}>
                    {
                      me && 
                      
                      <Badge badgeContent={`${me.count_searches}/${me.limit_searches}`} color="primary">
                      <MDButton
                        xs={{ width: "100%" }}
                        fullWidth
                        type="submit"
                        color="info"
                        variant="contained"
                      >
                        Rechercher
                      </MDButton>
                    </Badge>
                    }
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} sm={12}>
                <MDTypography align="center" fontWeight="bold">
                  Affinez votre recherche via la recherche multi-critéres
                </MDTypography>
              </Grid>
              <Grid item xs={12} md={12} sm={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4} sm={6}>
                    <MDInput
                      fullWidth
                      name="num_ice"
                      label="Identifiant (exp: ICE; TVA; ...)"
                      error={errors.num_ice ? true : false}
                      onChange={handleChange}
                      value={values.num_ice}
                      helperText={errors?.num_ice ?? ""}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} sm={6}>
                    <MDInput
                      fullWidth
                      name="adresse"
                      label="Localisation"
                      error={errors.adresse? true : false}
                      onChange={handleChange}
                      value={values.adresse}
                      helperText={errors?.adresse?? ""}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} sm={6}>
                    <FormControl fullWidth>
                      <Autocomplete
                        id="productService_id"
                        options={activities && activities.data ? activities?.data : []}
                        getOptionLabel={(option) => option.name}
                        //onChange={handleChange}
                        onChange={(e, newValue) => {
                          console.log("new value:::", newValue)
                          formik.setFieldValue(
                            "productService_id",
                            newValue.id
                          );
                        }}
                        filterSelectedOptions
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={errors.productService_id ? true : false}
                            name="productService_id"
                            label="Activités"
                            placeholder="Activités"
                          />
                        )}
                      />
                      {errors.productService_id ? (
                        <FormHelperText error>{errors?.productService_id}</FormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </form>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox pt={2}>
              {searched &&
                searchResult &&
                (searchResult.length > 0 ? (
                  searchResult?.map((result, i) => (
                    <MDBox key={i}>
                      <SearchResultCards
                        result={result}
                        companyId={result.id}
                        companySlug={result.slug}
                        raison_social={result.raison_social}
                        date_creation={result.date_creation}
                        country={result.country.name}
                        product_services={
                          result.product_services?.name
                        }
                        numero_rc={result.numero_rc}
                      />
                    </MDBox>
                  ))
                ) : (
                  <Typography variant="h4" p={3}>
                    
                    {error ? <p style={{ color:"red" }}>{error.response.data.message}</p> : "Aucun resultat ..." }
                  </Typography>
                ))}
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Search;

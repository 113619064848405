import { useEffect } from "react";
import * as ActionsTypes from "../../../../common/state/StatesConstants";

const initialStateCountries = {
  error: null,
  isLoading: false,
  countries: [],
  country: {},
};

// const initialStateCountry = {
//   error: null,
//   isLoading: false,
//   country: {},
// };

const countriesReducer = (
  stateCountries = initialStateCountries,
  // stateCountry = initialStateCountry,
  action
) => {
  switch (action.type) {
    // ##-----------GET_COUNTRIES-----------##
    case ActionsTypes.GET_COUNTRIES:
      return {
        ...stateCountries,
        isLoading: true,
        error: null,
        countries: [],
      };

    case ActionsTypes.GET_COUNTRIES_SUCCESS:
      return {
        ...stateCountries,
        isLoading: false,
        error: null,
        countries: action.response.data.data,
      };

    case ActionsTypes.GET_COUNTRIES_FAIL:
      return {
        ...stateCountries,
        isLoading: false,
        error: action.error,
        countries: [],
      };
    // ##-----------END_GET_COUNTRIES-----------##

    // ##-----------ADD_COUNTRY-----------##
    case ActionsTypes.ADD_COUNTRY:
      return {
        ...stateCountries,
        isLoading: true,
        error: null,
        countries: [...stateCountries.countries],
      };

    case ActionsTypes.ADD_COUNTRY_SUCCESS:
      return {
        ...stateCountries,
        isLoading: false,
        error: null,
        countries: [action.response.data.data, ...stateCountries.countries],
      };

    case ActionsTypes.ADD_COUNTRY_FAIL:
      return {
        ...stateCountries,
        isLoading: false,
        error: action.error,
        countries: [...stateCountries.countries],
      };
    // ##-----------END_ADD_COUNTRY-----------##

    // ##-----------EDIT_COUNTRY-----------##
    case ActionsTypes.EDIT_COUNTRY:
      return {
        ...stateCountries,
        isLoading: true,
        error: null,
        country: {},
      };

    case ActionsTypes.EDIT_COUNTRY_SUCCESS:
      return {
        ...stateCountries,
        isLoading: false,
        error: null,
        country: action.response.data.data,
      };

    case ActionsTypes.EDIT_COUNTRY_FAIL:
      return {
        ...stateCountries,
        isLoading: false,
        error: action.error,
        country: {},
      };
    // ##-----------END_EDIT_COUNTRY-----------##

    // ##-----------UPDATE_COUNTRY-----------##
    case ActionsTypes.UPDATE_COUNTRY:
      return {
        ...stateCountries,
        isLoading: true,
        error: null,
        countries: [...stateCountries.countries],
      };

    case ActionsTypes.UPDATE_COUNTRY_SUCCESS:
      return {
        ...stateCountries,
        isLoading: false,
        error: null,
        countries: [
          action.response.data.data,
          ...stateCountries.countries.filter(
            (country) => country.id != action.response.data.data.id
          ),
        ],
      };

    case ActionsTypes.UPDATE_COUNTRY_FAIL:
      return {
        ...stateCountries,
        isLoading: false,
        error: action.error,
        countries: [...stateCountries.countries],
      };
    // ##-----------END_UPDATE_COUNTRY-----------##

    // ##-----------DELETE_COUNTRY-----------##
    case ActionsTypes.DELETE_COUNTRY:
      return {
        ...stateCountries,
        isLoading: true,
        error: null,
        countries: [...stateCountries.countries],
      };

    case ActionsTypes.DELETE_COUNTRY_SUCCESS:
      return {
        ...stateCountries,
        isLoading: false,
        error: null,
        countries: [
          ...stateCountries.countries,
          stateCountries.countries.filter((country) => country.id != action.response.data.deleted),
        ],
        // countries: [...stateCountries.countries],
      };

    case ActionsTypes.DELETE_COUNTRY_FAIL:
      return {
        ...stateCountries,
        isLoading: false,
        error: action.error,
        countries: [...stateCountries.countries],
      };
    // ##-----------END_DELETE_COUNTRY-----------##

    default:
      return stateCountries;
  }
};

export default countriesReducer;

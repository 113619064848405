import * as Yup from "yup";

export const addCompanySchema = Yup.object().shape({
  //assurtrad_id: Yup.string().max(50, "La donnée saisie est trop longue!").required("Obligatoire"),
  raison_social: Yup.string().max(50, "La donnée saisie est trop longue!").required("Obligatoire"),
  forme_juridique: Yup.string().max(50, "La donnée saisie est trop longue!").required("Obligatoire"),
  statut: Yup.string().max(50, "La donnée saisie est trop longue!").required("Obligatoire"),
  numero_rc: Yup.string().max(50, "La donnée saisie est trop longue!").required("Obligatoire"),
  website: Yup.string().max(50, "La donnée saisie est trop longue!").required("Obligatoire"),
  adresse_siege_social: Yup.string().required("Obligatoire"),
  city: Yup.string().max(50, "La donnée saisie est trop longue!").required("Obligatoire"),
  prefecture: Yup.string().max(50, "La donnée saisie est trop longue!").required("Obligatoire"),
  type_identite: Yup.string().max(50, "La donnée saisie est trop longue!").required("Obligatoire"),
  numero_ice: Yup.string().max(50, "La donnée saisie est trop longue!").required("Obligatoire"),
  country_id: Yup.string().required("Obligatoire"),
  date_creation: Yup.date().required("Obligatoire"),
});
export const updateCompanySchema = Yup.object().shape({
  raison_social: Yup.string().max(50, "La donnée saisie est trop longue!").required("Obligatoire"),
  forme_juridique: Yup.string().max(50, "La donnée saisie est trop longue!").when('shouldAdd', {
    is: true, // This condition checks if updatePassword is true
    then: Yup.string().required("Obligatoire"), // If true, password is required
    otherwise: Yup.string().notRequired(), // If false, password is not required
  }),
  statut: Yup.string().max(50, "La donnée saisie est trop longue!").when('shouldAdd', {
    is: true, // This condition checks if updatePassword is true
    then: Yup.string().required("Obligatoire"), // If true, password is required
    otherwise: Yup.string().notRequired(), // If false, password is not required
  }),
  numero_rc: Yup.string().max(50, "La donnée saisie est trop longue!").required("Obligatoire"),
  website: Yup.string().max(50, "La donnée saisie est trop longue!").when('shouldAdd', {
    is: true, // This condition checks if updatePassword is true
    then: Yup.string().required("Obligatoire"), // If true, password is required
    otherwise: Yup.string().notRequired(), // If false, password is not required
  }),
  adresse_siege_social: Yup.string().when('shouldAdd', {
    is: true, // This condition checks if updatePassword is true
    then: Yup.string().required("Obligatoire"), // If true, password is required
    otherwise: Yup.string().notRequired(), // If false, password is not required
  }),
  city: Yup.string().max(50, "La donnée saisie est trop longue!").required("Obligatoire"),
  prefecture: Yup.string().max(50, "La donnée saisie est trop longue!").when('shouldAdd', {
    is: true, // This condition checks if updatePassword is true
    then: Yup.string().required("Obligatoire"), // If true, password is required
    otherwise: Yup.string().notRequired(), // If false, password is not required
  }),
  type_identite: Yup.string().max(50, "La donnée saisie est trop longue!").when('shouldAdd', {
    is: true, // This condition checks if updatePassword is true
    then: Yup.string().required("Obligatoire"), // If true, password is required
    otherwise: Yup.string().notRequired(), // If false, password is not required
  }),
  numero_ice: Yup.string().max(50, "La donnée saisie est trop longue!").required("Obligatoire"),
  country_id: Yup.string().when('shouldAdd', {
    is: true, // This condition checks if updatePassword is true
    then: Yup.string().required("Obligatoire"), // If true, password is required
    otherwise: Yup.string().notRequired(), // If false, password is not required
  }),
  date_creation: Yup.date().required("Obligatoire"),
});


export const uploadCompanySchema = Yup.object().shape({
  file: Yup.mixed().required("Obligatoire !"),
});

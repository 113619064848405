import { put, call, delay } from "redux-saga/effects";
import * as actions from "../state/CountryProfilesAction";
import {
  getCountryProfilesApi,
  addCountryProfileApi,
  editCountryProfileApi,
  updateCountryProfileApi,
  deleteCountryProfileApi,
} from "common/services/Apis/countryProfilesApi";

export function* getCountryProfilesSaga(action) {
  try {
    const response = yield call(getCountryProfilesApi, action.payload);
    yield put(actions.getCountryProfilesSuccess(response));
  } catch (error) {
    yield put(actions.getCountryProfilesFail(error));
  }
}

export function* addCountryProfileSaga(action) {
  try {
    const response = yield call(addCountryProfileApi, action?.payload?.payload);
    if (response) {
      yield put(actions.addCountryProfileSuccess(response));
      yield call(action.payload.successCallBack, response);
    }
  } catch (error) {
    yield put(actions.addCountryProfileFail(error));
    yield call(action.payload.failCallBack, error);
  }
}

export function* editCountryProfileSaga(action) {
  try {
    const response = yield call(editCountryProfileApi, action.payload);
    yield put(actions.editCountryProfileSuccess(response));
  } catch (error) {
    yield put(actions.editCountryProfileSuccess(error));
  }
}

export function* updateCountryProfileSaga(action) {
  try {
    const response = yield call(updateCountryProfileApi, action?.payload?.payload);
    if (response) {
      yield put(actions.updateCountryProfileSuccess(response));
      yield call(action.payload.successCallBack, response);
    }
  } catch (error) {
    yield put(actions.updateCountryProfileFail(error));
    yield call(action.payload.failCallBack, error);
  }
}

export function* deleteCountryProfileSaga(action) {
  try {
    const response = yield call(deleteCountryProfileApi, action?.payload?.payload);
    yield put(actions.deleteCountryProfileSuccess(response));
    yield call(action.payload.successCallBack, response);
  } catch (error) {
    yield put(actions.deleteCountryProfileFail(error));
    yield call(action.payload.failCallBack, error);
  }
}

import { useEffect } from "react";
import * as ActionsTypes from "../../../../../../common/state/StatesConstants";

const initialState = {
  error: null,
  isLoading: false,
  passifBilans: [],
  passifBilan: {},
};

const passifBilansReducer = (state = initialState, action) => {
  switch (action.type) {
    // ##-----------GET_PASSIF_BILANS-----------##
    case ActionsTypes.GET_PASSIF_BILANS:
      return {
        ...state,
        isLoading: true,
        error: null,
        passifBilans: [],
      };
    case ActionsTypes.GET_PASSIF_BILANS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        passifBilans: action.response.data.data,
      };
    case ActionsTypes.GET_PASSIF_BILANS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        passifBilans: [],
      };
    // ##-----------END_GET_PASSIF_BILANS-----------##

    // ##-----------ADD_PASSIF_BILAN-----------##
    case ActionsTypes.ADD_PASSIF_BILAN:
      return {
        ...state,
        isLoading: true,
        error: null,
        //passifBilans: [...state.passifBilans],
      };

    case ActionsTypes.ADD_PASSIF_BILAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        //passifBilans: [...state.passifBilans, action.response.data.data],
      };

    case ActionsTypes.ADD_PASSIF_BILAN_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        //passifBilans: [...state.passifBilans],
      };
    // ##-----------END_ADD_PASSIF_BILAN-----------##

    // ##-----------EDIT_PASSIF_BILAN-----------##
    case ActionsTypes.EDIT_PASSIF_BILAN:
      return {
        ...state,
        isLoading: true,
        error: null,
        passifBilan: {},
      };

    case ActionsTypes.EDIT_PASSIF_BILAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        passifBilan: action.response.data.data,
      };

    case ActionsTypes.EDIT_PASSIF_BILAN_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        passifBilan: {},
      };
    // ##-----------END_EDIT_PASSIF_BILAN-----------##

    // ##-----------UPDATE_PASSIF_BILAN-----------##
    case ActionsTypes.UPDATE_PASSIF_BILAN:
      return {
        ...state,
        isLoading: true,
        error: null,
        //passifBilans: [...state.passifBilans],
      };

    case ActionsTypes.UPDATE_PASSIF_BILAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        // passifBilans: [
        //   action.response.data.data,
        //   ...state.passifBilans.filter(
        //     (passifBilan) => passifBilan.id != action.response.data.data.id
        //   ),
        // ],
      };

    case ActionsTypes.UPDATE_PASSIF_BILAN_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        //passifBilans: [...state.passifBilans],
      };
    // ##-----------END_UPDATE_PASSIF_BILAN-----------##

    case ActionsTypes.DELETE_PASSIF_BILAN:
      return {
        ...state,
        isLoading: true,
        error: null,
        //passifBilans: [...state.passifBilans],
      };

    case ActionsTypes.DELETE_PASSIF_BILAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        // passifBilans: [
        //   ...state.passifBilans.filter(
        //     (passifBilan) => passifBilan.id != action.response.data.data
        //   ),
        // ],
      };

    case ActionsTypes.DELETE_PASSIF_BILAN_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        //passifBilans: [...state.passifBilans],
      };
    // ##-----------END_DELETE_PASSIF_BILAN-----------##

    default:
      return state;
  }
};

export default passifBilansReducer;

import { Card, FormControl, Grid, Icon, InputLabel, MenuItem, Select } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
// Data
import passifBilansTableData from "./data/passifBilansTableData";
import DataTable from "examples/Tables/DataTable";
import { Box } from "@mui/system";
import MDButton from "components/MDButton";
import * as modalActions from "./modalActionTypes";
import { useLocation, useParams } from "react-router-dom";
import FormAddPassifBilan from "./components/FormAddPassifBilan";
import PassifBilanModal from "./components/PassifBilanModal";
import { useFormik } from "formik/dist";
import * as actionsCompanies from "../../../companies/redux/state/CompaniesAction";
import { useDispatch, useSelector } from "react-redux";
import FormUpdatePassifBilan from "./components/FormUpdatePassifBilan";
import ConfirmDeletePassifBilan from "./components/ConfirmDeletePassifBilan";
import styled from "@emotion/styled";

const PassifBilans = ({ companyId, setCompanyId }) => {
  const location = useLocation();
  const [passifBilanId, setPassifBilanId] = useState("");
  const [action, setAction] = useState(modalActions.ADD);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setAction(modalActions.ADD);
    setPassifBilanId("");
    setOpen(true);
  };
  const { columns, rows } = passifBilansTableData(setOpen, setPassifBilanId, setAction, companyId);

  const handleChange = (e) => {
    setCompanyId(e.target.value);
  };

  const companies = useSelector((state) => state?.companies.companies);

  // Responsive Header Inputs styled components
  const HeaderInputs = styled("div")(({ theme }) => ({
    padding: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
    },
  }));

  const FilterContainer = styled("div")(({ theme }) => ({
    padding: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: 250,
    },
  }));

  const ButtonsContainer = styled("div")(({ theme }) => ({
    padding: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      display: "grid",
      gridGap:".4em"
      // gridAutoFlow: "column",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "flex-end",
    },
  }));

  const disabledSelect = location?.state?.id ? true: false;

  return (
    <Box m={2}>
      <HeaderInputs px={1}>
      <Box mr={1} >
          <FilterContainer>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Filtre par entreprise</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="company_filter"
                value={companyId ?? ""}
                label="Filtre par entreprise"
                onChange={handleChange}
                sx={{ height: 40, background: "white" }}
                disabled = {disabledSelect}
              >
                <MenuItem value="">Selectionner une entreprise</MenuItem>
                {companies && companies.data && companies?.data?.map((company) => {
                  return (
                    <MenuItem key={company?.id} value={company?.id}>
                      {/* {company?.raison_social} */}
                      <p>
                        <b>{company?.raison_social}</b> ({company?.country?.name})
                      </p>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </FilterContainer>
        </Box>
        <ButtonsContainer>
          <Box mr={1}>
            <MDButton fullWidth onClick={handleOpen} variant="contained" size="small" color="info">
              <Icon>add</Icon> Passif Bilan
            </MDButton>
          </Box>
          <Box mr={1}>
            <MDButton fullWidth to="#" variant="contained" size="small" color="success">
              Exporter <Icon>downloadfile</Icon>
            </MDButton>
          </Box>
        </ButtonsContainer>
      </HeaderInputs>

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Passif Bilan
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {rows && 
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <PassifBilanModal
        title={
          action == modalActions.UPDATE
            ? "Modifier Compte de resultat bilan"
            : action == modalActions.DELETE
            ? "Supprimer Compte de resultat bilan"
            : "Ajouter nouveau Compte de resultat bilan"
        }
        open={open}
        setOpen={setOpen}
      >
        {/* ADD MODAL */}
        {action == modalActions.ADD && (
          <FormAddPassifBilan
            open={open}
            setOpen={setOpen}
            setAction={setAction}
            companyId={companyId}
            setCompanyId={setCompanyId}
          />
        )}
        {/* DELETE MODAL */}
        {action == modalActions.DELETE && (
          <ConfirmDeletePassifBilan
            setOpen={setOpen}
            passifBilanId={passifBilanId}
            setPassifBilanId={setPassifBilanId}
            setAction={setAction}
            companyId={companyId}
          />
        )}
        {/* UPDATE MODAL */}
        {action == modalActions.UPDATE && (
          <FormUpdatePassifBilan
            setOpen={setOpen}
            passifBilanId={passifBilanId}
            setPassifBilanId={setPassifBilanId}
            setAction={setAction}
            companyId={companyId}
            setCompanyId={setCompanyId}
          />
        )}
      </PassifBilanModal>
    </Box>
  );
};
export default PassifBilans;

import * as Yup from "yup";

export const addMemoBilanSchema = Yup.object().shape({
  fond_roulement_net_global: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  actif_circulant_net: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  date: Yup.date().required("Obligatoire"),
  company_id: Yup.number("La valeur doit être un nombre").integer().required("Obligatoire"),
  effectifs: Yup.number("La valeur doit être un nombre").integer().required("Obligatoire"),
  valeur_de_lentreprise:Yup.number("La valeur doit être un nombre").integer().required("Obligatoire"), 
});

export const updateMemoBilanSchema = Yup.object().shape({
  fond_roulement_net_global: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  actif_circulant_net: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  date: Yup.date().required("Obligatoire"),
  company_id: Yup.number("La valeur doit être un nombre").integer().required("Obligatoire"),
  effectifs: Yup.number("La valeur doit être un nombre").integer().required("Obligatoire"),
  valeur_de_lentreprise:Yup.number("La valeur doit être un nombre").integer().required("Obligatoire"), 
});

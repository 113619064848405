import { takeLatest } from "redux-saga/effects";

import * as ActionsTypes from "../../../../common/state/StatesConstants";
import {
  addProductServiceSaga,
  deleteProductServiceSaga,
  editProductServiceSaga,
  getProductServicesSaga,
  updateProductServiceSaga,
} from "./ProductServicesSaga";

export default function* ProductServicesWatcher() {
  yield takeLatest(ActionsTypes.GET_PRODUCTSERVICES, getProductServicesSaga);
  yield takeLatest(ActionsTypes.ADD_PRODUCTSERVICE, addProductServiceSaga);
  yield takeLatest(ActionsTypes.EDIT_PRODUCTSERVICE, editProductServiceSaga);
  yield takeLatest(ActionsTypes.UPDATE_PRODUCTSERVICE, updateProductServiceSaga);
  yield takeLatest(ActionsTypes.DELETE_PRODUCTSERVICE, deleteProductServiceSaga);
}

import { instance } from "../../utils/Axios";

// export const getSearchApi = (data) => {
//   return instance
//     .get(
//       `/companies/search?raison_social=${data.raison_social ?? ""}&assurtrad_id=${
//         data.assurtrad_id ?? ""
//       }&activity=${data?.activity ?? ""}&country_id=${data.country_id ?? ""}`
//     ,data)
//     .then((response) => {
//       return response;
//     });
// };

export const getSearchApi = (data) => {
  const token = "Bearer "+localStorage.getItem("access_token"); // Replace 'your_token_here' with your actual token
  return instance
     .get(
       `/companies/search?raison_social=${data.raison_social ?? ""}&productService_id=${
         data.productService_id ?? ""
       }&adresse=${data?.adresse ?? ""}&num_ice=${data.num_ice ?? ""}`,
       {
         headers: {
           'Authorization': `Bearer ${token}`
         }
       }
     )
     .then((response) => {
       return response;
     });
 };



 export const getMeApi = (data) => {
  const token = "Bearer "+localStorage.getItem("access_token"); // Replace 'your_token_here' with your actual token
  return instance
     .get(
       `/me`,
       {
         headers: {
           'Authorization': `Bearer ${token}`
         }
       }
     )
     .then((response) => {
       return response;
     });
 };


 

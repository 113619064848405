import { Box, Card, CardActionArea, CardContent, Grid, Typography } from "@mui/material";
import MDButton from "components/MDButton";
import Rapport from "layouts/rapport";
import React, { useEffect, useState } from "react";
import FormUpdateInformation from "./components/FormUpdateInformation";
import InformationModal from "./components/InformationModal";
import * as modalActions from "./modalActionTypes";
import GaugeChart from "react-gauge-chart";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Devises from "../../../../common/staticData/Devises";

function formatNumberWithMetricPrefix(n) {
  if(n==undefined){
    return undefined;
  }
  // Define the ranges and their corresponding suffixes
  const ranges = [
     { divider: 1e18, suffix: 'E' },
     { divider: 1e15, suffix: 'T' },
     { divider: 1e12, suffix: 'G' },
     { divider: 1e9, suffix: 'MM' },
     { divider: 1e6, suffix: 'M' },
     { divider: 1e3, suffix: 'K' }
  ];
 
  // Handle negative numbers
  if (n < 0) {
     return '-' + formatNumberWithMetricPrefix(-n);
  }
 
  // Loop through the ranges to find the appropriate prefix
  for (let i = 0; i < ranges.length; i++) {
     if (n >= ranges[i].divider) {
       // Format the number with the appropriate prefix and return
       return (n / ranges[i].divider).toFixed(1) + " "+ranges[i].suffix;
     }
  }
 
  // If the number is less than 1000, return it as is
  return n.toString();
 }
 
function Information({ companyId }) {
  // const { companyId } = useParams();
  console.log("company id props", companyId);
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState(modalActions.ADD);
  const handleOpen = () => {
    setAction(modalActions.UPDATE);
    setOpen(true);
  };


  const company = useSelector((state) => state?.companies?.company) ?? {};
  const info_company = company?.info_companies;
  const devises = Devises.devises;
  console.log("companyyyyyy:",company);

  return (
    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
      <Grid container spacing={1}>
        {/* left */}
        <Grid item sx={12} sm={12} md={6}>
          <Grid container spacing={1}>
            <Grid item sx={12} sm={12} md={12} style={{ width: "100%" }}>
              <Card sx={{ display: "flex" }}>
                {/* left */}
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <CardContent sx={{ flex: "1 0 auto" }}>
                    <Typography
                      component="div"
                      sx={{ display: "flex", justifyContent: "space-between", fontWeight: "bold" }}
                      variant="overline"
                    >
                      Assurtrade ID :
                      <Typography component="div" variant="overline">
                        {company?.assurtrad_id ?? "--"}
                      </Typography>
                    </Typography>

                    <Typography
                      component="div"
                      sx={{ display: "flex", justifyContent: "space-between", fontWeight: "bold" }}
                      variant="overline"
                    >
                      Numero de register de commerce :
                      <Typography component="div" variant="overline">
                        {company?.numero_rc ?? "--"} ({company?.country?.name ?? "--"})
                      </Typography>
                    </Typography>

                    <Typography
                      component="div"
                      sx={{ display: "flex", justifyContent: "space-between", fontWeight: "bold" }}
                      variant="overline"
                    >
                      ICE (identifiant Commun Entreprise)
                      <Typography component="div" variant="overline">
                        {company?.numero_ice ?? "--"}
                      </Typography>
                    </Typography>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
            <Grid item sx={12} sm={12} md={12}>
              <Box>
                <Typography
                  component="div"
                  sx={{ display: "flex", justifyContent: "space-between", fontWeight: "bold" }}
                  variant="subtitle2"
                >
                  Score :
                </Typography>
                {company && company.score!==undefined && company.score.scoring!==undefined && company.score.scoring!==null ?
                  <>
                  <GaugeChart percent={company && company.score !== undefined ? company.score.scoring/10:0} id="gauge-chart1" textColor="text.dark" nrOfLevels={10} colors={["#FF0000", "#008000"]} formatTextValue={val=>(company && company.score !== undefined ? company.score.scoring.toFixed(0):0)+"/10"}/>
                  <Typography
                    component="div"
                    sx={{ display: "flex", justifyContent: "space-between", fontWeight: "bold", justifyContent:"center", fontSize:"larger" }}
                    variant="subtitle2"
                  >
                  Limite crédit : {company && company.score !== undefined && company?.score?.credit_limit >= 0 && company.score.scoring> 3 ? formatNumberWithMetricPrefix(company?.score?.credit_limit):0} {company && company?.devise}
                  </Typography>
                  <Typography
                    component="div"
                    sx={{ display: "flex", justifyContent: "space-between", fontWeight: "bold", justifyContent:"center"}}
                    variant="subtitle2"
                  >
                  Date de notation : 
                  {company && company.score !== undefined && new Date(company?.score?.updated_at).toLocaleDateString('en-GB', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                    })
                    }
                  </Typography>
                  </>
                  :
                  <Typography>Pas encore ajouté !</Typography>
                }
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {/* right */}
        <Grid item sx={12} sm={12} md={6}>
          <Grid container spacing={1}>
            <Grid item sx={12} sm={12} md={12} style={{ width: "100%" }}>
              <Card sx={{ padding: "10px" }}>
                <Grid container spacing={1}>
                  <Grid item sx={12} sm={12} md={6}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            width: "200px",
                            fontWeight: "bold",
                            height: "40px",
                          }}
                          component="div"
                          variant="overline"
                        >
                          Totale des produits d'exploitation{" "}
                          {info_company?.year_net_income ?? "--"}
                        </Typography>
                        <Box
                          pt={5}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{
                              width: "50%",
                              fontWeight: "regular",
                            }}
                            component="div"
                            variant="overline"
                            color="text"
                          >
                            {info_company && formatNumberWithMetricPrefix(info_company?.total_produits_exploitation)} {company && company?.devise} 
                            {/* {info_company?.total_produits_exploitation?.toString()?.length > 0
                              ? `${devises[info_company?.devise_product]?.symbol}
                              ${info_company?.total_produits_exploitation}
                            `
                              : "--"} */}
                          </Typography>
                          <Typography
                            sx={{
                              width: "50%",
                              fontWeight: "regular",
                            }}
                            component="div"
                            variant="overline"
                          >
                            {info_company &&info_company.pourcentage_product!==undefined && info_company.pourcentage_product!==null
                              ? `${info_company?.pourcentage_product.toFixed(1)}%`
                              : "--"}
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          pt={2}
                          sx={{
                            fontWeight: "bold",
                          }}
                          component="div"
                          variant="overline"
                        >
                          Actionnariat
                        </Typography>
                        <Box
                          pt={2}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography component="div" variant="overline">
                            {info_company?.actionnaires ?? "--"} Actionnaires
                          </Typography>
                          <Typography component="div" variant="overline">
                            {info_company?.filiales ?? "--"} Filiales
                          </Typography>
                          <Typography component="div" variant="overline">
                            {info_company?.companies_du_groupe ?? "--"} Compagnies du groupe
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item sx={12} sm={12} md={6}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            width: "200px",
                            fontWeight: "bold",
                            height: "40px",
                          }}
                          component="div"
                          variant="overline"
                        >
                          P/L[=Net Income] pour {info_company?.year_net_income ?? "--"}
                        </Typography>
                        <Box pt={5} sx={{ display: "flex", justifyContent: "space-between" }}>
                          <Typography
                            sx={{
                              width: "50%",
                              fontWeight: "regular",
                            }}
                            component="div"
                            variant="overline"
                          >
                            {info_company && info_company.net_income!=null && formatNumberWithMetricPrefix(info_company?.net_income)} {company && company?.devise}
                            {/* {info_company?.net_income?.toString()?.length > 0
                              ? `${devises[info_company?.devise_exercice]?.symbol}
                              ${info_company?.net_income != null ? info_company?.net_income : "--"}
                            `
                              : "--"} */}
                          </Typography>
                          <Typography
                            sx={{
                              width: "50%",
                              fontWeight: "regular",
                            }}
                            component="div"
                            variant="overline"
                          >
                            {info_company &&info_company.pourcentage_exercice!==undefined && info_company.pourcentage_exercice!==null
                              ? `${info_company?.pourcentage_exercice.toFixed(1)}%`
                              : "--"}
                          </Typography>
                        </Box>
                      </Box>

                      <Box>
                        <Typography
                          pt={2}
                          sx={{
                            fontWeight: "bold",
                          }}
                          component="div"
                          variant="overline"
                        >
                          PPE et Sanctions
                        </Typography>
                        <Box
                          pt={2}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography component="div" variant="overline">
                            {info_company?.ppe ?? "--"}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid align={"right"} item sx={12} sm={12} md={12}>
              <MDButton onClick={handleOpen} color="primary">
                Modifier
              </MDButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <InformationModal
        title={action == modalActions.UPDATE ? "Mettre a jour - informations" : ""}
        open={open}
        setOpen={setOpen}
      >
        {/* UPDATE MODAL */}
        {action == modalActions.UPDATE && (
          <FormUpdateInformation setOpen={setOpen} setAction={setAction} />
        )}
      </InformationModal>
    </Box>
  );
}

export default Information;

import * as ActionsTypes from "../../../../common/state/StatesConstants";

// ##-----------GET_COUNTRIES-----------##
export const getCountries = (data = {}) => {
  return { type: ActionsTypes.GET_COUNTRIES, payload: data };
};

export const getCountriesSuccess = (response) => {
  return { type: ActionsTypes.GET_COUNTRIES_SUCCESS, response };
};

export const getCountriesFail = (error) => {
  return { type: ActionsTypes.GET_COUNTRIES_FAIL, error };
};
// ##-----------END_GET_COUNTRIES-----------##

// ##-----------ADD_COUNTRY-----------##
export const addCountry = (data) => {
  return { type: ActionsTypes.ADD_COUNTRY, payload: data };
};

export const addCountrySuccess = (response) => {
  return { type: ActionsTypes.ADD_COUNTRY_SUCCESS, response };
};

export const addCountryFail = (error) => {
  return { type: ActionsTypes.ADD_COUNTRY_FAIL, error };
};
// ##-----------END_ADD_COUNTRY-----------##

// ##-----------EDIT_COUNTRY-----------##
export const editCountry = (data) => {
  return { type: ActionsTypes.EDIT_COUNTRY, payload: data };
};

export const editCountrySuccess = (response) => {
  return { type: ActionsTypes.EDIT_COUNTRY_SUCCESS, response };
};

export const editCountryFail = (error) => {
  return { type: ActionsTypes.EDIT_COUNTRY_FAIL, error };
};
// ##-----------END_EDIT_COUNTRY-----------##

// ##-----------UPDATE_COUNTRY-----------##
export const updateCountry = (data) => {
  return { type: ActionsTypes.UPDATE_COUNTRY, payload: data };
};

export const updateCountrySuccess = (response) => {
  return { type: ActionsTypes.UPDATE_COUNTRY_SUCCESS, response };
};

export const updateCountryFail = (error) => {
  return { type: ActionsTypes.UPDATE_COUNTRY_FAIL, error };
};
// ##-----------END_UPDATE_COUNTRY-----------##

// ##-----------DELETE_COUNTRY-----------##
export const deleteCountry = (data) => {
  return { type: ActionsTypes.DELETE_COUNTRY, payload: data };
};

export const deleteCountrySuccess = (response) => {
  return { type: ActionsTypes.DELETE_COUNTRY_SUCCESS, response };
};

export const deleteCountryFail = (error) => {
  return { type: ActionsTypes.DELETE_COUNTRY_FAIL, error };
};
// ##-----------END_DELETE_COUNTRY-----------##

import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { style } from "./style";
import { Divider, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { dark } from "@mui/material/styles/createPalette";

export default function MDModal({ open, setOpen, children, title }) {
  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ margin: "12px" }}
    >
      <Box sx={style}>
        <Typography id="modal-title" variant="h5" color={grey[700]} fontWeight="light" my={3}>
          {title}
        </Typography>
        <Divider />
        {children}
      </Box>
    </Modal>
  );
}

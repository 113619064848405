import { useEffect } from "react";
import * as ActionsTypes from "../../../../common/state/StatesConstants";

const initialState = {
  error: null,
  isLoading: false,
  users: [],
  user: {},
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    // ##-----------GET_USERS-----------##
    case ActionsTypes.GET_USERS:
      return {
        ...state,
        isLoading: true,
        error: null,
        users: [],
      };
    case ActionsTypes.GET_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        users: action.response.data.data,
      };
    case ActionsTypes.GET_USERS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        users: [],
      };
    // ##-----------END_GET_USERS-----------##

    // ##-----------ADD_USER-----------##
    case ActionsTypes.ADD_USER:
      console.log(action)
      return {
        ...state,
        isLoading: true,
        error: null,
        //users: [...state.users],
      };

    case ActionsTypes.ADD_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        //users: [...state.users, action.response.data.data],
      };

    case ActionsTypes.ADD_USER_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        //users: [...state.users],
      };
    // ##-----------END_ADD_USER-----------##

    // ##-----------DELETE_USER-----------##
    case ActionsTypes.DELETE_USER:
      return {
        ...state,
        isLoading: true,
        error: null,
        //users: [...state.users],
      };

    case ActionsTypes.DELETE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        //users: [...state.users.filter((user) => user.id != action.response.data.deleted)],
      };

    case ActionsTypes.DELETE_USER_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        //users: [...state.users],
      };
    // ##-----------END_DELETE_USER-----------##

    // ##-----------EDIT_USER-----------##
    case ActionsTypes.EDIT_USER:
      return {
        ...state,
        isLoading: true,
        error: null,
        user: {},
      };

    case ActionsTypes.EDIT_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        user: action.response.data.data,
      };

    case ActionsTypes.EDIT_USER_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        user: {},
      };
    // ##-----------END_EDIT_USER-----------##

    // ##-----------UPDATE_USER-----------##
    case ActionsTypes.UPDATE_USER:
      return {
        ...state,
        isLoading: true,
        error: null,
        //users: [...state.users],
      };

    case ActionsTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        // users: [
        //   action.response.data.data,
        //   ...state.users.filter((user) => user.id != action.response.data.data.id),
        // ],
      };

    case ActionsTypes.UPDATE_USER_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        //users: [...state.users],
      };
    // ##-----------END_UPDATE_USER-----------##
    default:
      return state;
  }
};

export default usersReducer;

import React, { Component } from "react";
import { Outlet, Navigate } from "react-router-dom";
// import { isAuthenticated } from "common/utils/Helper"
import JwtUtils from "./JwtUtils";

const AuthRoute = ({ component: Component, ...rest }) => {
  const auth = JwtUtils.isActif();
  return auth ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to="/authentication/sign-in" />
  );
};

export default AuthRoute;

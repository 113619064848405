import { Alert, Box, Divider } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/state/PassifBilansAction";
import * as modalActions from "../modalActionTypes";
import { grey } from "@mui/material/colors";

export default function ConfirmPassifBilan({
  setOpen,
  passifBilanId,
  setPassifBilanId,
  setAction,
  companyId,
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.editPassifBilan(passifBilanId));
  }, []);

  const passifBilan = useSelector((state) => state?.passifBilans?.passifBilan);

  const closeModal = () => {
    setAction(modalActions.ADD);
    setPassifBilanId("");
    setOpen(false);
  };

  const onSubmit = (values) => {
    const request = {
      payload: values,
      successCallBack: (response) => {
        closeModal();
        let queryParam = {};
        if (companyId.toString().length > 0 && companyId !== undefined) {
          queryParam = { companyId: companyId, sort: "date", order: "desc" };
        }
        dispatch(actions.getPassifBilans(queryParam));
        console.log("response", response);
      },
      failCallBack: (error) => {
        console.log("error", error);
      },
    };
    dispatch(actions.deletePassifBilan(request));
  };

  // const navigate = useNavigate();
  const { handleSubmit, touched } = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: passifBilan?.id,
      // company: passifBilan?.company?.raison_social,
      // date: passifBilan?.date,
    },
    onSubmit,
  });
  return (
    <MDBox my={1}>
      <form onSubmit={handleSubmit}>
        <MDBox py={1}>
          <Alert severity="error">
            Etes vous sure de vouloir supprimer le compte et resultat bilan de{" "}
            <i>"{passifBilan?.company?.raison_social}"</i> au date <i>"{passifBilan?.date}"</i>
          </Alert>
        </MDBox>
        <Divider />
        <Box mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box mr={1}>
            <MDButton type="submit" variant="contained" size="small" color="error">
              Supprimer
            </MDButton>
          </Box>
          <Box mr={1}>
            <MDButton
              size="small"
              onClick={closeModal}
              style={{ background: grey[500], color: "white" }}
            >
              Annuler
            </MDButton>
          </Box>
        </Box>
      </form>
    </MDBox>
  );
}

import React, { useEffect } from "react";
import MDBox from "components/MDBox";
import { useDispatch, useSelector } from "react-redux";
import { Field } from "formik";
import { useParams } from "react-router-dom";

// Function to format numbers with spaces
function formatNumberWithSpaces(input) {
 if (isNaN(input)) {
    return input;
 }
 const roundedNum = Math.round(input);
 const numStr = roundedNum.toString();
 const formattedStr = numStr.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
 return formattedStr;
}

export default function Data() {
 const dispatch = useDispatch();
 const { companyId } = useParams();

 const actifBilans = useSelector((state) => state?.companies?.company?.bilans?.actif_bilans) ?? [];

 const filterKeys = [0, 11, 12, 13, 14];

 function toObject(arr) {
    var obj = {};
    for (var i = 0; i < arr.length; ++i) if (arr[i] !== undefined) obj[dates[i]] = arr[i];
    return obj;
 }

 function transpose(data) {
    let dt = [];
    if (data?.length > 0) {
      dt = Object.keys(data[0])?.map((colNumber) =>
        toObject(data?.map((rowNumber) => rowNumber[colNumber]))
      );
    }
    return dt;
 }

 const rowsHeader = [
  "Actif immobilisé",
  "Immobilisations incorporelles",
  "Immobilisation corporelles",
  "Autres actifs immobilisés",
  "Actif circulant",
  "Actif circulant: stock",
  "Actif circulant: créances",
  "Autres actifs circulants",
  "Valeurs disponibles",
  "Totals de l'actif"
 ];

 const dates = actifBilans?.map((actifBilan, i) => actifBilan.date);

 const pivot = transpose(actifBilans).filter((el, i) => !filterKeys.some((j) => i === j));

 const datesColumn = dates?.map((date, i) => ({
    Header: date.toString(),
    accessor: date.toString(),
    align: "right",
 }));

 const columns = [{ Header: "Annee", accessor: "date", align: "left" }].concat(datesColumn);

 console.log("pivot",pivot)

 let rows = pivot?.map((p, i) => singleRow(p, i));

 function singleRow(p, i) {
    const obj = {};
    dates.map((date) => (obj[date] = p[date]));
    obj['total_actif'] = "total_actif";
    return { date: rowsHeader[i], ...obj };
 }

 // Format numbers in rows
 rows = rows.map(row => {
    const formattedRow = { ...row };
    Object.keys(row).forEach(key => {
      if (!isNaN(row[key])) {
        formattedRow[key] = formatNumberWithSpaces(row[key]);
      }
    });
    return formattedRow;
 });

 console.log(rows);

 return {
    columns: columns,
    rows: rows,
 };
}

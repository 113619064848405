import { Box, Grid, Icon, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import React from "react";
import { Link } from "react-router-dom";

function Activities({ companyId,company }) {
  return (
    <MDBox p={1}>
      <Grid container>
        <Grid item xs={6} sm={6} md={6}>
          <Typography component="div" pb={2} variant="h4" align="left">
            Secteur et activité
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Box sx={{ textAlign: "right" }}>
            <Link
              to="/bilans"
              state={{
                id: companyId,
                //slug: companySlug,
              }}
            >
              <MDButton p={0} type="submit" color="primary">
                <Icon fontSize="small">settings-icon</Icon>
                <Typography variant="p" pl={1}>
                    Paramétrage
                  </Typography>
              </MDButton>
            </Link>
          </Box>
        </Grid>

        <Grid item xs={6} sm={6} md={6}>
          <Typography variant="p" pl={1} style={{ fontFamily:"Roboto, Helvetica, Arial, sans-serif",fontSize:"0.75rem",lineHeight:"2.66",letterSpacing:"0.08333em",textTransform:"uppercase",fontWeight:"700" }}>
            TYPE D'ENTITÉ :
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Typography variant="p" pl={1} style={{ fontFamily:"Roboto, Helvetica, Arial, sans-serif",fontSize:"0.75rem",lineHeight:"2.66",letterSpacing:"0.08333em",textTransform:"uppercase",fontWeight:"700" }}>
            {company && company?.type_identite}
          </Typography>
        </Grid>

        <Grid item xs={6} sm={6} md={6}>
          <Typography variant="p" pl={1} style={{ fontFamily:"Roboto, Helvetica, Arial, sans-serif",fontSize:"0.75rem",lineHeight:"2.66",letterSpacing:"0.08333em",textTransform:"uppercase",fontWeight:"700" }}>
            SECTEUR D'ACTIVITÉ :
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Typography variant="p" pl={1} style={{ fontFamily:"Roboto, Helvetica, Arial, sans-serif",fontSize:"0.75rem",lineHeight:"2.66",letterSpacing:"0.08333em",textTransform:"uppercase",fontWeight:"700" }}>
            {company && company?.productSercice.name}
          </Typography>
        </Grid>

        <Grid item xs={6} sm={6} md={6}>
          <Typography variant="p" pl={1} style={{ fontFamily:"Roboto, Helvetica, Arial, sans-serif",fontSize:"0.75rem",lineHeight:"2.66",letterSpacing:"0.08333em",textTransform:"uppercase",fontWeight:"700" }}>
            CODE ACTIVITÉ :
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Typography variant="p" pl={1} style={{ fontFamily:"Roboto, Helvetica, Arial, sans-serif",fontSize:"0.75rem",lineHeight:"2.66",letterSpacing:"0.08333em",textTransform:"uppercase",fontWeight:"700" }}>
            {company && company?.productSercice.code_activity}
          </Typography>
        </Grid>
      </Grid>
    </MDBox>
  )
  //return <Box>Secteur et activite {company && company?.type_identite}</Box>;
}

export default Activities;

import { useSelector } from "react-redux";
import * as Yup from "yup";

// const GetYears = () => {
//   const data = useSelector((state) => state?.countryProfiles?.countryProfiles)
//   years = []
//   data?.map((dt, _) =>{
//     if(dt.id == 1){
//       years.push(dt.year)
//     }
//   })
//   return years
// }

const yearNow = new Date().getFullYear();

// const isUniqueNickname = Yup.string().test(
//   'no-duplicate-name',
//   'Name already in use.',
//   function test(value) {
//     // Must not be an arrow function, because yup binds to "this".
//     // Note the use of this.options.context to reference context.
//     const { myArray } = this.options.context;
//     const foundMatch = myArray.some((year) => year === value);
//     const isValid = !foundMatch;

//     return isValid;
//   }
// );

export const addCountryProfileSchema = Yup.object().shape({
  // myArray: Yup.ref("$myArray"),
  year: Yup.number()
    // .notOneOf()
    .min(1900, "l'annee minimal est 1900")
    .max(yearNow, `l'annee maximal est ${yearNow}`)
    // .test("unique-year", "Cette annee existe deja", function test(year) {
    //   console.log(this.options.context.years)
    //   const foundMatch = this.options.context.years.includes(year);
    //   const isValid = !foundMatch;

    //   return isValid;
    // })
    .required("Required"),
  population: Yup.number().required("Required").integer(),
  pib_taux_fix: Yup.number().required("Required"),
  pib_taux_crois: Yup.number().required("Required"),
  pib_tete: Yup.number().required("Required"),
  taux_inflation: Yup.number().max(100, "Too Long!").required("Required").positive(),
  equ_budgetaire: Yup.number().required("Required"),
  dette_publique: Yup.number().required("Required"),
  chomage: Yup.number().max(100, "Too Long!").required("Required").positive(),
  balance_paiement_courant: Yup.number().required("Required"),
  balance_comerciale: Yup.number().required("Required"),
  reserve_internationale: Yup.number().required("Required"),
});

export const updateCountryProfileSchema = Yup.object().shape({
  // myArray: Yup.ref("$myArray"),
  year: Yup.number()
    // .notOneOf()
    .min(1900, "l'annee minimal est 1900")
    .max(yearNow, `l'annee maximal est ${yearNow}`)
    // .test("unique-year", "Cette annee existe deja", function test(year) {
    //   console.log(this.options.context.years)
    //   const foundMatch = this.options.context.years.includes(year);
    //   const isValid = !foundMatch;

    //   return isValid;
    // })
    .required("Required"),
  population: Yup.number().required("Required").integer(),
  pib_taux_fix: Yup.number().required("Required"),
  pib_taux_crois: Yup.number().required("Required"),
  pib_tete: Yup.number().required("Required"),
  taux_inflation: Yup.number().max(100, "Too Long!").required("Required").positive(),
  equ_budgetaire: Yup.number().required("Required"),
  dette_publique: Yup.number().required("Required"),
  chomage: Yup.number().max(100, "Too Long!").required("Required").positive(),
  balance_paiement_courant: Yup.number().required("Required"),
  balance_comerciale: Yup.number().required("Required"),
  reserve_internationale: Yup.number().required("Required"),
});

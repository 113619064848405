import * as ActionsTypes from "../../../../common/state/StatesConstants";

const initialState = {
  error: null,
  isLoading: false,
  financialProfiles: [],
  financialProfile: {},
};

const financialProfilesReducer = (state = initialState, action) => {
  switch (action.type) {
    // ##-----------GET_FINANCIAL_PROFILES-----------##
    case ActionsTypes.GET_FINANCIAL_PROFILES:
      return {
        ...state,
        isLoading: true,
        error: null,
        financialProfiles: [],
      };
    case ActionsTypes.GET_FINANCIAL_PROFILES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        financialProfiles: action.response.data.data,
      };
    case ActionsTypes.GET_FINANCIAL_PROFILES_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        financialProfiles: [],
      };
    // ##-----------END_GET_FINANCIAL_PROFILES-----------##

    // ##-----------ADD_FINANCIAL_PROFILE-----------##
    case ActionsTypes.ADD_FINANCIAL_PROFILE:
      return {
        ...state,
        isLoading: true,
        error: null,
        //financialProfiles: [...state.financialProfiles],
      };

    case ActionsTypes.ADD_FINANCIAL_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        //financialProfiles: [action.response.data.data, ...state.financialProfiles],
      };

    case ActionsTypes.ADD_FINANCIAL_PROFILE_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        //financialProfiles: [...state.financialProfiles],
      };
    // ##-----------END_ADD_FINANCIAL_PROFILE-----------##

    // ##-----------EDIT_FINANCIAL_PROFILE-----------##
    case ActionsTypes.EDIT_FINANCIAL_PROFILE:
      return {
        ...state,
        isLoading: true,
        error: null,
        financialProfile: {},
      };

    case ActionsTypes.EDIT_FINANCIAL_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        financialProfile: action.response.data.data,
      };

    case ActionsTypes.EDIT_FINANCIAL_PROFILE_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        financialProfile: {},
      };
    // ##-----------END_EDIT_FINANCIAL_PROFILE-----------##

    // ##-----------UPDATE_FINANCIAL_PROFILE-----------##
    case ActionsTypes.UPDATE_FINANCIAL_PROFILE:
      return {
        ...state,
        isLoading: true,
        error: null,
        //financialProfiles: [...state.financialProfiles],
      };

    case ActionsTypes.UPDATE_FINANCIAL_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        // financialProfiles: [
        //   action.response.data.data,
        //   ...state.financialProfiles.filter(
        //     (financialProfile) => financialProfile.id != action.response.data.data.id
        //   ),
        // ],
      };

    case ActionsTypes.UPDATE_FINANCIAL_PROFILE_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        //financialProfiles: [...state.financialProfiles],
      };
    // ##-----------END_UPDATE_FINANCIAL_PROFILE-----------##

    // ##-----------DELETE_FINANCIAL_PROFILE-----------##
    case ActionsTypes.DELETE_FINANCIAL_PROFILE:
      return {
        ...state,
        isLoading: true,
        error: null,
        //financialProfiles: [...state.financialProfiles],
      };

    case ActionsTypes.DELETE_FINANCIAL_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        // financialProfiles: [
        //   ...state.financialProfiles.filter(
        //     (financialProfile) => financialProfile.id != action.response.data.data
        //   ),
        // ],
      };

    case ActionsTypes.DELETE_FINANCIAL_PROFILE_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        //financialProfiles: [...state.financialProfiles],
      };
    // ##-----------END_DELETE_FINANCIAL_PROFILE-----------##

    default:
      return state;
  }
};

export default financialProfilesReducer;

import { instance } from "../../utils/Axios";

export const getCompaniesApi = (data = {}) => {
  if(data.search===undefined){
    return instance
    .get(`/companies?sort=${data.sort ?? ""}&order=${data.order ?? ""}&page=${data.order ?? 1}`)
    .then((response) => {
      return response;
    });
  }else{
    return instance
    .get(`/companies/search?raison_social=${data.search ?? ""}`)
    .then((response) => {
      return {last_page:1, data:response};
    });
  }
};
export const addCompanyApi = (data) => {
  return instance.post("/companies", data).then((response) => {
    return response;
  });
};

export const editCompanyApi = (idOrSlug) => {
  return instance.get(`/companies/${idOrSlug}`).then((response) => {
    return response;
  });
};

export const updateCompanyApi = (data) => {
  return instance.put(`/companies/${data.id}`, data).then((response) => {
    return response;
  });
};

export const deleteCompanyApi = (data) => {
  return instance.delete(`/companies/${data.id}`).then((response) => {
    return response;
  });
};


export const uploadCompanyApi = (data) => {
  return instance
    .post("/companies/import-excel", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response;
    });
};

export const getRapportApi = (data) => {
  const token = "Bearer "+localStorage.getItem("access_token"); // Replace 'your_token_here' with your actual token
  return instance
     .get(
       `/pdf/${data}`,
       {
         headers: {
           'Authorization': `Bearer ${token}`
         }
       }
     )
     .then((response) => {
        return response.blob();
     }).then(blob=>{
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.pdf'); // You can specify the file name here
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
     });
 };

import { put, call, delay } from "redux-saga/effects";
import * as actions from "../state/UsersAction";
import {
  addUserApi,
  deleteUserApi,
  editUserApi,
  getUsersApi,
  updateUserApi,
} from "../../../../common/services/Apis/usersApi";

export function* getUsersSaga() {
  try {
    const response = yield call(getUsersApi);
    yield put(actions.getUsersSuccess(response));
  } catch (error) {
    yield put(actions.getUsersFail(error));
  }
}

export function* addUserSaga(action) {
  try {
    const response = yield call(addUserApi, action?.payload?.payload);
    if (response) {
      yield put(actions.addUserSuccess(response));
      yield call(action.payload.successCallBack, response);
    }
  } catch (error) {
    yield put(actions.addUserFail(error));
    yield call(action.payload.failCallBack, error);
  }
}

export function* deleteUserSaga(action) {
  try {
    const response = yield call(deleteUserApi, action?.payload?.payload);
    yield put(actions.deleteUserSuccess(response));
    yield call(action.payload.successCallBack, response);
  } catch (error) {
    yield put(actions.deleteUserFail(error));
    yield call(action.payload.failCallBack, error);
  }
}

export function* editUserSaga(action) {
  try {
    const response = yield call(editUserApi, action.payload);
    yield put(actions.editUserSuccess(response));
  } catch (error) {
    yield put(actions.editUserFail(error));
  }
}

export function* updateUserSaga(action) {
  try {
    console.log("action updateUserSaga",action);
    const response = yield call(updateUserApi, action?.payload?.payload);
    console.log("response updateUserSaga",response);
    if (response) {
      yield put(actions.updateUserSuccess(response));
      yield call(action.payload.successCallBack, response);
    }
  } catch (error) {
    yield put(actions.updateUserFail(error));
    yield call(action.payload.failCallBack, error);
  }
}

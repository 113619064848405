import { put, call, delay } from "redux-saga/effects";
import * as actions from "../state/PassifBilansAction";
import {
  getPassifBilansApi,
  addPassifBilanApi,
  editPassifBilanApi,
  updatePassifBilanApi,
  deletePassifBilanApi,
} from "common/services/Apis/passifBilansApi";

export function* getPassifBilansSaga(action) {
  try {
    const response = yield call(getPassifBilansApi, action.payload);
    yield put(actions.getPassifBilansSuccess(response));
  } catch (error) {
    yield put(actions.getPassifBilansFail(error));
  }
}

export function* addPassifBilanSaga(action) {
  try {
    const response = yield call(addPassifBilanApi, action?.payload?.payload);
    if (response) {
      yield put(actions.addPassifBilanSuccess(response));
      yield call(action.payload.successCallBack, response);
    }
  } catch (error) {
    yield put(actions.addPassifBilanFail(error));
    yield call(action.payload.failCallBack, error);
  }
}

export function* editPassifBilanSaga(action) {
  try {
    const response = yield call(editPassifBilanApi, action.payload);
    yield put(actions.editPassifBilanSuccess(response));
  } catch (error) {
    yield put(actions.editPassifBilanFail(error));
  }
}

export function* updatePassifBilanSaga(action) {
  try {
    const response = yield call(updatePassifBilanApi, action?.payload?.payload);
    if (response) {
      yield put(actions.updatePassifBilanSuccess(response));
      yield call(action.payload.successCallBack, response);
    }
  } catch (error) {
    yield put(actions.updatePassifBilanFail(error));
    yield call(action.payload.failCallBack, error);
  }
}

export function* deletePassifBilanSaga(action) {
  try {
    const response = yield call(deletePassifBilanApi, action?.payload?.payload);
    yield put(actions.deletePassifBilanSuccess(response));
    yield call(action.payload.successCallBack, response);
  } catch (error) {
    yield put(actions.deletePassifBilanFail(error));
    yield call(action.payload.failCallBack, error);
  }
}

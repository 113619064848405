import {
  editInformationCompanyApi,
  addInformationCompanysApi,
} from "common/services/Apis/informationCompaniesApi";
import { put, call, delay } from "redux-saga/effects";
import * as actions from "../state/InformationCompanyAction";

export function* addInformationCompanySaga(action) {
  console.log("information company saga", action);
  try {
    const response = yield call(addInformationCompanysApi, action?.payload?.payload);
    if (response) {
      yield put(actions.addInformationCompanySuccess(response));
      yield call(action.payload.successCallBack, response);
    }
  } catch (error) {
    yield put(actions.addInformationCompanyFail(error));
    yield call(action.payload.failCallBack, error);
  }
}
export function* editInformationCompanySaga(action) {
  try {
    const response = yield call(editInformationCompanyApi, action.payload);
    yield put(actions.editInformationCompanySuccess(response));
  } catch (error) {
    yield put(actions.editInformationCompanyFail(error));
  }
}

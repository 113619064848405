import * as Yup from "yup";

export const addPassifBilanSchema = Yup.object().shape({
  capitaux_propres: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  capital: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  autre_capitaux_propre: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  passif_non_courant: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  dette_long_terme: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  autre_dette_long_terme: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  provision_risque_charge: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  dette_court_terme: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  dette_ct_emprunt: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  dette_ct_fournisseur: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  autre_dette_ct: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  total_passif: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  date: Yup.date().required("Obligatoire"),
  company_id: Yup.number("La valeur doit être un nombre").integer().required("Obligatoire"),
});

export const updatePassifBilanSchema = Yup.object().shape({
  capitaux_propres: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  capital: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  autre_capitaux_propre: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  passif_non_courant: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  dette_long_terme: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  autre_dette_long_terme: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  provision_risque_charge: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  dette_court_terme: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  dette_ct_emprunt: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  dette_ct_fournisseur: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  autre_dette_ct: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  total_passif: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  date: Yup.date().required("Obligatoire"),
  company_id: Yup.number("La valeur doit être un nombre").integer().required("Obligatoire"),
});

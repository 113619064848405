import { instance } from "../../utils/Axios";

export const getProductServicesApi = (data = {}) => {
  return instance
    .get(`/productServices?sort=${data.sort ?? ""}&order=${data.order ?? ""}&page=${data.page ?? 1}`)
    .then((response) => {
      return response;
    });
};

export const addProductServiceApi = (data) => {
  return instance.post("/productServices", data).then((response) => {
    return response;
  });
};

export const editProductServiceApi = (id) => {
  return instance.get(`/productServices/${id}`).then((response) => {
    return response;
  });
};

export const updateProductServiceApi = (data) => {
  return instance.put(`/productServices/${data.id}`, data).then((response) => {
    return response;
  });
};

export const deleteProductServiceApi = (data) => {
  return instance.delete(`/productServices/${data.id}`).then((response) => {
    return response;
  });
};

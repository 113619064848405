import { Button, Card, Grid, Icon, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
// Data
import countryProfilesTableData from "layouts/countryProfiles/data/countryProfilesTableData";
import DataTable from "examples/Tables/DataTable";
import { Box } from "@mui/system";
import MDButton from "components/MDButton";
import * as modalActions from "./modalActionTypes";
import FormAddCountryProfile from "./components/FormAddCountryProfile";
import CountryProfileModal from "./components/CountryProfileModal";
import { useParams } from "react-router-dom";
import FormUpdateCountryProfile from "./components/FormUpdateCountryProfile";
import ConfirmDeleteCountryProfile from "./components/ConfirmDeleteCountryProfile";
import { Link, useLocation } from "react-router-dom";
import { useMaterialUIController } from "context";
import tabs from "layouts/rapport/tabs";
import { setLayout } from "context";
import { layouts } from "chart.js";

function CountryProfiles() {
  const location = useLocation();
  const [, dispatch] = useMaterialUIController();
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, location?.state?.id ? "page" : "dashboard");
  }, [pathname]);

  const { countrySlug } = useParams();
  const { countryId } = useParams();
  console.log(countrySlug);
  const [countryProfileId, setCountryProfileId] = useState("");
  const [action, setAction] = useState(modalActions.ADD);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setAction(modalActions.ADD);
    setCountryProfileId("");
    setOpen(true);
  };
  const { columns, rows } = countryProfilesTableData(
    setOpen,
    setCountryProfileId,
    setAction,
    countrySlug
  );
  return (
    <DashboardLayout hide={location?.state?.id}>
      <DashboardNavbar />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
          {location?.state?.id ? (
            <Typography color="info" variant="body2" fontWeight="bold">
              <Button
                size="small"
                component={Link}
                to={`/rapport/${location?.state?.slug}`}
                state={{
                  rapportTab: tabs.find((tab) => tab.key == "profil-pays").index,
                }}
              >
                <Icon fontSize="large">arrow_back</Icon> Retour a la page rapport
              </Button>
            </Typography>
          ) : (
            ""
          )}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box mr={1}>
            <MDButton onClick={handleOpen} variant="contained" size="small" color="info">
              <Icon>add</Icon> profile pays
            </MDButton>
          </Box>
          <Box mr={1}>
            <MDButton to="#" variant="contained" size="small" color="success">
              Exporter <Icon>downloadfile</Icon>
            </MDButton>
          </Box>
        </Box>
      </Box>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Profils Pays
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {rows && 
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                }
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <CountryProfileModal
        title={
          action == modalActions.UPDATE
            ? "Modifier profile pays"
            : action == modalActions.DELETE
            ? "Supprimer profile pays"
            : "Ajouter nouveau profile pays"
        }
        open={open}
        setOpen={setOpen}
      >
        {/* ADD MODAL */}
        {action == modalActions.ADD && (
          <FormAddCountryProfile
            countryId={countryId}
            countrySlug={countrySlug}
            open={open}
            setOpen={setOpen}
            setAction={setAction}
          />
        )}
        {/* DELETE MODAL */}
        {action == modalActions.DELETE && (
          <ConfirmDeleteCountryProfile
            setOpen={setOpen}
            countrySlug={countrySlug}
            countryProfileId={countryProfileId}
            setCountryProfileId={setCountryProfileId}
            setAction={setAction}
          />
        )}
        {/* UPDATE MODAL */}
        {action == modalActions.UPDATE && (
          <FormUpdateCountryProfile
            setOpen={setOpen}
            countryProfileId={countryProfileId}
            countrySlug={countrySlug}
            setCountryProfileId={setCountryProfileId}
            setAction={setAction}
          />
        )}
      </CountryProfileModal>
    </DashboardLayout>
  );
}
export default CountryProfiles;

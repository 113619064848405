import {
  Alert,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateActifBilanSchema } from "../schema";
import * as actions from "../redux/state/ActifBilansAction";
import * as actionsCompanies from "../../../../companies/redux/state/CompaniesAction";
import * as modalActions from "../modalActionTypes";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import { getErrorMessage } from "common/utils/Helper";

export default function FormUpdateActifBilan({
  setOpen,
  actifBilanId,
  setActifBilanId,
  setAction,
  companyId,
  setCompanyId,
}) {
  const dispatch = useDispatch();
  const location = useLocation();

  const [errorSB, setErrorSB] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  // order select companies
  const orderCompanies = {
    sort: "raison_social",
    order: "asc",
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    dispatch(actions.editActifBilan(actifBilanId));
    dispatch(actionsCompanies.getCompanies(orderCompanies));
  }, []);
  const actifBilan = useSelector((state) => state?.actifBilans?.actifBilan);

  const companies = useSelector((state) => state?.companies.companies);
  const [value, setValue] = useState(dayjs(actifBilan?.date));

  const onSubmit = (values) => {
    const request = {
      payload: values,
      successCallBack: (response) => {
        closeErrorSB()
        setCompanyId(values?.company_id ?? companyId);
        let queryParam = {};
        if (
          (companyId.toString().length > 0 && companyId !== undefined) ||
          (values?.company_id.toString().length > 0 && values?.company_id !== undefined)
        ) {
          queryParam = { companyId: values?.company_id ?? companyId, sort: "date", order: "desc" };
        }
        dispatch(actions.getActifBilans(queryParam));
        setAction(modalActions.ADD);
        setActifBilanId("");
        setOpen(false);
        console.log("response", response);
      },
      failCallBack: (error) => {
        console.log("error", error);
        setErrorMessage(getErrorMessage(error))
        openErrorSB();
      },
    };
    dispatch(actions.updateActifBilan(request));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: actifBilan?.id,
      actif_immobilise: actifBilan?.actif_immobilise,
      immobilisation_incorporelle: actifBilan?.immobilisation_incorporelle,
      immobilisation_corporelle: actifBilan?.immobilisation_corporelle,
      autre_actif_immobilise: actifBilan?.autre_actif_immobilise,
      actif_circulant: actifBilan?.actif_circulant,
      actif_circulant_stock: actifBilan?.actif_circulant_stock,
      actif_circulant_creance: actifBilan?.actif_circulant_creance,
      autre_actif_circulant: actifBilan?.autre_actif_circulant,
      valeur_disponible: actifBilan?.valeur_disponible,
      total_actif: actifBilan?.total_actif,
      date: actifBilan?.date,
      company_id: actifBilan?.company_id,
    },
    validationSchema: updateActifBilanSchema,
    onSubmit,
  });
  const disabledSelect = location?.state?.id ? true: false;

  const { handleSubmit, errors, values, handleChange } = formik;
  return (
    <MDBox m={1}>
      {errorSB && (
        <Alert severity="error">
          {errorMessage}
        </Alert>
      )}
      <form onSubmit={handleSubmit}>
        <MDBox py={2}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="actif_immobilise"
                  label="Actif immobilise"
                  error={errors.actif_immobilise ? true : false}
                  onChange={handleChange}
                  value={values.actif_immobilise?.toString().replace(",", ".") ?? ""}
                  helperText={errors?.actif_immobilise ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="immobilisation_incorporelle"
                  label="Immobilisations incorporelles"
                  error={errors.immobilisation_incorporelle ? true : false}
                  onChange={handleChange}
                  value={values.immobilisation_incorporelle?.toString().replace(",", ".") ?? ""}
                  helperText={errors?.immobilisation_incorporelle ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="immobilisation_corporelle"
                  label="Immobilisation corporelles"
                  error={errors.immobilisation_corporelle ? true : false}
                  onChange={handleChange}
                  value={values.immobilisation_corporelle?.toString().replace(",", ".") ?? ""}
                  helperText={errors?.immobilisation_corporelle ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="autre_actif_immobilise"
                  label="Autres actifs immobilises"
                  error={errors.autre_actif_immobilise ? true : false}
                  onChange={handleChange}
                  value={values.autre_actif_immobilise?.toString().replace(",", ".") ?? ""}
                  helperText={errors?.autre_actif_immobilise ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="actif_circulant"
                  label="Actif circulant"
                  error={errors.actif_circulant ? true : false}
                  onChange={handleChange}
                  value={values.actif_circulant?.toString().replace(",", ".") ?? ""}
                  helperText={errors?.actif_circulant ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="actif_circulant_stock"
                  label="Actif circulant: stock"
                  error={errors.actif_circulant_stock ? true : false}
                  onChange={handleChange}
                  value={values.actif_circulant_stock?.toString().replace(",", ".") ?? ""}
                  helperText={errors?.actif_circulant_stock ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="actif_circulant_creance"
                  label="Actif circulant: creance"
                  error={errors.actif_circulant_creance ? true : false}
                  onChange={handleChange}
                  value={values.actif_circulant_creance?.toString().replace(",", ".") ?? ""}
                  helperText={errors?.actif_circulant_creance ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="autre_actif_circulant"
                  label="Autres actifs circulants"
                  error={errors.autre_actif_circulant ? true : false}
                  onChange={handleChange}
                  value={values.autre_actif_circulant?.toString().replace(",", ".") ?? ""}
                  helperText={errors?.autre_actif_circulant ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="valeur_disponible"
                  label="Valeurs disponibles"
                  error={errors.valeur_disponible ? true : false}
                  onChange={handleChange}
                  value={values.valeur_disponible?.toString().replace(",", ".") ?? ""}
                  helperText={errors?.valeur_disponible ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="total_actif"
                  label="Totals de l'actif"
                  error={errors.total_actif ? true : false}
                  onChange={handleChange}
                  value={values.total_actif?.toString()?.toString().replace(",", ".") ?? ""}
                  helperText={errors?.total_actif ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    renderInput={(props) => <TextField {...props} />}
                    name="date"
                    value={value}
                    error={errors.date ? true : false}
                    onChange={(newValue) => {
                      setValue(newValue);
                      formik.setFieldValue("date", dayjs(newValue).format("YYYY-MM-DD"));
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <InputLabel id="company_id">Entreprise</InputLabel>
                <Select
                  name="company_id"
                  style={{ height: "40px" }}
                  labelId="state_label"
                  label="Entreprise"
                  error={errors.company_id ? true : false}
                  onChange={handleChange}
                  value={values.company_id ?? ""}
                  disabled = {disabledSelect}
                >
                  {companies?.data?.map((company) => {
                    return (
                      <MenuItem key={company?.id} value={company?.id}>
                        {/* <b>{company?.raison_social}</b>&nbsp;({company?.country_id}) */}
                        <p>
                          <b>{company?.raison_social}</b> ({company?.country?.name})
                        </p>
                      </MenuItem>
                    );
                  })}
                </Select>
                {errors.country_id ? (
                  <FormHelperText error>{errors?.company}</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox sx={{ display: "flex" }}>
          <MDBox mr={1} sx={{ flexGrow: "1" }}>
            <MDButton onClick={handleClose} type="submit" color="primary" fullWidth>
              Annuler
            </MDButton>
          </MDBox>
          <MDBox sx={{ flexGrow: "1" }}>
            <MDButton type="submit" color="info" fullWidth>
              Modifier
            </MDButton>
          </MDBox>
        </MDBox>
      </form>
    </MDBox>
  );
}

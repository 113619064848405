import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/state/CompaniesAction";
import * as modalActions from "../modalActionTypes";
import { Box } from "@mui/system";
import { Icon, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import { Link } from "react-router-dom";

export default function Data(setOpen, setCompanyId, setAction, page, search) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getCompanies({page,search}));
  }, [page, search]);

  const handleOpenEdit = (id) => {
    setOpen(true);
    setCompanyId(id);
    setAction(modalActions.UPDATE);
  };

  const handleOpenDelete = (id) => {
    setOpen(true);
    setCompanyId(id);
    setAction(modalActions.DELETE);
  };

  const companies = useSelector((state) => state?.companies?.companies);
  const companiesRows = companies && companies?.data?.map((company, i) => ({
    index: i + 1,
    assurtrad_id: company?.assurtrad_id,
    raison_social: (
      <Link to={`/rapport/${company?.slug}`} style={{ color:"#1261ca" }}>{company?.raison_social}</Link>
    ),
    forme_juridique: company?.forme_juridique,
    statut: (
      <MDBox ml={-1}>
        <MDBadge
          badgeContent={company?.statut ? "active" : "inactive"}
          color={company?.statut ? "success" : "secondary"}
          variant="gradient"
          size="sm"
        />
      </MDBox>
    ),
    numero_rc: company?.numero_rc,
    website: company?.website,
    adresse_siege_social: company?.adresse_siege_social,
    country: company?.country?.name,
    city: company?.city,
    prefecture: company?.prefecture,
    type_identite: company?.type_identite,
    numero_ice: company?.numero_ice,
    date_creation: company?.date_creation,
    created_at: new Date(company?.created_at)?.toLocaleDateString(),
    updated_at: new Date(company?.updated_at)?.toLocaleDateString(),

    action: (
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box>
          <IconButton color="info" onClick={() => handleOpenEdit(company.id)} component="label">
            <Icon color="info" fontSize="small">
              mode
            </Icon>
          </IconButton>
        </Box>
        <Box>
          <IconButton
            color="primary"
            onClick={() => handleOpenDelete(company.id)}
            component="label"
          >
            <Icon color="primary" fontSize="small">
              delete
            </Icon>
          </IconButton>
        </Box>
      </Box>
    ),
  }));
  return {
    columns: [
      { Header: "#", accessor: "index", align: "left" },
      { Header: "assurtrad id", accessor: "assurtrad_id", align: "left" },
      { Header: "raison social", accessor: "raison_social", align: "left" },
      { Header: "forme juridique", accessor: "forme_juridique", align: "left" },
      { Header: "statut", accessor: "statut", align: "left" },
      { Header: "numero rc", accessor: "numero_rc", align: "left" },
      { Header: "site web", accessor: "website", align: "left" },
      { Header: "adresse siege social", accessor: "adresse_siege_social", align: "left" },
      { Header: "pays", accessor: "country", align: "left" },
      { Header: "ville", accessor: "city", align: "left" },
      { Header: "prefecture", accessor: "prefecture", align: "left" },
      { Header: "type identite", accessor: "type_identite", align: "left" },
      { Header: "numero ice", accessor: "numero_ice", align: "left" },
      { Header: "date creation", accessor: "date_creation", align: "left" },
      { Header: "Date d'ajout", accessor: "created_at", align: "left" },
      { Header: "Date de modification", accessor: "updated_at", align: "left" },
      { Header: "action", accessor: "action", align: "left" },
    ],

    rows: companiesRows,
    data: companies,
  };
}

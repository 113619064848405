import * as Yup from "yup";

export const addCompteDeResultatBilanSchema = Yup.object().shape({
  achat_mat_prem_approv: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  added_value: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  autre_element_exploitation: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  capacite_autofin_avant_rep: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  company_id: Yup.number("La valeur doit être un nombre").integer().required("Obligatoire"),
  date: Yup.date().required("Obligatoire"),
  depreciation_ammortissement: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  ebitda: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  export_turnover: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  interest_paid: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  r_d_expenses: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  salaries_traitement: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
 });

export const updateCompteDeResultatBilanSchema = Yup.object().shape({
  achat_mat_prem_approv: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  salaries_traitement: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  depreciation_ammortissement: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  autre_element_exploitation: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  capacite_autofin_avant_rep: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  ebitda: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  date: Yup.date().required("Obligatoire"),
  company_id: Yup.number("La valeur doit être un nombre").integer().required("Obligatoire"),
});

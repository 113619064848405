// ########-------------------AUTH-------------------########
//        ######-----------LOGIN_USER-----------######
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";
//        ######---------END_LOGIN_USER---------######
export const ME = "ME";
export const ME_SUCCESS = "ME_SUCCESS";
export const ME_FAIL ="ME_FAIL";
// ########-----------------END_AUTH-----------------########

// ########-------------------COUNTRY-------------------########

//        ######-----------GET_COUNTRIES-----------######
export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAIL = "GET_COUNTRIES_FAIL";
//        ######---------END_GET_COUNTRIES---------######

//        ######-----------ADD_COUNTRY-----------######
export const ADD_COUNTRY = "ADD_COUNTRY";
export const ADD_COUNTRY_SUCCESS = "ADD_COUNTRY_SUCCESS";
export const ADD_COUNTRY_FAIL = "ADD_COUNTRY_FAIL";
//        ######---------END_ADD_COUNTRY---------######

//        ######-----------DELETE_COUNTRY-----------######
export const DELETE_COUNTRY = "DELETE_COUNTRY";
export const DELETE_COUNTRY_SUCCESS = "DELETE_COUNTRY_SUCCESS";
export const DELETE_COUNTRY_FAIL = "DELETE_COUNTRY_FAIL";
//        ######---------END_DELETE_COUNTRY---------######

//        ######-----------SHOW_COUNTRY-----------######
export const SHOW_COUNTRY = "SHOW_COUNTRY";
export const SHOW_COUNTRY_SUCCESS = "SHOW_COUNTRY_SUCCESS";
export const SHOW_COUNTRY_FAIL = "SHOW_COUNTRY_FAIL";
//        ######---------END_SHOW_COUNTRY---------######

//        ######-----------EDIT_COUNTRY-----------######
export const EDIT_COUNTRY = "EDIT_COUNTRY";
export const EDIT_COUNTRY_SUCCESS = "EDIT_COUNTRY_SUCCESS";
export const EDIT_COUNTRY_FAIL = "EDIT_COUNTRY_FAIL";
//        ######---------END_EDIT_COUNTRY---------######

//        ######-----------UPDATE_COUNTRY-----------######
export const UPDATE_COUNTRY = "UPDATE_COUNTRY";
export const UPDATE_COUNTRY_SUCCESS = "UPDATE_COUNTRY_SUCCESS";
export const UPDATE_COUNTRY_FAIL = "UPDATE_COUNTRY_FAIL";
//        ######---------END_UPDATE_COUNTRY---------######

// ########-----------------END_COUNTRY-----------------########

// ########-------------------USERS-------------------########

//        ######-----------GET_USERS-----------######
export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";
//        ######---------END_GET_USERS---------######

//        ######-----------ADD_USER-----------######
export const ADD_USER = "ADD_USER";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAIL = "ADD_USER_FAIL";
//        ######---------ADD_USER---------######

//        ######-----------DELETE_USER-----------######
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";
//        ######---------DELETE_USER---------######

//        ######-----------EDIT_USER-----------######
export const EDIT_USER = "EDIT_USER";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_FAIL = "EDIT_USER_FAIL";
//        ######---------EDIT_USER---------######

//        ######-----------UPDATE_USER-----------######
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
//        ######---------UPDATE_USER---------######

// ########-------------------END_USERS-------------------########

// ########-------------------COMPANIES-------------------########

//        ######-----------GET_USERS-----------######
export const GET_COMPANIES = "GET_COMPANIES";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
export const GET_COMPANIES_FAIL = "GET_COMPANIES_FAIL";
//        ######---------END_GET_COMPANIES---------######

//        ######-----------GET_USERS-----------######
export const GET_MISSING_COMPANIES = "GET_MISSING_COMPANIES";
export const GET_MISSING_COMPANIES_SUCCESS = "GET_MISSING_COMPANIES_SUCCESS";
export const GET_MISSING_COMPANIES_FAIL = "GET_MISSING_COMPANIES_FAIL";
//        ######---------END_GET_MISSING_COMPANIES---------######

//        ######-----------ADD_COMPANY-----------######
export const ADD_COMPANY = "ADD_COMPANY";
export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS";
export const ADD_COMPANY_FAIL = "ADD_COMPANY_FAIL";
//        ######---------END_ADD_COMPANY---------######

//        ######-----------DELETE_COMPANY-----------######
export const DELETE_COMPANY = "DELETE_COMPANY";
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS";
export const DELETE_COMPANY_FAIL = "DELETE_COMPANY_FAIL";
//        ######---------DELETE_COMPANY---------######
//        ######-----------UPLOAD_COMPANY-----------######
export const UPLOAD_COMPANY = "UPLOAD_COMPANY";
export const UPLOAD_COMPANY_SUCCESS = "UPLOAD_COMPANY_SUCCESS";
export const UPLOAD_COMPANY_FAIL = "UPLOAD_COMPANY_FAIL";
//        ######---------UPLOAD_COMPANY---------######
export const DOWNLOAD_RAPPORT = "DOWNLOAD_RAPPORT";

//        ######-----------EDIT_COMPANY-----------######
export const EDIT_COMPANY = "EDIT_COMPANY";
export const EDIT_COMPANY_SUCCESS = "EDIT_COMPANY_SUCCESS";
export const EDIT_COMPANY_FAIL = "EDIT_COMPANY_FAIL";
//        ######---------EDIT_COMPANY---------######

//        ######-----------UPDATE_COMPANY-----------######
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_FAIL = "UPDATE_COMPANY_FAIL";
//        ######---------UPDATE_USER---------######

//        ######-----------ADD_COMPANY-----------######
export const ADD_MISSING_COMPANY = "ADD_MISSING_COMPANY";
export const ADD_MISSING_COMPANY_SUCCESS = "ADD_MISSING_COMPANY_SUCCESS";
export const ADD_MISSING_COMPANY_FAIL = "ADD_MISSING_COMPANY_FAIL";
//        ######---------END_ADD_MISSING_COMPANY---------######

//        ######-----------DELETE_MISSING_COMPANY-----------######
export const DELETE_MISSING_COMPANY = "DELETE_MISSING_COMPANY";
export const DELETE_MISSING_COMPANY_SUCCESS = "DELETE_MISSING_COMPANY_SUCCESS";
export const DELETE_MISSING_COMPANY_FAIL = "DELETE_MISSING_COMPANY_FAIL";
//        ######---------DELETE_MISSING_COMPANY---------######
//        ######-----------UPLOAD_MISSING_COMPANY-----------######
export const UPLOAD_MISSING_COMPANY = "UPLOAD_MISSING_COMPANY";
export const UPLOAD_MISSING_COMPANY_SUCCESS = "UPLOAD_MISSING_COMPANY_SUCCESS";
export const UPLOAD_MISSING_COMPANY_FAIL = "UPLOAD_MISSING_COMPANY_FAIL";

//        ######-----------EDIT_MISSING_COMPANY-----------######
export const EDIT_MISSING_COMPANY = "EDIT_MISSING_COMPANY";
export const EDIT_MISSING_COMPANY_SUCCESS = "EDIT_MISSING_COMPANY_SUCCESS";
export const EDIT_MISSING_COMPANY_FAIL = "EDIT_MISSING_COMPANY_FAIL";
//        ######---------EDIT_MISSING_COMPANY---------######

//        ######-----------UPDATE_MISSING_COMPANY-----------######
export const UPDATE_MISSING_COMPANY = "UPDATE_MISSING_COMPANY";
export const UPDATE_MISSING_COMPANY_SUCCESS = "UPDATE_MISSING_COMPANY_SUCCESS";
export const UPDATE_MISSING_COMPANY_FAIL = "UPDATE_MISSING_COMPANY_FAIL";
//        ######---------UPDATE_USER---------######

// ########-------------------END_COMPANIES-------------------########

// ########-------------------COUNTRY_PROFILES-------------------########

//        ######-----------GET_COUNTRY_PROFILES-----------######
export const GET_COUNTRY_PROFILES = "GET_COUNTRY_PROFILES";
export const GET_COUNTRY_PROFILES_SUCCESS = "GET_COUNTRY_PROFILES_SUCCESS";
export const GET_COUNTRY_PROFILES_FAIL = "GET_COUNTRY_PROFILES_FAIL";
//        ######---------END_GET_COUNTRY_PROFILES---------######

//        ######-----------ADD_COUNTRY_PROFILE-----------######
export const ADD_COUNTRY_PROFILE = "ADD_COUNTRY_PROFILE";
export const ADD_COUNTRY_PROFILE_SUCCESS = "ADD_COUNTRY_PROFILE_SUCCESS";
export const ADD_COUNTRY_PROFILE_FAIL = "ADD_COUNTRY_PROFILE_FAIL";
//        ######---------END_ADD_COUNTRY_PROFILE---------######

//        ######-----------EDIT_COUNTRY_PROFILE-----------######
export const EDIT_COUNTRY_PROFILE = "EDIT_COUNTRY_PROFILE";
export const EDIT_COUNTRY_PROFILE_SUCCESS = "EDIT_COUNTRY_PROFILE_SUCCESS";
export const EDIT_COUNTRY_PROFILE_FAIL = "EDIT_COUNTRY_PROFILE_FAIL";
//        ######---------END_EDIT_COUNTRY_PROFILE---------######

//        ######-----------UPDATE_COUNTRY_PROFILE-----------######
export const UPDATE_COUNTRY_PROFILE = "UPDATE_COUNTRY_PROFILE";
export const UPDATE_COUNTRY_PROFILE_SUCCESS = "UPDATE_COUNTRY_PROFILE_SUCCESS";
export const UPDATE_COUNTRY_PROFILE_FAIL = "UPDATE_COUNTRY_PROFILE_FAIL";
//        ######---------END_UPDATE_COUNTRY_PROFILE---------######

//        ######-----------DELETE_COUNTRY_PROFILE-----------######
export const DELETE_COUNTRY_PROFILE = "DELETE_COUNTRY_PROFILE";
export const DELETE_COUNTRY_PROFILE_SUCCESS = "DELETE_COUNTRY_PROFILE_SUCCESS";
export const DELETE_COUNTRY_PROFILE_FAIL = "DELETE_COUNTRY_PROFILE_FAIL";
//        ######---------END_DELETE_COUNTRY_PROFILE---------######

// ########-------------------END_COUNTRY_PROFILES-------------------########

// ########-------------------SHAREHOLDINGS-------------------########

//        ######-----------GET_SHAREHOLDINGS-----------######
export const GET_SHAREHOLDINGS = "GET_SHAREHOLDINGS";
export const GET_SHAREHOLDINGS_SUCCESS = "GET_SHAREHOLDINGS_SUCCESS";
export const GET_SHAREHOLDINGS_FAIL = "GET_SHAREHOLDINGS_FAIL";
//        ######---------END_GET_SHAREHOLDINGS---------######

//        ######-----------ADD_SHAREHOLDING-----------######
export const ADD_SHAREHOLDING = "ADD_SHAREHOLDING";
export const ADD_SHAREHOLDING_SUCCESS = "ADD_SHAREHOLDING_SUCCESS";
export const ADD_SHAREHOLDING_FAIL = "ADD_SHAREHOLDING_FAIL";
//        ######---------END_ADD_SHAREHOLDING---------######

//        ######-----------EDIT_SHAREHOLDING-----------######
export const EDIT_SHAREHOLDING = "EDIT_SHAREHOLDING";
export const EDIT_SHAREHOLDING_SUCCESS = "EDIT_SHAREHOLDING_SUCCESS";
export const EDIT_SHAREHOLDING_FAIL = "EDIT_SHAREHOLDING_FAIL";
//        ######---------END_EDIT_SHAREHOLDING---------######

//        ######-----------UPDATE_SHAREHOLDING-----------######
export const UPDATE_SHAREHOLDING = "UPDATE_SHAREHOLDING";
export const UPDATE_SHAREHOLDING_SUCCESS = "UPDATE_SHAREHOLDING_SUCCESS";
export const UPDATE_SHAREHOLDING_FAIL = "UPDATE_SHAREHOLDING_FAIL";
//        ######---------END_UPDATE_SHAREHOLDING---------######

//        ######-----------DELETE_SHAREHOLDING-----------######
export const DELETE_SHAREHOLDING = "DELETE_SHAREHOLDING";
export const DELETE_SHAREHOLDING_SUCCESS = "DELETE_SHAREHOLDING_SUCCESS";
export const DELETE_SHAREHOLDING_FAIL = "DELETE_SHAREHOLDING_FAIL";
//        ######---------END_DELETE_SHAREHOLDING---------######
// ########-------------------END_SHAREHOLDINGS-------------------########

// ########-------------------SHAREHOLDINGS-------------------########
// ########-------------------DIRECTOR-------------------########

//        ######-----------GET_DIRECTORS-----------######
export const GET_DIRECTORS = "GET_DIRECTORS";
export const GET_DIRECTORS_SUCCESS = "GET_DIRECTORS_SUCCESS";
export const GET_DIRECTORS_FAIL = "GET_DIRECTORS_FAIL";
//        ######---------END_GET_DIRECTORS---------######

//        ######-----------ADD_DIRECTOR-----------######
export const ADD_DIRECTOR = "ADD_DIRECTOR";
export const ADD_DIRECTOR_SUCCESS = "ADD_DIRECTOR_SUCCESS";
export const ADD_DIRECTOR_FAIL = "ADD_DIRECTOR_FAIL";
//        ######---------END_ADD_DIRECTOR---------######

//        ######-----------EDIT_DIRECTOR-----------######
export const EDIT_DIRECTOR = "EDIT_DIRECTOR";
export const EDIT_DIRECTOR_SUCCESS = "EDIT_DIRECTOR_SUCCESS";
export const EDIT_DIRECTOR_FAIL = "EDIT_DIRECTOR_FAIL";
//        ######---------END_EDIT_DIRECTOR---------######

//        ######-----------UPDATE_DIRECTOR-----------######
export const UPDATE_DIRECTOR = "UPDATE_DIRECTOR";
export const UPDATE_DIRECTOR_SUCCESS = "UPDATE_DIRECTOR_SUCCESS";
export const UPDATE_DIRECTOR_FAIL = "UPDATE_DIRECTOR_FAIL";
//        ######---------END_UPDATE_DIRECTOR---------######

//        ######-----------DELETE_DIRECTOR-----------######
export const DELETE_DIRECTOR = "DELETE_DIRECTOR";
export const DELETE_DIRECTOR_SUCCESS = "DELETE_DIRECTOR_SUCCESS";
export const DELETE_DIRECTOR_FAIL = "DELETE_DIRECTOR_FAIL";
//        ######---------END_DELETE_DIRECTOR---------######

//        ######-----------UPLOAD_DIRECTOR-----------######
export const UPLOAD_DIRECTOR = "UPLOAD_DIRECTOR";
export const UPLOAD_DIRECTOR_SUCCESS = "UPLOAD_DIRECTOR_SUCCESS";
export const UPLOAD_DIRECTOR_FAIL = "UPLOAD_DIRECTOR_FAIL";
//        ######---------END_UPLOAD_DIRECTOR---------######

// ########-------------------END_DIRECTOR-------------------########

// ########-------------------SEARCH-------------------########

//        ######-----------GET_SEARCH-----------######
export const GET_SEARCH = "GET_SEARCH";
export const GET_SEARCH_SUCCESS = "GET_SEARCH_SUCCESS";
export const GET_SEARCH_FAIL = "GET_SEARCH_FAIL";
//        ######---------END_GET_SEARCH---------######

// ########-------------------END_SEARCH-------------------########

// ########-------------------COMPTE_RESULTAT_BILANS-------------------########

//        ######-----------GET_COMPTE_RESULTAT_BILANS-----------######
export const GET_COMPTE_RESULTAT_BILANS = "GET_COMPTE_RESULTAT_BILANS";
export const GET_COMPTE_RESULTAT_BILANS_SUCCESS = "GET_COMPTE_RESULTAT_BILANS_SUCCESS";
export const GET_COMPTE_RESULTAT_BILANS_FAIL = "GET_COMPTE_RESULTAT_BILANS_FAIL";
//        ######---------END_GET_COMPTE_RESULTAT_BILANS---------######

//        ######-----------ADD_COMPTE_RESULTAT_BILAN-----------######
export const ADD_COMPTE_RESULTAT_BILAN = "ADD_COMPTE_RESULTAT_BILAN";
export const ADD_COMPTE_RESULTAT_BILAN_SUCCESS = "ADD_COMPTE_RESULTAT_BILAN_SUCCESS";
export const ADD_COMPTE_RESULTAT_BILAN_FAIL = "ADD_COMPTE_RESULTAT_BILAN_FAIL";
//        ######---------END_ADD_COMPTE_RESULTAT_BILAN---------######

//        ######-----------EDIT_COMPTE_RESULTAT_BILAN-----------######
export const EDIT_COMPTE_RESULTAT_BILAN = "EDIT_COMPTE_RESULTAT_BILAN";
export const EDIT_COMPTE_RESULTAT_BILAN_SUCCESS = "EDIT_COMPTE_RESULTAT_BILAN_SUCCESS";
export const EDIT_COMPTE_RESULTAT_BILAN_FAIL = "EDIT_COMPTE_RESULTAT_BILAN_FAIL";
//        ######---------END_EDIT_COMPTE_RESULTAT_BILAN---------######

//        ######-----------UPDATE_COMPTE_RESULTAT_BILAN-----------######
export const UPDATE_COMPTE_RESULTAT_BILAN = "UPDATE_COMPTE_RESULTAT_BILAN";
export const UPDATE_COMPTE_RESULTAT_BILAN_SUCCESS = "UPDATE_COMPTE_RESULTAT_BILAN_SUCCESS";
export const UPDATE_COMPTE_RESULTAT_BILAN_FAIL = "UPDATE_COMPTE_RESULTAT_BILAN_FAIL";
//        ######---------END_UPDATE_COMPTE_RESULTAT_BILAN---------######

//        ######-----------DELETE_COMPTE_RESULTAT_BILAN-----------######
export const DELETE_COMPTE_RESULTAT_BILAN = "DELETE_COMPTE_RESULTAT_BILAN";
export const DELETE_COMPTE_RESULTAT_BILAN_SUCCESS = "DELETE_COMPTE_RESULTAT_BILAN_SUCCESS";
export const DELETE_COMPTE_RESULTAT_BILAN_FAIL = "DELETE_COMPTE_RESULTAT_BILAN_FAIL";
//        ######---------END_DELETE_COMPTE_RESULTAT_BILAN---------######

// ########-------------------END_COMPTE_RESULTAT_BILANS-------------------########

// ########-------------------COMPTE_RESULTAT_BILANS-------------------########

//        ######-----------GET_MEMO_BILANS-----------######
export const GET_MEMO_BILANS = "GET_MEMO_BILANS";
export const GET_MEMO_BILANS_SUCCESS = "GET_MEMO_BILANS_SUCCESS";
export const GET_MEMO_BILANS_FAIL = "GET_MEMO_BILANS_FAIL";
//        ######---------END_GET_MEMO_BILANS---------######

//        ######-----------ADD_MEMO_BILAN-----------######
export const ADD_MEMO_BILAN = "ADD_MEMO_BILAN";
export const ADD_MEMO_BILAN_SUCCESS = "ADD_MEMO_BILAN_SUCCESS";
export const ADD_MEMO_BILAN_FAIL = "ADD_MEMO_BILAN_FAIL";
//        ######---------END_ADD_MEMO_BILAN---------######

//        ######-----------EDIT_MEMO_BILAN-----------######
export const EDIT_MEMO_BILAN = "EDIT_MEMO_BILAN";
export const EDIT_MEMO_BILAN_SUCCESS = "EDIT_MEMO_BILAN_SUCCESS";
export const EDIT_MEMO_BILAN_FAIL = "EDIT_MEMO_BILAN_FAIL";
//        ######---------END_EDIT_MEMO_BILAN---------######

//        ######-----------UPDATE_MEMO_BILAN-----------######
export const UPDATE_MEMO_BILAN = "UPDATE_MEMO_BILAN";
export const UPDATE_MEMO_BILAN_SUCCESS = "UPDATE_MEMO_BILAN_SUCCESS";
export const UPDATE_MEMO_BILAN_FAIL = "UPDATE_MEMO_BILAN_FAIL";
//        ######---------END_UPDATE_MEMO_BILAN---------######

//        ######-----------DELETE_MEMO_BILAN-----------######
export const DELETE_MEMO_BILAN = "DELETE_MEMO_BILAN";
export const DELETE_MEMO_BILAN_SUCCESS = "DELETE_MEMO_BILAN_SUCCESS";
export const DELETE_MEMO_BILAN_FAIL = "DELETE_MEMO_BILAN_FAIL";
//        ######---------END_DELETE_MEMO_BILAN---------######

// ########-------------------END_MEMO_BILANS-------------------########

// ########-------------------ACTIF_BILANS-------------------########

//        ######-----------GET_ACTIF_BILANS-----------######
export const GET_ACTIF_BILANS = "GET_ACTIF_BILANS";
export const GET_ACTIF_BILANS_SUCCESS = "GET_ACTIF_BILANS_SUCCESS";
export const GET_ACTIF_BILANS_FAIL = "GET_ACTIF_BILANS_FAIL";
//        ######---------END_GET_ACTIF_BILANS---------######

//        ######-----------ADD_ACTIF_BILAN-----------######
export const ADD_ACTIF_BILAN = "ADD_ACTIF_BILAN";
export const ADD_ACTIF_BILAN_SUCCESS = "ADD_ACTIF_BILAN_SUCCESS";
export const ADD_ACTIF_BILAN_FAIL = "ADD_ACTIF_BILAN_FAIL";
//        ######---------END_ADD_ACTIF_BILAN---------######

//        ######-----------EDIT_ACTIF_BILAN-----------######
export const EDIT_ACTIF_BILAN = "EDIT_ACTIF_BILAN";
export const EDIT_ACTIF_BILAN_SUCCESS = "EDIT_ACTIF_BILAN_SUCCESS";
export const EDIT_ACTIF_BILAN_FAIL = "EDIT_ACTIF_BILAN_FAIL";
//        ######---------END_EDIT_ACTIF_BILAN---------######

//        ######-----------UPDATE_ACTIF_BILAN-----------######
export const UPDATE_ACTIF_BILAN = "UPDATE_ACTIF_BILAN";
export const UPDATE_ACTIF_BILAN_SUCCESS = "UPDATE_ACTIF_BILAN_SUCCESS";
export const UPDATE_ACTIF_BILAN_FAIL = "UPDATE_ACTIF_BILAN_FAIL";
//        ######---------END_UPDATE_ACTIF_BILAN---------######

//        ######-----------DELETE_ACTIF_BILAN-----------######
export const DELETE_ACTIF_BILAN = "DELETE_ACTIF_BILAN";
export const DELETE_ACTIF_BILAN_SUCCESS = "DELETE_ACTIF_BILAN_SUCCESS";
export const DELETE_ACTIF_BILAN_FAIL = "DELETE_ACTIF_BILAN_FAIL";
//        ######---------END_DELETE_ACTIF_BILAN---------######

// ########-------------------END_ACTIF_BILANS-------------------########

// ########-------------------PRODUCTSERVICES-------------------########

//        ######-----------GET_PRODUCTSERVICES-----------######
export const GET_PRODUCTSERVICES = "GET_DIRECTORS";
export const GET_PRODUCTSERVICES_SUCCESS = "GET_PRODUCTSERVICES_SUCCESS";
export const GET_PRODUCTSERVICES_FAIL = "GET_PRODUCTSERVICES_FAIL";
//        ######---------END_GET_DIRECTORS---------######

//        ######-----------ADD_PRODUCTSERVICE-----------######
export const ADD_PRODUCTSERVICE = "ADD_PRODUCTSERVICE";
export const ADD_PRODUCTSERVICE_SUCCESS = "ADD_PRODUCTSERVICE_SUCCESS";
export const ADD_PRODUCTSERVICE_FAIL = "ADD_PRODUCTSERVICE_FAIL";
//        ######---------END_ADD_PRODUCTSERVICE---------######

//        ######-----------EDIT_PRODUCTSERVICE-----------######
export const EDIT_PRODUCTSERVICE = "EDIT_PRODUCTSERVICE";
export const EDIT_PRODUCTSERVICE_SUCCESS = "EDIT_PRODUCTSERVICE_SUCCESS";
export const EDIT_PRODUCTSERVICE_FAIL = "EDIT_PRODUCTSERVICE_FAIL";
//        ######---------END_EDIT_PRODUCTSERVICE---------######

//        ######-----------UPDATE_PRODUCTSERVICE-----------######
export const UPDATE_PRODUCTSERVICE = "UPDATE_PRODUCTSERVICE";
export const UPDATE_PRODUCTSERVICE_SUCCESS = "UPDATE_PRODUCTSERVICE_SUCCESS";
export const UPDATE_PRODUCTSERVICE_FAIL = "UPDATE_PRODUCTSERVICE_FAIL";
//        ######---------END_UPDATE_PRODUCTSERVICE---------######

//        ######-----------DELETE_PRODUCTSERVICE-----------######
export const DELETE_PRODUCTSERVICE = "DELETE_PRODUCTSERVICE";
export const DELETE_PRODUCTSERVICE_SUCCESS = "DELETE_PRODUCTSERVICE_SUCCESS";
export const DELETE_PRODUCTSERVICE_FAIL = "DELETE_PRODUCTSERVICE_FAIL";
//        ######---------END_DELETE_PRODUCTSERVICE---------######

// ########-------------------END_PRODUCTSERVICE-------------------########

// ########-------------------COMPTE_RESULTAT_BILANS-------------------########

//        ######-----------GET_COMPTE_DE_RESULTAT_BILANS-----------######
export const GET_COMPTE_DE_RESULTAT_BILANS = "GET_COMPTE_DE_RESULTAT_BILANS";
export const GET_COMPTE_DE_RESULTAT_BILANS_SUCCESS = "GET_COMPTE_DE_RESULTAT_BILANS_SUCCESS";
export const GET_COMPTE_DE_RESULTAT_BILANS_FAIL = "GET_COMPTE_DE_RESULTAT_BILANS_FAIL";
//        ######---------END_GET_COMPTE_DE_RESULTAT_BILANS---------######

//        ######-----------ADD_COMPTE_DE_RESULTAT_BILAN-----------######
export const ADD_COMPTE_DE_RESULTAT_BILAN = "ADD_COMPTE_DE_RESULTAT_BILAN";
export const ADD_COMPTE_DE_RESULTAT_BILAN_SUCCESS = "ADD_COMPTE_DE_RESULTAT_BILAN_SUCCESS";
export const ADD_COMPTE_DE_RESULTAT_BILAN_FAIL = "ADD_COMPTE_DE_RESULTAT_BILAN_FAIL";
//        ######---------END_ADD_COMPTE_DE_RESULTAT_BILAN---------######

//        ######-----------EDIT_COMPTE_DE_RESULTAT_BILAN-----------######
export const EDIT_COMPTE_DE_RESULTAT_BILAN = "EDIT_COMPTE_DE_RESULTAT_BILAN";
export const EDIT_COMPTE_DE_RESULTAT_BILAN_SUCCESS = "EDIT_COMPTE_DE_RESULTAT_BILAN_SUCCESS";
export const EDIT_COMPTE_DE_RESULTAT_BILAN_FAIL = "EDIT_COMPTE_DE_RESULTAT_BILAN_FAIL";
//        ######---------END_EDIT_COMPTE_DE_RESULTAT_BILAN---------######

//        ######-----------UPDATE_COMPTE_DE_RESULTAT_BILAN-----------######
export const UPDATE_COMPTE_DE_RESULTAT_BILAN = "UPDATE_COMPTE_DE_RESULTAT_BILAN";
export const UPDATE_COMPTE_DE_RESULTAT_BILAN_SUCCESS = "UPDATE_COMPTE_DE_RESULTAT_BILAN_SUCCESS";
export const UPDATE_COMPTE_DE_RESULTAT_BILAN_FAIL = "UPDATE_COMPTE_DE_RESULTAT_BILAN_FAIL";
//        ######---------END_UPDATE_COMPTE_DE_RESULTAT_BILAN---------######

//        ######-----------DELETE_COMPTE_DE_RESULTAT_BILAN-----------######
export const DELETE_COMPTE_DE_RESULTAT_BILAN = "DELETE_COMPTE_DE_RESULTAT_BILAN";
export const DELETE_COMPTE_DE_RESULTAT_BILAN_SUCCESS = "DELETE_COMPTE_DE_RESULTAT_BILAN_SUCCESS";
export const DELETE_COMPTE_DE_RESULTAT_BILAN_FAIL = "DELETE_COMPTE_DE_RESULTAT_BILAN_FAIL";
//        ######---------END_DELETE_COMPTE_DE_RESULTAT_BILAN---------######

// ########-------------------END_COMPTE_DE_RESULTAT_BILANS-------------------########

// ########-------------------FINANCIAL_PROFILES-------------------########

//        ######-----------GET_FINANCIAL_PROFILES-----------######
export const GET_FINANCIAL_PROFILES = "GET_DIRECTORS";
export const GET_FINANCIAL_PROFILES_SUCCESS = "GET_FINANCIAL_PROFILES_SUCCESS";
export const GET_FINANCIAL_PROFILES_FAIL = "GET_FINANCIAL_PROFILES_FAIL";
//        ######---------END_GET_DIRECTORS---------######

//        ######-----------ADD_FINANCIAL_PROFILE-----------######
export const ADD_FINANCIAL_PROFILE = "ADD_FINANCIAL_PROFILE";
export const ADD_FINANCIAL_PROFILE_SUCCESS = "ADD_FINANCIAL_PROFILE_SUCCESS";
export const ADD_FINANCIAL_PROFILE_FAIL = "ADD_FINANCIAL_PROFILE_FAIL";
//        ######---------END_ADD_FINANCIAL_PROFILE---------######

//        ######-----------EDIT_FINANCIAL_PROFILE-----------######
export const EDIT_FINANCIAL_PROFILE = "EDIT_FINANCIAL_PROFILE";
export const EDIT_FINANCIAL_PROFILE_SUCCESS = "EDIT_FINANCIAL_PROFILE_SUCCESS";
export const EDIT_FINANCIAL_PROFILE_FAIL = "EDIT_FINANCIAL_PROFILE_FAIL";
//        ######---------END_EDIT_FINANCIAL_PROFILE---------######

//        ######-----------UPDATE_FINANCIAL_PROFILE-----------######
export const UPDATE_FINANCIAL_PROFILE = "UPDATE_FINANCIAL_PROFILE";
export const UPDATE_FINANCIAL_PROFILE_SUCCESS = "UPDATE_FINANCIAL_PROFILE_SUCCESS";
export const UPDATE_FINANCIAL_PROFILE_FAIL = "UPDATE_FINANCIAL_PROFILE_FAIL";
//        ######---------END_UPDATE_FINANCIAL_PROFILE---------######

//        ######-----------DELETE_FINANCIAL_PROFILE-----------######
export const DELETE_FINANCIAL_PROFILE = "DELETE_FINANCIAL_PROFILE";
export const DELETE_FINANCIAL_PROFILE_SUCCESS = "DELETE_FINANCIAL_PROFILE_SUCCESS";
export const DELETE_FINANCIAL_PROFILE_FAIL = "DELETE_FINANCIAL_PROFILE_FAIL";
//        ######---------END_DELETE_FINANCIAL_PROFILE---------######

// ########-------------------END_FINANCIAL_PROFILE-------------------########

// ########-------------------PASSIF_BILANS-------------------########

//        ######-----------GET_PASSIF_BILANS-----------######
export const GET_PASSIF_BILANS = "GET_PASSIF_BILANS";
export const GET_PASSIF_BILANS_SUCCESS = "GET_PASSIF_BILANS_SUCCESS";
export const GET_PASSIF_BILANS_FAIL = "GET_PASSIF_BILANS_FAIL";
//        ######---------END_GET_PASSIF_BILANS---------######

//        ######-----------ADD_PASSIF_BILAN-----------######
export const ADD_PASSIF_BILAN = "ADD_PASSIF_BILAN";
export const ADD_PASSIF_BILAN_SUCCESS = "ADD_PASSIF_BILAN_SUCCESS";
export const ADD_PASSIF_BILAN_FAIL = "ADD_PASSIF_BILAN_FAIL";
//        ######---------END_ADD_PASSIF_BILAN---------######

//        ######-----------EDIT_PASSIF_BILAN-----------######
export const EDIT_PASSIF_BILAN = "EDIT_PASSIF_BILAN";
export const EDIT_PASSIF_BILAN_SUCCESS = "EDIT_PASSIF_BILAN_SUCCESS";
export const EDIT_PASSIF_BILAN_FAIL = "EDIT_PASSIF_BILAN_FAIL";
//        ######---------END_EDIT_PASSIF_BILAN---------######

//        ######-----------UPDATE_PASSIF_BILAN-----------######
export const UPDATE_PASSIF_BILAN = "UPDATE_PASSIF_BILAN";
export const UPDATE_PASSIF_BILAN_SUCCESS = "UPDATE_PASSIF_BILAN_SUCCESS";
export const UPDATE_PASSIF_BILAN_FAIL = "UPDATE_PASSIF_BILAN_FAIL";
//        ######---------END_UPDATE_PASSIF_BILAN---------######

//        ######-----------DELETE_PASSIF_BILAN-----------######
export const DELETE_PASSIF_BILAN = "DELETE_PASSIF_BILAN";
export const DELETE_PASSIF_BILAN_SUCCESS = "DELETE_PASSIF_BILAN_SUCCESS";
export const DELETE_PASSIF_BILAN_FAIL = "DELETE_PASSIF_BILAN_FAIL";
//        ######---------END_DELETE_PASSIF_BILAN---------######

// ########-------------------END_PASSIF_BILANS-------------------########

// ########-------------------INFORMATION_COMPANY-------------------########

//        ######-----------ADD_INFORMATION_COMPANY-----------######
export const ADD_INFORMATION_COMPANY = "ADD_INFORMATION_COMPANY";
export const ADD_INFORMATION_COMPANY_SUCCESS = "ADD_INFORMATION_COMPANY_SUCCESS";
export const ADD_INFORMATION_COMPANY_FAIL = "ADD_INFORMATION_COMPANY_FAIL";
//        ######---------END_ADD_INFORMATION_COMPANY---------######

//        ######-----------EDIT_INFORMATION_COMPANY-----------######
export const EDIT_INFORMATION_COMPANY = "EDIT_INFORMATION_COMPANY";
export const EDIT_INFORMATION_COMPANY_SUCCESS = "EDIT_INFORMATION_COMPANY_SUCCESS";
export const EDIT_INFORMATION_COMPANY_FAIL = "EDIT_INFORMATION_COMPANY_FAIL";
//        ######---------END_EDIT_INFORMATION_COMPANY---------######

// ########-------------------END_INFORMATION_COMPANY-------------------########

import { Alert, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUserSchema } from "../schema";
import * as actions from "../redux/state/UsersAction";
import * as modalActions from "../modalActionTypes";
import * as actionsCountries from "../../countries/redux/state/CountriesAction";
import { getErrorMessage } from "common/utils/Helper";

export default function FormUpdateUser({ setOpen, userId, setUserId, setAction }) {
  const dispatch = useDispatch();

  const [errorSB, setErrorSB] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  // order select countries
  const orderCountries = {
    sort: "name",
    order: "asc",
  };
  useEffect(() => {
    dispatch(actions.editUser(userId));
    dispatch(actionsCountries.getCountries(orderCountries));
  }, []);

  const countries = useSelector((state) => state?.countries?.countries);
  const user = useSelector((state) => state?.users?.user);

  const onSubmit = (values) => {
    console.log("values ::: ",values);

    const request = {
      payload: {...values, updatePassword:values.updatePassword===true?1:0},
      successCallBack: (response) => {
        dispatch(actions.getUsers());
        closeErrorSB()
        setAction(modalActions.ADD);
        setUserId("");
        setOpen(false);
        console.log("response", response);
      },
      failCallBack: (error) => {
        setErrorMessage(getErrorMessage(error))
        openErrorSB();
      },
    };
    console.log("onSubmit",request);
    dispatch(actions.updateUser(request));
  };

  const { handleSubmit, errors, values, handleChange } = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: user?.id,
      first_name: user?.first_name,
      last_name: user?.last_name,
      phone: user?.phone,
      email: user?.email,
      address: user?.address,
      city: user?.city,
      role: user?.role,
      country_id: user?.country_id,
      active: user?.active,
      password: "",
      confirme_password: "",
      updatePassword:false,
      updateLimits:false,
      limit_downloads:user && user.limit_downloads!==undefined ? user?.limit_downloads : 0,
      limit_searches:user && user.limit_searches!==undefined ? user?.limit_searches : 0,
    },
    validationSchema: updateUserSchema,
    onSubmit,
  });
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <MDBox m={1}>
      {errorSB && (
        <Alert severity="error">
          {errorMessage}
        </Alert>
      )}
      <form onSubmit={handleSubmit}>
        <MDBox py={2}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="first_name"
                  label="Prenom"
                  error={errors.first_name ? true : false}
                  onChange={handleChange}
                  value={values.first_name ?? ""}
                  helperText={errors?.first_name ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="last_name"
                  label="Nom"
                  error={errors.last_name ? true : false}
                  onChange={handleChange}
                  value={values.last_name ?? ""}
                  helperText={errors?.last_name ?? ""}
                />
              </FormControl>
            </Grid>
            {/* <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="username"
                  label="nom d'utilisateur"
                  error={errors.username ? true : false}
                  onChange={handleChange}
                  value={values.username ?? ""}
                  helperText={errors?.username ?? ""}
                />
              </FormControl>
            </Grid> */}
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="phone"
                  label="Telephone"
                  error={errors.phone ? true : false}
                  onChange={handleChange}
                  value={values.phone ?? ""}
                  helperText={errors?.phone ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  id="email"
                  name="email"
                  label="Email"
                  error={errors.email ? true : false}
                  onChange={handleChange}
                  value={values.email ?? ""}
                  helperText={errors?.email ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <MDInput
                  name="address"
                  label="Adresse"
                  error={errors.address ? true : false}
                  onChange={handleChange}
                  value={values.address ?? ""}
                  helperText={errors?.address ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="city"
                  label="Ville"
                  error={errors.city ? true : false}
                  onChange={handleChange}
                  value={values.city ?? ""}
                  helperText={errors?.city ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <InputLabel id="state_label">Status</InputLabel>
                <Select
                  name="active"
                  style={{ height: "40px" }}
                  labelId="state_label"
                  label="Status"
                  error={errors.active ? true : false}
                  onChange={handleChange}
                  value={values.active ?? ""}
                >
                  <MenuItem value={1}>Active</MenuItem>
                  <MenuItem value={0}>Unactive</MenuItem>
                </Select>
                {errors.active ? <FormHelperText error>{errors?.active}</FormHelperText> : null}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <InputLabel id="role_label">Role</InputLabel>
                <Select
                  name="role"
                  style={{ height: "40px" }}
                  labelId="role_label"
                  label="role"
                  error={errors.role ? true : false}
                  onChange={handleChange}
                  value={values.role ?? ""}
                >
                  <MenuItem key="admin" value="admin">
                    Admin
                  </MenuItem>
                  <MenuItem key="complet" value="complet">
                    Complet
                  </MenuItem>
                  <MenuItem key="consultation" value="consultation">
                    Consultation
                  </MenuItem>
                </Select>
                {errors?.role ? <FormHelperText error>{errors?.role}</FormHelperText> : null}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <InputLabel id="country_id">Pays</InputLabel>
                <Select
                  name="country_id"
                  style={{ height: "40px" }}
                  labelId="state_label"
                  label="pays"
                  error={errors?.country_id ? true : false}
                  onChange={handleChange}
                  value={values.country_id ?? ""}
                >
                  {countries && countries?.data?.map((country) => {
                    return (
                      <MenuItem key={country?.id} value={country?.id}>
                        {country?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                {errors.country_id ? (
                  <FormHelperText error>{errors?.country_id}</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>

            

            {/* Adding the checkbox input */}
            <Grid item xs={12} sm={12} md={12}>
              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="updateLimits"
                      checked={values.updateLimits}
                      onChange={handleChange}
                    />
                  }
                  label="Si vous voulez mettre à jour les limites, cochez ceci"
                />
              </FormControl>
            </Grid>

            {
              values.updateLimits && 
            <>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  type="number"
                  min="0"
                  name="limit_searches"
                  label="Limite de recherche"
                  error={errors.limit_searches ? true : false}
                  onChange={handleChange}
                  value={values.limit_searches}
                  helperText={errors?.limit_searches ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  type="number"
                  min="0"
                  name="limit_downloads"
                  label="Limite de téléchargement"
                  error={errors.limit_downloads ? true : false}
                  onChange={handleChange}
                  value={values.limit_downloads}
                  helperText={errors?.limit_downloads ?? ""}
                />
              </FormControl>
            </Grid>
            </>
            }
            {/* Adding the checkbox input */}
            <Grid item xs={12} sm={12} md={12}>
              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="updatePassword"
                      checked={values.updatePassword}
                      onChange={handleChange}
                    />
                  }
                  label="Si vous voulez mettre à jour le mot de passe, cochez ceci"
                />
              </FormControl>
            </Grid>
            {
              values.updatePassword && 
              <>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <MDInput
                    name="password"
                    label="Mot de passe"
                    error={errors.Password ? true : false}
                    onChange={handleChange}
                    value={values.Password}
                    helperText={errors?.Password ?? ""}
                    type="password"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <MDInput
                    name="confirme_password"
                    label="Confirme mot de passe"
                    error={errors.confirme_password ? true : false}
                    onChange={handleChange}
                    value={values.confirme_password}
                    helperText={errors?.confirme_password ?? ""}
                    type="password"
                  />
                </FormControl>
              </Grid>
              </>
            }
          </Grid>
        </MDBox>

        <MDBox sx={{ display: "flex" }}>
          <MDBox mr={1} sx={{ flexGrow: "1" }}>
            <MDButton onClick={handleClose} type="submit" color="primary" fullWidth>
              Annuler
            </MDButton>
          </MDBox>
          <MDBox sx={{ flexGrow: "1" }}>
            <MDButton type="submit" color="info" fullWidth>
              Modifier
            </MDButton>
          </MDBox>
        </MDBox>
      </form>
    </MDBox>
  );
}

import { Card, Grid, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
// Data
import usersTableData from "layouts/users/data/usersTableData";
import DataTable from "examples/Tables/DataTable";
import { Box } from "@mui/system";
import MDButton from "components/MDButton";
import UserModal from "./components/UserModal";
import FormAddUser from "./components/FormAddUser";
import FormDeleteUser from "./components/FormDeleteUser";
import * as modalActions from "./modalActionTypes";
import FormUpdateUser from "./components/FormUpdateUser";

function Users() {
  const [userId, setUserId] = useState("");
  const [action, setAction] = useState(modalActions.ADD);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setAction(modalActions.ADD);
    setUserId("");
    setOpen(true);
  };
  const { columns, rows } = usersTableData(setOpen, setUserId, setAction);
  console.log("usersTableData !!! ",columns,rows );
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box mr={1}>
          <MDButton onClick={handleOpen} variant="contained" size="small" color="info">
            <Icon>add</Icon> utilisateur
          </MDButton>
        </Box>
      </Box>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Utilisateurs
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {rows && 
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                }
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <UserModal
        title={
          action == modalActions.UPDATE
            ? "Modifier utilisateur"
            : action == modalActions.DELETE
            ? "Supprimer utilisateur"
            : "Ajouter nouveau utilisateur"
        }
        open={open}
        setOpen={setOpen}
      >
        {/* ADD MODAL */}
        {action == modalActions.ADD && (
          <FormAddUser open={open} setOpen={setOpen} setAction={setAction} />
        )}
        {/* DELETE MODAL */}
        {action == modalActions.DELETE && (
          <FormDeleteUser
            setOpen={setOpen}
            userId={userId}
            setUserId={setUserId}
            setAction={setAction}
          />
        )}
        {/* UPDATE MODAL */}
        {action == modalActions.UPDATE && (
          <FormUpdateUser
            setOpen={setOpen}
            userId={userId}
            setUserId={setUserId}
            setAction={setAction}
          />
        )}
      </UserModal>
    </DashboardLayout>
  );
}
export default Users;

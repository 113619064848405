import {
  Alert,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/state/InformationCompanyAction";
import * as actionsCompanies from "../../../../companies/redux/state/CompaniesAction";
import * as Devises from "../../../../../common/staticData/Devises";
import { addInformationCompanySchema } from "../schema";
import { getErrorMessage } from "common/utils/Helper";
import { Box } from "@mui/system";

export default function FormUpdateInformation({ setOpen }) {
  const dispatch = useDispatch();
  const [errorSB, setErrorSB] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const company = useSelector((state) => state?.companies?.company);
  const info_company = company?.info_companies;
  const onSubmit = (values) => {
    // alert(JSON.stringify(values));
    const request = {
      payload: values,
      successCallBack: (response) => {
        closeErrorSB();
        dispatch(actionsCompanies.editCompany(company?.id));
        setOpen(false);
        console.log("response", response);
      },
      failCallBack: (error) => {
        console.log("error", error);
        setErrorMessage(getErrorMessage(error));
        openErrorSB();
      },
    };
    dispatch(actions.addInformationCompany(request));
  };

  const handleClose = () => {
    setOpen(false);
  };
  // static devises
  const devises = Devises.devises;
  Object.keys(devises);
  const { handleSubmit, errors, values, handleChange } = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      year_total_produits_exploitation: info_company?.year_total_produits_exploitation,
      total_produits_exploitation: info_company?.total_produits_exploitation,
      pm_product: info_company?.pm_product,
      devise_product: info_company?.devise_product,
      pourcentage_product: info_company?.pourcentage_product, //**

      year_net_income: info_company?.year_net_income,
      net_income: info_company?.net_income,
      pm_exercice: info_company?.pm_exercice,
      devise_exercice: info_company?.devise_exercice,
      pourcentage_exercice: info_company?.pourcentage_exercice, //**
      ppe: info_company?.ppe,
      actionnaires: info_company?.actionnaires,
      filiales: info_company?.filiales,
      companies_du_groupe: info_company?.companies_du_groupe,
      company_id: company?.id,
    },
    validationSchema: addInformationCompanySchema,
    onSubmit,
  });
  return (
    <Box>
      {errorSB && <Alert severity="error">{errorMessage}</Alert>}
      <form onSubmit={handleSubmit}>
        <MDBox py={1}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {/* line 1 */}
            <Grid item xs={12}>
              <Typography
                sx={{
                  width: "100%",
                  fontWeight: "bold",
                }}
                component="div"
                variant="subtitle2"
              >
                Totale des produits d'exploitation le plus récent
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <FormControl fullWidth>
                <MDInput
                  name="year_total_produits_exploitation"
                  label="Année"
                  error={errors.year_total_produits_exploitation ? true : false}
                  onChange={handleChange}
                  value={values.year_total_produits_exploitation ?? ""}
                  helperText={errors?.year_total_produits_exploitation ?? ""}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{ inputProps: { min: 1990 } }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <FormControl fullWidth>
                <MDInput
                  name="total_produits_exploitation"
                  label="MT"
                  error={errors.total_produits_exploitation ? true : false}
                  onChange={handleChange}
                  value={values.total_produits_exploitation ?? ""}
                  helperText={errors?.total_produits_exploitation ?? ""}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{ inputProps: { min: 0 } }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <FormControl fullWidth>
                <InputLabel id="devise_product">Devises</InputLabel>
                <Select
                  name="devise_product"
                  style={{ height: "40px" }}
                  labelId="Devises"
                  label="Devises"
                  error={errors.devise_product ? true : false}
                  onChange={handleChange}
                  value={values.devise_product}
                >
                  {Object.entries(devises).map(([key, val]) => {
                      return (
                          <MenuItem key={key} value={key}>
                            {key}
                            {/* {`${key} ( ${val.symbol_native} )`} */}
                          </MenuItem>
                      );
                  })}

                </Select>
                {errors.devise_product ? (
                  <FormHelperText error>{errors?.devise_product}</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <FormControl fullWidth>
                <InputLabel id="pm_product">+/-</InputLabel>
                <Select
                  name="pm_product"
                  style={{ height: "40px" }}
                  labelId="+/-"
                  label="+/-"
                  error={errors.pm_product ? true : false}
                  onChange={handleChange}
                  value={values.pm_product}
                >
                  <MenuItem key="0" value="0">
                    Moins
                  </MenuItem>
                  <MenuItem key="0" value="1">
                    Plus
                  </MenuItem>
                </Select>
                {errors.pm_product ? (
                  <FormHelperText error>{errors?.pm_product}</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <FormControl fullWidth>
                <MDInput
                  name="pourcentage_product"
                  label="%"
                  error={errors.pourcentage_product ? true : false}
                  onChange={handleChange}
                  value={values?.pourcentage_product?.toString()?.replace(",", ".") ?? ""}
                  helperText={errors?.pourcentage_product ?? ""}
                  InputProps={{ inputProps: { min: 0, max: 100 } }}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>
            {/* line 2 */}
            <Grid item xs={12}>
              <Typography
                sx={{
                  width: "100%",
                  fontWeight: "bold",
                }}
                component="div"
                variant="subtitle2"
              >
                P/L [=Net Income] de l'exercice le plus récent
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <FormControl fullWidth>
                <MDInput
                  name="year_net_income"
                  label="Année"
                  error={errors.year_net_income ? true : false}
                  onChange={handleChange}
                  value={values.year_net_income ?? ""}
                  helperText={errors?.year_net_income ?? ""}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{ inputProps: { min: 1990 } }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <FormControl fullWidth>
                <MDInput
                  name="net_income"
                  label="MT"
                  error={errors.net_income ? true : false}
                  onChange={handleChange}
                  value={values.net_income ?? ""}
                  helperText={errors?.net_income ?? ""}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{ inputProps: { min: 0 } }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <FormControl fullWidth>
                <InputLabel id="pm_product">Devises</InputLabel>
                <Select
                  name="devise_exercice"
                  style={{ height: "40px" }}
                  labelId="Devises"
                  label="Devises"
                  error={errors.devise_exercice ? true : false}
                  onChange={handleChange}
                  value={values.devise_exercice}
                >
                  {Object.keys(devises).map((devise) => {
                    return (
                      <MenuItem key={devise} value={devise}>
                        {devise}
                      </MenuItem>
                    );
                  })}
                </Select>
                {errors.devise_exercice ? (
                  <FormHelperText error>{errors?.devise_exercice}</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <FormControl fullWidth>
                <InputLabel id="pm_exercice">+/-</InputLabel>
                <Select
                  name="pm_exercice"
                  style={{ height: "40px" }}
                  labelId="+/-"
                  label="+/-"
                  error={errors.pm_exercice ? true : false}
                  onChange={handleChange}
                  value={values.pm_exercice}
                >
                  <MenuItem key="0" value="0">
                    Moins
                  </MenuItem>
                  <MenuItem key="0" value="1">
                    Plus
                  </MenuItem>
                </Select>
                {errors.pm_exercice ? (
                  <FormHelperText error>{errors?.pm_exercice}</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <FormControl fullWidth>
                <MDInput
                  name="pourcentage_exercice"
                  label="%"
                  error={errors.pourcentage_exercice ? true : false}
                  onChange={handleChange}
                  value={values?.pourcentage_exercice?.toString()?.replace(",", ".") ?? ""}
                  helperText={errors?.pourcentage_exercice ?? ""}
                  InputProps={{ inputProps: { min: 0, max: 100 } }}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  width: "100%",
                  fontWeight: "bold",
                }}
                component="div"
                variant="subtitle2"
              >
                Actionnariat
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <FormControl fullWidth>
                <MDInput
                  name="actionnaires"
                  label="Actionnaires"
                  error={errors.actionnaires ? true : false}
                  onChange={handleChange}
                  value={values?.actionnaires ?? ""}
                  helperText={errors?.actionnaires ?? ""}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{ inputProps: { min: 0 } }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <FormControl fullWidth>
                <MDInput
                  name="filiales"
                  label="Filiales"
                  error={errors.filiales ? true : false}
                  onChange={handleChange}
                  value={values?.filiales ?? ""}
                  helperText={errors?.filiales ?? ""}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{ inputProps: { min: 0 } }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <FormControl fullWidth>
                <MDInput
                  name="companies_du_groupe"
                  label="Companies du groupe"
                  error={errors.companies_du_groupe ? true : false}
                  onChange={handleChange}
                  value={values?.companies_du_groupe ?? ""}
                  helperText={errors?.companies_du_groupe ?? ""}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{ inputProps: { min: 0 } }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  width: "100%",
                  fontWeight: "bold",
                }}
                component="div"
                variant="subtitle2"
              >
                PPE et Sanctions
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextareaAutosize
                name="ppe"
                placeholder="description PPE"
                style={{ width: "100%" }}
                minRows={5}
                error={errors.ppe ? true : false}
                onChange={handleChange}
                value={values?.ppe ?? ""}
                helperText={errors?.ppe ?? ""}
                variant="outlined"
              />
              {errors.ppe ? <FormHelperText error>{errors?.ppe}</FormHelperText> : null}
            </Grid>
          </Grid>
        </MDBox>

        <MDBox sx={{ display: "flex" }}>
          <MDBox mr={1} sx={{ flexGrow: "1" }}>
            <MDButton onClick={handleClose} color="primary" fullWidth>
              Annuler
            </MDButton>
          </MDBox>
          <MDBox sx={{ flexGrow: "1" }}>
            <MDButton type="submit" color="info" fullWidth>
              Modifier
            </MDButton>
          </MDBox>
        </MDBox>
      </form>
    </Box>
  );
}

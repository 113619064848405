import { Box, Grid, Icon, Typography, useMediaQuery } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import React from "react";
import { Link, useParams } from "react-router-dom";
import financialProfileTableData from "./data/financialProfileTableData";


function FinancialProfile({ companyId }) {
  const { columns, rows } = financialProfileTableData();
  const media600 = useMediaQuery("(min-width:600px)");
  const { companySlug } = useParams();

  return (
    <MDBox p={3}>
      <Grid container>
        <Grid item xs={9} sm={8} md={8}>
          <Typography component="div" pb={2} variant="h4" align="left">
            Compte sociaux, Depot au registre local
          </Typography>
        </Grid>
        <Grid item xs={3} sm={4} md={4}>
          <Box sx={{ textAlign: "right" }}>
            <Link
              to="/profiles-financier"
              state={{
                id: companyId,
                slug: companySlug,
              }}
            >
              <MDButton p={0} type="submit" color="primary">
                <Icon fontSize="small">settings-icon</Icon>
                {media600 ? (
                  <Typography variant="p" pl={1}>
                    Paramétrage
                  </Typography>
                ) : (
                  ""
                )}
              </MDButton>
            </Link>
          </Box>
        </Grid>
      </Grid>
      <DataTable
        table={{ columns, rows }}
        isSorted={false}
        entriesPerPage={{ defaultValue: 15 }}
        showTotalEntries={true}
        noEndBorder
      />
    </MDBox>
  );
}

export default FinancialProfile;

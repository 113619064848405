import { takeLatest } from "redux-saga/effects";

import * as ActionsTypes from "../../../../common/state/StatesConstants";
import {
  getUsersSaga,
  addUserSaga,
  deleteUserSaga,
  editUserSaga,
  updateUserSaga,
} from "./UsersSaga";

export default function* UsersWatcher() {
  yield takeLatest(ActionsTypes.GET_USERS, getUsersSaga);
  yield takeLatest(ActionsTypes.ADD_USER, addUserSaga);
  yield takeLatest(ActionsTypes.EDIT_USER, editUserSaga);
  yield takeLatest(ActionsTypes.DELETE_USER, deleteUserSaga);
  yield takeLatest(ActionsTypes.UPDATE_USER, updateUserSaga);
}

import React, { useEffect } from "react";
import MDTypography from "components/MDTypography";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/state/CountriesAction";
import * as modalActions from "../modalActionTypes";
import MDButton from "components/MDButton";
import { Box } from "@mui/system";
import { Icon, IconButton, Typography } from "@mui/material";

export default function Data(setOpen, setCountryId, setAction) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getCountries());
  }, []);

  const handleOpenEdit = (id) => {
    setOpen(true);
    setCountryId(id);
    setAction(modalActions.UPDATE);
  };

  const handleOpenDelete = (id) => {
    setOpen(true);
    setCountryId(id);
    setAction(modalActions.DELETE);
  };

  const countries = useSelector((state) => state?.countries?.countries);
  const countriesRows = countries && countries?.data?.map((country, i) => ({
    index: i + 1,
    // flag: <img src={`https://countryflagsapi.com/png/${country.name}`} height="20" />,
    nom: country?.name,
    created_at: new Date(country?.created_at)?.toLocaleDateString(),
    updated_at: new Date(country?.updated_at)?.toLocaleDateString(),
    action: (
      // <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      //   Edit
      // </MDTypography>

      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box>
          <IconButton color="secondary" href={`/pays/${country.id}/${country.slug}`} variant="a">
            <Icon color="success" fontSize="small">
              assignment
            </Icon>
          </IconButton>
          <IconButton color="info" onClick={() => handleOpenEdit(country.id)} component="label">
            <Icon color="info" fontSize="small">
              mode
            </Icon>
          </IconButton>
        </Box>
        <Box>
          <IconButton
            color="primary"
            onClick={() => handleOpenDelete(country.id)}
            component="label"
          >
            <Icon color="primary" fontSize="small">
              delete
            </Icon>
          </IconButton>
        </Box>
      </Box>
    ),
  }));
  return {
    columns: [
      { Header: "#", accessor: "index", width: "10px", align: "left" },
      // { Header: "drapeau", accessor: "flag", width: "20px", align: "left" },
      { Header: "nom", accessor: "nom", align: "left" },
      { Header: "Date d'ajout", accessor: "created_at", align: "left" },
      { Header: "Date de modification", accessor: "updated_at", align: "left" },
      { Header: "action", accessor: "action", align: "left" },
    ],

    rows: countriesRows,
  };
}

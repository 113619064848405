import React, { useEffect } from "react";
import MDBox from "components/MDBox";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/state/ActifBilansAction";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import { Box, Icon, IconButton } from "@mui/material";
import MDButton from "components/MDButton";
import * as modalActions from "../modalActionTypes";

function formatNumberWithSpaces(input) {
  if (isNaN(input)) {
     return input;
  }
  const roundedNum = Math.round(input);
  const numStr = roundedNum.toString();
  const formattedStr = numStr.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return formattedStr;
 }

export default function Data(setOpen, setActifBilanId, setAction, companyId) {
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(companyId);
    let queryParam = {};
    if (companyId.toString().length > 0 && companyId !== undefined) {
      queryParam = { companyId: companyId, sort: "date", order: "desc" };
    }
    dispatch(actions.getActifBilans(queryParam));
    console.log(queryParam)
  }, [companyId]);

  const handleOpenDelete = (id) => {
    setOpen(true);
    setActifBilanId(id);
    setAction(modalActions.DELETE);
  };
  const handleOpenEdit = (id) => {
    // console.log("edit id", id);
    setOpen(true);
    setActifBilanId(id);
    setAction(modalActions.UPDATE);
  };

  const actifBilans = useSelector(
    (state) => state?.actifBilans?.actifBilans
  );

  const usersRows = actifBilans && actifBilans?.data && actifBilans?.data?.map((actifBilan, i) => ({
    actif_immobilise: formatNumberWithSpaces(actifBilan?.actif_immobilise),
    immobilisation_incorporelle: formatNumberWithSpaces(actifBilan?.immobilisation_incorporelle),
    immobilisation_corporelle: formatNumberWithSpaces(actifBilan?.immobilisation_corporelle),
    autre_actif_immobilise: formatNumberWithSpaces(actifBilan?.autre_actif_immobilise),
    actif_circulant: formatNumberWithSpaces(actifBilan?.actif_circulant),
    actif_circulant_stock: formatNumberWithSpaces(actifBilan?.actif_circulant_stock),
    actif_circulant_creance: formatNumberWithSpaces(actifBilan?.actif_circulant_creance),
    autre_actif_circulant: formatNumberWithSpaces(actifBilan?.autre_actif_circulant),
    valeur_disponible: formatNumberWithSpaces(actifBilan?.valeur_disponible),
    total_actif: formatNumberWithSpaces(actifBilan?.total_actif),
    date: actifBilan?.date,
    company: actifBilan?.company?.raison_social,

    action: (
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box>
          <IconButton
            color="info"
            onClick={() => handleOpenEdit(actifBilan.id)}
            component="label"
          >
            <Icon color="info" fontSize="small">
              mode
            </Icon>
          </IconButton>
        </Box>
        <Box>
          <IconButton
            color="primary"
            onClick={() => handleOpenDelete(actifBilan.id)}
            component="label"
          >
            <Icon color="primary" fontSize="small">
              delete
            </Icon>
          </IconButton>
        </Box>
      </Box>
    ),
}));

  return {
    columns: [
      // { Header: "Pays", accessor: "country", align: "left" },
      { Header: "date", accessor: "date", align: "left" },
      { Header: "Entreprise", accessor: "company", align: "left" },
      { Header: "Actif immobilise", accessor: "actif_immobilise", align: "right" },
      { Header: "Immobilisations incorporelles", accessor: "immobilisation_incorporelle", align: "right" },
      { Header: "Immobilisation corporelles", accessor: "immobilisation_corporelle", align: "right" },
      { Header: "Autres actifs immobilises", accessor: "autre_actif_immobilise", align: "right" },
      { Header: "Actif circulant", accessor: "actif_circulant", align: "right" },
      { Header: "Actif circulant: stock", accessor: "actif_circulant_stock", align: "right" },
      { Header: "Actif circulant: creance", accessor: "actif_circulant_creance", align: "right" },
      { Header: "Autres actifs circulants", accessor: "autre_actif_circulant", align: "right" },
      { Header: "Valeurs disponibles", accessor: "valeur_disponible", align: "right" },
      { Header: "Totals de l'actif", accessor: "total_actif", align: "right" },
      { Header: "Action", accessor: "action", align: "center" },
    ],

    rows: usersRows,
  };
}

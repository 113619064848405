import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

export default function Data() {
  const directors = useSelector((state) => state?.companies?.company?.directors) ?? [];

  const FullName = ({ first_name, last_name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      {/* <MDAvatar src={image} utilisateur={utilisateur} size="sm" /> */}
      <MDBox lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {first_name} {last_name}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
  const directorsRows = directors?.map((director, i) => ({
    index: i + 1,
    fullname: <FullName first_name={director.first_name} last_name={director.last_name} />,
    company_id: director?.company?.raison_social,
    associate: director.associate,
    role: (
      <MDBox ml={-1}>
        <MDBadge badgeContent={director.role} color="secondary" variant="gradient" size="sm" />
      </MDBox>
    ),
  }));
  return {
    columns: [
      { Header: "Nom", accessor: "fullname", align: "left" },
      { Header: "Entreprise", accessor: "company_id", align: "left" },
      { Header: "Associer", accessor: "associate", align: "left" },
      { Header: "Role", accessor: "role", align: "center" },
      // { Header: "active", accessor: "active", align: "left" },
    ],

    rows: directorsRows,
  };
}

import React, { useEffect } from "react";
import MDBox from "components/MDBox";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/state/CompteResultatBilansAction";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import { Box, Icon, IconButton } from "@mui/material";
import MDButton from "components/MDButton";
import * as modalActions from "../modalActionTypes";

function formatNumberWithSpaces(input) {
  if (isNaN(input)) {
     return input;
  }
  const roundedNum = Math.round(input);
  const numStr = roundedNum.toString();
  const formattedStr = numStr.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return formattedStr;
 }

export default function Data(setOpen, setCompteResultatBilanId, setAction, companyId) {
  const dispatch = useDispatch();
  console.log(companyId)

  useEffect(() => {
    console.log(companyId);
    let queryParam = {};
    if (companyId.toString().length > 0  && companyId !== undefined) {
      queryParam = { companyId: companyId, sort: "date", order: "desc" }
    }
    dispatch(actions.getCompteResultatBilans(queryParam));
  }, [companyId]);

  const handleOpenDelete = (id) => {
    setOpen(true);
    setCompteResultatBilanId(id);
    setAction(modalActions.DELETE);
  };
  const handleOpenEdit = (id) => {
    // console.log("edit id", id);
    setOpen(true);
    setCompteResultatBilanId(id);
    setAction(modalActions.UPDATE);
  };

  const compteResultatBilans = useSelector(
    (state) => state?.compteResultatBilans?.compteResultatBilans
  );

  // Inside your Data function, after mapping over compteResultatBilans?.data
const usersRows = compteResultatBilans?.data?.map((compteResultatBilan, i) => {
  const formattedRow = {
     total_production_exploitation: formatNumberWithSpaces(compteResultatBilan.total_production_exploitation),
     chiffre_affaire_net: formatNumberWithSpaces(compteResultatBilan.chiffre_affaire_net),
     cout_vente: formatNumberWithSpaces(compteResultatBilan.cout_vente),
     marge_brut: formatNumberWithSpaces(compteResultatBilan.marge_brut),
     autre_charge_exploitation: formatNumberWithSpaces(compteResultatBilan.autre_charge_exploitation),
     resultat_exploitation: formatNumberWithSpaces(compteResultatBilan.resultat_exploitation),
     resultat_financier: formatNumberWithSpaces(compteResultatBilan.resultat_financier),
     produit_financier: formatNumberWithSpaces(compteResultatBilan.produit_financier),
     charge_financier: formatNumberWithSpaces(compteResultatBilan.charge_financier),
     resultat_courant_avant_impot: formatNumberWithSpaces(compteResultatBilan.resultat_courant_avant_impot),
     impot_taxe_assimitee: formatNumberWithSpaces(compteResultatBilan.impot_taxe_assimitee),
     resultat_courant_apres_imposition: formatNumberWithSpaces(compteResultatBilan.resultat_courant_apres_imposition),
     resultat_exceptionnel: formatNumberWithSpaces(compteResultatBilan.resultat_exceptionnel),
     change_exceptionnelle: formatNumberWithSpaces(compteResultatBilan.change_exceptionnelle),
     benefice_pert: formatNumberWithSpaces(compteResultatBilan.benefice_pert),
     date: compteResultatBilan.date,
     company: compteResultatBilan.company?.raison_social,
     action: (
       <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
         <Box>
           <IconButton
             color="info"
             onClick={() => handleOpenEdit(compteResultatBilan.id)}
             component="label"
           >
             <Icon color="info" fontSize="small">
               mode
             </Icon>
           </IconButton>
         </Box>
         <Box>
           <IconButton
             color="primary"
             onClick={() => handleOpenDelete(compteResultatBilan.id)}
             component="label"
           >
             <Icon color="primary" fontSize="small">
               delete
             </Icon>
           </IconButton>
         </Box>
       </Box>
     ),
  };
  return formattedRow;
 });

  return {
    columns: [
      // { Header: "Pays", accessor: "country", align: "left" },
      { Header: "date", accessor: "date", align: "left" },
      { Header: "Entreprise", accessor: "company", align: "left" },
      {
        Header: "total des productions exploitations",
        accessor: "total_production_exploitation",
        align: "right",
      },
      { Header: "chiffre d'affaires net", accessor: "chiffre_affaire_net", align: "right" },
      { Header: "cout des ventes", accessor: "cout_vente", align: "right" },
      { Header: "marge brut", accessor: "marge_brut", align: "right" },
      {
        Header: "autres charges d'exploitation",
        accessor: "autre_charge_exploitation",
        align: "right",
      },
      { Header: "resultat d'exploitation", accessor: "resultat_exploitation", align: "right" },
      { Header: "resultat financier", accessor: "resultat_financier", align: "right" },
      { Header: "produit financier", accessor: "produit_financier", align: "right" },
      { Header: "charge financier", accessor: "charge_financier", align: "right" },
      {
        Header: "resultat courant avant impots",
        accessor: "resultat_courant_avant_impot",
        align: "right",
      },
      { Header: "impot et taxes assimitees", accessor: "impot_taxe_assimitee", align: "right" },
      {
        Header: "resultat courant apres imposition",
        accessor: "resultat_courant_apres_imposition",
        align: "right",
      },
      { Header: "resultat exceptionnels", accessor: "resultat_exceptionnel", align: "right" },
      { Header: "changes exceptionnelles", accessor: "change_exceptionnelle", align: "right" },
      { Header: "benefices ou perts", accessor: "benefice_pert", align: "right" },
      { Header: "Action", accessor: "action", align: "center" },
    ],

    rows: usersRows,
  };
}

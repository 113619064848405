import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";
import CompteResultatBilans from "./pages/compteResultatBilans";
import CompteDeResultatBilans from "./pages/compteDeResultatBilans";
import MemoBilans from "./pages/memoBilans";
import ActifBilans from "./pages/compteDeResultatBilans";
import PassifBilans from "./pages/passifBilans";

const tabs = [
  {
    name: "Compte resultat bilans",
    key: "compte-resultat-bilans",
    icon: <Icon fontSize="medium">table_chart</Icon>,
    route: "/bilans/compte-resultat-bilans",
    component: <CompteResultatBilans />,
  },
  {
    name: "Compte de resultat bilans",
    key: "compte-de-resultat-bilans",
    icon: <Icon fontSize="medium">table_chart</Icon>,
    route: "/bilans/compte-de-resultat-bilans",
    component: <CompteDeResultatBilans />,
  },
  {
    name: "Memo bilans",
    key: "memo-bilans",
    icon: <Icon fontSize="medium">table_chart</Icon>,
    route: "/bilans/memo-bilans",
    component: <MemoBilans />,
  },
  {
    name: "Actif bilans",
    key: "actif-bilans",
    icon: <Icon fontSize="medium">table_chart</Icon>,
    route: "/bilans/actif-bilans",
    component: <ActifBilans />,
  },
  {
    name: "Passif bilans",
    key: "passif-bilans",
    icon: <Icon fontSize="medium">table_chart</Icon>,
    route: "/bilans/passif-bilans",
    component: <PassifBilans />,
  },
];

export default tabs;

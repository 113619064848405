import { SignalCellularNullTwoTone } from "@mui/icons-material";
import config from "./Config";

export const isAuthenticated = () => {
  const jwt = localStorage.getItem(config.jwt_token_name);
  if (jwt) {
    return JSON.parse(jwt);
  }
  return false;
};

export const getErrorMessage = (error, status = undefined) => {
  const err = error.response.data;
  let errMessage = "Une opération incomplète a été détectée";
  console.log("err", err);
  if (err?.message) {
    const msgObj = err?.message;
    console.log(msgObj);
    if (msgObj !== null) {
      if (typeof msgObj === "object") {
        errMessage = msgObj[Object.keys(msgObj)[0]];
        console.log(errMessage);
      } else {
        errMessage = msgObj;
      }
      if (typeof errMessage !== "undefined") {
        if (Array.isArray(errMessage)) {
          return errMessage[0];
        } else {
          return errMessage;
        }
      }
    }
  }
  console.log(errMessage);
  if ((errMessage?.toString().length === 0 || typeof errMessage == "undefined") && status) {
    switch (status.toString()) {
      case "301":
        errMessage = "Redirection, respectivement permanente et temporaire";
        break;
      case "302":
        errMessage = "Redirection, respectivement permanente et temporaire";
        break;
      case "401":
        errMessage = "Utilisateur non authentifié";
        break;
      case "403":
        errMessage = "Accès refusé";
        break;
      case "404":
        errMessage = "Ressource non trouvée";
        break;
      case "405":
        errMessage = "Méthode de requête non autorisée.";
        break;
      case "405":
        errMessage = "Méthode de requête non autorisée.";
        break;
      case "409":
        errMessage =
          "La requête ne peut être traitée à la suite d'un conflit avec l'état actuel du serveur.";
        break;
      case "429":
        errMessage = "Le client a émis trop de requêtes dans un délai donné.";
        break;
      case "500":
        errMessage = "Erreur interne du serveur.";
        break;

      default:
      // code block
    }
  }
  return errMessage;
};

import React, { useEffect } from "react";
import MDBox from "components/MDBox";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/state/UsersAction";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import { Box, Icon, IconButton } from "@mui/material";
import MDButton from "components/MDButton";
import * as modalActions from "../modalActionTypes";

export default function Data(setOpen, setUserId, setAction) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getUsers());
  }, []);

  const handleOpenDelete = (id) => {
    setOpen(true);
    setUserId(id);
    setAction(modalActions.DELETE);
  };
  const handleOpenEdit = (id) => {
    // console.log("edit id", id);
    setOpen(true);
    setUserId(id);
    setAction(modalActions.UPDATE);
  };

  const users = useSelector((state) => state?.users?.users);
  console.log("users: ",users)

  const Author = ({ image, utilisateur, email, nom, prenom }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      {/* <MDAvatar src={image} utilisateur={utilisateur} size="sm" /> */}
      <MDBox lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {utilisateur}
        </MDTypography>
        <MDTypography display="block" variant="caption">
          {email}
        </MDTypography>
        <MDTypography variant="caption">
          {nom} {prenom}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const usersRows = users?.data?.map((user, i) => ({
    searchNumbers:user?.count_searches+"/"+user?.limit_searches,
    downloadNumbers:user?.count_downloads+"/"+user?.limit_downloads,
    author: (
      <Author
        nom={user.last_name}
        prenom={user.first_name}
        utilisateur={user.username}
        email={user.email}
      />
    ),
    telephone: user.phone,
    // country: user?.country?.name,
    role: (
      <MDBox ml={-1}>
        <MDBadge
          badgeContent={user.role}
          color={user.role == "admin" ? "primary" : user.role == "complet" ? "success" : "info"}
          variant="gradient"
          size="sm"
        />
      </MDBox>
    ),
    adresse: (
      <div style={{ width: "300px" }}>
        {user.address} <br /> <i>{user.city}</i>, {user.country?.name}
      </div>
    ),
    // ville: user.city,
    active: (
      <MDBox ml={-1}>
        <MDBadge
          badgeContent={user.active ? "active" : "inactive"}
          color={user.active ? "success" : "secondary"}
          variant="gradient"
          size="sm"
        />
      </MDBox>
    ),
    action: (
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box>
          <IconButton color="info" onClick={() => handleOpenEdit(user.id)} component="label">
            <Icon color="info" fontSize="small">
              mode
            </Icon>
          </IconButton>
        </Box>
        <Box>
          <IconButton color="primary" onClick={() => handleOpenDelete(user.id)} component="label">
            <Icon color="primary" fontSize="small">
              delete
            </Icon>
          </IconButton>
        </Box>
      </Box>
    ),
  }));

  return {
    columns: [
      { Header: "Utilisateur", accessor: "author", align: "left" },
      { Header: "telephone", accessor: "telephone", align: "left" },
      // { Header: "pays", accessor: "country", align: "left" },
      { Header: "role", accessor: "role", align: "center" },
      { Header: "adresse", accessor: "adresse", width: "300px", align: "left" },
      // { Header: "ville", accessor: "ville", align: "left" },
      { Header: "active", accessor: "status", align: "left" },
      { Header: "NOMBRE DE RECHERCHE", accessor: "searchNumbers", width: "300px", align: "left" },
      { Header: "NOMBRE DE TÉLÉCHARGEMENT", accessor: "downloadNumbers", width: "300px", align: "left" },
      { Header: "action", accessor: "action", align: "left" },
    ],

    rows: usersRows,
  };
}

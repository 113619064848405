import * as ActionsTypes from "../../../../common/state/StatesConstants";

// ##-----------GET_FINANCIAL_PROFILES-----------##
export const getFinancialProfiles = (data) => {
  return { type: ActionsTypes.GET_FINANCIAL_PROFILES, payload: data };
};

export const getFinancialProfilesSuccess = (response) => {
  return { type: ActionsTypes.GET_FINANCIAL_PROFILES_SUCCESS, response };
};

export const getFinancialProfilesFail = (error) => {
  return { type: ActionsTypes.GET_FINANCIAL_PROFILES_FAIL, error };
};
// ##-----------END_GET_FINANCIAL_PROFILES-----------##

// ##-----------ADD_FINANCIAL_PROFILE-----------##
export const addFinancialProfile = (data) => {
  return { type: ActionsTypes.ADD_FINANCIAL_PROFILE, payload: data };
};

export const addFinancialProfileSuccess = (response) => {
  return { type: ActionsTypes.ADD_FINANCIAL_PROFILE_SUCCESS, response };
};

export const addFinancialProfileFail = (error) => {
  return { type: ActionsTypes.ADD_FINANCIAL_PROFILE_FAIL, error };
};
// ##-----------END_ADD_FINANCIAL_PROFILE-----------##

// ##-----------EDIT_FINANCIAL_PROFILE-----------##
export const editFinancialProfile = (data) => {
  return { type: ActionsTypes.EDIT_FINANCIAL_PROFILE, payload: data };
};

export const editFinancialProfileSuccess = (response) => {
  return { type: ActionsTypes.EDIT_FINANCIAL_PROFILE_SUCCESS, response };
};

export const editFinancialProfileFail = (error) => {
  return { type: ActionsTypes.EDIT_FINANCIAL_PROFILE_FAIL, error };
};
// ##-----------END_EDIT_FINANCIAL_PROFILE-----------##

// ##-----------UPDATE_FINANCIAL_PROFILE-----------##
export const updateFinancialProfile = (data) => {
  return { type: ActionsTypes.UPDATE_FINANCIAL_PROFILE, payload: data };
};

export const updateFinancialProfileSuccess = (response) => {
  return { type: ActionsTypes.UPDATE_FINANCIAL_PROFILE_SUCCESS, response };
};

export const updateFinancialProfileFail = (error) => {
  return { type: ActionsTypes.UPDATE_FINANCIAL_PROFILE_FAIL, error };
};
// ##-----------END_UPDATE_FINANCIAL_PROFILE-----------##

// ##-----------DELETE_FINANCIAL_PROFILE-----------##
export const deleteFinancialProfile = (data) => {
  return { type: ActionsTypes.DELETE_FINANCIAL_PROFILE, payload: data };
};

export const deleteFinancialProfileSuccess = (response) => {
  return { type: ActionsTypes.DELETE_FINANCIAL_PROFILE_SUCCESS, response };
};

export const deleteFinancialProfileFail = (error) => {
  return { type: ActionsTypes.DELETE_FINANCIAL_PROFILE_FAIL, error };
};
// ##-----------END_DELETE_FINANCIAL_PROFILE-----------##

import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";
import Users from "layouts/users";
import Countries from "layouts/countries";
import Companies from "layouts/companies";
import CountryProfiles from "layouts/countryProfiles";
import Shareholdings from "layouts/shareholdings";
import Directors from "layouts/directors";
import Rapport from "layouts/rapport";
import Information from "layouts/rapport/pages/informations";
import FinancialProfile from "layouts/rapport/pages/financialProfiles";
import FinancialProfiles from "layouts/financialProfiles";
import Activities from "layouts/rapport/pages/activities";
import Search from "layouts/search";
import Bilans from "layouts/bilans";
import ProductServices from "layouts/productServices";
import Login from "layouts/authentication/login";
import * as ROLE from "./router/roles"
import MissingCompanies from "layouts/missingCompanies";

const routes = [
  {
    type: "title",
    title: "Consultation",
    key: ROLE.CONSULTANT,
    roles: [ROLE.ADMIN, ROLE.CONSULTANT]
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
    roles: [ROLE.ADMIN]
  },
  {
    type: "collapse",
    name: "Recherche",
    key: "recherche",
    icon: <Icon fontSize="small">search</Icon>,
    route: "/recherche",
    component: <Search />,
    roles: [ROLE.ADMIN, ROLE.CONSULTANT]
  },
  {
    type: "collapse",
    name: "Entreprises Suggérées",
    key: "entreprises-suggerees",
    icon: <Icon fontSize="small">assistant</Icon>,
    route: "/entreprises-suggerees",
    component: <MissingCompanies />,
    roles: [ROLE.ADMIN]
  },
  {
    type: "collapse",
    name: "Rapport",
    key: "rapport",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/rapport/:companySlug",
    component: <Rapport />,
    roles: [ROLE.ADMIN, ROLE.CONSULTANT]
  },
  {
    type: "divider",
    key: "divider1",
    roles: [ROLE.ADMIN]
  },
  {
    type: "title",
    title: "Parametrage",
    key: "parametrage",
    roles: [ROLE.ADMIN]
  },
  {
    type: "collapse",
    name: "Utilisateurs",
    key: "utilisateurs",
    icon: <Icon fontSize="small">people</Icon>,
    route: "/utilisateurs",
    component: <Users />,
    roles: [ROLE.ADMIN]
  },
  {
    type: "collapse",
    name: "Pays",
    key: "pays",
    icon: <Icon fontSize="small">flag</Icon>,
    route: "/pays",
    component: <Countries />,
    roles: [ROLE.ADMIN]
  },
  {
    type: "collapse",
    name: "Entreprises",
    key: "entreprises",
    icon: <Icon fontSize="small">apartment</Icon>,
    route: "/entreprises",
    component: <Companies />,
    roles: [ROLE.ADMIN]
  },
  {
    type: "collapse",
    name: "Structure de l'actionnariat",
    key: "actionnariat",
    icon: <Icon fontSize="small">people</Icon>,
    route: "/actionnariat",
    component: <Shareholdings />,
    roles: [ROLE.ADMIN]
  },
  {
    type: "collapse",
    name: "Secteurs d'activité",
    key: "produit-service",
    icon: <Icon fontSize="small">design_services_icon</Icon>,
    route: "/produit-service",
    component: <ProductServices />,
    roles: [ROLE.ADMIN]
  },
  {
    type: "collapse",
    name: "Directeurs",
    key: "directeurs",
    icon: <Icon fontSize="small">supervised_user_circle</Icon>,
    route: "/directeurs",
    component: <Directors />,
    roles: [ROLE.ADMIN]
  },
  {
    type: "collapse",
    name: "Profil Pays",
    key: "profile-pays",
    icon: <Icon fontSize="small">info</Icon>,
    route: "/pays/:countryId/:countrySlug",
    component: <CountryProfiles />,
    roles: [ROLE.ADMIN]
  },
  {
    type: "collapse",
    name: "Bilans",
    key: "bilans",
    icon: <Icon fontSize="small">document_scanner</Icon>,
    route: "/bilans",
    component: <Bilans />,
    roles: [ROLE.ADMIN]
  },
  {
    type: "collapse",
    name: "Profiles financier",
    key: "profiles-financier",
    icon: <Icon fontSize="small">document_scanner</Icon>,
    route: "/profiles-financier",
    component: <FinancialProfiles />,
    roles: [ROLE.ADMIN]
  },
  // {
  //   type: "collapse",
  //   name: "Information",
  //   key: "information",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/rapport/information",
  //   component: <Information />,
  // },
  // {
  //   type: "collapse",
  //   name: "Profile financier",
  //   key: "profile-financier",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/rapport/profile-financier",
  //   component: <FinancialProfile />,
  // },
  // {
  //   type: "collapse",
  //   name: "Secteur et activite",
  //   key: "secteur-activite",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/rapport/secteur-activite",
  //   component: <Activities />,
  // },
  // {
  //   type: "collapse",
  //   name: "Notifications",
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/notifications",
  //   component: <Notifications />,
  // },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/profile",
  //   component: <Profile />,
  // },
  {
    type: "collapse",
    name: "Se connecter",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <Login />,
    roles: []
  },
  // {
  //   type: "collapse",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/authentication/sign-up",
  //   component: <SignUp />,
  // },
];

export default routes;

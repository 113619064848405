import { Box, Card, Grid, Icon, Typography, useMediaQuery } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import directorsTableData from "./data/directorsTableData";

function Directors({ companyId }) {
  const { columns, rows } = directorsTableData();
  const { companySlug } = useParams();
  const media600 = useMediaQuery("(min-width:600px)");

  return (
    <MDBox pt={2} p={2}>
      <Grid container>
        <Grid item xs={8} sm={8} md={6}>
          <Typography component="div" pb={2} variant="h4" align="left">
            Direction et personnel
          </Typography>
        </Grid>
        <Grid item xs={4} sm={4} md={6}>
          <Box sx={{ textAlign: "right" }}>
            <Link
              to="/directeurs"
              state={{
                id: companyId,
                slug: companySlug,
              }}
            >
              <MDButton p={0} type="submit" color="primary">
                <Icon fontSize="small">settings-icon</Icon>
                {media600 ? (
                  <Typography variant="p" pl={1}>
                    Paramétrage
                  </Typography>
                ) : (
                  ""
                )}
              </MDButton>
            </Link>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox pt={3}>
              <DataTable
                table={{ columns, rows }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
                noEndBorder
              />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default Directors;

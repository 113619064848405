import * as Yup from "yup";

export const addShareholdingSchema = Yup.object().shape({
  name: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
  type: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
  source: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
  act_direct: Yup.number().required("Required"),
  act_total: Yup.number().required("Required"),
  op_rev: Yup.number().required("Required"),
  country_id: Yup.number().required("Required"),
  date: Yup.date().required("Required"),
});
export const updateShareholdingSchema = Yup.object().shape({
  name: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
  type: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
  source: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
  act_direct: Yup.number().required("Required"),
  act_total: Yup.number().required("Required"),
  op_rev: Yup.number().required("Required"),
  country_id: Yup.number().required("Required"),
  date: Yup.date().required("Required"),
});

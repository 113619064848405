import * as ActionsTypes from "../../../../common/state/StatesConstants";

// ##-----------GET_SHAREHOLDINGS-----------##
export const getShareholdings = (data) => {
  return { type: ActionsTypes.GET_SHAREHOLDINGS, payload: data };
};

export const getShareholdingsSuccess = (response) => {
  return { type: ActionsTypes.GET_SHAREHOLDINGS_SUCCESS, response };
};

export const getShareholdingsFail = (error) => {
  return { type: ActionsTypes.GET_SHAREHOLDINGS_FAIL, error };
};
// ##-----------END_GET_SHAREHOLDINGS-----------##

// ##-----------ADD_SHAREHOLDING-----------##
export const addShareholding = (data) => {
  return { type: ActionsTypes.ADD_SHAREHOLDING, payload: data };
};

export const addShareholdingSuccess = (response) => {
  return { type: ActionsTypes.ADD_SHAREHOLDING_SUCCESS, response };
};

export const addShareholdingFail = (error) => {
  return { type: ActionsTypes.ADD_SHAREHOLDING_FAIL, error };
};
// ##-----------END_ADD_SHAREHOLDING-----------##

// ##-----------EDIT_SHAREHOLDING-----------##
export const editShareholding = (data) => {
  return { type: ActionsTypes.EDIT_SHAREHOLDING, payload: data };
};

export const editShareholdingSuccess = (response) => {
  return { type: ActionsTypes.EDIT_SHAREHOLDING_SUCCESS, response };
};

export const editShareholdingFail = (error) => {
  return { type: ActionsTypes.EDIT_SHAREHOLDING_FAIL, error };
};
// ##-----------END_EDIT_SHAREHOLDING-----------##

// ##-----------UPDATE_SHAREHOLDING-----------##
export const updateShareholding = (data) => {
  return { type: ActionsTypes.UPDATE_SHAREHOLDING, payload: data };
};

export const updateShareholdingSuccess = (response) => {
  return { type: ActionsTypes.UPDATE_SHAREHOLDING_SUCCESS, response };
};

export const updateShareholdingFail = (error) => {
  return { type: ActionsTypes.UPDATE_SHAREHOLDING_FAIL, error };
};
// ##-----------END_UPDATE_SHAREHOLDING-----------##

// ##-----------DELETE_SHAREHOLDING-----------##
export const deleteShareholding = (data) => {
  return { type: ActionsTypes.DELETE_SHAREHOLDING, payload: data };
};

export const deleteShareholdingSuccess = (response) => {
  return { type: ActionsTypes.DELETE_SHAREHOLDING_SUCCESS, response };
};

export const deleteShareholdingFail = (error) => {
  return { type: ActionsTypes.DELETE_SHAREHOLDING_FAIL, error };
};
// ##-----------END_DELETE_SHAREHOLDING-----------##

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  maxWidth: "100%",
  height: "auto",
  maxHeight: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  overflow: "auto",
  // pt: 2,
  px: 4,
  pb: 4,
};

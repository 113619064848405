import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/state/ProductServicesAction";
import * as modalActions from "../modalActionTypes";
import { Box } from "@mui/system";
import { Chip, Icon, IconButton } from "@mui/material";

export default function Data(setOpen, setProductServiceId, setAction, page) {
  const dispatch = useDispatch();
  const order = {
    sort: "updated_at",
    order: "desc",
  };
  useEffect(() => {
    dispatch(actions.getProductServices({...order,page}));
  }, [page]);

  const handleOpenEdit = (id) => {
    setOpen(true);
    setProductServiceId(id);
    setAction(modalActions.UPDATE);
  };

  const handleOpenDelete = (id) => {
    setOpen(true);
    setProductServiceId(id);
    setAction(modalActions.DELETE);
  };


  const productServices = useSelector((state) => state?.productServices?.productServices);
  
  const productServicesRows = productServices && productServices?.data?.map((productService, i) => ({
    index: i + 1,
    code_activity: productService.code_activity? <Chip label={productService.code_activity}  color="primary" size="small"/>:"",
    name: productService?.name,
    created_at: new Date(productService?.created_at)?.toLocaleDateString(),
    updated_at: new Date(productService?.updated_at)?.toLocaleDateString(),
    action: (
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box>
          <IconButton
            color="info"
            onClick={() => handleOpenEdit(productService.id)}
            component="label"
          >
            <Icon color="info" fontSize="small">
              mode
            </Icon>
          </IconButton>
        </Box>
        <Box>
          <IconButton
            color="primary"
            onClick={() => handleOpenDelete(productService.id)}
            component="label"
          >
            <Icon color="primary" fontSize="small">
              delete
            </Icon>
          </IconButton>
        </Box>
      </Box>
    ),
  }));
  return {
    columns: [
      { Header: "CODE ACTIVITÉ", accessor: "code_activity", align: "left" },
      { Header: "name", accessor: "name", align: "left" },
      { Header: "date creation", accessor: "created_at", align: "left" },
      { Header: "Date de modification", accessor: "updated_at", align: "left" },
      { Header: "action", accessor: "action", align: "left" },
    ],

    rows: productServicesRows,
    productServicePageInfo:productServices
  };
}

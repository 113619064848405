import React, { useEffect } from "react";
import MDBox from "components/MDBox";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../financialProfiles/redux/state/FinancialProfilesAction";
import { useParams } from "react-router-dom";

// Function to format numbers with spaces
function formatNumberWithSpaces(input) {
  if (isNaN(input)) {
     return input;
  }
  const roundedNum = Math.round(input);
  const numStr = roundedNum.toString();
  const formattedStr = numStr.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return formattedStr;
 }
 
 export default function Data() {
  const dispatch = useDispatch();
  const { companyId } = useParams();
 
  // Fetch financial profiles
  useEffect(() => {
     dispatch(actions.getFinancialProfiles({ companyId: companyId }));
  }, [dispatch, companyId]);
 
  const financialProfiles = useSelector((state) => state?.companies?.company?.financial_profiles) ?? [];
 
  // Get years
  const years = financialProfiles?.map((financialProfile, i) => financialProfile.year);
 
 // Function to transpose data
//  function transpose(data) {
//   let dt = [];
//   if (data?.length > 0) {
//      dt = Object.keys(data[0])?.map((colNumber) =>
//        toObject(data?.map((rowNumber) => rowNumber[colNumber]))
//      );
//   }
//   return dt;
//  }
// Function to transpose data
// Function to transpose data
function transpose(data) {
  let dt = [];
  if (data?.length > 0) {
     dt = Object.keys(data[0])?.filter(key => key !== 'id' && key !== 'year') // Exclude 'id' and 'year' from the keys
                               .map((colNumber) =>
       toObject(data?.map((rowNumber) => rowNumber[colNumber]))
     );
  }
  return dt;
 }
 
 
 
 // Function to convert array to object
 function toObject(arr) {
  var obj = {};
  for (var i = 0; i < arr.length; ++i) if (arr[i] !== undefined) obj[years[i]] = arr[i];
  return obj;
 }
 
  const rowsHeader = [
    "Taux change",
    "Total des prod d'exploitation",
    "Résultat courant avant impôts",
    "Bénéfice ou perte [= Revenu net]",
    "Capacité d’autofin. avant rép",
    "Total de l'actif",
    "Capitaux propres",
    "Liquidité reduite (x)",
    "Performance (%)",
    "Rend. des capitaux propres nets (%)",
    "Rend. du capital investi (%)",
    "Ratio de solvabilité (à partir de l'actif) (%)",
    "Effectifs"
   ];
  // Pivot financialProfiles data
  let pivot = transpose(financialProfiles);
 
  // Format numbers and update object properties for pivot
  pivot.forEach(obj => {
     Object.keys(obj).forEach(key => {
       if (!isNaN(obj[key])) {
         obj[key] = formatNumberWithSpaces(obj[key]);
       }
     });
  });
 
  console.log("pivot::::", pivot);
  //years colums
  const yearsColumn = years?.map((year, i) => ({
    Header: year.toString(),
    accessor: year.toString(),
    align: "right",
  }));

  //merge years column with static column annee
  const columns = [{ Header: "Annee", accessor: "year", align: "left" }].concat(yearsColumn);

  //map over data pivot
  let rows = pivot?.map((p, i) => singleRow(p, i));

  //map over years
  function singleRow(p, i) {
    const obj = {};
    years.map((year) => (obj[year] = p[year]));
    return { year: rowsHeader[i], ...obj };
  }

  console.log(rows);

  return {
    columns: columns,

    rows: rows,
  };
}

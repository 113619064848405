import * as ActionsTypes from "../../../../common/state/StatesConstants";

const initialState = {
  error: null,
  isLoading: false,
  directors: [],
  director: {},
};

const directorsReducer = (state = initialState, action) => {
  switch (action.type) {
    // ##-----------GET_DIRECTORS-----------##
    case ActionsTypes.GET_DIRECTORS:
      return {
        ...state,
        isLoading: true,
        error: null,
        directors: [],
      };
    case ActionsTypes.GET_DIRECTORS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        directors: action.response.data.data,
      };
    case ActionsTypes.GET_DIRECTORS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        directors: [],
      };
    // ##-----------END_GET_DIRECTORS-----------##

    // ##-----------ADD_DIRECTOR-----------##
    case ActionsTypes.ADD_DIRECTOR:
      return {
        ...state,
        isLoading: true,
        error: null,
        //directors: [...state.directors],
      };

    case ActionsTypes.ADD_DIRECTOR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        //directors: [action.response.data.data, ...state.directors],
      };

    case ActionsTypes.ADD_DIRECTOR_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        //directors: [...state.directors],
      };
    // ##-----------END_ADD_DIRECTOR-----------##

    // ##-----------EDIT_DIRECTOR-----------##
    case ActionsTypes.EDIT_DIRECTOR:
      return {
        ...state,
        isLoading: true,
        error: null,
        director: {},
      };

    case ActionsTypes.EDIT_DIRECTOR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        director: action.response.data.data,
      };

    case ActionsTypes.EDIT_DIRECTOR_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        director: {},
      };
    // ##-----------END_EDIT_DIRECTOR-----------##

    // ##-----------UPDATE_DIRECTOR-----------##
    case ActionsTypes.UPDATE_DIRECTOR:
      return {
        ...state,
        isLoading: true,
        error: null,
        //directors: [...state.directors],
      };

    case ActionsTypes.UPDATE_DIRECTOR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        // directors: [
        //   action.response.data.data,
        //   ...state.directors.filter((director) => director.id != action.response.data.data.id),
        // ],
      };

    case ActionsTypes.UPDATE_DIRECTOR_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        //directors: [...state.directors],
      };
    // ##-----------END_UPDATE_DIRECTOR-----------##

    // ##-----------DELETE_DIRECTOR-----------##
    case ActionsTypes.DELETE_DIRECTOR:
      return {
        ...state,
        isLoading: true,
        error: null,
        //directors: [...state.directors],
      };

    case ActionsTypes.DELETE_DIRECTOR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        // directors: [
        //   ...state.directors.filter((director) => director.id != action.response.data.data),
        // ],
      };

    case ActionsTypes.DELETE_DIRECTOR_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        //directors: [...state.directors],
      };
    // ##-----------END_DELETE_DIRECTOR-----------##

    // ##-----------DELETE_DIRECTOR-----------##
    case ActionsTypes.UPLOAD_DIRECTOR:
      return {
        ...state,
        isLoading: true,
        error: null,
        //directors: [...state.directors],
      };

    case ActionsTypes.UPLOAD_DIRECTOR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        //directors: [...state.directors],
      };

    case ActionsTypes.UPLOAD_DIRECTOR_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        //directors: [...state.directors],
      };
    // ##-----------END_UPLOAD_DIRECTOR-----------##

    default:
      return state;
  }
};

export default directorsReducer;

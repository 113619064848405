import { Card, Grid, Icon, Pagination, Stack } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import * as modalActions from "./modalActionTypes";
import React, { useEffect, useState } from "react";
// Data
import companiesTableData from "./data/CompaniesTable";
import DataTable from "examples/Tables/DataTable";
import { Box } from "@mui/system";
import MDButton from "components/MDButton";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "./redux/state/CompaniesAction";
import CompanyModal from "./components/CompanyModal";
import FormUpdateCompany from "./components/FormUpdateCompany";
import FormAddCompany from "./components/FormAddCompany";
import ComfirmeDeleteCompany from "./components/ComfirmeDeleteCompany";

function MissingCompanies() {
  const [open, setOpen] = useState(false);
  const [companyId, setCompanyId] = useState("");
  const [action, setAction] = useState(modalActions.ADD);
  const [page, setPage] = useState(1);
  const handleOpen = () => {
    setAction(modalActions.ADD);
    setCompanyId("");
    setOpen(true);
  };
  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(actions.getMissingCompanies());
  // }, []);
  // const data = useSelector((state) => state?.missingCompanies?.companies);
  // console.log("missing0,0 ",data)

  const { columns, rows, data } = companiesTableData(setOpen, setCompanyId, setAction,page);
  
  const changePage=(event, value)=>{
    console.log(value);
    setPage(old=>value)
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                Entreprises Suggérées
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
              {rows && 
              <>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                  pagination={false}
                />
                <Box sx={{ mt: 2}} style={{ paddingBottom:"20px", paddingLeft:"20px" }}>
                    <Stack spacing={2}>
                        <Pagination
                        count={data?.last_page} 
                        variant="outlined" 
                        shape="rounded"
                        page={page} 
                        onChange={changePage}
                        />
                    </Stack>
                </Box></>
                }
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <CompanyModal
        open={open}
        setOpen={setOpen}
        title={
          action == modalActions.UPDATE
            ? "Modifier entreprise"
            : action == modalActions.DELETE
            ? "Supprimer entreprise"
            : "Ajouter entreprise"
        }
      >
        {/* ADD MODAL */}

        {/* UPDATE MODAL */}
        {action == modalActions.UPDATE && (
          <FormUpdateCompany
            setOpen={setOpen}
            setAction={setAction}
            setCompanyId={setCompanyId}
            companyId={companyId}
          />
        )}

        {/* DELETE MODAL */}
        {action == modalActions.DELETE && (
          <ComfirmeDeleteCompany
            setOpen={setOpen}
            setAction={setAction}
            setCompanyId={setCompanyId}
            companyId={companyId}
          />
        )}
      </CompanyModal>
      ;
    </DashboardLayout>
  );
}
export default MissingCompanies;

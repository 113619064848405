import * as ActionsTypes from "../../../../common/state/StatesConstants";

// ##-----------GET_SEARCH-----------##
export const getSearch = (data) => {
  return { type: ActionsTypes.GET_SEARCH, payload: data };
};
export const getMe = (data) => {
  return { type: ActionsTypes.ME, payload: data };
};
export const getMeSuccess = (response) => {
  return { type: ActionsTypes.ME_SUCCESS, response };
};
export const getMeFail = (data) => {
  return { type: ActionsTypes.ME_FAIL, payload: data };
};

export const getSearchSuccess = (response) => {
  return { type: ActionsTypes.GET_SEARCH_SUCCESS, response };
};

export const getSearchFail = (error) => {
  return { type: ActionsTypes.GET_SEARCH_FAIL, error };
};
// ##-----------END_GET_SEARCH-----------##
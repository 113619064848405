import { put, call, delay } from "redux-saga/effects";
import * as actions from "../state/CompaniesAction";
import {
  getMissingCompaniesApi,
  addMissingCompanyApi,
  editMissingCompanyApi,
  updateMissingCompanyApi,
  deleteMissingCompanyApi,
  uploadMissingCompanyApi,
  getRapportApi
} from "../../../../common/services/Apis/missingCompaniesApi";

export function* getMissingCompaniesSaga(data = {}) {
  console.log("missi inside getMissingCompaniesSaga")
  try {
    const response = yield call(getMissingCompaniesApi, data?.payload);
    yield put(actions.getMissingCompaniesSuccess(response));
  } catch (error) {
    yield put(actions.getMissingCompaniesFail(error));
  }
}
export function* addMissingCompanySaga(action) {
  console.log("action", action);
  try {
    const response = yield call(addMissingCompanyApi, action?.payload?.payload);
    if (response) {
      yield put(actions.addMissingCompanySuccess(response));
      yield call(action.payload.successCallBack, response);
    }
  } catch (error) {
    yield put(actions.addMissingCompanyFail(error));
    yield call(action.payload.failCallBack, error);
  }
}
export function* editMissingCompanySaga(action) {
  try {
    const response = yield call(editMissingCompanyApi, action.payload);
    yield put(actions.editMissingCompanySuccess(response));
  } catch (error) {
    yield put(actions.editMissingCompanyFail(error));
  }
}

export function* updateMissingCompanySaga(action) {
  try {
    const response = yield call(updateMissingCompanyApi, action?.payload?.payload);
    if (response) {
      yield put(actions.updateMissingCompanySuccess(response));
      yield call(action.payload.successCallBack, response);
    }
  } catch (error) {
    yield put(actions.updateMissingCompanyFail(error));
    yield call(action.payload.failCallBack, error);
  }
}

export function* deleteMissingCompanySaga(action) {
  try {
    const response = yield call(deleteMissingCompanyApi, action?.payload?.payload);
    yield put(actions.deleteMissingCompanySuccess(response));
    yield call(action.payload.successCallBack, response);
  } catch (error) {
    yield put(actions.deleteMissingCompanyFail(error));
    yield call(action.payload.failCallBack, error);
  }
}


export function* getRapportSaga(action) {
  try {
    const response = yield call(getRapportApi, action.payload);
    yield put(actions.getRapport(response));
  } catch (error) {
  }
}

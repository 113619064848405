import { put, call, delay } from "redux-saga/effects";
import * as actions from "../state/CompteResultatBilansAction";
import {
  getCompteResultatBilansApi,
  addCompteResultatBilanApi,
  editCompteResultatBilanApi,
  updateCompteResultatBilanApi,
  deleteCompteResultatBilanApi,
} from "common/services/Apis/compteResultatBilansApi";

export function* getCompteResultatBilansSaga(action) {
  try {
    const response = yield call(getCompteResultatBilansApi, action.payload);
    yield put(actions.getCompteResultatBilansSuccess(response));
  } catch (error) {
    yield put(actions.getCompteResultatBilansFail(error));
  }
}

export function* addCompteResultatBilanSaga(action) {
  try {
    const response = yield call(addCompteResultatBilanApi, action?.payload?.payload);
    if (response) {
      yield put(actions.addCompteResultatBilanSuccess(response));
      yield call(action.payload.successCallBack, response);
    }
  } catch (error) {
    yield put(actions.addCompteResultatBilanFail(error));
    yield call(action.payload.failCallBack, error);
  }
}

export function* editCompteResultatBilanSaga(action) {
  try {
    const response = yield call(editCompteResultatBilanApi, action.payload);
    yield put(actions.editCompteResultatBilanSuccess(response));
  } catch (error) {
    yield put(actions.editCompteResultatBilanFail(error));
  }
}

export function* updateCompteResultatBilanSaga(action) {
  try {
    const response = yield call(updateCompteResultatBilanApi, action?.payload?.payload);
    if (response) {
      yield put(actions.updateCompteResultatBilanSuccess(response));
      yield call(action.payload.successCallBack, response);
    }
  } catch (error) {
    yield put(actions.updateCompteResultatBilanFail(error));
    yield call(action.payload.failCallBack, error);
  }
}

export function* deleteCompteResultatBilanSaga(action) {
  try {
    const response = yield call(deleteCompteResultatBilanApi, action?.payload?.payload);
    yield put(actions.deleteCompteResultatBilanSuccess(response));
    yield call(action.payload.successCallBack, response);
  } catch (error) {
    yield put(actions.deleteCompteResultatBilanFail(error));
    yield call(action.payload.failCallBack, error);
  }
}

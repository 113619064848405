import { put, call, delay } from "redux-saga/effects";
import * as actions from "../state/DirectorsAction";
import {
  addDirectorsApi,
  deleteDirectorApi,
  editDirectorApi,
  getDirectorsApi,
  updateDirectorApi,
  uploadDirectorApi,
} from "../../../../common/services/Apis/directorsApi";

export function* getDirectorsSaga(action) {
  try {
    const response = yield call(getDirectorsApi, action.payload);
    yield put(actions.getDirectorsSuccess(response));
  } catch (error) {
    yield put(actions.getDirectorsFail(error));
  }
}

export function* addDirectorSaga(action) {
  try {
    const response = yield call(addDirectorsApi, action?.payload?.payload);
    if (response) {
      yield put(actions.addDirectorSuccess(response));
      yield call(action.payload.successCallBack, response);
    }
  } catch (error) {
    yield put(actions.addDirectorFail(error));
    yield call(action.payload.failCallBack, error);
  }
}

export function* editDirectorSaga(action) {
  try {
    const response = yield call(editDirectorApi, action.payload);
    yield put(actions.editDirectorSuccess(response));
  } catch (error) {
    yield put(actions.editDirectorFail(error));
  }
}

export function* updateDirectorSaga(action) {
  try {
    const response = yield call(updateDirectorApi, action?.payload?.payload);
    if (response) {
      yield put(actions.updateDirectorSuccess(response));
      yield call(action.payload.successCallBack, response);
    }
  } catch (error) {
    yield put(actions.updateDirectorFail(error));
    yield call(action.payload.failCallBack, error);
  }
}

export function* deleteDirectorSaga(action) {
  try {
    const response = yield call(deleteDirectorApi, action?.payload?.payload);
    yield put(actions.deleteDirectorSuccess(response));
    yield call(action.payload.successCallBack, response);
  } catch (error) {
    yield put(actions.deleteDirectorFail(error));
    yield call(action.payload.failCallBack, error);
  }
}
export function* uploadDirectorSaga(action) {
  try {
    const response = yield call(uploadDirectorApi, action?.payload?.payload);
    yield put(actions.uploadDirectorSuccess(response));
    yield call(action.payload.successCallBack, response);
  } catch (error) {
    yield put(actions.uploadDirectorFail(error));
    yield call(action.payload.failCallBack, error);
  }
}

import { Alert, Box, Chip, Divider, FormControl, Grid, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/state/ShareholdingsAction";
import * as modalActions from "../modalActionTypes";
import { grey } from "@mui/material/colors";
import { useLocation } from "react-router-dom";
import { getErrorMessage } from "common/utils/Helper";

export default function ConfirmDeleteShareholding({
  setOpen,
  shareholdingId,
  setShareholdingId,
  setAction,
}) {
  const location = useLocation();
  const dispatch = useDispatch();

  const [errorSB, setErrorSB] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  useEffect(() => {
    dispatch(actions.editShareholding(shareholdingId));
  }, []);

  const shareholding = useSelector((state) => state?.shareholdings?.shareholding);
  const shareholdingErrorStatus = useSelector((state) => state?.shareholdings?.error?.response?.status);

  const closeModal = () => {
    setAction(modalActions.ADD);
    setShareholdingId("");
    setOpen(false);
  };

  const onSubmit = (values) => {
    const request = {
      payload: values,
      successCallBack: (response) => {
        closeErrorSB();
        let data = {};
        closeModal();
        if (location?.state?.id) {
          data = { companyId: location?.state?.id };
        }
        dispatch(actions.getShareholdings(data));
        console.log("response", response);
      },
      failCallBack: (error) => {
        console.log("error", error);
        setErrorMessage(getErrorMessage(error, shareholdingErrorStatus));
        openErrorSB();
      },
    };
    dispatch(actions.deleteShareholding(request));
  };

  // const navigate = useNavigate();
  const { handleSubmit, touched } = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: shareholding?.id,
      name: shareholding?.name,
    },
    onSubmit,
  });
  return (
    <MDBox my={1}>
      {errorSB && (
        <Typography textAlign="center" color="error" variant="body2">
          {errorMessage} {" < Echec de suppresion >"}
        </Typography>
      )}
      <form onSubmit={handleSubmit}>
        <MDBox py={1}>
          <Alert severity="error">Etes vous sure de vouloir supprimer - {shareholding?.name}</Alert>
        </MDBox>
        <Divider />
        <Box mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box mr={1}>
            <MDButton type="submit" variant="contained" size="small" color="error">
              Supprimer
            </MDButton>
          </Box>
          <Box mr={1}>
            <MDButton
              size="small"
              onClick={closeModal}
              style={{ background: grey[500], color: "white" }}
            >
              Annuler
            </MDButton>
          </Box>
        </Box>
      </form>
    </MDBox>
  );
}

import * as Yup from "yup";

export const addFinancialProfileSchema = Yup.object().shape({
  taux_change: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  total_prod_exploitations: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  resultat_courant_avant_impots: Yup.number("La valeur doit être un nombre").required(
    "Obligatoire"
  ),
  benefice_perte: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  capacite_autofin_avant_rep: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  total_actif: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  capitaux_propres: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  liquidite_reduite: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  performance: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  rend_capitaux_propres_nets: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  ratio_solvabilite: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  year: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  taux_change: Yup.number("La valeur doit être un nombre").required("Obligatoire"),

  company_id: Yup.number().integer().required("Required"),
});

export const updateFinancialProfileSchema = Yup.object().shape({
  taux_change: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  total_prod_exploitations: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  resultat_courant_avant_impots: Yup.number("La valeur doit être un nombre").required(
    "Obligatoire"
  ),
  benefice_perte: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  capacite_autofin_avant_rep: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  total_actif: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  capitaux_propres: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  liquidite_reduite: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  performance: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  rend_capitaux_propres_nets: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  ratio_solvabilite: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  year: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  taux_change: Yup.number("La valeur doit être un nombre").required("Obligatoire"),

  company_id: Yup.number().integer().required("Required"),
});

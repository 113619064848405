/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import ApartmentIcon from '@mui/icons-material/Apartment';
import PeopleIcon from '@mui/icons-material/People';
import DownloadIcon from '@mui/icons-material/Download';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import config from "common/utils/Config";
import { useEffect, useState } from "react";


const fetchData = (setDashboardData)=>{
  const token = "Bearer "+localStorage.getItem("access_token");
  const res = fetch(`${config.url}/dashboard`,{
      headers: {
          'Content-Type' : 'application/json',
          'Authorization': token
      }
  } ).then((response) => {
      console.log(response);
      if (!response.ok) {
          throw Error('Error');
      }
      return response.json();
    })
    .then((data) => {
      setDashboardData(data)
      return data;
    })
    .catch((err) => {
      console.error(err.message);
      return undefined;
    });
  return res;
};

function Dashboard() {
  const { sales, tasks } = reportsLineChartData;
  const [dashboardData, setDashboardData] = useState(undefined);

  useEffect(()=>{
    if(dashboardData===null || dashboardData === undefined){
      fetchData(setDashboardData);
    }
  },[])

  console.log("dash data",dashboardData)
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon={<ApartmentIcon/>}
                title="Nombre d'entreprises"
                count={dashboardData?.data?.nbr_companies}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon={<ApartmentIcon/>}
                title="Nombre d'entreprises non verifiées"
                count={dashboardData?.data?.nbr_missing_companies_ongoing}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon={<ApartmentIcon/>}
                title="Nombre d'entreprises verifiées"
                count={dashboardData?.data?.nbr_missing_companies_verified}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon={<PeopleIcon/>}
                title="Nombre de clients"
                count={dashboardData?.data?.nbr_clients}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon={<DownloadIcon/>}
                title="Nombre de téléchargements des rapport PDF"
                count={dashboardData?.data?.nbr_downloadsPdf}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon={<ExitToAppIcon/>}
                title="Nombre de connexions"
                count={dashboardData?.data?.nbr_connections}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;

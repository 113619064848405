import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";
import Information from "layouts/rapport/pages/informations";
import FinancialProfile from "layouts/rapport/pages/financialProfiles";
import Activities from "layouts/rapport/pages/activities";
import CountryProfiles from "./pages/countryProfiles";
import Bilans from "./pages/bilans";
import Directors from "./pages/directors";
import Shareholdings from "./pages/shareholdings";

const tabs = [
  {
    index: 1,
    name: "Information",
    key: "information",
    icon: <Icon fontSize="medium">info</Icon>,
    route: "/rapport/information",
    component: <Information />,
  },
  {
    index: 2,
    name: "Profile financier",
    key: "profile-financier",
    icon: <Icon fontSize="medium">payments</Icon>,
    route: "/rapport/profile-financier",
    component: <FinancialProfile />,
  },
  {
    index: 3,
    name: "Secteur et activite",
    key: "secteur-activite",
    icon: <Icon fontSize="medium">inventory_2</Icon>,
    route: "/rapport/secteur-activite",
    component: <Activities />,
  },
  {
    index: 4,
    name: "Profil du pays",
    key: "profil-pays",
    icon: <Icon fontSize="medium">stacked_bar_chart</Icon>,
    route: "/rapport/profil-pays",
    component: <CountryProfiles />,
  },
  {
    index: 5,
    name: "Bilan",
    key: "bilan",
    icon: <Icon fontSize="medium">percent</Icon>,
    route: "/rapport/bilan",
    component: <Bilans />,
  },
  {
    index: 6,
    name: "Directeurs",
    key: "directeurs",
    icon: <Icon fontSize="medium">supervised_user_circle</Icon>,
    route: "/rapport/directeurs",
    component: <Directors />,
  },
  {
    index: 7,
    name: "Structure de l'actionnariat",
    key: "structure-actionnariat",
    icon: <Icon fontSize="medium">people</Icon>,
    route: "/rapport/structure-actionnariat",
    component: <Shareholdings />,
  },
];

export default tabs;

import { instance } from "../../utils/Axios";

export const getDirectorsApi = (data) => {
  return instance.get(`/directors/?page=${data?.page ?? 1}`).then((response) => {
    return response;
  });
};

export const addDirectorsApi = (data) => {
  return instance.post("/directors", data).then((response) => {
    return response;
  });
};

export const editDirectorApi = (id) => {
  return instance.get(`/directors/${id}`).then((response) => {
    return response;
  });
};

export const updateDirectorApi = (data) => {
  return instance.put(`/directors/${data.id}`, data).then((response) => {
    return response;
  });
};

export const deleteDirectorApi = (data) => {
  return instance.delete(`/directors/${data.id}`).then((response) => {
    return response;
  });
};
export const uploadDirectorApi = (data) => {
  return instance
    .post("/directors/import-excel", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response;
    });
};

import countriesReducer from "../../layouts/countries/redux/state/CountriesReducer";
import { combineReducers } from "redux";
import usersReducer from "../../layouts/users/redux/state/UsersReducer";
import AuthReducer from "../../layouts/authentication/redux/state/AuthReducer";
import companiesReducer from "../../layouts/companies/redux/state/CompaniesReducer";
import missingCompaniesReducer from "../../layouts/missingCompanies/redux/state/CompaniesReducer";
import directorsReducer from "../../layouts/directors/redux/state/DirectorsReducer";
import CountryProfilesReducer from "../../layouts/countryProfiles/redux/state/CountryProfilesReducer";
import ShareholdingsReducer from "../../layouts/shareholdings/redux/state/ShareholdingsReducer";
import searchReducer from "../../layouts/search/redux/state/SearchReducer";
import compteResultatBilansReducer from "../../layouts/bilans/pages/compteResultatBilans/redux/state/CompteResultatBilansReducer";
import productServicesReducer from "../../layouts/productServices/redux/state/ProductServicesReducer";
import compteDeResultatBilansReducer from "layouts/bilans/pages/compteDeResultatBilans/redux/state/CompteDeResultatBilansReducer";
import memoBilansReducer from "layouts/bilans/pages/memoBilans/redux/state/MemoBilansReducer";
import financialProfilesReducer from "layouts/financialProfiles/redux/state/FinancialProfilesReducer";
import passifBilansReducer from "layouts/bilans/pages/passifBilans/redux/state/PassifBilansReducer";
import ActifBilansReducer from "layouts/bilans/pages/actifBilans/redux/state/ActifBilansReducer";
import informationCompanyReducer from "layouts/rapport/pages/informations/redux/state/InformationCompanyReducer";

const rootReducer = combineReducers({
  countries: countriesReducer,
  users: usersReducer,
  companies: companiesReducer,
  missingCompanies: missingCompaniesReducer,
  auth: AuthReducer,
  countryProfiles: CountryProfilesReducer,
  shareholdings: ShareholdingsReducer,
  directors: directorsReducer,
  search: searchReducer,
  compteResultatBilans: compteResultatBilansReducer,
  compteDeResultatBilans: compteDeResultatBilansReducer,
  productServices: productServicesReducer,
  memoBilans: memoBilansReducer,
  actifBilans: ActifBilansReducer,
  financialProfiles: financialProfilesReducer,
  passifBilans: passifBilansReducer,
  informationCompany: informationCompanyReducer,
});

export default rootReducer;

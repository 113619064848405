import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/state/CompaniesAction";
import * as modalActions from "../modalActionTypes";
import { Box } from "@mui/system";
import { Icon, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import { Link } from "react-router-dom";

export default function Data(setOpen, setCompanyId, setAction, page) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getMissingCompanies({data:{page:page}}));
  }, []);

  const handleOpenEdit = (id) => {
    setOpen(true);
    setCompanyId(id);
    setAction(modalActions.UPDATE);
  };

  const handleOpenDelete = (id) => {
    setOpen(true);
    setCompanyId(id);
    setAction(modalActions.DELETE);
  };

  const missingCompanies = useSelector((state) => {
    console.log('state',state)
    return state?.missingCompanies?.missingCompanies
  });
  console.log("missingCompanies",missingCompanies)
  const missingCompaniesRows = missingCompanies && missingCompanies?.data?.map((missingCompany, i) => ({
    index: i + 1,
    created_by: missingCompany?.created_by?.email,
    raison_social: missingCompany?.name,
    forme_juridique: missingCompany?.forme_juridique,
    statut: (
      <MDBox ml={-1}>
        <MDBadge
          badgeContent={missingCompany?.statut ? "active" : "inactive"}
          color={missingCompany?.statut ? "success" : "secondary"}
          variant="gradient"
          size="sm"
        />
      </MDBox>
    ),
    numero_rc: missingCompany?.numero_rc,
    website: missingCompany?.website,
    adresse_siege_social: missingCompany?.adresse_siege_social,
    country: missingCompany?.country,
    city: missingCompany?.city,
    prefecture: missingCompany?.prefecture,
    type_identite: missingCompany?.type_identite,
    numero_ice: missingCompany?.numero_ice,
    date_creation: missingCompany?.date_creation,
    created_at: new Date(missingCompany?.created_at)?.toLocaleDateString(),
    updated_at: new Date(missingCompany?.updated_at)?.toLocaleDateString(),

    action: (
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box>
          <IconButton color="info" onClick={() => handleOpenEdit(missingCompany.id)} component="label">
            <Icon color="info" fontSize="small">
              mode
            </Icon>
          </IconButton>
        </Box>
        <Box>
          <IconButton
            color="primary"
            onClick={() => handleOpenDelete(missingCompany.id)}
            component="label"
          >
            <Icon color="primary" fontSize="small">
              delete
            </Icon>
          </IconButton>
        </Box>
      </Box>
    ),
  }));
  return {
    columns: [
      { Header: "raison social", accessor: "raison_social", align: "left" },
      { Header: "Created By", accessor: "created_by", align: "left" },
      { Header: "statut", accessor: "statut", align: "left" },
      { Header: "numero rc", accessor: "numero_rc", align: "left" },
      { Header: "pays", accessor: "country", align: "left" },
      { Header: "ville", accessor: "city", align: "left" },
      { Header: "numero ice", accessor: "numero_ice", align: "left" },
      { Header: "Date d'ajout", accessor: "created_at", align: "left" },
      { Header: "Date de modification", accessor: "updated_at", align: "left" },
      { Header: "action", accessor: "action", align: "left" },
    ],

    rows: missingCompaniesRows,
  };
}

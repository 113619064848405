import { Alert, FormControl, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { Form, Formik, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/state/ProductServicesAction";
import * as modalActions from "../modalActionTypes";
import { addProductServiceSchema } from "../schema";
import { useNavigate } from "react-router-dom";
import { getErrorMessage } from "common/utils/Helper";

export default function FormUpdateProductService({
  setOpen,
  productServiceId,
  setProductServiceId,
  setAction,
}) {
  const dispatch = useDispatch();

  const [errorSB, setErrorSB] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  
  const order = {
    sort: "updated_at",
    order: "desc",
  };

  useEffect(() => {
    dispatch(actions.editProductService(productServiceId));
  }, []);

  const productService = useSelector((state) => state?.productServices?.productService);

  const closeModal = () => {
    setAction(modalActions.ADD);
    setProductServiceId("");
    setOpen(false);
  };

  const onSubmit = (values) => {
    const request = {
      payload: values,
      successCallBack: (response) => {
        closeErrorSB()
        dispatch(actions.getProductServices(order));
        closeModal();
        console.log("response", response);
      },
      failCallBack: (error) => {
        console.log("error", error);
        setErrorMessage(getErrorMessage(error))
        openErrorSB();
      },
    };
    dispatch(actions.updateProductService(request));

    // alert(JSON.stringify(values, null, 2));
  };

  // const navigate = useNavigate();
  const { handleSubmit, errors, values, handleChange, touched } = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: productService?.id,
      name: productService?.name,
      code_activity: productService?.code_activity,
    },
    validationSchema: addProductServiceSchema,
    onSubmit,
  });
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <MDBox m={1}>
      {errorSB && (
        <Alert severity="error">
          {errorMessage}
        </Alert>
      )}
      <form onSubmit={handleSubmit}>
        {/* {productService?.name} */}
        <MDBox py={2}>
          <FormControl fullWidth>
            <MDInput
              id="name"
              label="Nom de produit ou service"
              error={errors.name ? true : false}
              onChange={handleChange}
              value={values.name ?? ""}
              helperText={errors?.name ?? ""}
            />
            {/* {errors.name && touched.name ? <div>{errors.name}</div> : null} */}
          </FormControl>
        </MDBox>
        <MDBox py={2}>
          <FormControl fullWidth>
            <MDInput
              id="code_activity"
              label="Code activité"
              error={errors.code_activity ? true : false}
              onChange={handleChange}
              value={values.code_activity ?? "" }
              helperText={errors?.code_activity ?? ""}
            />
            {/* {errors.code_activity && touched.code_activity ? <div>{errors.code_activity}</div> : null} */}
          </FormControl>
        </MDBox>

        <MDBox sx={{ display: "flex" }}>
          <MDBox mr={1} sx={{ flexGrow: "1" }}>
            <MDButton onClick={handleClose} type="submit" color="primary" fullWidth>
              Annuler
            </MDButton>
          </MDBox>
          <MDBox sx={{ flexGrow: "1" }}>
            <MDButton type="submit" color="info" fullWidth>
              Modifier
            </MDButton>
          </MDBox>
        </MDBox>
      </form>
    </MDBox>
  );
}

import * as Yup from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const addUserSchema = Yup.object().shape({
  first_name: Yup.string().max(50, "Trop longue!").required("Obligatoire"),
  last_name: Yup.string().max(50, "Trop longue!").required("Obligatoire"),
  // username: Yup.string().max(50, "Trop longue!").required("Obligatoire"),
  phone: Yup.string()
    .matches(phoneRegExp, "Numero de telephone non valide!")
    .required("Obligatoire"),
  email: Yup.string().email().required("Obligatoire"),
  password: Yup.string().min(8).required("Obligatoire"),
  confirme_password: Yup.string()
    .min(8)
    .oneOf(
      [Yup.ref("password")],
      "Le mot de passe et le mot de passe de confirmation ne correspondent pas"
    )
    .required("Obligatoire"),
  address: Yup.string().min(8, "Too Short!").max(100, "Trop longue!").required("Obligatoire"),
  city: Yup.string().max(100, "Trop longue!").required("Obligatoire"),
  role: Yup.string().max(50, "Trop longue!").required("Obligatoire"),
  active: Yup.boolean().required("Obligatoire"),
});

export const updateUserSchema = Yup.object().shape({
  first_name: Yup.string().max(50, "Trop longue!").required("Obligatoire"),
  last_name: Yup.string().max(50, "Trop longue!").required("Obligatoire"),
  // username: Yup.string().max(50, "Trop longue!").required("Obligatoire"),
  phone: Yup.string()
    .matches(phoneRegExp, "Numero de telephone non valide!")
    .required("Obligatoire"),
  
  password: Yup.string().min(8).when('updatePassword', {
    is: true, // This condition checks if updatePassword is true
    then: Yup.string().required("Obligatoire"), // If true, password is required
    otherwise: Yup.string().notRequired(), // If false, password is not required
  }),
  confirme_password: Yup.string()
    .min(8)
    .oneOf(
      [Yup.ref("password")],
      "Le mot de passe et le mot de passe de confirmation ne correspondent pas"
    ).when('updatePassword', {
      is: true, // This condition checks if updatePassword is true
      then: Yup.string().required("Obligatoire"), // If true, password is required
      otherwise: Yup.string().notRequired(), // If false, password is not required
    }),
  email: Yup.string().email().required("Obligatoire"),
  address: Yup.string().min(8, "Too Short!").max(100, "Trop longue!").required("Obligatoire"),
  city: Yup.string().max(100, "Trop longue!").required("Obligatoire"),
  role: Yup.string().max(50, "Trop longue!").required("Obligatoire"),
  active: Yup.boolean().required("Obligatoire"),
});

import React, { useEffect } from "react";
import MDBox from "components/MDBox";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../countryProfiles/redux/state/CountryProfilesAction";
import { Field } from "formik";
import { useParams } from "react-router-dom";


// Function to format numbers with spaces
function formatNumberWithSpaces(input) {
  if (isNaN(input)) {
     return input;
  }
  const roundedNum = Math.round(input);
  const numStr = roundedNum.toString();
  const formattedStr = numStr.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return formattedStr;
 }
 

export default function Data() {
  // const dispatch = useDispatch();

  //get country profile by name
  // useEffect(() => {
  //   dispatch(actions.getCountryProfiles({companyId:companyId}));
  // }, []);

  const countryProfiles = useSelector((state) => state?.companies?.company?.country_profiles) ?? [];

  //index of rows to remove
  const filterKeys = [0, 12, 13, 14, 15, 16, 17, 18];

  //convert array to object
  function toObject(arr) {
    var obj = {};
    for (var i = 0; i < arr.length; ++i) if (arr[i] !== undefined) obj[years[i]] = arr[i];
    return obj;
  }

  //pivot data (table)
  function transpose(data) {
    let dt = [];
    if (data?.length > 0) {
      dt = Object.keys(data[0])?.map((colNumber) =>
        toObject(data?.map((rowNumber) => formatNumberWithSpaces(rowNumber[colNumber])))
      );
    }
    return dt;
  }


  const rowsHeader = [
    "Population (millions)", 
    "PIB à taux fixe (billions)", 
    "Taux de croissance réel du PIB (%)", 
    "PIB par tête à taux fixe", 
    "Taux d'inflation (%)", 
    "Equilibre budgétaire (% du PIB)", 
    "Dette publique (% du PIB)", 
    "Chômage (% des actifs)", 
    "Balance des paiements courants (% du PIB)", 
    "Balance commerciale (% du PIB)", 
    "Réserves internationales (billions)"
  ];

  //get years
  const years = countryProfiles?.map((countryProfile, i) => countryProfile.year);

  //pivote countryProfiles data
  const pivot = transpose(countryProfiles).filter((el, i) => !filterKeys.some((j) => i === j));


  //years colums
  const yearsColumn = years?.map((year, i) => ({
    Header: year.toString(),
    accessor: year.toString(),
    align: "right",
  }));

  //merge years column with static column annee
  const columns = [{ Header: "Annee", accessor: "year", align: "left" }].concat(yearsColumn);

  //map over data pivot
  let rows = pivot?.map((p, i) => (
    singleRow(p, i)
  ));

  //map over years
  function singleRow(p, i){
    const obj = {}
    years.map((year)=>
      obj[year] = p[year]
    )
    return {year: rowsHeader[i], ...obj}
  }

  console.log(rows)

  return {
    columns: columns,
    rows: rows,
  };
}

import { Button, Card, Grid, Icon, Pagination, Stack, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
// Data
import directorsTableData from "layouts/directors/data/directorsTableData";
import DataTable from "examples/Tables/DataTable";
import { Box } from "@mui/system";
import MDButton from "components/MDButton";
import * as modalActions from "./modalActionTypes";
import FormAddDirector from "./components/FormAddDirector";
import DirectorModal from "./components/DirectorModal";
import FormUpdateDirector from "./components/FormUpdateDirector";
import ConfirmDeleteDirector from "./components/ConfirmDeleteDirector";
import FormUploadDirector from "./components/FormUploadDirector";
import { Link, useLocation } from "react-router-dom";
import { useMaterialUIController } from "context";
import tabs from "layouts/rapport/tabs";
import { setLayout } from "context";

function Directors() {
  const location = useLocation();
  const [, dispatch] = useMaterialUIController();
  const { pathname } = useLocation();
  const [page, setPage] = useState(1);

  useEffect(() => {
    setLayout(dispatch, location?.state?.id ? "page" : "dashboard");
  }, [pathname]);
  const changePage=(event, value)=>{
    console.log(value);
    setPage(old=>value)
  }

  const [directorId, setDirectorId] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [action, setAction] = useState(modalActions.ADD);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setAction(modalActions.ADD);
    setDirectorId("");
    setOpen(true);
  };
  const handleOpenUpload = () => {
    setCompanyId(location?.state?.id ? location?.state?.id : "");
    setAction(modalActions.UPLOAD);
    setOpen(true);
  };
  const { columns, rows, data } = directorsTableData(setOpen, setDirectorId, setAction, page);

  return (
    <DashboardLayout hide={location?.state?.id}>
      <DashboardNavbar />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
          {location?.state?.id ? (
            <Typography color="info" variant="body2" fontWeight="bold">
              <Button
                size="small"
                component={Link}
                to={`/rapport/${location?.state?.slug}`}
                state={{
                  rapportTab: tabs.find((tab) => tab.key == "directeurs").index,
                }}
              >
                <Icon fontSize="large">arrow_back</Icon> Retour a la page rapport
              </Button>
            </Typography>
          ) : (
            ""
          )}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box mr={1}>
            <MDButton onClick={handleOpen} variant="contained" size="small" color="info">
              <Icon>add</Icon> Directeur
            </MDButton>
          </Box>
          {/* <Box mr={1}>
            <MDButton to="#" variant="contained" size="small" color="success">
              Exporter <Icon>downloadfile</Icon>
            </MDButton>
          </Box> */}
        </Box>
        {location?.state?.id ? (
          <Box mr={1}>
            <MDButton onClick={handleOpenUpload} variant="contained" size="small" color="success">
              Importer <Icon>uploadfile</Icon>
            </MDButton>
          </Box>
        ) : (
          ""
        )}
      </Box>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Directeurs
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {rows && 
                <>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                  pagination={false}
                />
                <Box sx={{ mt: 2}} style={{ paddingBottom:"20px", paddingLeft:"20px" }}>
                    <Stack spacing={2}>
                        <Pagination
                        count={data.last_page} 
                        variant="outlined" 
                        shape="rounded"
                        page={page} 
                        onChange={changePage}
                        />
                    </Stack>
                </Box>
                </>}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <DirectorModal
        title={
          action == modalActions.UPDATE
            ? "Modifier directeur"
            : action == modalActions.DELETE
            ? "Supprimer directeur"
            : action == modalActions.UPLOAD
            ? "Importe directeurs"
            : "Ajouter nouveau directeur"
        }
        open={open}
        setOpen={setOpen}
      >
        {/* ADD MODAL */}
        {action == modalActions.ADD && (
          <FormAddDirector open={open} setOpen={setOpen} setAction={setAction} />
        )}
        {/* DELETE MODAL */}
        {action == modalActions.DELETE && (
          <ConfirmDeleteDirector
            setOpen={setOpen}
            directorId={directorId}
            setDirectorId={setDirectorId}
            setAction={setAction}
          />
        )}
        {/* UPDATE MODAL */}
        {action == modalActions.UPDATE && (
          <FormUpdateDirector
            setOpen={setOpen}
            directorId={directorId}
            setDirectorId={setDirectorId}
            setAction={setAction}
          />
        )}
        {/* UPDATE MODAL */}
        {action == modalActions.UPLOAD && (
          <FormUploadDirector setOpen={setOpen} setAction={setAction} companyId={companyId} />
        )}
      </DirectorModal>
    </DashboardLayout>
  );
}
export default Directors;

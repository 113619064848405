import { Box, Button, ButtonGroup, Icon, Typography, Card, Grid } from "@mui/material";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PhoneIcon from "@mui/icons-material/Phone";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import tabs from "./tabs";
import { grey } from "@mui/material/colors";
import MDButton from "components/MDButton";
import { height } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import CompteResultatBilans from "./pages/compteResultatBilans";
import * as actionsCompanies from "../companies/redux/state/CompaniesAction";
import CompteDeResultatBilans from "./pages/compteDeResultatBilans";
import MemoBilans from "./pages/memoBilans";
import ActifBilans from "./pages/actifBilans";
import PassifBilans from "./pages/passifBilans";
import styled from "@emotion/styled";
import { useMaterialUIController } from "context";
import rapportTabs from "layouts/rapport/tabs";
import { setLayout } from "context";

function Bilans() {
  const location = useLocation();
  const [, dispatch] = useMaterialUIController();
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, location?.state?.id ? "page" : "dashboard");
  }, [pathname]);

  const [toggleState, setToggleState] = useState(0);
  const [companyId, setCompanyId] = useState(location?.state?.id ?? "");

  const dispatchRedux = useDispatch();
  // order select companies
  const orderCompanies = {
    sort: "raison_social",
    order: "asc",
  };

  useEffect(() => {
    dispatchRedux(actionsCompanies.getCompanies(orderCompanies));
  }, []);

  useEffect(() => {
    console.log(companyId);
  }, [companyId]);

  const toggleTab = (index) => {
    console.log(index);
    setToggleState(index);
  };

  const TabsTypography = styled("p")(({ theme }) => ({
    padding: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      fontSize: "8px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "12px",
    },
  }));

  const renderTabs = tabs.map(({ name, icon, title, noCollapse, key, href, route }, i) => {
    let returnValue;

    returnValue = (
      <Button
        sx={{ minHeight: "50px", width: 1, display: "block", padding: "auto" }}
        key={key}
        color="white"
        // icon={icon}
        // className={toggleState === i ? "info" : "info"}
        variant={toggleState === i ? "contained" : ""}
        onClick={() => toggleTab(i)}
      >
        {/* <Box sx={{ display:"block", padding:"auto" }}> */}
        <Box>{icon}</Box>
        <Box>
          <TabsTypography>{name}</TabsTypography>
        </Box>
        {/* </Box> */}
      </Button>
    );

    return returnValue;
  });

  console.log("togle state",toggleState);
  return (
    <DashboardLayout hide={location?.state?.id}>
      <DashboardNavbar />
      {/* <Box sx={{ display: "flex" }}> */}
      {/* <Box
          sx={{
            width: '100%',
          }}
        > */}
      <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
        {location?.state?.id ? (
          <Typography color="info" variant="body2" fontWeight="bold">
            <Button
              size="small"
              component={Link}
              to={`/rapport/${location?.state?.slug}`}
              state={{
                rapportTab: rapportTabs.find((tab) => tab.key == "bilan").index,
              }}
            >
              <Icon fontSize="large">arrow_back</Icon> Retour a la page rapport
            </Button>
          </Typography>
        ) : (
          ""
        )}
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider", overflowY: "scroll" }}>
        {/* <Box className="bloc-tabs"> */}
        <ButtonGroup variant="outlined" aria-label="outlined button group">
          {renderTabs}
        </ButtonGroup>
        {/* </Box> */}
      </Box>
      <Box
        sx={{
          padding: "5px",
          border: 1,
          borderColor: "divider",
          backgroundColor: grey[50],
          borderRadius: "5px",
          minHeight: "515px",
          marginTop: "10px",
        }}
      >
        {toggleState === 0 && (
          <Box index={0}>
            <CompteResultatBilans companyId={companyId} setCompanyId={setCompanyId} />
          </Box>
        )}
        {toggleState === 1 && (
          <Box index={1}>
            <CompteDeResultatBilans companyId={companyId} setCompanyId={setCompanyId} />
          </Box>
        )}
        {toggleState === 2 && (
          <Box index={2}>
            <MemoBilans companyId={companyId} setCompanyId={setCompanyId} />
          </Box>
        )}
        {toggleState === 3 && (
          <Box index={3}>
            <ActifBilans companyId={companyId} setCompanyId={setCompanyId} />
          </Box>
        )}
        {toggleState === 4 && (
          <Box index={4}>
            <PassifBilans companyId={companyId} setCompanyId={setCompanyId} />
          </Box>
        )}
      </Box>
      {/* </Box>
      </Box> */}
    </DashboardLayout>
  );
}

export default Bilans;

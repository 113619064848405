import React, { useEffect } from "react";
import MDTypography from "components/MDTypography";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/state/ShareholdingsAction";
import * as modalActions from "../modalActionTypes";
import MDButton from "components/MDButton";
import { Box } from "@mui/system";
import { Icon, IconButton, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";

export default function Data(setOpen, setShareholdingId, setAction) {
  const dispatch = useDispatch();
  const location = useLocation();
  let data = {};
  useEffect(() => {
    if (location?.state?.id) {
      data = { companyId: location?.state?.id };
    }
    dispatch(actions.getShareholdings(data));
  }, []);

  const handleOpenEdit = (id) => {
    setOpen(true);
    setShareholdingId(id);
    setAction(modalActions.UPDATE);
  };

  const handleOpenDelete = (id) => {
    setOpen(true);
    setShareholdingId(id);
    setAction(modalActions.DELETE);
  };

  const shareholdings = useSelector((state) => state?.shareholdings?.shareholdings);
  const shareholdingsRows = shareholdings && shareholdings?.data?.map((shareholding, i) => ({
    index: i + 1,
    nom: shareholding.name,
    type: shareholding.type,
    act_direct: shareholding.act_direct,
    act_total: shareholding.act_total,
    op_rev: shareholding.op_rev,
    source: shareholding.source,
    date: shareholding.date,
    country: shareholding.country?.name,
    // ?? shareholding.country_id,
    created_at: new Date(shareholding.created_at)?.toLocaleDateString(),
    updated_at: new Date(shareholding.updated_at)?.toLocaleDateString(),
    action: (
      // <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      //   Edit
      // </MDTypography>

      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box>
          <IconButton
            color="info"
            onClick={() => handleOpenEdit(shareholding.id)}
            component="label"
          >
            <Icon color="info" fontSize="small">
              mode
            </Icon>
          </IconButton>
        </Box>
        <Box>
          <IconButton
            color="primary"
            onClick={() => handleOpenDelete(shareholding.id)}
            component="label"
          >
            <Icon color="primary" fontSize="small">
              delete
            </Icon>
          </IconButton>
        </Box>
      </Box>
    ),
  }));
  return {
    columns: [
      { Header: "#", accessor: "index", width: "10px", align: "left" },
      { Header: "nom", accessor: "nom", align: "left" },
      { Header: "type", accessor: "type", align: "left" },
      { Header: "act_direct", accessor: "act_direct", align: "left" },
      { Header: "act_total", accessor: "act_total", align: "left" },
      { Header: "op_rev", accessor: "op_rev", align: "left" },
      { Header: "source", accessor: "source", align: "left" },
      { Header: "date", accessor: "date", align: "left" },
      { Header: "country", accessor: "country", align: "left" },
      { Header: "Date d'ajout", accessor: "created_at", align: "left" },
      { Header: "Date de moodification", accessor: "updated_at", align: "left" },
      { Header: "action", accessor: "action", align: "left"},
    ],

    rows: shareholdingsRows,
  };
}

import { instance } from "../../utils/Axios";

export const getPassifBilansApi = (data = {}) => {
  return instance
    .get(
      `/passifBilans?company_id=${data.companyId ?? ""}&sort=${data.sort ?? ""}&order=${
        data.order ?? ""
      }`,
      data
    )
    .then((response) => {
      return response;
    });
};
export const addPassifBilanApi = (data) => {
  return instance.post("/passifBilans", data).then((response) => {
    return response;
  });
};

export const editPassifBilanApi = (id) => {
  return instance.get(`/passifBilans/${id}`).then((response) => {
    return response;
  });
};

export const updatePassifBilanApi = (data) => {
  return instance.put(`/passifBilans/${data.id}`, data).then((response) => {
    return response;
  });
};

export const deletePassifBilanApi = (data) => {
  console.log("delete data", data);
  return instance.delete(`/passifBilans/${data.id}`).then((response) => {
    return response;
  });
};

import * as ActionsTypes from "../../../../common/state/StatesConstants";

// ##-----------GET_DIRECTORS-----------##
export const getDirectors = (data) => {
  return { type: ActionsTypes.GET_DIRECTORS, payload: data };
};

export const getDirectorsSuccess = (response) => {
  return { type: ActionsTypes.GET_DIRECTORS_SUCCESS, response };
};

export const getDirectorsFail = (error) => {
  return { type: ActionsTypes.GET_DIRECTORS_FAIL, error };
};
// ##-----------END_GET_DIRECTORS-----------##

// ##-----------ADD_DIRECTOR-----------##
export const addDirector = (data) => {
  return { type: ActionsTypes.ADD_DIRECTOR, payload: data };
};
export const addDirectorSuccess = (response) => {
  return { type: ActionsTypes.ADD_DIRECTOR_SUCCESS, response };
};
export const addDirectorFail = (error) => {
  return { type: ActionsTypes.ADD_DIRECTOR_FAIL, error };
};

// ##-----------UPLOAD_DIRECTOR-----------##
export const uploadDirector = (data) => {
  return { type: ActionsTypes.UPLOAD_DIRECTOR, payload: data };
};

export const uploadDirectorSuccess = (response) => {
  return { type: ActionsTypes.UPLOAD_DIRECTOR_SUCCESS, response };
};

export const uploadDirectorFail = (error) => {
  return { type: ActionsTypes.UPLOAD_DIRECTOR_FAIL, error };
};

// ##-----------END_ADD_DIRECTOR-----------##

// ##-----------EDIT_DIRECTOR-----------##
export const editDirector = (data) => {
  return { type: ActionsTypes.EDIT_DIRECTOR, payload: data };
};

export const editDirectorSuccess = (response) => {
  return { type: ActionsTypes.EDIT_DIRECTOR_SUCCESS, response };
};

export const editDirectorFail = (error) => {
  return { type: ActionsTypes.EDIT_DIRECTOR_FAIL, error };
};
// ##-----------END_EDIT_DIRECTOR-----------##

// ##-----------UPDATE_DIRECTOR-----------##
export const updateDirector = (data) => {
  return { type: ActionsTypes.UPDATE_DIRECTOR, payload: data };
};

export const updateDirectorSuccess = (response) => {
  return { type: ActionsTypes.UPDATE_DIRECTOR_SUCCESS, response };
};

export const updateDirectorFail = (error) => {
  return { type: ActionsTypes.UPDATE_DIRECTOR_FAIL, error };
};
// ##-----------END_UPDATE_DIRECTOR-----------##

// ##-----------DELETE_DIRECTOR-----------##
export const deleteDirector = (data) => {
  return { type: ActionsTypes.DELETE_DIRECTOR, payload: data };
};

export const deleteDirectorSuccess = (response) => {
  return { type: ActionsTypes.DELETE_DIRECTOR_SUCCESS, response };
};

export const deleteDirectorFail = (error) => {
  return { type: ActionsTypes.DELETE_DIRECTOR_FAIL, error };
};
// ##-----------END_DELETE_DIRECTOR-----------##

import { Box, Card, Grid, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import DataTable from "examples/Tables/DataTable";
import React from "react";
import actifBilansTableData from "./../data/actifBilansTableData";

function ActifBilanTable() {
  const { columns, rows } = actifBilansTableData();
  return (
    <MDBox py={2}>
      <Typography py={2} px={1} variant="h5">
        Actif Bilan
      </Typography>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox pt={3}>
              <DataTable
                table={{ columns, rows }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
                noEndBorder
              />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default ActifBilanTable;

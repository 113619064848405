import { Button, Card, Grid, Icon, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
// Data
import financialProfilesTableData from "layouts/financialProfiles/data/financialProfilesTableData";
import DataTable from "examples/Tables/DataTable";
import { Box } from "@mui/system";
import MDButton from "components/MDButton";
import * as modalActions from "./modalActionTypes";
import FormAddFinancialProfile from "./components/FormAddFinancialProfile";
import FinancialProfileModal from "./components/FinancialProfileModal";
import FormUpdateFinancialProfile from "./components/FormUpdateFinancialProfile";
import ConfirmDeleteFinancialProfile from "./components/ConfirmDeleteFinancialProfile";
import { Link, useLocation } from "react-router-dom";
import { useMaterialUIController } from "context";
import tabs from "layouts/rapport/tabs";
import { setLayout } from "context";

function FinancialProfiles() {
  const location = useLocation();
  const [, dispatch] = useMaterialUIController();
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, location?.state?.id ? "page" : "dashboard");
  }, [pathname]);

  const [financialProfileId, setFinancialProfileId] = useState("");
  const [action, setAction] = useState(modalActions.ADD);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setAction(modalActions.ADD);
    setFinancialProfileId("");
    setOpen(true);
  };
  const { columns, rows } = financialProfilesTableData(setOpen, setFinancialProfileId, setAction);

    return (
      <DashboardLayout hide={location?.state?.id}>
        <DashboardNavbar />
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            {location?.state?.id ? (
              <Typography color="info" variant="body2" fontWeight="bold">
                <Button
                  size="small"
                  component={Link}
                  to={`/rapport/${location?.state?.slug}`}
                  state={{
                    rapportTab: tabs.find((tab) => tab.key == "profile-financier").index,
                  }}
                >
                  <Icon fontSize="large">arrow_back</Icon> Retour a la page rapport
                </Button>
              </Typography>
            ) : (
              ""
            )}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Box mr={1}>
              <MDButton onClick={handleOpen} variant="contained" size="small" color="info">
                <Icon>add</Icon> Profile financier
              </MDButton>
            </Box>
            <Box mr={1}>
              <MDButton to="#" variant="contained" size="small" color="success">
                Exporter <Icon>downloadfile</Icon>
              </MDButton>
            </Box>
          </Box>
        </Box>
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Profiles financier
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <FinancialProfileModal
          title={
            action == modalActions.UPDATE
              ? "Modifier profile financier"
              : action == modalActions.DELETE
              ? "Supprimer profile financier"
              : "Ajouter nouveau profile financier"
          }
          open={open}
          setOpen={setOpen}
        >
          {/* ADD MODAL */}
          {action == modalActions.ADD && (
            <FormAddFinancialProfile open={open} setOpen={setOpen} setAction={setAction} />
          )}
          {/* DELETE MODAL */}
          {action == modalActions.DELETE && (
            <ConfirmDeleteFinancialProfile
              setOpen={setOpen}
              financialProfileId={financialProfileId}
              setFinancialProfileId={setFinancialProfileId}
              setAction={setAction}
            />
          )}
          {/* UPDATE MODAL */}
          {action == modalActions.UPDATE && (
            <FormUpdateFinancialProfile
              setOpen={setOpen}
              financialProfileId={financialProfileId}
              setFinancialProfileId={setFinancialProfileId}
              setAction={setAction}
            />
          )}
        </FinancialProfileModal>
      </DashboardLayout>
    );
}
export default FinancialProfiles;

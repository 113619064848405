import { instance } from "../../utils/Axios";

export const getCountryProfilesApi = (data) => {
  console.log(data)
  return instance.get(`/countriesprofile?name=${data?.countrySlug?? ""}&company_id=${data?.companyId?? ""}`).then((response) => {
    return response;
  });
};

export const addCountryProfileApi = (data) => {
  return instance.post("/countriesprofile", data).then((response) => {
    return response;
  });
};

export const editCountryProfileApi = (id) => {
  return instance.get(`/countriesprofile/${id}`).then((response) => {
    return response;
  });
};

export const updateCountryProfileApi = (data) => {
  return instance.put(`/countriesprofile/${data.id}`, data).then((response) => {
    return response;
  });
};

export const deleteCountryProfileApi = (data) => {
  return instance.delete(`/countriesprofile/${data.id}`).then((response) => {
    return response;
  });
};
import * as ActionsTypes from "../../../../../../common/state/StatesConstants";

// ##-----------ADD_INFORMATION_COMPANY-----------##
export const addInformationCompany = (data) => {
  return { type: ActionsTypes.ADD_INFORMATION_COMPANY, payload: data };
};

export const addInformationCompanySuccess = (response) => {
  return { type: ActionsTypes.ADD_INFORMATION_COMPANY_SUCCESS, response };
};

export const addInformationCompanyFail = (error) => {
  return { type: ActionsTypes.ADD_INFORMATION_COMPANY_FAIL, error };
};
// ##-----------END_ADD_INFORMATION_COMPANY-----------##

// ##-----------EDIT_INFORMATION_COMPANY-----------##
export const editInformationCompany = (data) => {
  return { type: ActionsTypes.EDIT_INFORMATION_COMPANY, payload: data };
};

export const editInformationCompanySuccess = (response) => {
  return { type: ActionsTypes.EDIT_INFORMATION_COMPANY_SUCCESS, response };
};

export const editInformationCompanyFail = (error) => {
  return { type: ActionsTypes.EDIT_INFORMATION_COMPANY_FAIL, error };
};
// ##-----------END_EDIT_INFORMATION_COMPANY-----------##

import { Alert, Box, Chip, Divider, FormControl, Grid, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { Form, Formik, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/state/CountriesAction";
import * as modalActions from "../modalActionTypes";
import { addCountrySchema } from "../schema";
import { useNavigate } from "react-router-dom";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import { grey } from "@mui/material/colors";
import MDSnackbar from "components/MDSnackbar";
import { getErrorMessage } from "common/utils/Helper";

export default function ConfirmCountry({ setOpen, countryId, setCountryId, setAction }) {
  const dispatch = useDispatch();

  const [errorSB, setErrorSB] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  useEffect(() => {
    dispatch(actions.editCountry(countryId));
  }, []);

  const country = useSelector((state) => state?.countries?.country);
  const contryErrorStatus = useSelector((state) => state?.countries?.error?.response?.status);

  const closeModal = () => {
    setAction(modalActions.ADD);
    setCountryId("");
    setOpen(false);
  };

  const onSubmit = (values) => {
    const request = {
      payload: values,
      successCallBack: (response) => {
        closeErrorSB();
        closeModal();
        dispatch(actions.getCountries());
        console.log("response", response);
      },
      failCallBack: (error) => {
        console.log("error", error);
        setErrorMessage(getErrorMessage(error, contryErrorStatus));
        openErrorSB();
      },
    };
    dispatch(actions.deleteCountry(request));
  };

  // const navigate = useNavigate();
  const { handleSubmit, touched } = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: country?.id,
      name: country?.name,
    },
    onSubmit,
  });
  return (
    <MDBox my={1}>
      {errorSB && (
        <Typography textAlign="center" color="error" variant="body2">
          {errorMessage} {" < Echec de suppresion >"}
        </Typography>
      )}
      <form onSubmit={handleSubmit}>
        <MDBox py={1}>
          <Alert severity="error">Etes vous sure de vouloir supprimer - {country?.name}</Alert>
        </MDBox>
        <Divider />
        <Box mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box mr={1}>
            <MDButton type="submit" variant="contained" size="small" color="error">
              Supprimer
            </MDButton>
          </Box>
          <Box mr={1}>
            <MDButton
              size="small"
              onClick={closeModal}
              style={{ background: grey[500], color: "white" }}
            >
              Annuler
            </MDButton>
          </Box>
        </Box>
      </form>
    </MDBox>
  );
}

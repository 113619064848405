import { instance } from "../../utils/Axios";

export const getUsersApi = () => {
  return instance.get("/users").then((response) => {
    return response;
  });
};
export const addUserApi = (data) => {
  return instance.post("/users", data).then((response) => {
    return response;
  });
};
export const deleteUserApi = (data) => {
  return instance.delete(`/users/${data.id}`).then((response) => {
    return response;
  });
};
export const editUserApi = (id) => {
  return instance.get(`/users/${id}`).then((response) => {
    return response;
  });
};
export const updateUserApi = (data) => {
  return instance.put(`/users/${data.id}`, data).then((response) => {
    return response;
  });
};

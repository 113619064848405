import CountriesWatcher from "../../layouts/countries/redux/saga/CountriesWatcher";
import UsersWatcher from "../../layouts/users/redux/saga/UsersWatcher";
import AuthWatcher from "../../layouts/authentication/redux/saga/AuthWatcher";
import CompaniesWatcher from "../../layouts/companies/redux/saga/CompaniesWatcher";
import MissingCompaniesWatcher from "../../layouts/missingCompanies/redux/saga/MissingCompaniesWatcher";
import CountryProfilesWatcher from "../../layouts/countryProfiles/redux/saga/CountryProfilesWatcher";
import ShareholdingsWatcher from "../../layouts/shareholdings/redux/saga/ShareholdingsWatcher";
import DirectorsWatcher from "../../layouts/directors/redux/saga/DirectorsWatcher";
import SearchWatcher from "../../layouts/search/redux/saga/SearchWatcher";
import CompteResultatBilansWatcher from "../../layouts/bilans/pages/compteResultatBilans/redux/saga/CompteResultatBilansWatcher";
import CompteDeResultatBilansWatcher from "../../layouts/bilans/pages/compteDeResultatBilans/redux/saga/CompteDeResultatBilansWatcher";
import ProductServicesWatcher from "layouts/productServices/redux/saga/ProductServicesWatcher";
import MemoBilansWatcher from "layouts/bilans/pages/memoBilans/redux/saga/MemoBilansWatcher";
import FinancialProfilesWatcher from "layouts/financialProfiles/redux/saga/FinancialProfilesWatcher";
import ActifBilansWatcher from "layouts/bilans/pages/actifBilans/redux/saga/CompteDeResultatBilansWatcher";
import PassifBilansWatcher from "layouts/bilans/pages/passifBilans/redux/saga/PassifBilansWatcher";

import { fork } from "redux-saga/effects";
import InformationCompanysWatcher from "layouts/rapport/pages/informations/redux/saga/InformationCompanyWatcher";

export default function* rootSaga() {
  yield fork(CountriesWatcher);
  yield fork(UsersWatcher);
  yield fork(AuthWatcher);
  yield fork(CompaniesWatcher);
  yield fork(MissingCompaniesWatcher);
  yield fork(CountryProfilesWatcher);
  yield fork(ShareholdingsWatcher);
  yield fork(DirectorsWatcher);
  yield fork(SearchWatcher);
  yield fork(CompteResultatBilansWatcher);
  yield fork(CompteDeResultatBilansWatcher);
  yield fork(ProductServicesWatcher);
  yield fork(MemoBilansWatcher);
  yield fork(FinancialProfilesWatcher);
  yield fork(ActifBilansWatcher);
  yield fork(PassifBilansWatcher);
  yield fork(InformationCompanysWatcher);
}

import { instance } from "../../utils/Axios";

export const addInformationCompanysApi = (data) => {
  return instance.post("/infoCompanies", data).then((response) => {
    return response;
  });
};

export const editInformationCompanyApi = (id) => {
  return instance.get(`/infoCompanies/${id}`).then((response) => {
    return response;
  });
};

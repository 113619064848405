import {
  Alert,
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateCompanySchema } from "../schema";
import * as actions from "../redux/state/CompaniesAction";
import * as actionsCountries from "../../countries/redux/state/CountriesAction";
import * as modalActions from "../modalActionTypes";
import * as actionsProductServices from "../../productServices/redux/state/ProductServicesAction";
import * as actionsShareholdings from "../../shareholdings/redux/state/ShareholdingsAction";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import dayjs from "dayjs";
import { getErrorMessage } from "common/utils/Helper";
import * as Devises from "common/staticData/Devises";

const isActive = ["inactive","active"];
export default function FormUpdateCompany({ setOpen, companyId, setCompanyId, setAction }) {
  const dispatch = useDispatch();

  const [errorSB, setErrorSB] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  // order select countries
  const orderCountries = {
    sort: "name",
    order: "asc",
  };
  // order select productServices
  const orderProductServices = {
    sort: "name",
    order: "asc",
  };
  // order select shareholdings
  const orderSharholdings = {
    sort: "name",
    order: "asc",
  };
  useEffect(() => {
    dispatch(actionsProductServices.getProductServices(orderProductServices));
    dispatch(actionsShareholdings.getShareholdings(orderSharholdings));
    dispatch(actionsCountries.getCountries(orderCountries));
    dispatch(actions.editMissingCompany(companyId));
  }, []);

  // const getIdsObject = (tables) => {
  //   console.log("tables",tables)
  //   return tables.map((table) => {
  //     return table.id;
  //   });
  // };

  
  const devises = Devises.devises;
  Object.keys(devises);

  const onSubmit = (values) => {
    console.log("values :::: ", values)
    const request = {
      payload: values,
      successCallBack: (response) => {
        closeErrorSB()
        dispatch(actions.getMissingCompanies());
        setAction(modalActions.ADD);
        setCompanyId("");
        setOpen(false);
        console.log("response", response);
      },
      failCallBack: (error) => {
        console.log("error", error);
        setErrorMessage(getErrorMessage(error))
        openErrorSB();
      },
    };
    console.log(request);
    if(!values.shouldAdd){
      dispatch(actions.updateMissingCompany(request));
    }else{
      dispatch(actions.addMissingCompany(request));
    }
  };

  const activities = useSelector((state) => state?.productServices?.productServices);
  const shareholdings = useSelector((state) => state?.shareholdings?.shareholdings);
  const countries = useSelector((state) => state?.countries?.countries);
  const company = useSelector((state) => state?.missingCompanies?.missingCompany);
  const [value, setValue] = useState(dayjs(company?.date_creation));

  const handleClose = () => {
    setOpen(false);
  };

  console.log("company,,,",company)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      shouldAdd:false,
      id: company?.id,
      name: company?.name,
      forme_juridique: company?.forme_juridique,
      statut: company?.statut === isActive[0] ? 0 : 1,
      numero_rc: company?.numero_rc,
      website: company?.numero_rc,
      adresse_siege_social: company?.adresse_siege_social,
      city: company?.city,
      prefecture: company?.prefecture,
      type_identite: company?.type_identite,
      numero_ice: company?.numero_ice,
      country_id: company?.country?.id,
      date_creation: company?.date_creation,
      //activities: company?.product_services,
      product_service_id: company?.product_service_id,
      shareholdings: company?.shareholdings,
      devise: company?.devise,
    },
    onSubmit,
    //validationSchema: updateCompanySchema,
  });

  const { handleSubmit, errors, values, handleChange, setFieldValue } = formik;
  console.log("all activities", activities);
  console.log("company", company);
  console.log("state activities", values?.activities ?? []);
  return (
    <MDBox m={1}>
      {errorSB && (
        <Alert severity="error">
          {errorMessage}
        </Alert>
      )}
      {
        values.id!==undefined &&
        <form onSubmit={handleSubmit}>
        <MDBox py={2}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            
            <Grid item xs={12} sm={12} md={12}>
              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="shouldAdd"
                      checked={values.shouldAdd}
                      onChange={handleChange}
                    />
                  }
                  label="Ajouter l'entreprise"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="name"
                  label="raison social"
                  error={errors.name ? true : false}
                  onChange={handleChange}
                  value={values.name}
                  helperText={errors?.name ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  disabled={!values.shouldAdd}
                  name="forme_juridique"
                  label="forme juridique"
                  error={errors.forme_juridique ? true : false}
                  onChange={handleChange}
                  value={values.forme_juridique}
                  helperText={errors?.forme_juridique ?? ""}
                />
              </FormControl>
            </Grid>
            
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <InputLabel id="statut">Statut</InputLabel>
                <Select
                  disabled={!values.shouldAdd}
                  name="statut"
                  style={{ height: "40px" }}
                  labelId="state_label"
                  label="Statut"
                  error={errors.statut ? true : false}
                  onChange={handleChange}
                  value={values.statut}
                >
                  {isActive && isActive?.map((value, id) => {
                    return (
                      <MenuItem key={id} value={id}>
                        {value}
                      </MenuItem>
                    );
                  })}
                </Select>
                {errors.statut ? (
                  <FormHelperText error>{errors?.statut}</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="numero_rc"
                  label="numero rc"
                  error={errors.numero_rc ? true : false}
                  onChange={handleChange}
                  value={values.numero_rc}
                  helperText={errors?.numero_rc ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                
                disabled={!values.shouldAdd}
                  name="website"
                  label="site web"
                  error={errors.website ? true : false}
                  onChange={handleChange}
                  value={values.website}
                  helperText={errors?.website ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                
                disabled={!values.shouldAdd}
                  name="adresse_siege_social"
                  label="adresse siege social"
                  error={errors.adresse_siege_social ? true : false}
                  onChange={handleChange}
                  value={values.adresse_siege_social}
                  helperText={errors?.adresse_siege_social ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="city"
                  label="ville"
                  error={errors.city ? true : false}
                  onChange={handleChange}
                  value={values.city}
                  helperText={errors?.city ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  disabled={!values.shouldAdd}
                  name="prefecture"
                  label="prefecture"
                  error={errors.prefecture ? true : false}
                  onChange={handleChange}
                  value={values.prefecture}
                  helperText={errors?.prefecture ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  disabled={!values.shouldAdd}
                  name="type_identite"
                  label="type identite"
                  error={errors.type_identite ? true : false}
                  onChange={handleChange}
                  value={values.type_identite}
                  helperText={errors?.type_identite ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="numero_ice"
                  label="numero ice"
                  error={errors.numero_ice ? true : false}
                  onChange={handleChange}
                  value={values.numero_ice}
                  helperText={errors?.numero_ice ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                  disabled={!values.shouldAdd}
                    renderInput={(props) => <TextField {...props} />}
                    value={values.date_creation}
                    name="date_creation"
                    error={errors.date_creation ? true : false}
                    onChange={(newValue) => {
                      setFieldValue("date_creation", moment(newValue).format("YYYY-MM-DD")); // December 22nd 2022, 10:01:13 pm);
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <InputLabel id="country_id">Pays</InputLabel>
                <Select
                  disabled={!values.shouldAdd}
                  name="country_id"
                  style={{ height: "40px" }}
                  labelId="state_label"
                  label="pays"
                  error={errors.country_id ? true : false}
                  onChange={handleChange}
                  value={values.country_id}
                >
                  {countries && countries?.data?.map((country) => {
                    return (
                      <MenuItem key={country?.id} value={country?.id}>
                        {country?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                {errors.country_id ? (
                  <FormHelperText error>{errors?.country_id}</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <Autocomplete
                  disabled={!values.shouldAdd}
                  id="product_service_id"
                  options={activities && activities.data ? activities?.data : []}
                  getOptionLabel={(option) => option.name}
                  //onChange={handleChange}
                  onChange={(e, newValue) => {
                    console.log("new value:::", newValue)
                    formik.setFieldValue(
                      "product_service_id",
                      newValue.id
                    );
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={errors.product_service_id ? true : false}
                      name="product_service_id"
                      label="Activités"
                      placeholder="Activités"
                    />
                  )}
                />
                {errors.product_service_id ? (
                  <FormHelperText error>{errors?.product_service_id}</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
            
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <InputLabel id="devise">Devises</InputLabel>
                <Select
                  disabled={!values.shouldAdd}
                  name="devise"
                  style={{ height: "40px" }}
                  labelId="Devises"
                  label="Devises"
                  error={errors.devise ? true : false}
                  onChange={handleChange}
                  value={values.devise}
                >
                  {Object.entries(devises).map(([key, val]) => {
                      return (
                          <MenuItem key={key} value={key}>
                            {key}
                            {/* {`${key} ( ${val.symbol_native} )`} */}
                          </MenuItem>
                      );
                  })}

                </Select>
                {errors.devise ? (
                  <FormHelperText error>{errors?.devise}</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl fullWidth>
                <Autocomplete
                  disabled={!values.shouldAdd}
                  multiple
                  id="shareholdings"
                  options={shareholdings && shareholdings.data ? shareholdings.data :[]}
                  getOptionLabel={(option) => option.name}
                  defaultValue={[]}
                  onChange={(e, newValue) => {
                    formik.setFieldValue(
                      "shareholdings",
                      newValue.map((value) => {
                        return value.id;
                      })
                    );
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={errors.shareholdings ? true : false}
                      name="shareholdings"
                      label="Actionnaires"
                      placeholder="Actionnaires"
                    />
                  )}
                />
                {errors.shareholdings ? (
                  <FormHelperText error>{errors?.shareholdings}</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox sx={{ display: "flex" }}>
          <MDBox mr={1} sx={{ flexGrow: "1" }}>
            <MDButton onClick={handleClose} type="submit" color="primary" fullWidth>
              Annuler
            </MDButton>
          </MDBox>
          <MDBox sx={{ flexGrow: "1" }}>
            <MDButton type="submit" color="info" fullWidth>
              Modifier
            </MDButton>
          </MDBox>
        </MDBox>
      </form>

      }
    </MDBox>
  );
}

import { useEffect } from "react";
import * as ActionsTypes from "../../../../common/state/StatesConstants";

const initialState = {
  error: null,
  isLoading: false,
  search: [],
  me:undefined
};

const searchReducer = (state = initialState, action) => {
    switch (action.type) {
      // ##-----------GET_SEARCH-----------##
      case ActionsTypes.GET_SEARCH:
        return {
          ...state,
          isLoading: true,
          error: null,
          search: [],
        };
      case ActionsTypes.GET_SEARCH_SUCCESS:
        return {
          ...state,
          isLoading: false,
          error: null,
          search: action.response.data.data,
        };
      case ActionsTypes.GET_SEARCH_FAIL:
        return {
          ...state,
          isLoading: false,
          error: action.error,
          search: [],
        };
        case ActionsTypes.ME:
          return {
            ...state,
            isLoading: true,
            error: null,
            //me: null,
          };
        case ActionsTypes.ME_SUCCESS:
          return {
            ...state,
            isLoading: false,
            me: action.response.data.user,
          };
        case ActionsTypes.ME_FAIL:
          return {
            ...state,
            isLoading: false,
            error: action.error,
          };
      // ##-----------END_GET_SEARCH-----------##
  
      default:
        return state;
    }
  };
  
  export default searchReducer;
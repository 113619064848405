import * as Yup from "yup";

export const addDirectorSchema = Yup.object().shape({
  first_name: Yup.string().max(50, "Too Long!").required("Required"),
  last_name: Yup.string().max(50, "Too Long!").required("Required"),
  company_id: Yup.number().integer().required("Required"),
  associate: Yup.string().required("Required"),
  role: Yup.string().required("Required"),
});

export const updateDirectorSchema = Yup.object().shape({
  first_name: Yup.string().max(50, "Too Long!").required("Required"),
  last_name: Yup.string().max(50, "Too Long!").required("Required"),
  company_id: Yup.number().integer().required("Required"),
  associate: Yup.string().required("Required"),
  role: Yup.string().required("Required"),
});
function hasExtension(inputID, exts) {
  var fileName = document.getElementById(inputID).value;
  return new RegExp("(" + exts.join("|").replace(/\./g, "\\.") + ")$").test(fileName);
}

export const uploadDirectorSchema = Yup.object().shape({
  file: Yup.mixed().required("Obligatoire !"),
});

import axios from "axios";
import config from "./Config";

export const instance = axios.create({
  baseURL: config.url,
  validateStatus: function (status) {
    return status >= 200 && status < 400
  },
  headers: { Authorization: "Bearer " + localStorage.getItem(config.jwt_token_name) },
});

import { takeLatest } from "redux-saga/effects";

import * as ActionsTypes from "../../../../common/state/StatesConstants";
import {
  addCompanySaga,
  deleteCompanySaga,
  editCompanySaga,
  getCompaniesSaga,
  updateCompanySaga,
  get,
  getRapportSaga
} from "./CompaniesSaga";

export default function* CompaniesWatcher() {
  yield takeLatest(ActionsTypes.GET_COMPANIES, getCompaniesSaga);
  yield takeLatest(ActionsTypes.ADD_COMPANY, addCompanySaga);
  yield takeLatest(ActionsTypes.EDIT_COMPANY, editCompanySaga);
  yield takeLatest(ActionsTypes.UPDATE_COMPANY, updateCompanySaga);
  yield takeLatest(ActionsTypes.DELETE_COMPANY, deleteCompanySaga);
  yield takeLatest(ActionsTypes.DOWNLOAD_RAPPORT, getRapportSaga);
}

import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import logoAssurtrade from "assets/images/logo-assurtrade.png";
import { isAuthenticated } from "common/utils/Helper";
import AuthRoute from "router/AuthRoute";
import Rapport from "layouts/rapport";

// jwt utils
import JwtUtils from "router/JwtUtils";
import ProtectedRoute from "router/ProtectedRoute";
import { useSelector } from "react-redux";
import SpinnerCustomized from "components/SpinerCostumized";
import _ from "lodash";
import { Box } from "@mui/material";

export default function App() {
  const state = useSelector((state) => state);

  let loadingProps;
  let reducerHasLoading = _.pickBy(state, { isLoading: true });
  if (reducerHasLoading) {
    const target = _.keys(reducerHasLoading)[0];
    let nextProps = reducerHasLoading[target];
    if (target) {
      loadingProps = { ...nextProps };
    }
  }
  // const isActif = JwtUtils.isActif();
  const location = useLocation();
  const path = location.pathname;
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        if (route.key != "sign-in") {
          return (
            // <ProtectedRoute key={route.key} component={route.component}>
            <Route
              path={route.route}
              key={route.key}
              element={<ProtectedRoute roles={route.roles} />}
            >
              <Route exact path={route.route} element={route.component} key={route.key} />
            </Route>
            // </ProtectedRoute>
          );
        } else {
          return <Route exact path={route.route} element={route.component} key={route.key} />;
        }
      }
      return null;
    });

  // const rapportRoutes = ["profile-financier", "information"];

  const forbiddenRoutes = routes
    ?.map((route) => {
      let arr = [];
      if (!JwtUtils.hasAnyRole(route.roles)) {
        return route.key;
      }
    })
    .filter(function (element) {
      return element !== undefined;
    });
  console.log("forbiddenRoutes", forbiddenRoutes);

  const hiddenRoutes = ["sign-in", "profile-pays", "rapport","profiles-financier","bilans"].concat(forbiddenRoutes);
  // .filter(function (item, pos, self) {
  //   return self.indexOf(item) == pos;
  // });

  // const allHiddenRoutes = hiddenRoutes.concat(forbiddenRoutes)

  console.log("hiddenRoutes", hiddenRoutes);

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return (
    <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
      <CssBaseline />

      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            logo={logoAssurtrade}
            // brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="Assurtrade"
            routes={routes.filter((route) => !hiddenRoutes.includes(route.key))}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          {/* <Configurator /> */}
          {/* {configsButton} */}
        </>
      )}
      <Box>
        {loadingProps?.isLoading ? <SpinnerCustomized sx={{ zIndex: "2000" }} /> : <></>}
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<AuthRoute />}>
            <Route path="*" element={<Navigate to="/recherche" />} />
          </Route>
        </Routes>
      </Box>
    </ThemeProvider>
  );
}

import { takeLatest } from "redux-saga/effects";

import * as ActionsTypes from "../../../../../../common/state/StatesConstants";
import {
  addCompteResultatBilanSaga,
  deleteCompteResultatBilanSaga,
  editCompteResultatBilanSaga,
  getCompteResultatBilansSaga,
  updateCompteResultatBilanSaga,
} from "./CompteResultatBilansSaga";

export default function* CompteResultatBilansWatcher() {
  yield takeLatest(ActionsTypes.GET_COMPTE_RESULTAT_BILANS, getCompteResultatBilansSaga);
  yield takeLatest(ActionsTypes.ADD_COMPTE_RESULTAT_BILAN, addCompteResultatBilanSaga);
  yield takeLatest(ActionsTypes.EDIT_COMPTE_RESULTAT_BILAN, editCompteResultatBilanSaga);
  yield takeLatest(ActionsTypes.UPDATE_COMPTE_RESULTAT_BILAN, updateCompteResultatBilanSaga);
  yield takeLatest(ActionsTypes.DELETE_COMPTE_RESULTAT_BILAN, deleteCompteResultatBilanSaga);
}
import { instance } from "../../utils/Axios";

export const getShareholdingsApi = (data) => {
  console.log(data)
  return instance.get(`/shareholdings?company_id=${data?.companyId ?? ""}`).then((response) => {
    return response;
  });
};
export const addShareholdingApi = (data) => {
  return instance.post("/shareholdings", data).then((response) => {
    return response;
  });
};

export const editShareholdingApi = (id) => {
  return instance.get(`/shareholdings/${id}`).then((response) => {
    return response;
  });
};

export const updateShareholdingApi = (data) => {
  return instance.put(`/shareholdings/${data.id}`, data).then((response) => {
    return response;
  });
};

export const deleteShareholdingApi = (data) => {
  console.log("delete data", data);
  return instance.delete(`/shareholdings/${data.id}`).then((response) => {
    return response;
  });
};

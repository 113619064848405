import React, { useEffect } from "react";
import MDBox from "components/MDBox";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/state/PassifBilansAction";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import { Box, Icon, IconButton } from "@mui/material";
import MDButton from "components/MDButton";
import * as modalActions from "../modalActionTypes";

function formatNumberWithSpaces(input) {
  if (isNaN(input)) {
     return input;
  }
  const roundedNum = Math.round(input);
  const numStr = roundedNum.toString();
  const formattedStr = numStr.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return formattedStr;
 }

export default function Data(setOpen, setPassifBilanId, setAction, companyId) {
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(companyId);
    let queryParam = {};
    if (companyId.toString().length > 0 && companyId !== undefined) {
      queryParam = { companyId: companyId, sort: "date", order: "desc" };
    }
    dispatch(actions.getPassifBilans(queryParam));
    console.log(queryParam);
  }, [companyId]);

  const handleOpenDelete = (id) => {
    setOpen(true);
    setPassifBilanId(id);
    setAction(modalActions.DELETE);
  };
  const handleOpenEdit = (id) => {
    // console.log("edit id", id);
    setOpen(true);
    setPassifBilanId(id);
    setAction(modalActions.UPDATE);
  };

  const passifBilans = useSelector((state) => state?.passifBilans?.passifBilans);

  const usersRows = passifBilans && passifBilans?.data && passifBilans?.data?.map((passifBilan, i) => ({
    capitaux_propres:formatNumberWithSpaces(passifBilan?.capitaux_propres),
    capital: formatNumberWithSpaces(passifBilan?.capital),
    autre_capitaux_propre: formatNumberWithSpaces(passifBilan?.autre_capitaux_propre),
    passif_non_courant: formatNumberWithSpaces(passifBilan?.passif_non_courant),
    dette_long_terme: formatNumberWithSpaces(passifBilan?.dette_long_terme),
    autre_dette_long_terme: formatNumberWithSpaces(passifBilan?.autre_dette_long_terme),
    provision_risque_charge: formatNumberWithSpaces(passifBilan?.provision_risque_charge),
    dette_court_terme: formatNumberWithSpaces(passifBilan?.dette_court_terme),
    dette_ct_emprunt: formatNumberWithSpaces(passifBilan?.dette_ct_emprunt),
    dette_ct_fournisseur: formatNumberWithSpaces(passifBilan?.dette_ct_fournisseur),
    autre_dette_ct: formatNumberWithSpaces(passifBilan?.autre_dette_ct),
    total_passif: formatNumberWithSpaces(passifBilan?.total_passif),
    date: passifBilan?.date,
    company: passifBilan?.company?.raison_social,

    action: (
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box>
          <IconButton color="info" onClick={() => handleOpenEdit(passifBilan.id)} component="label">
            <Icon color="info" fontSize="small">
              mode
            </Icon>
          </IconButton>
        </Box>
        <Box>
          <IconButton
            color="primary"
            onClick={() => handleOpenDelete(passifBilan.id)}
            component="label"
          >
            <Icon color="primary" fontSize="small">
              delete
            </Icon>
          </IconButton>
        </Box>
      </Box>
    ),
}));

  return {
    columns: [
      // { Header: "Pays", accessor: "country", align: "left" },
      { Header: "date", accessor: "date", align: "left" },
      { Header: "Entreprise", accessor: "company", align: "left" },
      { Header: "CAPITAUX PROPRES", accessor: "capitaux_propres", align: "right" },
      { Header: "Capital", accessor: "capital", align: "right" },
      { Header: "Autre capitaux propre", accessor: "autre_capitaux_propre", align: "right" },
      {
        Header: "Passif non courant",
        accessor: "passif_non_courant",
        align: "right",
      },
      {
        Header: "Dette long terme",
        accessor: "dette_long_terme",
        align: "right",
      },
      { Header: "Autre dette long terme", accessor: "autre_dette_long_terme", align: "right" },
      { Header: "Provision risque charge", accessor: "provision_risque_charge", align: "right" },
      { Header: "Dette court terme", accessor: "dette_court_terme", align: "right" },
      { Header: "Dette ct emprunt", accessor: "dette_ct_emprunt", align: "right" },
      { Header: "Dette ct fournisseur", accessor: "dette_ct_fournisseur", align: "right" },
      { Header: "Autre dette ct", accessor: "autre_dette_ct", align: "right" },
      { Header: "Totals de l'passif", accessor: "total_passif", align: "right" },
      { Header: "Action", accessor: "action", align: "center" },
    ],

    rows: usersRows,
  };
}

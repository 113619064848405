import * as ActionsTypes from "../../../../common/state/StatesConstants";

// ##-----------LOGIN_USER-----------##
export const loginUser = (data) => {
  return { type: ActionsTypes.LOGIN_USER, payload: data };
};

export const loginUserSuccess = (response) => {
  return { type: ActionsTypes.LOGIN_USER_SUCCESS, response };
};

export const loginUserFail = (error) => {
  return { type: ActionsTypes.LOGIN_USER_FAIL, error };
};
// ##-----------END_LOGIN_USER-----------##
import { put, call, delay } from "redux-saga/effects";
import * as actions from "../state/ShareholdingsAction";
import {
  getShareholdingsApi,
  addShareholdingApi,
  editShareholdingApi,
  updateShareholdingApi,
  deleteShareholdingApi,
} from "../../../../common/services/Apis/shareholdingsApi";

export function* getShareholdingsSaga(action) {
  try {
    const response = yield call(getShareholdingsApi, action.payload);
    yield put(actions.getShareholdingsSuccess(response));
  } catch (error) {
    yield put(actions.getShareholdingsFail(error));
  }
}
export function* addShareholdingSaga(action) {
  try {
    const response = yield call(addShareholdingApi, action?.payload?.payload);
    if (response) {
      yield put(actions.addShareholdingSuccess(response));
      yield call(action.payload.successCallBack, response);
    }
  } catch (error) {
    yield put(actions.addShareholdingFail(error));
    yield call(action.payload.failCallBack, error);
  }
}
export function* editShareholdingSaga(action) {
  try {
    const response = yield call(editShareholdingApi, action.payload);
    yield put(actions.editShareholdingSuccess(response));
  } catch (error) {
    yield put(actions.editShareholdingFail(error));
  }
}

export function* updateShareholdingSaga(action) {
  try {
    const response = yield call(updateShareholdingApi, action?.payload?.payload);
    if (response) {
      yield put(actions.updateShareholdingSuccess(response));
      yield call(action.payload.successCallBack, response);
    }
  } catch (error) {
    yield put(actions.updateShareholdingFail(error));
    yield call(action.payload.failCallBack, error);
  }
}

export function* deleteShareholdingSaga(action) {
  try {
    const response = yield call(deleteShareholdingApi, action?.payload?.payload);
    yield put(actions.deleteShareholdingSuccess(response));
    yield call(action.payload.successCallBack, response);
  } catch (error) {
    yield put(actions.deleteShareholdingFail(error));
    yield call(action.payload.failCallBack, error);
  }
}

import MDBox from "components/MDBox";
import MDModal from "components/MDModal";
import React from "react";

export default function CountryModal({ open, setOpen, title, children }) {
  return (
    <MDModal sx={6} title={title} open={open} setOpen={setOpen}>
      {children}
    </MDModal>
  );
}

import { instance } from "../../utils/Axios";

export const getMissingCompaniesApi = (data = {}) => {
  //console.log("missi inside api", data)
  return instance
    .get(`/missingCompanies?page=${data!==undefined && data.data!==undefined && data.data.page !== undefined? data.data.page : 1}`)
    .then((response) => {
      return response;
    });
};
export const addMissingCompanyApi = (data) => {
  return instance.post("/missingCompanies", data).then((response) => {
    return response;
  });
};

export const editMissingCompanyApi = (idOrSlug) => {
  return instance.get(`/missingCompanies/${idOrSlug}`).then((response) => {
    return response;
  });
};

export const updateMissingCompanyApi = (data) => {
  return instance.put(`/missingCompanies/${data.id}`, data).then((response) => {
    return response;
  });
};

export const deleteMissingCompanyApi = (data) => {
  return instance.delete(`/missingCompanies/${data.id}`).then((response) => {
    return response;
  });
};


export const uploadMissingCompanyApi = (data) => {
  return instance
    .post("/missingCompanies/import-excel", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response;
    });
};

export const getRapportApi = (data) => {
  const token = "Bearer "+localStorage.getItem("access_token"); // Replace 'your_token_here' with your actual token
  return instance
     .get(
       `/pdf/${data}`,
       {
         headers: {
           'Authorization': `Bearer ${token}`
         }
       }
     )
     .then((response) => {
        return response.blob();
     }).then(blob=>{
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.pdf'); // You can specify the file name here
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
     });
 };

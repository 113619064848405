import React, { useEffect } from "react";
import MDBox from "components/MDBox";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/state/CompteDeResultatBilansAction";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import { Box, Icon, IconButton } from "@mui/material";
import MDButton from "components/MDButton";
import * as modalActions from "../modalActionTypes";

function formatNumberWithSpaces(input) {
  if (isNaN(input)) {
     return input;
  }
  const roundedNum = Math.round(input);
  const numStr = roundedNum.toString();
  const formattedStr = numStr.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return formattedStr;
 }
 

export default function Data(setOpen, setCompteDeResultatBilanId, setAction, companyId) {
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(companyId);
    let queryParam = {};
    if (companyId.toString().length > 0 && companyId !== undefined) {
      queryParam = { companyId: companyId, sort: "date", order: "desc" };
    }
    dispatch(actions.getCompteDeResultatBilans(queryParam));
    console.log(queryParam)
  }, [companyId]);

  const handleOpenDelete = (id) => {
    setOpen(true);
    setCompteDeResultatBilanId(id);
    setAction(modalActions.DELETE);
  };
  const handleOpenEdit = (id) => {
    // console.log("edit id", id);
    setOpen(true);
    setCompteDeResultatBilanId(id);
    setAction(modalActions.UPDATE);
  };

  const compteDeResultatBilans = useSelector(
    (state) => state?.compteDeResultatBilans?.compteDeResultatBilans
  );


  const usersRows = compteDeResultatBilans && compteDeResultatBilans?.data?.map((compteDeResultatBilan, i) => ({
    achat_mat_prem_approv: formatNumberWithSpaces(compteDeResultatBilan?.achat_mat_prem_approv),
    salaries_traitement: formatNumberWithSpaces(compteDeResultatBilan?.salaries_traitement),
    depreciation_ammortissement: formatNumberWithSpaces(compteDeResultatBilan?.depreciation_ammortissement),
    autre_element_exploitation: formatNumberWithSpaces(compteDeResultatBilan?.autre_element_exploitation),
    capacite_autofin_avant_rep: formatNumberWithSpaces(compteDeResultatBilan?.capacite_autofin_avant_rep),
    resultat_exploitation: formatNumberWithSpaces(compteDeResultatBilan?.capacite_autofin_avant_rep),
    ebitda: formatNumberWithSpaces(compteDeResultatBilan?.ebitda),
    date: compteDeResultatBilan?.date,
    company: compteDeResultatBilan?.company?.raison_social,

    action: (
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box>
          <IconButton
            color="info"
            onClick={() => handleOpenEdit(compteDeResultatBilan.id)}
            component="label"
          >
            <Icon color="info" fontSize="small">
              mode
            </Icon>
          </IconButton>
        </Box>
        <Box>
          <IconButton
            color="primary"
            onClick={() => handleOpenDelete(compteDeResultatBilan.id)}
            component="label"
          >
            <Icon color="primary" fontSize="small">
              delete
            </Icon>
          </IconButton>
        </Box>
      </Box>
    ),
}));

  return {
    columns: [
      // { Header: "Pays", accessor: "country", align: "left" },
      { Header: "date", accessor: "date", align: "left" },
      { Header: "Entreprise", accessor: "company", align: "left" },
      {
        Header: "Achat mat prem approv",
        accessor: "achat_mat_prem_approv",
        align: "right",
      },
      { Header: "chiffre d'affaires net", accessor: "salaries_traitement", align: "right" },
      {
        Header: "Depreciation ammortissement",
        accessor: "depreciation_ammortissement",
        align: "right",
      },
      {
        Header: "Autre element exploitation",
        accessor: "autre_element_exploitation",
        align: "right",
      },
      {
        Header: "Capacite autofin avant rep",
        accessor: "capacite_autofin_avant_rep",
        align: "right",
      },
      { Header: "EBITDA", accessor: "ebitda", align: "right" },
      { Header: "Action", accessor: "action", align: "center" },
    ],

    rows: usersRows,
  };
}

import { useEffect } from "react";
import * as ActionsTypes from "../../../../../../common/state/StatesConstants";

const initialStateInformationCompanies = {
  error: null,
  isLoading: false,
  informationCompanies: [],
  informationCompany: {},
};

// const initialStateInformationCompany = {
//   error: null,
//   isLoading: false,
//   informationCompany: {},
// };

const informationCompaniesReducer = (
  stateInformationCompanies = initialStateInformationCompanies,
  // stateInformationCompany = initialStateInformationCompany,
  action
) => {
  switch (action.type) {
    // ##-----------GET_INFORMATION_COMPANYS-----------##
    // case ActionsTypes.GET_INFORMATION_COMPANYS:
    //   return {
    //     ...stateInformationCompanies,
    //     isLoading: true,
    //     error: null,
    //     informationCompanies: [],
    //   };

    // case ActionsTypes.GET_INFORMATION_COMPANYS_SUCCESS:
    //   return {
    //     ...stateInformationCompanies,
    //     isLoading: false,
    //     error: null,
    //     informationCompanies: action.response.data.data,
    //   };

    // case ActionsTypes.GET_INFORMATION_COMPANYS_FAIL:
    //   return {
    //     ...stateInformationCompanies,
    //     isLoading: false,
    //     error: action.error,
    //     informationCompanies: [],
    //   };
    // ##-----------END_GET_INFORMATION_COMPANYS-----------##

    // ##-----------ADD_INFORMATION_COMPANY-----------##
    case ActionsTypes.ADD_INFORMATION_COMPANY:
      return {
        ...stateInformationCompanies,
        isLoading: true,
        error: null,
        informationCompanies: [...stateInformationCompanies.informationCompanies],
      };

    case ActionsTypes.ADD_INFORMATION_COMPANY_SUCCESS:
      return {
        ...stateInformationCompanies,
        isLoading: false,
        error: null,
        informationCompanies: [
          action.response.data.data,
          ...stateInformationCompanies.informationCompanies,
        ],
      };

    case ActionsTypes.ADD_INFORMATION_COMPANY_FAIL:
      return {
        ...stateInformationCompanies,
        isLoading: false,
        error: action.error,
        informationCompanies: [...stateInformationCompanies.informationCompanies],
      };
    // ##-----------END_ADD_INFORMATION_COMPANY-----------##

    // ##-----------EDIT_INFORMATION_COMPANY-----------##
    case ActionsTypes.EDIT_INFORMATION_COMPANY:
      return {
        ...stateInformationCompanies,
        isLoading: true,
        error: null,
        informationCompany: {},
      };

    case ActionsTypes.EDIT_INFORMATION_COMPANY_SUCCESS:
      return {
        ...stateInformationCompanies,
        isLoading: false,
        error: null,
        informationCompany: action.response.data.data,
      };

    case ActionsTypes.EDIT_INFORMATION_COMPANY_FAIL:
      return {
        ...stateInformationCompanies,
        isLoading: false,
        error: action.error,
        informationCompany: {},
      };
    // ##-----------END_EDIT_INFORMATION_COMPANY-----------##

    // ##-----------UPDATE_INFORMATION_COMPANY-----------##
    // case ActionsTypes.UPDATE_INFORMATION_COMPANY:
    //   return {
    //     ...stateInformationCompanies,
    //     isLoading: true,
    //     error: null,
    //     informationCompanies: [...stateInformationCompanies.informationCompanies],
    //   };

    // case ActionsTypes.UPDATE_INFORMATION_COMPANY_SUCCESS:
    //   return {
    //     ...stateInformationCompanies,
    //     isLoading: false,
    //     error: null,
    //     informationCompanies: [
    //       action.response.data.data,
    //       ...stateInformationCompanies.informationCompanies.filter(
    //         (informationCompany) => informationCompany.id != action.response.data.data.id
    //       ),
    //     ],
    //   };

    // case ActionsTypes.UPDATE_INFORMATION_COMPANY_FAIL:
    //   return {
    //     ...stateInformationCompanies,
    //     isLoading: false,
    //     error: action.error,
    //     informationCompanies: [...stateInformationCompanies.informationCompanies],
    //   };
    // ##-----------END_UPDATE_INFORMATION_COMPANY-----------##

    // ##-----------DELETE_INFORMATION_COMPANY-----------##
    // case ActionsTypes.DELETE_INFORMATION_COMPANY:
    //   return {
    //     ...stateInformationCompanies,
    //     isLoading: true,
    //     error: null,
    //     informationCompanies: [...stateInformationCompanies.informationCompanies],
    //   };

    // case ActionsTypes.DELETE_INFORMATION_COMPANY_SUCCESS:
    //   return {
    //     ...stateInformationCompanies,
    //     isLoading: false,
    //     error: null,
    //     informationCompanies: [
    //       ...stateInformationCompanies.informationCompanies.filter(
    //         (informationCompany) => informationCompany.id != action.response.data.deleted
    //       ),
    //     ],
    //     // informationCompanies: [...stateInformationCompanies.informationCompanies],
    //   };

    // case ActionsTypes.DELETE_INFORMATION_COMPANY_FAIL:
    //   return {
    //     ...stateInformationCompanies,
    //     isLoading: false,
    //     error: action.error,
    //     informationCompanies: [...stateInformationCompanies.informationCompanies],
    //   };
    // ##-----------END_DELETE_INFORMATION_COMPANY-----------##

    default:
      return stateInformationCompanies;
  }
};

export default informationCompaniesReducer;

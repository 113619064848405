import { Box, Card, Grid, Icon, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import React from "react";
import ActifBilanTable from "./components/ActifBilanTable";
import CompteDeResultatBilanTable from "./components/CompteDeResultatBilanTable";
import CompteResultatBilanTable from "./components/CompteResultatBilanTable";
import MemoBilanTable from "./components/MemoBilanTable";
import PassifBilanTable from "./components/PassifBilanTable";
import actifBilansTableData from "./data/actifBilansTableData";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link, useParams } from "react-router-dom";

function Bilans({ companyId }) {
  const media600 = useMediaQuery("(min-width:600px)");
  const { companySlug } = useParams();

  return (
    <MDBox p={1}>
      <Grid container>
        <Grid item xs={6} sm={6} md={6}>
          <Typography component="div" pb={2} variant="h4" align="left">
            Bilans
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Box sx={{ textAlign: "right" }}>
            <Link
              to="/bilans"
              state={{
                id: companyId,
                slug: companySlug,
              }}
            >
              <MDButton p={0} type="submit" color="primary">
                <Icon fontSize="small">settings-icon</Icon>
                {media600 ? (
                  <Typography variant="p" pl={1}>
                    Paramétrage
                  </Typography>
                ) : (
                  ""
                )}
              </MDButton>
            </Link>
          </Box>
        </Grid>
      </Grid>

      <ActifBilanTable />
      <PassifBilanTable />
      <CompteResultatBilanTable />
      <MemoBilanTable />
      <CompteDeResultatBilanTable />
    </MDBox>
  );
}

export default Bilans;

import {
  Alert,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addCountryProfileSchema } from "../schema";
import * as actions from "../redux/state/CountryProfilesAction";
import { getErrorMessage } from "common/utils/Helper";
// import * as actions from "../redux/state/CountryProfileAction";

export default function FormAddCountryProfile({ setOpen, countryId, countrySlug }) {
  const dispatch = useDispatch();

  const [errorSB, setErrorSB] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const data = useSelector((state) => state?.countryProfiles?.countryProfiles);

  const onSubmit = (values) => {
    const request = {
      payload: values,
      successCallBack: (response) => {
        closeErrorSB()
        dispatch(actions.getCountryProfiles({ countrySlug: countrySlug }));
        setOpen(false);
        console.log("response", response);
      },
      failCallBack: (error) => {
        console.log("error", error);
        setErrorMessage(getErrorMessage(error))
        openErrorSB();
      },
    };
    dispatch(actions.addCountryProfile(request));
  };

  const { handleSubmit, errors, values, handleChange } = useFormik({
    initialValues: {
      year: "",
      population: "",
      pib_taux_fix: "",
      pib_taux_crois: "",
      pib_tete: "",
      taux_inflation: "",
      equ_budgetaire: "",
      dette_publique: "",
      chomage: "",
      balance_paiement_courant: "",
      balance_comerciale: "",
      reserve_internationale: "",
      country_id: countryId,
    },
    onSubmit,
    validationSchema: addCountryProfileSchema,
  });
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <MDBox m={1}>
      {errorSB && (
        <Alert severity="error">
          {errorMessage}
        </Alert>
      )}
      <form onSubmit={handleSubmit}>
        <MDBox py={2}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="year"
                  label="Annee"
                  error={errors.year ? true : false}
                  onChange={handleChange}
                  value={values.year ?? ""}
                  helperText={errors?.year ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="population"
                  label="Population"
                  error={errors.population ? true : false}
                  onChange={handleChange}
                  value={values.population.replace(",", ".") ?? ""}
                  helperText={errors?.population ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="pib_taux_fix"
                  label="PIB a taux fix"
                  error={errors.pib_taux_fix ? true : false}
                  onChange={handleChange}
                  value={values.pib_taux_fix.replace(",", ".") ?? ""}
                  helperText={errors?.pib_taux_fix ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="pib_taux_crois"
                  label="Taux de crois reel de PIB"
                  error={errors.pib_taux_crois ? true : false}
                  onChange={handleChange}
                  value={values.pib_taux_crois.replace(",", ".") ?? ""}
                  helperText={errors?.pib_taux_crois ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="pib_tete"
                  label="PIB par tete"
                  error={errors.pib_tete ? true : false}
                  onChange={handleChange}
                  value={values.pib_tete.replace(",", ".") ?? ""}
                  helperText={errors?.pib_tete ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="taux_inflation"
                  label="Taux d'inflation"
                  error={errors.taux_inflation ? true : false}
                  onChange={handleChange}
                  value={values.taux_inflation.replace(",", ".") ?? ""}
                  helperText={errors?.taux_inflation ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="equ_budgetaire"
                  label="Equ budgetaire"
                  error={errors.equ_budgetaire ? true : false}
                  onChange={handleChange}
                  value={values.equ_budgetaire.replace(",", ".") ?? ""}
                  helperText={errors?.equ_budgetaire ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="dette_publique"
                  label="Dette publique"
                  error={errors.dette_publique ? true : false}
                  onChange={handleChange}
                  value={values.dette_publique.replace(",", ".") ?? ""}
                  helperText={errors?.dette_publique ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="chomage"
                  label="Chomage"
                  error={errors.chomage ? true : false}
                  onChange={handleChange}
                  value={values.chomage.replace(",", ".") ?? ""}
                  helperText={errors?.chomage ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="balance_paiement_courant"
                  label="Balance des paiements courants"
                  error={errors.balance_paiement_courant ? true : false}
                  onChange={handleChange}
                  value={values.balance_paiement_courant.replace(",", ".") ?? ""}
                  helperText={errors?.balance_paiement_courant ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="balance_comerciale"
                  label="Balance commerciale"
                  error={errors.balance_comerciale ? true : false}
                  onChange={handleChange}
                  value={values.balance_comerciale.replace(",", ".") ?? ""}
                  helperText={errors?.balance_comerciale ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MDInput
                  name="reserve_internationale"
                  label="Reserves internationales"
                  error={errors.reserve_internationale ? true : false}
                  onChange={handleChange}
                  value={values.reserve_internationale.replace(",", ".") ?? ""}
                  helperText={errors?.reserve_internationale ?? ""}
                />
              </FormControl>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox sx={{ display: "flex" }}>
          <MDBox mr={1} sx={{ flexGrow: "1" }}>
            <MDButton onClick={handleClose} type="submit" color="primary" fullWidth>
              Annuler
            </MDButton>
          </MDBox>
          <MDBox sx={{ flexGrow: "1" }}>
            <MDButton type="submit" color="info" fullWidth>
              Ajouter
            </MDButton>
          </MDBox>
        </MDBox>
      </form>
    </MDBox>
  );
}
